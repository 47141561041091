import { React, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import Button from "../../../../components/ui/Button";

import InputBox from "../../../../components/ui/InputBox";

import { useSelector, useDispatch } from 'react-redux';
import { updateProfileDetails } from '../../../../store/actions/settingsAction.js';

const UpdatePasswordModal = ({show, onHide, loginDetais}) => {

    const updateProfileDetailsResponse = useSelector((state) => state.settings?.updateProfileDetailsResponse)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const inputChange = (val) => {
        setPassword(val)
    }

    const updateLoginDetails = () => {
        loginDetais.current_password = password
        setIsLoading(true)
        dispatch(updateProfileDetails(loginDetais));
    }

    useEffect(()=>{
        if(updateProfileDetailsResponse != null) {
            setPassword("")
            setIsLoading(false);
        }
    },[updateProfileDetailsResponse, dispatch])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            >
                <Modal.Body>
                    <div className="setup-2fa-container p-3">
                        <h4>{t("accountSettingPage.updateDetailsModal.heading")}</h4>
                        <p>{t("accountSettingPage.updateDetailsModal.desc")}</p>
                        <div className="my-4">
                            <div className="mt-3 w-100">
                                <InputBox placeholder={'Current Password'} inputType={'password'} inputLabel={'Current Password'} inputChange={inputChange} inputValue={password}/>
                            </div>
                        </div>
                        <div className="d-flex gap-3">
                            <Button buttonText={"Cancel"} buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText={"Save"} buttonType={"primary"} buttonClicked={updateLoginDetails} disableButton={password === ""}/>}
                            {isLoading && <Button buttonText={"Loading..."} buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
            
        </Modal>
    )
}

export default UpdatePasswordModal