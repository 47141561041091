import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import arrowRight from "../../../../assets/images/common/arrow-right-border.svg";
import pause from "../../../../assets/images/domain/pause.svg";
import trash from "../../../../assets/images/common/trash.svg";
import close from "../../../../assets/images/common/close.svg";
import './index.scss';

const ScanProgressModal = ({ show, onHide, scanningDomainId, pausedDomainId, handlePauseResume }) => {
    const { t } = useTranslation();
    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="custom-modal-top"
            backdrop={false}
            style={{ marginTop: '25px' }} // Inline styling for quick adjustments
        >
            <Modal.Body>
               
               <button onClick={onHide} className="close-button">
                    <img src={close} alt="close" />
                </button> 
                <p>{t("domainPage.scanProgress")}: {scanningDomainId === 1 ? (pausedDomainId === 1 ? "Paused (24%)" : "24%") : "Yet To Start"}</p>
                <div
                    className={`${scanningDomainId === 1 ? "scan-progress" : "mt-3"}`}
                    style={{ display: 'flex', alignItems: 'center', gap: '5px' }} // Adjust gap as needed
                >
                    <ProgressBar
                        now={scanningDomainId === 1 ? 25 : 0}
                        style={{ height: '8px', width: '100%' }}
                        className={`custom-progress-bar ${pausedDomainId === 1 ? 'paused-progress' : ''}`}
                    />
                    {scanningDomainId === 1 && (
                        <>
                            <button className="view-details-btn-scan" onClick={() => handlePauseResume(1)}>
                                <img src={pausedDomainId === 1 ? arrowRight : pause} alt={pausedDomainId === 1 ? "resume" : "pause"} />
                            </button>
                            <button
                                className={`view-details-btn-trash ${pausedDomainId === 1 ? "" : "disabled-btn-grey"}`}
                                disabled={pausedDomainId !== 1}
                            >
                                <img src={trash} alt="trash" />
                            </button>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ScanProgressModal;
