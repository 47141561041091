import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import "./index.scss";


import SearchIcon from "../../../../assets/images/common/search-icon.svg";
import FilterIcon from "../../../../assets/images/common/filter-icon.svg";

import Pagination from "../../../../components/ui/Pagination";
import NoDataFound from "../../../../components/ui/NoDataFound";
import Spinner from "../../../../components/ui/spinner";

import { useScanInfoContext } from "../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { geCERStats, getCERData } from "../../../../store/actions/scanInfoAction";

import apiEndpoints from "../../../../apiEndpoints";


const CorporateEmailRisk = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const cerStatsResponse = useSelector((state) => state.scanInfo?.cerStats);
    const cerDataListResponse = useSelector((state) => state.scanInfo?.cerDataList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeTab, setActiveTab] = useState("dmarcChecklist");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const changeTab = (data) => {
        setActiveTab(data);
        setCurrentPageSize(10);
        setCurrentPage(1);
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchRecordData = useCallback(()=>{
        // setIsLoading(true)
        let url = `&domain=${domainId}&scan_id=${scanId}`
        dispatch(geCERStats(url))
    },[dispatch, domainId, scanId])

    const fetchCERdataList = useCallback(() => {
        setIsLoading(true)
        if(activeTab === "dmarcChecklist") {
            let url = `${apiEndpoints?.scanInfo?.exposureManagemnet?.corporateEmailRisk?.dmarcCheckList()}&domain=${domainId}&scan_id=${scanId}&page=${currentPage}&page_size=${currentPageSize}`;
            dispatch(getCERData(url))
        } else if(activeTab === "spfChecklist") {
            let url = `${apiEndpoints?.scanInfo?.exposureManagemnet?.corporateEmailRisk?.spfCheckList()}&domain=${domainId}&scan_id=${scanId}&page=${currentPage}&page_size=${currentPageSize}`;
            dispatch(getCERData(url))
        } else if(activeTab === "dkimChecklist") {
            let url = `${apiEndpoints?.scanInfo?.exposureManagemnet?.corporateEmailRisk?.dkimCheckList()}&domain=${domainId}&scan_id=${scanId}&page=${currentPage}&page_size=${currentPageSize}`;
            dispatch(getCERData(url))
        } else {
            let url = `${apiEndpoints?.scanInfo?.exposureManagemnet?.corporateEmailRisk?.bimiCheckList()}&domain=${domainId}&scan_id=${scanId}&page=${currentPage}&page_size=${currentPageSize}`;
            dispatch(getCERData(url))
        }
    },[activeTab, domainId, scanId, currentPage, currentPageSize, dispatch])

    useEffect(() => {
        fetchCERdataList();
    }, [activeTab, fetchCERdataList]);

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchRecordData();
                fetchCERdataList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchRecordData, fetchCERdataList]);

    useEffect(()=>{
        if(cerStatsResponse !== null) {
            setIsLoading(false)
        }
    },[cerStatsResponse])

    useEffect(() => {
        console.log("cerDataListResponse", cerDataListResponse)
        if(cerDataListResponse !== null) {
            setIsLoading(false)
        }
    },[cerDataListResponse])

    return (
        <>
            <div className="scan-info-main-content CER-main-content">
                <div className="bg-white-with-shadow p-4">
                    <div className="header-container d-flex justify-content-between align-items-center">
                        <h2>BEC</h2>
                        {/* <p>Service Provider : <span>ProofPoint</span></p> */}
                    </div>
                    <Row>
                        <Col lg={3} className="mt-2">
                            <div className="bec-main-container p-3 h-100">
                                <div>
                                    <h4>DMARC</h4>
                                    <p>Domain-based Message Authentication, Reporting and Conformance</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="passed text-center">
                                        <p className="count">{cerStatsResponse?.dmarc?.passed}%</p>
                                        <p className="text">Passed</p>
                                    </div>
                                    <div className="warning text-center">
                                        <p className="count">{cerStatsResponse?.dmarc?.warning}%</p>
                                        <p className="text">Warning</p>
                                    </div>
                                    <div className="failed text-center">
                                        <p className="count">{cerStatsResponse?.dmarc?.failed}%</p>
                                        <p className="text">Failed</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} className="mt-2">
                            <div className="bec-main-container p-3 h-100">
                                <div>
                                    <h4>SPF</h4>
                                    <p>Sender Policy Framework</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="passed text-center">
                                        <p className="count">{cerStatsResponse?.dmarc?.passed}%</p>
                                        <p className="text">Passed</p>
                                    </div>
                                    <div className="warning text-center">
                                        <p className="count">{cerStatsResponse?.dmarc?.warning}%</p>
                                        <p className="text">Warning</p>
                                    </div>
                                    <div className="failed text-center">
                                        <p className="count">{cerStatsResponse?.dmarc?.failed}%</p>
                                        <p className="text">Failed</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} className="mt-2">
                            <div className="bec-main-container p-3 h-100">
                                <div>
                                    <h4>DKIM</h4>
                                    <p>Domain Key Identified Mail</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="passed text-center">
                                        <p className="count">{cerStatsResponse?.dkim?.passed}%</p>
                                        <p className="text">Passed</p>
                                    </div>
                                    <div className="warning text-center">
                                        <p className="count">{cerStatsResponse?.dkim?.warning}%</p>
                                        <p className="text">Warning</p>
                                    </div>
                                    <div className="failed text-center">
                                        <p className="count">{cerStatsResponse?.dkim?.failed}%</p>
                                        <p className="text">Failed</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} className="mt-2">
                            <div className="bec-main-container p-3 h-100">
                                <div>
                                    <h4>BIMI</h4>
                                    <p>Brand Indicators for Message Identification</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="passed text-center">
                                        <p className="count">{cerStatsResponse?.bimi?.passed}%</p>
                                        <p className="text">Passed</p>
                                    </div>
                                    <div className="warning text-center">
                                        <p className="count">{cerStatsResponse?.bimi?.warning}%</p>
                                        <p className="text">Warning</p>
                                    </div>
                                    <div className="failed text-center">
                                        <p className="count">{cerStatsResponse?.bimi?.failed}%</p>
                                        <p className="text">Failed</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <Row>
                        <Col lg={12}>
                            <div className="bg-white-with-shadow sub-domain-main-container p-3 h-100">
                                <div className="tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "dmarcChecklist" ? "active" : ""}`} onClick={()=>{changeTab('dmarcChecklist')}}>DMARC Checklist</p>
                                    <p className={`rounded text-center ${activeTab === "spfChecklist" ? "active" : ""}`} onClick={()=>{changeTab('spfChecklist')}}>SPF Checklist</p>
                                    <p className={`rounded text-center ${activeTab === "dkimChecklist" ? "active" : ""}`} onClick={()=>{changeTab('dkimChecklist')}}>DKIM Checklist</p>
                                    <p className={`rounded text-center ${activeTab === "bimiChecklist" ? "active" : ""}`} onClick={()=>{changeTab('bimiChecklist')}}>BIMI Checklist</p>
                                </div>
                                <div className="tab-details-content mt-3 tab-content-CER">
                                    <div className="search-header-container d-flex align-items-center gap-3">
                                        <div className="search-container position-relative">
                                            <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                            <input type="text" placeholder="Search" />
                                        </div>
                                        <button className="search-btn">Search</button>
                                        <img src={FilterIcon} alt="filter" className="filtericon"/>
                                    </div>
                                    <div className="table-container mt-3 cer-data-table">
                                        {
                                            isLoading ?
                                            <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                                                <Spinner />
                                            </div>
                                            :
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Info</th>
                                                        <th>Status</th>
                                                        <th>Record</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        cerDataListResponse?.results.length > 0 &&
                                                        cerDataListResponse?.results.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {data?.name}
                                                                    </td>
                                                                    <td>
                                                                        {data?.info}
                                                                    </td>
                                                                    <td>
                                                                        {data?.status === "passed" && <p className="passed text-center">{data?.status}</p>}
                                                                        {data?.status === "warning" && <p className="warning text-center">{data?.status}</p>}
                                                                        {data?.status === "failed" && <p className="failed text-center">{data?.status}</p>}
                                                                    </td>
                                                                    <td>
                                                                        {data?.record}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        }
                                        
                                    </div>
                                    {
                                        !isLoading && cerDataListResponse !== "" && cerDataListResponse?.results.length === 0 && 
                                        <NoDataFound noDataText={"No Record Found"}/>
                                    }
                                    {cerDataListResponse !== "" && cerDataListResponse?.results.length > 0 && <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        pageSize={currentPageSize}
                                        totalCount={cerDataListResponse?.meta?.total_results}
                                        onPageChange={setCurrentPageandRecordSize}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        
    )
}


export default CorporateEmailRisk;