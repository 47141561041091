import { React, useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";


import SideModal from '../../components/ui/SideModal';
import Pagination from "../../components/ui/Pagination";
import Spinner from "../../components/ui/spinner";
import NoDataFound from "../../components/ui/NoDataFound";

import SideNav from "../../components/PostLogin/SideNav";
import MonitorTileView from "../../components/PostLogin/Monitor/MonitorTileView";
import MonitorListView from "../../components/PostLogin/Monitor/MonitorListView";
import AddMonitorModal from '../../components/PostLogin/Monitor/AddMonitorModal';

import { useSelector, useDispatch } from 'react-redux';
import { getMonitorList, resetDeleteMonitorResponse, getMonitorStats } from '../../store/actions/monitorAction';

const Monitor = () => {

    const monitorStatsResponse = useSelector((state) => state.monitor?.monitorStatsResponse)
    const monitorListResponse = useSelector((state) => state.monitor?.monitorList);
    const deleteMonitorResponse = useSelector((state) => state.monitor?.deleteMonitorResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [viewType, setViewType] = useState('tile');
    const [showAddMonitorModal, setShowAddMonitorModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddMonitorModal(false)
    }

    const backtoMainPage = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            setCurrentPage(1);
            setCurrentPageSize(10);
            fetchMonitorList();
            fetchMonitorStats();
        }
        closeAddOrgSidePanel()
    }

    const searchMonitor = (e) => {
        setSearchValue(e.target.value)
        setCurrentPage(1)
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchMonitorList = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${searchValue}`;
        setIsLoading(true);
        dispatch(getMonitorList(url));
    },[searchValue, currentPage, currentPageSize, dispatch])

    const fetchMonitorStats = useCallback(()=>{
        setIsStatsLoading(true);
        dispatch(getMonitorStats());
    },[dispatch])

    useEffect(() => {
        fetchMonitorList();
        fetchMonitorStats();
    },[fetchMonitorList, fetchMonitorStats])

    useEffect(() => {
        if(monitorListResponse !== null) {
            setIsLoading(false);
        }
    },[monitorListResponse])

    useEffect(()=>{
        if(deleteMonitorResponse !== null) {
            if(deleteMonitorResponse?.detail.status === "success") { 
                fetchMonitorList();
                fetchMonitorStats();
            }
            dispatch(resetDeleteMonitorResponse())
        }
    },[deleteMonitorResponse, fetchMonitorList, fetchMonitorStats, dispatch]);

    useEffect(()=>{
        if(monitorStatsResponse !== null) {
            setIsStatsLoading(false);
        }
    },[monitorStatsResponse])


    return (
        <div className="users-container">
            <SideNav />
            <div className="monitor-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("monitor.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={() => { setShowAddMonitorModal(true) }}>{t("monitor.addMonitor")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search Monitor' className="search-input" value={searchValue} onChange={searchMonitor}/>
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={()=>setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={()=>setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    <div className="users-header-container p-3 my-3">
                        <div className="d-flex gap-3">
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">{isStatsLoading ? '....' : monitorStatsResponse?.monitors?.total }</p>
                                    <div className="user-count mx-3 mt-2">
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"> {t("monitor.domainScan")}</p>
                                </div>
                            </div>
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">20</p>
                                    <div className="user-count mx-3 mt-2">
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"> {t("monitor.autoScan")}</p>
                                </div>
                            </div>
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">{isStatsLoading ? '....' : monitorStatsResponse?.monitors?.weekly}</p>
                                    <div className="user-count mx-3 mt-2">
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"> {t("monitor.weeklyScan")}</p>
                                </div>
                            </div>
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">{isStatsLoading ? '....' : monitorStatsResponse?.monitors?.monthly}</p>
                                    <div className="user-count mx-3 mt-2">
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"> {t("monitor.monthlyScan")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="secondary-heading my-4">{t("monitor.selectaDomain")}</h2>
                    {
                        isLoading ? 
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                        :
                        <>
                            {viewType === "tile" && <MonitorTileView monitorList={monitorListResponse} />}
                            {viewType === "list" && <MonitorListView monitorList={monitorListResponse} />}
                            {monitorListResponse != null && monitorListResponse?.results.length > 0 && <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                pageSize={currentPageSize}
                                totalCount={monitorListResponse.meta.total_results}
                                onPageChange={setCurrentPageandRecordSize}
                            />}
                        </>
                    }
                    {
                        !isLoading && monitorListResponse?.meta?.total_results === 0 && 
                        <NoDataFound />
                    }                    
                </div>
            </div>
            <SideModal showSidePanel={showAddMonitorModal} hideSidePanel={closeAddOrgSidePanel}>
                <AddMonitorModal closeAddmonitorModal={backtoMainPage}/>
            </SideModal>
        </div>
    )
}

export default Monitor;