import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';

import Button from "../../../ui/Button";
import { toaster } from "../../../ui/Toaster";

import { useSelector, useDispatch } from 'react-redux';
import { cancelSubscription } from '../../../../store/actions/customerAction.js';

const CancelSubscriptionModal = ({show, onHide, customerDetails}) => {

    const cancelSubscriptionResponse = useSelector((state)=> state.customer.cancelSubscriptionResponse)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const confirmCancel = () => {
        let obj = {
            subscription_id: customerDetails?.subscription?.subscription_id
        }
        setIsLoading(true)
        dispatch(cancelSubscription(obj))
    }

    useEffect(()=>{
        if(cancelSubscriptionResponse !== null){
            if(cancelSubscriptionResponse?.detail?.status === "success") {
                toaster.addToast(cancelSubscriptionResponse?.detail?.description, "success")                
            } else {
                toaster.addToast(cancelSubscriptionResponse?.description, "error")
            }
            setIsLoading(false)
            onHide()
        }
    },[cancelSubscriptionResponse, onHide])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            className=""
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("customerDetails.cancelSubscriptionModal.heading")}</h4>
                        <p className="my-5">{t("customerDetails.cancelSubscriptionModal.description")}</p>
                        <div className="d-flex gap-3 m-2 mt-4">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide} />
                            {!isLoading && <Button buttonText="Confirm" buttonType={"primary"} buttonClicked={confirmCancel}/>}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default CancelSubscriptionModal;