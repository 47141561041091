import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";


import ContentBox from "../../../../../../../components/ui/ContentBox";


const SubDomainInformation = ({selectedSubdomain}) => {
    return (
        <>
            {/* <div className="hacker-score-container p-3 rounded">
                <p className="hacker-score">846<span>/900</span></p>
                <p className="hacker-text">Latest Hacker Score</p>
            </div> */}
            <div className="subdomain-info-container">
                <Row className="mt-3">
                    <Col lg={6}>
                        <ContentBox label={"Subdomain"} value={selectedSubdomain?.subdomain}/>
                    </Col>
                    <Col lg={6}>
                        <ContentBox label={"Webserver"} value={selectedSubdomain?.webserver}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={6}>
                        <div className="content-box-container p-3 rounded http-status">
                            <h4 className="label">HTTP Status</h4>
                            <p className={`value ${selectedSubdomain?.http_status?.http ? "active" : "inactive"}`}>{selectedSubdomain?.http_status?.http ? "Active" :  "Inactive"}</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="content-box-container p-3 rounded http-status">
                            <h4 className="label">HTTPS Status</h4>
                            <p className={`value ${selectedSubdomain?.http_status?.https ? "active" : "inactive"}`}>{selectedSubdomain?.http_status?.https ? "Active" :  "Inactive"}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={6}>
                        <ContentBox label={"Type"} value={selectedSubdomain?.subdomain_type}/>
                    </Col>
                    <Col lg={6}>
                        <ContentBox label={"Force HTTPS"} value={selectedSubdomain?.forced_https}/>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SubDomainInformation;