import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

// import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";
// import filterIcon from "../../../../../assets/images/common/filter-icon.svg";
// import SearchIcon from "../../../../../assets/images/common/search-icon.svg";

// import ScanInfo from "../../../../ScanInfo";
// import ProfileAvatar from "../../../../../components/ui/ProfileAvatar";

// import SubDomainInformation from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/SubDomain/SubDomainInformation";
// import DNSRecordInformation from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/SubDomain/DNSRecordInformation";
import move from "../../../../../assets/images/common/fi_move.svg";

const BrandInsights = () => {

    const [activeTab, setActiveTab] = useState("subDomainInformation")


    return (

        <div className="brand-main-content mt-4">

            <Row>
                <Col lg={4}>
                    <div className="bg-white-with-shadow p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <p className="heading">Brand Insights</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>
                        <div className="brand-container rounded p-3">
                            <div className="header-content d-flex justify-content-between">
                                <p className="heading mr-3">Similar Domains</p>
                            </div>
                            <div className="d-flex gap-3">
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-score-count">14</p>
                                        <div className="score-count mx-3">
                                            <span className="new-record d-inline-block mx-1"></span>
                                            <span className="existing-record d-inline-block"></span>
                                            <p>Total </p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex gap-3">
                                        <p className="new-record"><span className="d-inline-block">17</span> New</p>
                                        <p className="existing-record"><span className="d-inline-block">16</span> Existing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={8}>
                    <div className="bg-white-with-shadow p-4">
                        <div className="d-flex justify-content-between mb-3">
                            <p className="heading">Infrastructure risks</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>
                        <Row>
                            <Col lg={6}>

                                <div className="brand-container rounded p-3">
                                    <div className="header-content d-flex justify-content-between">
                                        <p className="heading">Risky Ports</p>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div className="total-score-container rounded">
                                            <div className="users-totol-count d-flex">
                                                <p className="total-score-count">14</p>
                                                <div className="score-count mx-3">
                                                    <span className="new-record d-inline-block mx-1"></span>
                                                    <span className="existing-record d-inline-block"></span>
                                                    <p>Total</p>
                                                </div>
                                            </div>
                                            <div className="new-exist-count d-flex gap-3">
                                                <p className="new-record"><span className="d-inline-block">14</span> New </p>
                                                <p className="existing-record"><span className="d-inline-block">16</span> Existing </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="brand-container rounded p-3">
                                    <div className="header-content d-flex justify-content-between">
                                        <p className="heading">Blacklisted IPs</p>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div className="total-score-container rounded">
                                            <div className="users-totol-count d-flex">
                                                <p className="total-score-count">14</p>
                                                <div className="score-count mx-3">
                                                    <span className="new-record d-inline-block mx-1"></span>
                                                    <span className="existing-record d-inline-block"></span>
                                                    <p>Total</p>
                                                </div>
                                            </div>
                                            <div className="new-exist-count d-flex gap-3">
                                                <p className="new-record"><span className="d-inline-block">17</span> New</p>
                                                <p className="existing-record"><span className="d-inline-block">16</span> Existing</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Col>


            </Row>

            <div className="bg-white-with-shadow p-4 mt-4">
                <Row>
                <div className="d-flex justify-content-between mb-3">
                            <p className="heading">Dark Web Discovery</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>
                    <Col lg={4}>


                        <div className="brand-container rounded p-3">
                            <div className="header-content d-flex justify-content-between">
                                <p className="heading mr-3">Infected by Infostealer</p>
                            </div>
                            <div className="d-flex gap-3">
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-score-count">14</p>
                                        <div className="score-count mx-3">
                                            <span className="new-record d-inline-block mx-1"></span>
                                            <span className="existing-record d-inline-block"></span>
                                            <p>Total </p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex gap-3">
                                        <p className="new-record"><span className="d-inline-block">17</span> New</p>
                                        <p className="existing-record"><span className="d-inline-block">16</span> Existing</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>

                    <Col lg={4}>
                        <div className="brand-container rounded p-3">
                            <div className="header-content d-flex justify-content-between">
                                <p className="heading">Leaked Credentials</p>
                            </div>
                            <div className="d-flex gap-3">
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-score-count">14</p>
                                        <div className="score-count mx-3">
                                            <span className="new-record d-inline-block mx-1"></span>
                                            <span className="existing-record d-inline-block"></span>
                                            <p>Total</p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex gap-3">
                                        <p className="new-record"><span className="d-inline-block">14</span> New </p>
                                        <p className="existing-record"><span className="d-inline-block">16</span> Existing </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="brand-container rounded p-3">
                            <div className="header-content d-flex justify-content-between">
                                <p className="heading">Ransomware Intel</p>
                            </div>
                            <div className="d-flex gap-3">
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-score-count">14</p>
                                        <div className="score-count mx-3">
                                            <span className="new-record d-inline-block mx-1"></span>
                                            <span className="existing-record d-inline-block"></span>
                                            <p>Total</p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex gap-3">
                                        <p className="new-record"><span className="d-inline-block">17</span> New</p>
                                        <p className="existing-record"><span className="d-inline-block">16</span> Existing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>

    )
}


export default BrandInsights;