import { React, useState, useCallback, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";


import EditIcon from "../../../../assets/images/common/edit-icon.svg";
import DeleteIcon from "../../../../assets/images/common/delete.svg";
import GalleryIcon from "../../../../assets/images/common/gallery-add.svg";
import CopyIcon from "../../../../assets/images/account-settings/copy-icon.svg";
import RefreshIcon from "../../../../assets/images/account-settings/refersh-icon.svg";
import tickCircleGreen from "../../../../assets/images/common/tick-circle-green.svg";
import tickCircle from "../../../../assets/images/common/tick-circle.svg";

import InputBox from "../../../ui/InputBox";
import DropDown from '../../../ui/DropDown';
import Button from "../../../ui/Button";
import Setup2FaModal from "../Setup2FaModal";
import UpdatePasswordModal from "../UpdatePasswordModal";
import GenerateAPIKeyModal from "../GenerateAPIKeyModal";
import { toaster } from "../../../ui/Toaster";

import { formatDate, getUserImageUrl } from "../../../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { getTimeZone, updateProfileDetails, getAccountDetails, generate2FAQr } from '../../../../store/actions/settingsAction.js';

const UserDetailsTab = ({accountDetails}) => {

    const timeZonseResponse = useSelector((state) => state.settings?.timeZoneList);
    const updateProfileDetailsResponse = useSelector((state) => state.settings?.updateProfileDetailsResponse);
    const generateAPIKeyResponse = useSelector((state) => state.settings?.generateAPIKeyResponse);
    const generate2FaQrResponse = useSelector((state) => state.settings?.generate2FaQrResponse);
    const enable2FaResponse = useSelector((state) => state.settings?.enable2FaResponse);
    const disable2FaResponse = useSelector((state) => state.settings?.disable2FaResponse);

    const fileInputRef = useRef(null);

    const { t } = useTranslation()

    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [selectedTimeZone, setSelectedtimeZone] = useState("");
    const [profileEdit, setProfileEdit] = useState(false);
    const [loginEdit, setLoginEdit] = useState(false);
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isEnableNotificationActive, setIsEnableNotificationActive] = useState(false);
    const [is2FaActive, setIs2FaActive] = useState(false);
    const [isOtherSettingEdit, setIsOtherSettingEdit] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [timeZoneList, setTimeZoneList] = useState(null);

    const [passwordStrength, setPasswordStrenth] = useState('');
    const [lowerCasePassed, setLowerCasePassed] = useState(false);
    const [upperCasePassed, setUpperCasePassed] = useState(false);
    const [numberPassed, setNumberPassed] = useState(false);
    const [totalChracterPassed, setTotalChracterPassed] = useState(false);
    const [specialChracterPassed, setspecialChracterPassed] = useState(false);
    const [isPasswordNotSame, setIsPasswordNotSame] = useState(false);
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
    const [showAPIKeyGenerateModal, setShowAPIKeyGenerateModal] = useState(false);
    const [qrImageUrl, setQrImageUrl] = useState(null)

    const [isLoading, setIsLoading] = useState(false);
    const [loginDetails, setLoginDetails] = useState(null);
    const [enable2FALoading, setEnable2FALoading] = useState(false);
    const [userImageUrl, setUserImageUrl] = useState("");


    const inputChange = (val, type, label) => {
        if (label === "New Password") {
            validatePassword(val)
        } else if(label === "Confirm Password") {
            setConfirmPassword(val)
        } else {
            setFirstName(val)
        }
    }

    const validatePassword = (val) => {
        setNewPassword(val)

        let lowerCase = /[a-z]/g;
        let upperCase = /[A-Z]/g;
        let numbers = /[0-9]/g;
        let special = /(?=.*[!@#$%^&*])/g;

        setLowerCasePassed(lowerCase.test(val) ? true : false);
        setUpperCasePassed(upperCase.test(val) ? true : false);
        setNumberPassed(numbers.test(val) ? true : false);
        setTotalChracterPassed(val.length > 7 ? true : false);
        setspecialChracterPassed(special.test(val) ? true : false);
    }

    const updatePasswordStrength = useCallback(() => {
        const validated = [lowerCasePassed, upperCasePassed, numberPassed, totalChracterPassed, specialChracterPassed];
        const passwordStrength = validated.filter((data) => data === true);
    
        if (passwordStrength.length > 0 && passwordStrength.length < 3) {
            setPasswordStrenth('weak');
        } else if (passwordStrength.length === 3 || passwordStrength.length === 4) {
            setPasswordStrenth('medium');
        } else if (passwordStrength.length === 5) {
            setPasswordStrenth('strong');
        } else {
            setPasswordStrenth('');
        }
    }, [lowerCasePassed, upperCasePassed, numberPassed, totalChracterPassed, specialChracterPassed]);


    const saveLoginDetils = () => {
        if(newPassword !== confirmPassword) {
            setIsPasswordNotSame(true)
        } else {
            let obj = {
                username: userName,
                email: email,
                new_password: newPassword,
                confirm_password: confirmPassword
            }
            setLoginDetails(obj)
            setShowUpdatePasswordModal(true)
        }
    }

    const handleSelectUser = (value) => {
        setSelectedtimeZone(value.value)
    }

    const activate2Fa = () => {
        if(!is2FaActive) {
            setEnable2FALoading(true)
            dispatch(generate2FAQr())
        } else {
            setModalShow(true)
        }
    }

    const copyAPIKey = () => {
        navigator.clipboard
            .writeText(accountDetails?.api_key)
            .then(() => {
                toaster.addToast("API key copied to clipboard!", "success");
            })
            .catch((error) => {
                console.error("Failed to copy API Key ", error);
            });
    }

    const updateInputDetails = useCallback(()=>{
        if(accountDetails) {
            setFirstName(accountDetails?.firstname || "");
            setLastName(accountDetails?.lastname || "");
            setSelectedtimeZone(accountDetails?.timezone || "");
            setUserName(accountDetails?.username || "");
            setEmail(accountDetails?.email || "");
            setIsEnableNotificationActive(accountDetails?.notification || false)
            setIs2FaActive(accountDetails?.two_factor_enabled || false)
            setUserImageUrl(getUserImageUrl(accountDetails) || "")
        }
    },[accountDetails])

    const saveProfileDetails = () => {
        let obj = {
            firstname: firstName,
            lastname: lastName,
            timezone: selectedTimeZone,
            notification: isEnableNotificationActive
        }
        const isValidBase64 = /^(data:image\/[a-zA-Z]+;base64,)?([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        if(userImageUrl &&isValidBase64.test(userImageUrl)) {
            obj.avatar = userImageUrl
        }
        setIsLoading(true)
        dispatch(updateProfileDetails(obj));
        setUserImageUrl(null)
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the hidden input click
        }
    };

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {  
          const reader = new FileReader();
          reader.readAsDataURL(selectedFiles[0]);
          reader.onload = () => {
            setUserImageUrl(reader.result); // Set base64 string as file source
          };
          reader.onerror = (error) => {
            console.error("Error converting file to base64:", error);
          };
        }
    };

    useEffect(()=>{
        updatePasswordStrength()
        dispatch(getTimeZone())
        updateInputDetails()
    },[updatePasswordStrength, accountDetails, dispatch, updateInputDetails])

    useEffect(()=>{
        if(timeZonseResponse != null) {
            const list = timeZonseResponse?.timezones.map((data)=>{
                return {
                    label: data,
                    value: data
                }
            })
            setTimeZoneList(list)
        }
    },[timeZonseResponse])

    useEffect(()=>{
        if(updateProfileDetailsResponse != null) {
            if(updateProfileDetailsResponse?.detail?.status === "success") {
                toaster.addToast(updateProfileDetailsResponse?.detail?.description, "success");
                dispatch(getAccountDetails())
            } else {
                toaster.addToast(updateProfileDetailsResponse?.description, "error");
            }
            setIsLoading(false);
            setShowUpdatePasswordModal(false);
            setProfileEdit(false);
            setLoginEdit(false);
        }
    },[updateProfileDetailsResponse, dispatch])

    useEffect(()=>{
        if(generateAPIKeyResponse != null) {
            if(generateAPIKeyResponse?.detail?.status === "success") {
                toaster.addToast(generateAPIKeyResponse?.detail?.description, "success");
                dispatch(getAccountDetails())
            } else {
                toaster.addToast(generateAPIKeyResponse?.description, "error");
            }
            setIsLoading(false);
            setShowAPIKeyGenerateModal(false);
            setProfileEdit(false);
            setLoginEdit(false);
            setIsOtherSettingEdit(false);
        }
    },[generateAPIKeyResponse, dispatch])

    useEffect(()=>{
        if(generate2FaQrResponse != null) {
            setEnable2FALoading(false)
            if(generate2FaQrResponse?.qr_code) {
                setModalShow(true)
                setQrImageUrl(generate2FaQrResponse?.qr_code)
            } else {
                toaster.addToast(generate2FaQrResponse?.description, "error");
            }
        }
    },[generate2FaQrResponse])

    useEffect(()=>{
        if(enable2FaResponse != null) {
            if(enable2FaResponse?.detail?.status === "success") {
                toaster.addToast(enable2FaResponse?.detail?.description, "success");
                dispatch(getAccountDetails())
            } else {
                setIs2FaActive(false);
                toaster.addToast(enable2FaResponse?.description, "error");
            }
            setIsLoading(false);
            setIsOtherSettingEdit(false);
            setModalShow(false)
        }
    },[enable2FaResponse, dispatch])

    useEffect(()=>{
        if(disable2FaResponse != null) {
            if(disable2FaResponse?.detail?.status === "success") {
                toaster.addToast(disable2FaResponse?.detail?.description, "success");
                dispatch(getAccountDetails())
            } else {
                setIs2FaActive(true);
                toaster.addToast(disable2FaResponse?.description, "error");
            }
            setIsLoading(false);
            setIsOtherSettingEdit(false);
            setModalShow(false)
        }
    },[disable2FaResponse, dispatch])


    return (
        <>                
            <div className="profile-details-container">
                <div className="d-flex align-items-center justify-content-between profile-details">
                    <h2>{t("accountSettingPage.userDetailsTab.profileDetails")}</h2>
                    {!profileEdit && <p onClick={()=>{setProfileEdit(!profileEdit)}}><img src={EditIcon} alt="edit-profile"/>{t("accountSettingPage.userDetailsTab.edit")}</p>}
                </div>
                {!profileEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="profile-image-section d-flex justify-content-between">
                        <div className="profile-image">
                            <p>{t("accountSettingPage.userDetailsTab.avatar")}</p>
                            <div className="image mt-3">
                                <img src={userImageUrl} alt="profile"/>
                            </div>
                        </div>
                        <div className="login-time">
                            <p>{t("accountSettingPage.userDetailsTab.lastLogin")} <span>{formatDate(accountDetails?.last_login)}</span></p>
                        </div>
                    </div>
                    <div className="details-section mt-5">
                        <Row>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.firstName")}</p>
                                    <p className="value">{accountDetails?.firstname}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.lastName")}</p>
                                    <p className="value">{accountDetails?.lastname}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.timeZone")}</p>
                                    <p className="value">{accountDetails?.timezone}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.enableNotification")}</p>
                                    <div className={`switch-outer position-relative ${accountDetails?.notification ? "active" : ''}`}>
                                        <span></span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                {profileEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="profile-image-section d-flex justify-content-between">
                        <div className="profile-image d-flex align-items-center">
                            <div>
                                <p>{t("accountSettingPage.userDetailsTab.avatar")}</p>
                                <div className="image mt-3">
                                    <img src={userImageUrl} alt="profile"/>
                                </div>
                            </div>
                            <div className="delete-icon">
                                <img src={DeleteIcon} alt="delete-icon" />
                            </div>
                            <div className="upload-button">
                                <input
                                    type="file"
                                    hidden
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    accept="image/png, image/gif, image/jpeg"
                                    />
                                <button onClick={handleButtonClick}><img src={GalleryIcon} alt="gallery"/>{t("accountSettingPage.userDetailsTab.uploadImage")}</button>
                                <p>{t("accountSettingPage.userDetailsTab.uploadFormat")}</p>
                            </div>
                        </div>
                        <div className="login-time">
                            <p>{t("accountSettingPage.userDetailsTab.lastLogin")} <span>{formatDate(accountDetails?.last_login)}</span></p>
                        </div>
                    </div>
                    <div className="details-section mt-5">
                        <Row>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'First Name'} inputType={'text'} inputLabel={'First Name'} inputChange={(value)=>{setFirstName(value)}} inputValue={firstName}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'Last Name'} inputType={'text'} inputLabel={'Lasst Name'} inputChange={(value)=>{setLastName(value)}} inputValue={lastName}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details time-zone-width">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.timeZone")}</p>
                                    <DropDown
                                        options={timeZoneList}
                                        placeHolder='Please select time zone'
                                        onChange={(e) => handleSelectUser(e)}
                                        isSearchable = {true}
                                        defaultSelectedValue={selectedTimeZone}
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.enableNotification")}</p>
                                </div>
                                <div className={`switch-outer position-relative edit-mode ${isEnableNotificationActive ? "active" : ""}`} onClick={()=>{setIsEnableNotificationActive(!isEnableNotificationActive)}}>
                                    <span></span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="submit-profile-div d-flex gap-3">
                                    <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{setProfileEdit(!profileEdit)}}/>
                                    {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={saveProfileDetails} disableButton={firstName === "" || lastName === ""}/>}
                                    {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </div>
            <div className="profile-details-container mt-5">
                <div className="d-flex align-items-center justify-content-between profile-details">
                    <h2>{t("accountSettingPage.userDetailsTab.loginDetails")}</h2>
                    <p onClick={()=>{setLoginEdit(!loginEdit)}}><img src={EditIcon} alt="edit-icon"/>{t("accountSettingPage.userDetailsTab.edit")}</p>
                </div>
                {!loginEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="details mb-4">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.userName")}</p>
                                    <p className="value">{accountDetails?.username}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.emailId")}</p>
                                    <p className="value">{accountDetails?.email}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.password")}</p>
                                    <p className="value">
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                {loginEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="details mb-4">
                                    <InputBox placeholder={'Username'} inputType={'text'} inputLabel={'Username'} inputChange={(value)=>{setUserName(value)}} inputValue={userName}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'Email'} inputType={'text'} inputLabel={'Email ID'} inputChange={(value)=>{setEmail(value)}} inputValue={email}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'New Password'} inputType={'password'} inputLabel={'New Password'} inputChange={inputChange} inputValue={newPassword}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'Confirm Password'} inputType={'password'} inputLabel={'Confirm Password'} inputChange={inputChange} inputValue={confirmPassword} errorMsg={isPasswordNotSame ? "New password and Confirm Password are different" : ""}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="5">
                            {newPassword !== '' && <div className="resetPassword-container">
                                <div className="password-match-container mb-4">
                                    <div className="passowrd-container d-flex justify-content-between">
                                        <div className="password-status">
                                            <span className="strength">{t("loginPage.resetPassword.strength")}</span>
                                            {passwordStrength === "weak" && <span className="tooweak">{t("loginPage.resetPassword.tooWeak")}</span>}
                                            {passwordStrength === "medium" && <span className="medium">{t("loginPage.resetPassword.medium")}</span>}
                                            {passwordStrength === "strong" && <span className="very-strong">{t("loginPage.resetPassword.veryStrong")}</span>}
                                        </div>
                                        <div className="password-strength-bar d-flex">
                                            <span className={`password-strength ${passwordStrength === 'weak' ? 'weak' : passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'weak' ? 'weak' : passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                        </div>                                        
                                    </div>
                                    <div className="password-strength-list">
                                        <ul type="none">
                                            <li className={`${totalChracterPassed ? 'active' : ''}`}><img src={totalChracterPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.chracters")}</span></li>
                                            <li className={`${upperCasePassed ? 'active' : '' }`}><img src={upperCasePassed ?  tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.upperCase")}</span></li>
                                            <li className={`${lowerCasePassed ? 'active': ''}`}><img src={lowerCasePassed ? tickCircleGreen : tickCircle } alt="tick-circle" /><span>{t("loginPage.resetPassword.lowerCase")}</span></li>
                                            <li className={`${specialChracterPassed ? 'active' : ''}`}><img src={specialChracterPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.specialChracter")}</span></li>
                                            <li className={`${numberPassed ? 'active' : ''}`}><img src={numberPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.Numerical")}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="submit-profile-div d-flex gap-3">
                                    <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{setLoginEdit(!loginEdit)}}/>
                                    <Button buttonText={"Save"} buttonType="primary" buttonClicked={saveLoginDetils}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </div>
            <div className="profile-details-container mt-5">
                <div className="d-flex align-items-center justify-content-between profile-details">
                    <h2>{t("accountSettingPage.userDetailsTab.otherSettings")}</h2>
                    {!isOtherSettingEdit && <p onClick={()=>{setIsOtherSettingEdit(!isOtherSettingEdit)}}><img src={EditIcon} alt="edit-icon"/>{t("accountSettingPage.userDetailsTab.edit")}</p>}
                </div>
                {!isOtherSettingEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.apikeyGenerate")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <p className="value">*******_ ******_******</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.twoFactorAuthentication")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={`switch-outer position-relative ${accountDetails?.two_factor_enabled ? "active" : ""}`}>
                                    <span></span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                {isOtherSettingEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.apikeyGenerate")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details d-flex align-items-center gap-4 fs-5">
                                    <p className="value">*******_ ******_****** </p>
                                    <img src={CopyIcon} alt="copy-icon" className="api-key" onClick={()=>copyAPIKey()}/>  
                                    <img src={RefreshIcon} alt="refresh-icon" className="api-key" onClick={()=>{setShowAPIKeyGenerateModal(true)}}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.twoFactorAuthentication")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                {!enable2FALoading && <div className={`switch-outer position-relative edit-mode ${is2FaActive ? "active" : ""}`} 
                                    onClick={()=>{activate2Fa()}}>
                                    <span></span>
                                </div>}
                                {enable2FALoading && <div>
                                    <span>Loading...</span>
                                </div>}
                            </Col>
                        </Row>
                    </div>
                </div>}
                <Setup2FaModal show={modalShow} onHide={() => setModalShow(false)} qrCode={qrImageUrl} isActivate2Fa={is2FaActive}/>
                <UpdatePasswordModal show={showUpdatePasswordModal} onHide={() => setShowUpdatePasswordModal(false)} loginDetais={loginDetails}/>
                <GenerateAPIKeyModal show={showAPIKeyGenerateModal} onHide={() => setShowAPIKeyGenerateModal(false)} />
            </div>
        </>
    )
}

export default UserDetailsTab