import React,{ useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import "./index.scss";

import SideNav from "../../components/PostLogin/SideNav";
import SideMenu from "../../components/PostLogin/ScanInfo/SideMenu";
import Header from "../../components/PostLogin/ScanInfo/header";

import ScanInfoProvider from "./ScanInfoContext";


const ScanInfo = ({children}) => {

    const { t } = useTranslation();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const domainId = queryParams.get('domain');
    const scanId = queryParams.get('scanId');

    const [menuOpen, setMenuOpen] = useState(true);

    const toggleMenuUpdate = (value) => {
        setMenuOpen(value)
    }

    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container scan-info-main-container">
                <div className={`scan-info-menu-container position-relative ${menuOpen ? 'active' : ''}`}>
                    <SideMenu toggleMenuUpdate={toggleMenuUpdate}/>
                </div>
                <div className={`scan-info-details-container ${!menuOpen ? 'active' : ''}`}>
                    <Header />
                    <div className="page-main-section px-4 py-3">
                        <ScanInfoProvider domainId={domainId} scanId={scanId}>
                            {children}
                        </ScanInfoProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanInfo;