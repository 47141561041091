import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";

import ProfileAvatar from "../../../../components/ui/ProfileAvatar";
import MonitorModal from "../../../../components/PostLogin/Monitor/MonitorModal";

import { getDomainImageUrl } from "../../../../utils/util";


const MonitorTileView = ({monitorList}) => {

    const { t } = useTranslation();

    const navigate = useNavigate()

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // const handleShowModal = () => {
    //     setShowDeleteModal(true); // Open the modal
    // };
    
    const handleCloseModal = () => setShowDeleteModal(false);

    const secondaryText = (data) => {
        let scanType = data?.scan_type === "active" ? "Active Scan" : "Passive Scan"
        return `${scanType} | ${data.monitor_type}`
    }

    return (
        <>
            <Row>
                {
                    monitorList?.results.length > 0 &&
                    monitorList?.results.map((data, index) => {
                        return (
                            <Col xl="6" lg="12" md="12" className="col-xxxl-4 mb-4" key={index}>
                                <div className="monitor-container px-4 py-3 rounded">
                                    <div className="organization-profile">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="organization-details">
                                                <ProfileAvatar type="profile" secondaryText={secondaryText(data)} firstName={data?.monitor_domain?.domain} imageUrl={getDomainImageUrl(data?.monitor_domain)} />
                                            </div>
                                            <div className="organization-actions">
                                                {/* <button
                                                    className="view-profile-btn"
                                                    onClick={handleShowModal}
                                                >
                                                    {t("monitor.editBtn")}
                                                </button> */}
                                                <button
                                                    className="view-profile-btn"
                                                    onClick={()=>{navigate(`/monitor/details/${data.monitor_id}`)}}
                                                >
                                                    Manage
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-scan-details mt-4">
                                        <Row>
                                            <Col lg={6}>
                                                <div className="scan-count p-3">
                                                    <p className="label">{t("monitor.weekly")}</p>
                                                    <p className="value">64</p>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="scan-count p-3">
                                                    <p className="label">{t("monitor.monthly")}</p>
                                                    <p className="value">32</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
                <MonitorModal show={showDeleteModal} onHide={handleCloseModal} />
            </Row>
        </>
    )
}

export default MonitorTileView;