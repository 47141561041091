import React,{ useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
// import "./index.scss";

import ContentBox from "../../../../../../ui/ContentBox";


const DomainInformation = () => {
    return (
        <>
            <Row className="mb-4">
                <Col lg={6}>
                    <ContentBox label={"Domain"} value={"codescan.io"}/>
                </Col>
                <Col lg={6}>
                    <ContentBox label={"Registrant"} value={"Nginx"}/>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg={6}>
                    <ContentBox label={"Registrar"} value={"Godaddy"}/>
                </Col>
                <Col lg={6}>
                    <ContentBox label={"Email"} value={"test@cyber.com"}/>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg={6}>
                    <ContentBox label={"Created On"} value={"July 21, 2024 | 05:44"}/>
                </Col>
                <Col lg={6}>
                    <ContentBox label={"Expiray Date"} value={"July 21, 2024 | 05:44"}/>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg={6}>
                    <ContentBox label={"Hosting Provider"} value={"Amazon"}/>
                </Col>
            </Row>
        </>
    )
}

export default DomainInformation;