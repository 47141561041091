
const initialState = {
    customerList: null,
    organizationList: null,
    domainList: null,
    whoisInformation: null,
    subdomainList: null,
    aRecord: null,
    aaaRecord: null,
    cnameRecord: null,
    mxRecord: null,
    nsRecord: null,
    soaRecord: null,
    txtRecord: null,
    subDomainTechnologies: null,
    sslCertificateList: null,
    ipAddressList: null,
    ipAddressSubDomainList: null,
    ipAddressPortList: null,
    technologyList: null,
    endPointsList: null,
    sslCertificates: null,
    mobileAppsList: null,
    cerStats: null,
    cerDataList: null,
    gitLeaksList: null,
    sensitiveExpoList: null,
    sensitiveEndpointsList: null,
    jsSecretsEndpointsList: null,
    leakedCredentialsList: null,
    leakedCredentialsData: null,
    botnetLeaksList: null,
    botnetLeakedData: null,
    ransomwareGroupListList: null,
    ransomwereGroupData: null
}

export default function scanInfoReducer(state=initialState, action) {
    switch(action.type) {
        case 'SCAN_INFO_CUSTOMER_LIST_SUCCESS':
            return {
                ...state,
                customerList: action.payload
            }
        case 'SCAN_INFO_ORGANIZATION_LIST_SUCCESS': 
            return {
                ...state,
                organizationList: action.payload
            }
        case 'SCAN_INFO_DOMAIN_LIST_SUCCESS':
            return {
                ...state,
                domainList: action.payload
            }
        case 'SCAN_INFO_RESET_DOMAIN_LIST':
            return {
                ...state,
                domainList: null
            }
        case 'WHOIS_INFORMATION_SUCCESS':
            return {
                ...state,
                whoisInformation: action.payload
            }
        case 'SUBDOMAIN_LIST_SUCCESS':
            return {
                ...state,
                subdomainList: action.payload
            }
        case 'GET_ARECORD_SUCCESS':
            return {
                ...state,
                aRecord: action.payload
            }
        case 'GET_AAARECORD_SUCCESS':
            return {
                ...state,
                aaaRecord: action.payload
            }
        case 'GET_CNAMERECORD_SUCCESS':
            return {
                ...state,
                cnameRecord: action.payload
            }
        case 'GET_MXRECORD_SUCCESS':
            return {
                ...state,
                mxRecord: action.payload
            }
        case 'GET_NSRECORD_SUCCESS':
            return {
                ...state,
                nsRecord: action.payload
            }
        case 'GET_SOARECORD_SUCCESS':
            return {
                ...state,
                soaRecord: action.payload
            }
        case 'GET_TXTRECORD_SUCCESS':
            return {
                ...state,
                txtRecord: action.payload
            }
        case 'SUBDOMAIN_TECHNOLOGIES_SUCCESS':
            return {
                ...state,
                subDomainTechnologies: action.payload
            }
        case 'SSL_CERTIFICATE_SUCCESS':
            return {
                ...state,
                sslCertificateList: action.payload
            }
        case 'IP_ADDRESS_LIST_SUCCESS':
            return {
                ...state,
                ipAddressList: action.payload
            }
        case 'IP_ADDRESS_SUBDOMAIN_LIST_SUCCESS':
            return {
                ...state,
                ipAddressSubDomainList: action.payload
            }
        case 'IP_ADDRESS_PORT_LIST_SUCCESS':
            return {
                ...state,
                ipAddressPortList: action.payload
            }
        case 'TECHNOLOGY_LIST_SUCCESS':
            return {
                ...state,
                technologyList: action.payload
            }
        case 'ENDPOINTS_LIST_SUCCESS':
            return {
                ...state,
                endPointsList: action.payload
            }
        case 'SSL_CERTIFICATE_LIST_SUCCESS':
            return {
                ...state,
                sslCertificates: action.payload
            }
        case 'MOBILE_APPS_LIST_SUCCESS' :
            return {
                ...state,
                mobileAppsList: action.payload
            }
        case 'CER_STATS_SUCCESS':
            return {
                ...state,
                cerStats: action.payload
            }
        case 'CER_DATA_SUCCESS':
            return {
                ...state,
                cerDataList: action.payload
            }
        case 'GIT_LEAKS_SUCCESS':
            return {
                ...state,
                gitLeaksList: action.payload
            }
        case 'JS_SECRETS_LEAKS_SUCCESS':
            return {
                ...state,
                jsSecretsList: action.payload
            }
        case 'SENSITIVE_EXPO_LIST_SUCCESS':
            return {
                ...state,
                sensitiveExpoList: action.payload
            }
        case 'SENSITIVE_ENDPOINTS_LIST_SUCCESS':
            return {
                ...state,
                sensitiveEndpointsList: action.payload
            }
        case 'JS_SECRETS_ENDPOINTS_LIST_SUCCESS':
            return {
                ...state,
                jsSecretsEndpointsList: action.payload
            }
        case 'LEAKED_CREDENTALS_LIST_SUCCESS' :
            return {
                ...state,
                leakedCredentialsList: action.payload
            }
        case 'LEAKED_CREDENTALS_DATA_SUCCESS' :
            return {
                ...state,
                leakedCredentialsData: action.payload
            }
        case 'BOTNET_LEAKS_LIST_SUCCESS' :
            return {
                ...state,
                botnetLeaksList: action.payload
            }
        case 'BOTNET_LEAKS_DATA_SUCCESS' :
            return {
                ...state,
                botnetLeakedData: action.payload
            }
        case 'RANSOMEWARE_LIST_SUCCESS':
            return {
                ...state,
                ransomwareGroupListList: action.payload
            }
        case 'RANSOMEWARE_DATA_SUCCESS':
            return {
                ...state,
                ransomwereGroupData: action.payload
            }
        default:
            return state;
    }
}