
const initialState = {
    accountDetails: null,
    timeZoneList: null,
    updateProfileDetailsResponse: null,
    generateAPIKeyResponse: null,
    generate2FaQrResponse: null,
    enable2FaResponse: null,
    disable2FaResponse: null,
    userSessionList: null,
    deleteSessionResponse: null,
    logOutResponse: null
}

export default function domainReducer(state=initialState, action) {
    switch(action.type) {
        case 'USER_DETAILS_SUCCESS' :
            return {
                ...state,
                accountDetails: action.payload
            }
        case 'TIME_ZONE_SUCCESS' :
            return {
                ...state,
                timeZoneList: action.payload
            }
        case 'UPDATE_PROFILE_DETAILS_SUCCESS' :
            return {
                ...state,
                updateProfileDetailsResponse: action.payload
            }
        case 'GENERATE_API_KEY_SUCCESS' :
            return {
                ...state,
                generateAPIKeyResponse: action.payload
            }
        case 'GENERATE_2FA_QR_SUCCESS' : 
            return {
                ...state,
                generate2FaQrResponse: action.payload
            }
        case 'ENABLE_2FA_SUCCESS' :
            return {
                ...state,
                enable2FaResponse: action.payload
            }
        case 'DISABLE_2FA_SUCCESS' :
            return {
                ...state,
                disable2FaResponse: action.payload
            }
        case 'USER_SESSION_SUCCESS' :
            return {
                ...state,
                userSessionList: action.payload
            }
        case 'DELETE_SESSION_SUCCESS' :
            return {
                ...state,
                deleteSessionResponse: action.payload
            }
        case 'USER_LOGOUT_SUCCESS' :
            return {
                ...state,
                logOutResponse: action.payload
            }
        default:
            return state;
    }
}