// ScanModal.js
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import Button from "../../../../components/ui/Button";
import RadioButton from "../../../../components/ui/RadioButton";
import "./index.scss";

import { useDispatch } from 'react-redux';
import { StartScan } from '../../../../store/actions/domainAction.js';

const ScanModal = ({show, onHide, domainData}) => {

    // const startScanResponse = useSelector((state) => state.domain?.domainDetails);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    // State variables
    const [scanType, setScanType] = useState('active');  // Default to "active"
    const [scanSpeed, setScanSpeed] = useState('slow');   // Default to "slow"
    const [subdomainList, setSubdomainList] = useState([]);
    const [ipAddressList, setIpAddressList] = useState([]);
    const [emailList, setEmailList] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);

    // Handlers
    const scanTypeChange = (value) => {
        setScanType(value);
    };
    
    const scanSpeedChange = (value) => {
       setScanSpeed(value);
    };

    const startScan = () => {
        
        let obj = {
            domain: domainData.domain,
            scan_type: scanType,
            scan_speed: scanSpeed,
            addition_subdomain_list: [...subdomainList.split("\n")],
            addition_ip_address_list: [...ipAddressList.split("\n")],
            addition_email_list: [...emailList.split("\n")]
        }
        dispatch(StartScan(obj))
    };

    const isDisableButton = () => {
        return scanType === "" && scanSpeed === ""
    }

    const textareaChange = (type, value) => {
        if(type === "subdomain") {
            setSubdomainList(value)
        } else if (type === "ipAddress") {
            setIpAddressList(value)
        } else {
            setEmailList(value)
        }
    }

    return (
        <Modal show={show} onHide={onHide} centered className="delete-organization-modal">
            <Modal.Body>
                <div className="delete-organization-container">
                    <h4>{t("domainPage.scanModal.heading")}</h4>
                    <div className="add-organization-step-one">
                        <div className="organization-type">
                            <p>{t("domainPage.scanModal.selectScanType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="active"
                                    radioLabel="Active"
                                    radioValue="active"
                                    onChange={scanTypeChange}
                                    isChecked={scanType === 'active'}
                                />
                                <RadioButton
                                    radioId="passive"
                                    radioLabel="Passive"
                                    radioValue="passive"
                                    onChange={scanTypeChange}
                                    isChecked={scanType === 'passive'}
                                />
                            </div>
                        </div>
                        <div className="organization-type mt-3">
                            <p>{t("domainPage.scanModal.selectScanSpeed")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="slow"
                                    radioLabel="Slow"
                                    radioValue="slow"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'slow'}
                                />
                                <RadioButton
                                    radioId="moderate"
                                    radioLabel="Moderate"
                                    radioValue="moderate"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'moderate'}
                                />
                                <RadioButton
                                    radioId="fast"
                                    radioLabel="Fast"
                                    radioValue="fast"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'fast'}
                                />
                            </div>
                        </div>
                        <div className="input-container mt-4">
                            <div className="cymdr-input pb-3 ">
                                <label className="mb-2">Additional Subdomain List</label>
                                <textarea className="start-scan-textarea" value={subdomainList} placeholder="Subdomain list" onChange={(e) => textareaChange("subdomain", e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="input-container mt-2">
                            <div className="cymdr-input pb-3 ">
                                <label className="mb-2">Additional IP Address List</label>
                                <textarea className="start-scan-textarea" value={ipAddressList} placeholder="IP Address list" onChange={(e) => textareaChange("ipAddress", e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="input-container mt-2">
                            <div className="cymdr-input pb-3 ">
                                <label className="mb-2">Additional Email List</label>
                                <textarea className="start-scan-textarea" value={emailList} placeholder="Enter Emails" onChange={(e) => textareaChange("emails", e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="form-submit-btn-group d-flex gap-3">
                            <Button buttonText="Cancel" buttonType="secondary" buttonClicked={onHide} />
                            <Button buttonText="Save" buttonType="primary" className="save-btn" buttonClicked={startScan} disableButton={isDisableButton()}/>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ScanModal;
