import React, {useEffect, useState, useCallback} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";

import tickCircleGreen from "../../assets/images/common/tick-circle-green.svg";
import tickCircle from "../../assets/images/common/tick-circle.svg";
import arrowCircleLeft from "../../assets/images/common/arrow-circle-left.svg";
import successTick from "../../assets/images/common/Success.gif";

import LeftSection from "../../components/PreLogin/LoginPage/LeftSection.js";
import RightFooter from "../../components/PreLogin/RightFooter";
import InputBox from "../../components/ui/InputBox";
import Button from "../../components/ui/Button";


import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../../store/actions/loginAction.js';

const ResetPassword = () => {

    const response = useSelector((state) => state.login?.resetPasswordResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search } = useLocation();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrenth] = useState('');
    const [lowerCasePassed, setLowerCasePassed] = useState(false);
    const [upperCasePassed, setUpperCasePassed] = useState(false);
    const [numberPassed, setNumberPassed] = useState(false);
    const [totalChracterPassed, setTotalChracterPassed] = useState(false);
    const [specialChracterPassed, setspecialChracterPassed] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [isPasswordNotSame, setIsPasswordNotSame] = useState(false);
    const [resetPasswordError,setResetPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const inputChange = (val, type, label) => {
        if (label === "New Password") {
            validatePassword(val)
        } else {
            setConfirmPassword(val)
        }
    }

    const validatePassword = (val) => {
        setNewPassword(val)

        let lowerCase = /[a-z]/g;
        let upperCase = /[A-Z]/g;
        let numbers = /[0-9]/g;
        let special = /(?=.*[!@#$%^&*])/g;

        setLowerCasePassed(lowerCase.test(val) ? true : false);
        setUpperCasePassed(upperCase.test(val) ? true : false);
        setNumberPassed(numbers.test(val) ? true : false);
        setTotalChracterPassed(val.length > 8 ? true : false);
        setspecialChracterPassed(special.test(val) ? true : false);
    }

    const updatePasswordStrength = useCallback(() => {
        const validated = [lowerCasePassed, upperCasePassed, numberPassed, totalChracterPassed, specialChracterPassed];
        const passwordStrength = validated.filter((data) => data === true);
    
        if (passwordStrength.length > 0 && passwordStrength.length < 3) {
            setPasswordStrenth('weak');
        } else if (passwordStrength.length === 3 || passwordStrength.length === 4) {
            setPasswordStrenth('medium');
        } else if (passwordStrength.length === 5) {
            setPasswordStrenth('strong');
        } else {
            setPasswordStrenth('');
        }
    }, [lowerCasePassed, upperCasePassed, numberPassed, totalChracterPassed, specialChracterPassed]);

    const buttonClicked = () => {
        if(newPassword !== confirmPassword) {
            setIsPasswordNotSame(true)
        } else {
            const queryParams = new URLSearchParams(search);
            const userId = queryParams.get('userid');
            const token = queryParams.get('token');
            let obj = {
                user_id: userId,
                token: token,
                new_password: newPassword,
                confirm_password: confirmPassword
            }
            setIsLoading(true)
            dispatch(resetPassword(obj))
        }
    }

    const goBack = () => {
        navigate('/auth/login')
    }

    const isDisabledButton = () => {
        if(newPassword === '' || confirmPassword === '') {
            return true
        }
        return false
    }

    useEffect(()=>{
        updatePasswordStrength()

        if(response?.status === "failed") {
            setResetPasswordError(response?.description)
            setIsLoading(false)
        } 
        
        if(response?.detail?.status === "success") {
           setResetSuccess(true)
           setIsLoading(false)
        }


    },[response,updatePasswordStrength])

    return (
        <div className="resetPassword-container">
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='reset-password-right-section px-4 mx-3 h-100'>
                        <div className='right-container'>
                            {resetSuccess && <img src={arrowCircleLeft} alt="back" onClick={goBack} className="go-back-icon"/>}
                            {!resetSuccess && <>
                                <h1 className='mb-4'>{t("loginPage.resetPassword.heading")}</h1>
                                <p className="description">{t("loginPage.resetPassword.secondaryText")}</p>
                                {isPasswordNotSame && <p className="error-msg text-center mt-3">New password and Confirm Password are different</p>}
                                {resetPasswordError !== "" && <p className="error-msg text-center mt-3">{resetPasswordError}</p>}
                                <div className='input-container mt-3'>
                                    <InputBox placeholder={'Enter New Password'} inputType={'password'} inputLabel={'New Password'} inputChange={inputChange} inputValue={newPassword}/>
                                    {newPassword !== '' && <div className="password-match-container mb-4">
                                        <div className="passowrd-container d-flex justify-content-between">
                                            <div className="password-status">
                                                <span className="strength">{t("loginPage.resetPassword.strength")}</span>
                                                {passwordStrength === "weak" && <span className="tooweak">{t("loginPage.resetPassword.tooWeak")}</span>}
                                                {passwordStrength === "medium" && <span className="medium">{t("loginPage.resetPassword.medium")}</span>}
                                                {passwordStrength === "strong" && <span className="very-strong">{t("loginPage.resetPassword.veryStrong")}</span>}
                                            </div>
                                            <div className="password-strength-bar d-flex">
                                                <span className={`password-strength ${passwordStrength === 'weak' ? 'weak' : passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                                <span className={`password-strength ${passwordStrength === 'weak' ? 'weak' : passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                                <span className={`password-strength ${passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                                <span className={`password-strength ${passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                                <span className={`password-strength ${passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            </div>                                        
                                        </div>
                                        <div className="password-strength-list">
                                            <ul type="none">
                                                <li className={`${totalChracterPassed ? 'active' : ''}`}><img src={totalChracterPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.chracters")}</span></li>
                                                <li className={`${upperCasePassed ? 'active' : '' }`}><img src={upperCasePassed ?  tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.upperCase")}</span></li>
                                                <li className={`${lowerCasePassed ? 'active': ''}`}><img src={lowerCasePassed ? tickCircleGreen : tickCircle } alt="tick-circle" /><span>{t("loginPage.resetPassword.lowerCase")}</span></li>
                                                <li className={`${specialChracterPassed ? 'active' : ''}`}><img src={specialChracterPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.specialChracter")}</span></li>
                                                <li className={`${numberPassed ? 'active' : ''}`}><img src={numberPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.Numerical")}</span></li>
                                            </ul>
                                        </div>
                                    </div>}
                                    <InputBox placeholder={'Confirm New Password'} inputType={'password'} inputLabel={'Confirm Password'} inputChange={inputChange} inputValue={confirmPassword}/>
                                </div>
                                <div className='submit-button'>
                                    {!isLoading && <Button buttonText={"Reset Password"} buttonType="primary" buttonClicked={buttonClicked} disableButton={isDisabledButton()}/>}
                                    {isLoading && <Button buttonText={"Loading..."} buttonType="primary" buttonClicked={buttonClicked} disableButton={true}/>}
                                </div>
                            </>}
                            { resetSuccess && <div className="resend-success-container">
                                <div className="mt-5">
                                    <img src={successTick} alt="success" />
                                </div>
                                <h1 className="mb-4 mt-5">{t("loginPage.resetPassword.resetHeading")}</h1>
                                <p>{t("loginPage.resetPassword.resetSecondaryText")}</p>
                                <div className="link-not-received mt-5">
                                    <Button buttonText={"Back to Login"} buttonType="primary" buttonClicked={goBack} />
                                </div>
                            </div>
                            }
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    )
}


export default ResetPassword;