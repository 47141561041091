import React, {useState} from 'react';

import './index.scss';

import passwordEye from "../../../assets/images/LoginPage/eye-slash.svg"

const InputBox = ({placeholder, inputType, inputLabel, inputChange, inputValue, errorMsg= ''}) => {

    const [passwordType, setPasswordType] = useState('password')

    const handleChange = (event) => {
        inputChange(event.target.value, inputType, inputLabel);
    }

    const showPassword = () => {
        if(passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    return (
        <div className={`cymdr-input pb-3 ${errorMsg !== '' ? 'error-msg' : ''}`}>
            <label className='mb-2'>{inputLabel}</label>
            {inputType !== 'password' && <input className="w-100" type={inputType} placeholder={placeholder} onChange={handleChange} value={inputValue} />}
            {inputType === 'password' && <input className="w-100" type={passwordType} placeholder={placeholder} onChange={handleChange} value={inputValue} />}
            {inputType === 'password' && inputValue !== '' && <img className="password-eye" src={passwordEye} alt="password eye" onClick={showPassword} />}
            {errorMsg !== '' && <p className="error-msg mt-1">{errorMsg}</p>}
        </div>
    )
}

export default InputBox;