import React from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col, Table } from 'react-bootstrap';
import "./index.scss";

import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";

import move from "../../../../../assets/images/common/fi_move.svg";

const Findings = () => {

    const tooltipContent = () => {
        return (
            <div className="d-flex align-items-center gap-2 tooltip-logo">
                <img src={dummyImage} alt="logo" />
                <p>Google</p>
                <span>600</span>
            </div>
        )
    }

    return (
        <>
            <div className='findings-container'>
                <div className="bg-white-with-shadow p-4 mt-4">

                    <Row>
                        <Col lg={12}>
                            <div className="d-flex justify-content-between mb-2 ">
                                <p className="heading">Findings</p>
                                {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                            </div>
                            <Row>
                                <Col lg={2} className='custom-width'>
                                    <div className="dashboard-type-container rounded p-3">

                                        <div className="total-records-container">
                                            <p className="count-content">164 <span>Total </span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2} className='custom-width'>
                                    <div className="dashboard-type-container rounded p-3 bg-critical">

                                        <div className="total-records-container">
                                            <p className="content">54 <span>Critical </span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2} className='custom-width'>
                                    <div className="dashboard-type-container rounded p-3 bg-high">

                                        <div className="total-records-container">
                                            <p className="content">23 <span>High </span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2} className='custom-width'>
                                    <div className="dashboard-type-container rounded p-3 bg-medium">

                                        <div className="total-records-container">
                                            <p className="content">12 <span>Medium </span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2} className='custom-width'>
                                    <div className="dashboard-type-container rounded p-3  bg-low">

                                        <div className="total-records-container">
                                            <p className="content">03 <span>Low </span></p>
                                        </div>
                                    </div>
                                </Col>

                            </Row>


                        </Col>
                    </Row>


                    <div className="scan-info-main-content findings-main-content">
                        <div className="bg-white-with-shadow p-4">
                            <div className="heat-map-container d-flex gap-5">
                                <div className="risk-graph">
                                    <div className="row-first-div d-flex align-items-center main-row-container">
                                        <div className="score-value-div d-flex align-items-center">
                                            <p>Total</p>
                                        </div>
                                        <div className="row-common-div row-one-one d-flex align-items-center justify-content-between gap-2 p-2 px-5 mb-1">
                                            <p>12</p>
                                            <p>12</p>
                                            <p>12</p>
                                            <p>-</p>
                                            <p>12</p>
                                            <p>12</p>
                                            <p>12</p>
                                            <p>12</p>
                                            <p>-</p>
                                        </div>

                                    </div>
                                    <div className="row-two-div d-flex align-items-center main-row-container">
                                        <div className="score-value-div d-flex align-items-center">
                                            <p>Critical</p>
                                        </div>
                                        <div className="row-common-div row-two-one d-flex align-items-center justify-content-between gap-2 p-2 px-5 mb-1">
                                            <p>12</p>
                                            <p>-</p>
                                            <p>14</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>08</p>
                                            <p>-</p>
                                            <p>12</p>
                                            <p>-</p>
                                        </div>

                                    </div>
                                    <div className="row-three-div d-flex align-items-center main-row-container">
                                        <div className="score-value-div d-flex align-items-center">
                                            <p>High</p>
                                        </div>
                                        <div className="row-common-div row-three-one d-flex align-items-center justify-content-between gap-2 p-2 px-5 mb-1">
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>08</p>
                                            <p>06</p>
                                            <p>-</p>
                                            <p>-</p>
                                        </div>

                                    </div>
                                    <div className="row-four-div d-flex align-items-center main-row-container">
                                        <div className="score-value-div d-flex align-items-center">
                                            <p>Medium</p>
                                        </div>
                                        <div className="row-common-div row-four-one d-flex align-items-center justify-content-between gap-2 p-2 px-5 mb-1">
                                            <p>12</p>
                                            <p>-</p>
                                            <p>14</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>08</p>
                                            <p>-</p>
                                            <p>12</p>
                                            <p>-</p>
                                        </div>
                                    </div>
                                    <div className="row-five-div d-flex align-items-center main-row-container">
                                        <div className="score-value-div d-flex align-items-center">
                                            <p>Low</p>
                                        </div>
                                        <div className="row-common-div row-five-one d-flex align-items-center justify-content-between gap-2 p-2 px-5 mb-1">
                                            <p>-</p>
                                            <p>05</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                            <p>-</p>
                                        </div>

                                    </div>
                                    <div className="row-five-div d-flex align-items-center main-row-container">
                                        {/* <div className="score-value-div d-flex align-items-center">
                                        </div> */}
                                        <div className="score-names d-flex justify-content-center gap-3 p-2 px-5 mb-1 text-center">
                                            <p>Sensitive Exposures</p>
                                            <p>Misconfigs</p>
                                            <p>CVEs</p>
                                            <p>Subdomains Takeovers</p>
                                            <p>JS Secrets</p>
                                            <p>SSL Vulnerabilities</p>
                                            <p>Default Logins</p>
                                            <p>Other Vulnerabilities</p>
                                            <p>CNVD</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default Findings;