import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import FileUpload from "../../../ui/FileUpload";
import Button from "../../../ui/Button";

import { useDispatch } from 'react-redux';
import { updateDomainDetails } from '../../../../store/actions/domainAction';

const EditDomainModal = ({domainData}) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [files, setFiles] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [fileObject, setFileObject] = useState(null);

    const getUploadfile = (file) => {
        setFiles(file)
    }

    const saveDetails = () =>{
        let obj = {
            domain: domainData.domain,
        }
        const isValidBase64 = /^(data:image\/[a-zA-Z]+;base64,)?([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        if(files && isValidBase64.test(files)) {
            obj.logo = files
        }
        setIsLoading(true)
        dispatch(updateDomainDetails(obj))
        if (files && isValidBase64.test(files)) {
            setFiles(null); // Reset the files state to avoid reprocessing
        }
    }

    useEffect(()=>{
        if(domainData != null) {
            setFiles(domainData?.logo || "");
            let obj = {
                name: domainData.domain,
                logo: domainData.logo
            }
            setFileObject(obj)
        }        
    },[domainData])

    return (
        <div className="add-organization-container">  
            <div className="modal-header mt-4">
                <h2>{t("domainPage.editDomainModal.editDomain")}</h2>
            </div>
            <div className="add-organization-step-one mt-3">
                <FileUpload onFilesSelected={getUploadfile} label={'Upload an Avatar'} filePath={files} place="editDomain" fileObject={fileObject}/>
            </div>
            <div className="form-submit-btn-group mt-4">
                <Button buttonText={"Cancel"} buttonType="secondary" />
                {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={saveDetails} />}
                {isLoading && <Button buttonText={"Save"} buttonType="primary" disableButton={true} />}
            </div>
        </div>
    )
}

export default EditDomainModal;