import { React, useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import editIcon from "../../../../assets/images/common/edit-icon.svg";
import closeIcon from "../../../../assets/images/common/close-circle.svg";

import ProfileAvatar from '../../../ui/ProfileAvatar';
import CheckBox from "../../../ui/CheckBox";
import Button from "../../../ui/Button";
import Pagination from "../../../ui/Pagination";
import Spinner from "../../../ui/spinner";
import NoDataFound from "../../../ui/NoDataFound";
import SideModal from "../../../ui/SideModal";

import ModifyOrganization from "../ModifyOrganization";

import { formatDate, getOrganizationImageUrl, getUserImageUrl } from "../../../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationByUser } from '../../../../store/actions/usersAction';
import { getOrganizationList } from "../../../../store/actions/organizationActions";

const filterOption = [
    {
        label: "Critical",
        value: "critical"
    },
    {
        label: "High",
        value: "high"
    },
    {
        label: "Medium",
        value: "medium"
    },
    {
        label: "Low",
        value: "low"
    }
]


const OrganizationTab = ({customerDetails="", userDetails="", currentPageType="usersDetails"}) => {

    const organizationListByUserResponse = useSelector((state) => state?.users?.organizationListByUserResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [showModifySidePanel, setShowModifySidePanel] = useState(false);

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    const closeModifyModal = () => {
        setShowModifySidePanel(false)
    }

    const closeModifyOrgsModal = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            fetchOrganizationList()
        }
        closeModifyModal()
    }

    const isChecked = (data) => {
        if(selectedValue.length > 0 ){
            return selectedValue.some(item => item.organization_id === data.organization_id);
        }
        return false
    }

    const changeSelectAll = (e) => {
        if(e.target.checked) {
            setSelectedValue(organizationListByUserResponse?.results)
        } else {
            setSelectedValue([])
        }
    }

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            let checkedValue = organizationListByUserResponse?.results.filter(item => item.organization_id === e.target.value);
            setSelectedValue([...selectedValue, ...checkedValue])
        } else {
            const updatedList = selectedValue.filter(item => item.organization_id !== e.target.value);
            setSelectedValue(updatedList);
        }
    }

    const organizationSecondaryText = (data) => {
        let date = formatDate(data.created_at)
        return `${data.type} | ${date}`
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchOrganizationList = useCallback(()=>{
        if(currentPageType === "usersDetails") {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&user_id=${userDetails?.user_id}`;
            setIsLoading(true)
            dispatch(getOrganizationByUser(url))
        } else {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&customer_id=${customerDetails?.customer_id}`;
            setIsLoading(true)
            dispatch(getOrganizationByUser(url))
        }
        
    },[dispatch, currentPage, currentPageSize, customerDetails, userDetails, currentPageType])

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList])
    

    useEffect(()=>{
        fetchOrganizationList()
    },[fetchOrganizationList])

    useEffect(()=>{
        if(organizationListByUserResponse !== null) {
            setIsLoading(false)
        }
    },[organizationListByUserResponse])

    return (
        <Row>
            <Col md={12}>
                <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                    <div className="search-container d-flex align-items-center gap-3">
                        <div className="position-relative">
                            <input className="search-input rounded" placeholder="Search organization" value={domainSearchValue} onChange={(e)=>setDomainSearchValue(e.target.value)}/>
                            <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        </div>
                        <button className="search-btn">Search</button>
                        <div className="position-relative">
                            <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                            {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                <h6>Business Impact</h6>
                                {filterOption.map((data,index)=>{
                                    return (
                                        <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                            <p>{data.label}</p>
                                            <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                        </div>
                                    )
                                })}
                                <div className="filter-apply-btn mt-3">
                                    <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 ? true : false} buttonClicked={applyFilter}/>
                                </div>
                            </div>}
                        </div>
                        {!showFilter && <p className="selected-filters">
                            {
                                filterList.map((data,index)=>{
                                    return (
                                        <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                    )
                                })
                            }
                        </p>}
                    </div>
                    <div className="sort-edit-container">
                        <div className="container d-flex gap-3 align-items-center">
                            <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")} 
                                <select className="border-0">
                                    <option value="latest score">Latest Score</option>
                                    <option value="last scanned">Last scanned</option>
                                    <option value="created">Created</option>
                                </select>
                            </p>
                            {currentPageType === "usersDetails" && <button onClick={()=>{
                                let url = `&customer_id=${userDetails?.customer_detail?.customer_id}`
                                dispatch(getOrganizationList(url));                                
                                setShowModifySidePanel(true)
                            }}>
                                <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                                {t("organizationDetailsPage.modifyBtn")}
                            </button>}
                        </div>
                    </div>
                </div>
                <div className="domain-table-container user-page-org-table">
                    {
                        isLoading ?
                            <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%'}}>
                                <Spinner />
                            </div>
                            :
                            <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th className="org-tabel-select-all"><CheckBox checkBoxId={2} checkBoxValue={"select all"} handleCheckboxChange={changeSelectAll}/></th>
                                    <th>{t("userDetailsPage.detailPageOrganizationTab.organizationDetails")}</th>
                                    <th>{t("userDetailsPage.detailPageOrganizationTab.createdDetails")}</th>
                                    <th>{t("userDetailsPage.detailPageOrganizationTab.score")}</th>
                                    <th>{t("userDetailsPage.detailPageOrganizationTab.businessImpact")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    organizationListByUserResponse?.results.length > 0 && 
                                    organizationListByUserResponse?.results.map((data, index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <CheckBox checkBoxId={index} checkBoxValue={data.organization_id} handleCheckboxChange={setCheckboxvalue} isChecked={isChecked(data)} />
                                                </td>
                                                <td>
                                                    <ProfileAvatar type="profile" firstName={data.name} secondaryText={organizationSecondaryText(data)} imageUrl={getOrganizationImageUrl(data)} />
                                                </td>
                                                <td>
                                                    <ProfileAvatar type="profile" firstName={data?.created_by?.name} lastName={" "} secondaryText={data?.created_by?.role} imageUrl={getUserImageUrl(data)} />
                                                </td>
                                                <td>
                                                    {data?.stats?.overall_score?.obtained_score}
                                                </td>
                                                <td>
                                                    {data?.business_impact === "critical" && <p className="business-impact critical">Critical</p>}
                                                    {data?.business_impact === "high" && <p className="business-impact high">High</p>}
                                                    {data?.business_impact === "medium" && <p className="business-impact medium">Medium</p>}
                                                    {data?.business_impact === "low" && <p className="business-impact low">Low</p>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                    {
                    !isLoading && organizationListByUserResponse?.results.length === 0 && 
                        <NoDataFound />
                    }
                    {organizationListByUserResponse !== null && !isLoading && organizationListByUserResponse?.meta?.total_results !== 0 && <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        pageSize={currentPageSize}
                        totalCount={organizationListByUserResponse?.meta?.total_results}
                        onPageChange={setCurrentPageandRecordSize}
                    />}
                    <SideModal showSidePanel={showModifySidePanel} hideSidePanel={closeModifyModal}>
                        <ModifyOrganization assignedOrgsList={organizationListByUserResponse} closeModifyOrgsModal={closeModifyOrgsModal}/>
                    </SideModal>
                </div>
            </Col>
        </Row>
    )
}

export default OrganizationTab;