import { apiPost, apiGet } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getAccountDetails(){
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.common.loggedInUserDetails())
            dispatch({
                type: 'USER_DETAILS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getTimeZone() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.settings.timeZone())
            dispatch({
                type: 'TIME_ZONE_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateProfileDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.settings.updateDetails(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'UPDATE_PROFILE_DETAILS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'UPDATE_PROFILE_DETAILS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function generateAPIKey(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.settings.generateAPIKey(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'GENERATE_API_KEY_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GENERATE_API_KEY_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function generate2FAQr() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.settings.generate2FaQr())
            if(response?.status === 200) {
                dispatch({
                    type: 'GENERATE_2FA_QR_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GENERATE_2FA_QR_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function enable2FAAuthentication(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.settings.enable2Fa(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'ENABLE_2FA_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ENABLE_2FA_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function disable2FAAuthentication(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.settings.disable2Fa(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'DISABLE_2FA_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DISABLE_2FA_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function getUserSession(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.settings.userSession()+url)
            if(response?.status === 200) {
                dispatch({
                    type: 'USER_SESSION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'USER_SESSION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function deleteUserSession(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.settings.deleteSession(), obj)
            if(response?.status === 200) {
                dispatch({
                    type: 'DELETE_SESSION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DELETE_SESSION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}

export function userLogOut() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.settings.logOut())
            if(response?.status === 200) {
                dispatch({
                    type: 'USER_LOGOUT_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'USER_LOGOUT_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log("error")
        }
    }
}