import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';

import Button from "../../../ui/Button/index.js";
import { toaster } from "../../../ui/Toaster";

import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerStatus } from "../../../../store/actions/customerAction.js";


import apiEndpoints from "../../../../apiEndpoints.js";

const UpdateCustomerStatusModal = ({show, onHide, selectedCustomer}) => {

    const updateCustomerStatusResponse = useSelector((state) => state.customer?.updateCustomerStatusResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false)

    const confirmUpdate = () => {
        let url = selectedCustomer.status === "active" ? apiEndpoints.customer.deActivateCustomerSingle() : apiEndpoints.customer.activateCustomerSingle()
        let obj = {
            customer_id: selectedCustomer?.customer_id
        }
        setIsLoading(true)
        dispatch(updateCustomerStatus(url, obj))
    }

    useEffect(()=>{
        if(updateCustomerStatusResponse !== null) {
            if(updateCustomerStatusResponse?.detail?.status === "success") {
                toaster.addToast(updateCustomerStatusResponse?.detail?.description, "success")
            } else {
                toaster.addToast(updateCustomerStatusResponse?.description, "error")
            }
            onHide()
        }
    },[updateCustomerStatusResponse, onHide])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("users.updateUserStatusModal.heading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("users.updateUserStatusModal.warningMsg")} {selectedCustomer?.name} {t("users.updateUserStatusModal.status")}?</p>                            
                        </div>
                        <div className="d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Update" buttonType={"primary"} buttonClicked={()=>{confirmUpdate()}} />}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default UpdateCustomerStatusModal;