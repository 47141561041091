import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

const MapChart = () => {
    useEffect(() => {
        // Create root element
        const root = am5.Root.new("chartdiv");

        // Set themes
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create the map chart
        const chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "translateX",
            panY: "translateY",
            projection: am5map.geoMercator()
        }));

        // Disable "Created with amCharts 5" label
        root._logo.dispose();

        // Create main polygon series for countries
        const polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_worldLow,
            exclude: ["AQ"]
        }));

        polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}",
            toggleKey: "active",
            interactive: true,
            fill: am5.color("#35B2FF") // Set color to #35B2FF
        });

        polygonSeries.mapPolygons.template.states.create("hover", {
            fill: root.interfaceColors.get("primaryButtonHover")
        });

        polygonSeries.mapPolygons.template.states.create("active", {
            fill: root.interfaceColors.get("primaryButtonHover")
        });

        let previousPolygon;

        polygonSeries.mapPolygons.template.on("active", (active, target) => {
            if (previousPolygon && previousPolygon !== target) {
                previousPolygon.set("active", false);
            }
            if (target.get("active")) {
                polygonSeries.zoomToDataItem(target.dataItem);
            } else {
                chart.goHome();
            }
            previousPolygon = target;
        });

        // Add zoom control
        const zoomControl = chart.set("zoomControl", am5map.ZoomControl.new(root, {}));
        // Removed home button visibility configuration

        // Set clicking on "water" to zoom out
        chart.chartContainer.get("background").events.on("click", () => {
            chart.goHome();
        });

        // Make stuff animate on load
        chart.appear(1000, 100);

        // Cleanup function to dispose of the chart when the component unmounts
        return () => {
            root.dispose();
        };
    }, []);

    const countries = [
        "United States",
        "Canada",
        "Germany",
        "India",
        "Australia",
        "Brazil",
        "France",
        "China",
        "Japan",
        "South Korea",
        "United Kingdom",
        "Italy",
        "Spain",
        "Russia",
        "Mexico",
    ];

    return (
        <>
            <div className="bg-white-with-shadow p-4 mt-4">
                <Row className="align-items-start">
                    <Col lg={9}>
                        <p className="heading font-bold">Assets by Country</p>
                        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
                    </Col>

                    {/* Country List Section */}
                    <Col lg={3}>
                        <div className="list-of-countries p-4">
                            <p className="heading" style={{ color: "#000" }}>
                                List of Countries
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MapChart;
