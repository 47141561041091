import { apiGet, apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getDomainStats() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.domains.domainStats())
            dispatch({
                type: 'DOMAIN_STATS_SUCCESS',
                payload: response.data
            })
        } catch(error) {

        }
    }
}

export function getDomainDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.domains.domainDetails(), obj)
            dispatch({
                type: 'DOMAIN_DETAILS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function StartScan(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.scan.stratScan(), obj)
            dispatch({
                type: 'START_SCAN_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAssestInventorySubdomain(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.assestInventory.subDomainList()+url)
            dispatch({
                type: 'ASSEST_INVENTORY_SUBDOMAIN_LIST',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAssestInventoryIPAddress(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.assestInventory.ipAddressList()+url)
            dispatch({
                type: 'ASSEST_INVENTORY_IP_ADDRESS_LIST',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getAssestInventoryEmails(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.assestInventory.emailList()+url)
            dispatch({
                type: 'ASSEST_INVENTORY_EMAILS_LIST',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function subDomainInclude(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.assestInventory.subDomainInclude(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'SUBDOMAIN_INCLUDE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SUBDOMAIN_INCLUDE_SUCCESS',
                    payload: response.detail
                })
            }            
        } catch (error) {
            console.log(error)
        }
    }
}

export function subDomainExclude(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.assestInventory.subDomainExclude(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'SUBDOMAIN_EXCLUDE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SUBDOMAIN_EXCLUDE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function ipAddressInclude(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.assestInventory.ipAddressInclude(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'IP_ADDRESS_INCLUDE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'IP_ADDRESS_INCLUDE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function ipAddressExclude(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.assestInventory.ipAddressExclude(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'IP_ADDRESS_EXCLUDE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'IP_ADDRESS_EXCLUDE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function emailInclude(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.assestInventory.emailInclude(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'EMAIL_INCLUDE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'EMAIL_INCLUDE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function emailExclude(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.assestInventory.emailExclude(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'EMAIL_EXCLUDE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'EMAIL_EXCLUDE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetIncludeExcludeResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_INCLUDE_EXCLUDE_RESPONSE'
        })
    }
}

export function deleteDoamin(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.domains.deleteDomain(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'DELETE_DOMAIN_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DELETE_DOMAIN_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetDeleteDomainResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_DELETE_DOMAIN_RESPONSE'
        })
    }
}


export function updateDomainDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.domains.editDoamin(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'UPDATE_DOMAIN_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'UPDATE_DOMAIN_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetUpdateDomainResponse() {
    return (dispatch) => {
        dispatch({
            type: "RESET_UPDATE_DOMAIN_RESPONSE"
        })
    }
}

export function getScanHistory(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scan.scanHistory()+url)
            dispatch({
                type: 'SCAN_HISTORY_SUCCESS',
                payload: response.data
            })
        } catch(error) {
            console.log(error)
        }
    }
}