import React, { useEffect, useState, useRef } from 'react';

import './index.scss';

import searchIcon from "../../../assets/images/common/search-icon.svg";

import ProfileAvatar from "../ProfileAvatar";
import CheckBox from "../CheckBox";

// Icon component
const Icon = ({ isOpen }) => {
    return (
        <svg viewBox="0 0 24 24" width="18" height="18" stroke="#222" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round" className={isOpen ? 'translate' : ''}>
            <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
    );
};

// CloseIcon component
const CloseIcon = () => {
    return (
        <svg viewBox="0 0 24 24" width="14" height="14" stroke="#00308B" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    );
};

const DropDownCh = ({ placeHolder, options, isMulti, isSearchable, onChange, align, dropDownType, resetValue = false }) => {
    // State variables using React hooks
    const [showMenu, setShowMenu] = useState(false); 
    const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null); 
    const [searchValue, setSearchValue] = useState(""); 
    const searchRef = useRef(); 
    const inputRef = useRef(); 

    useEffect(() => {
        setSearchValue("");
        if (showMenu && searchRef.current) {
            searchRef.current.focus();
        }
    }, [showMenu]);

    useEffect(() => {
        const handler = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowMenu(false);
            }
        };

        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    });

    useEffect(() => {
        if (resetValue) {
            setSelectedValue([])
        }
    }, [resetValue])

    const handleInputClick = (e) => {
        setShowMenu(!showMenu);
    };

    const getDisplay = () => {
        if (!isMulti && (!selectedValue || selectedValue.length === 0)) {
            return placeHolder;
        }
        if (isMulti && selectedValue.length > 0) {
            return (
                <div className="dropdown-tags">
                    {
                        selectedValue.map((option, index) => (
                            <div key={`${option.value}-${index}`} className="dropdown-tag-item">
                                {option.label}
                                <span onClick={(e) => onTagRemove(e, option)} className="dropdown-tag-close" >
                                    <CloseIcon />
                                </span>
                            </div>
                        ))
                    }
                </div>
            );
        }
        return selectedValue.label;
    };

    const displaySelectedValue = () => {
        if (selectedValue !== null) {
            return (
                <ProfileAvatar type="domain" name={selectedValue?.name} imageUrl={selectedValue?.logo} imageType={"square"} />
            )
        } else {
            return placeHolder
        }
    }

    const removeOption = (option) => {
        return selectedValue.filter((o) => o.value !== option.value);
    };

    const onTagRemove = (e, option) => {
        e.stopPropagation();
        const newValue = removeOption(option);
        setSelectedValue(newValue);
        onChange(newValue);
    };

    const onItemClick = (option) => {
        let newValue;
        if (isMulti) {
            if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
                newValue = removeOption(option);
            } else {
                newValue = [...selectedValue, option];
            }
        } else {
            newValue = option;
            setShowMenu(false)
        }
        setSelectedValue(newValue);
        onChange(newValue);
    };

    const isSelected = (option) => {
        if (isMulti) {
            return selectedValue.filter((o) => o.value === option.value).length > 0;
        }

        if (!selectedValue) {
            return false;
        }

        return selectedValue.value === option.value;
    };

    const onSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const getOptions = () => {
        if (!searchValue) {
            return options;
        }
        return options.filter(
            (option) =>
                option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        );
    };

    const setCheckboxvalue = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            const checkedValue = options.find((item) => item.value === value);
            if (checkedValue) {
                const newSelectedValue = [...selectedValue, checkedValue];
                setSelectedValue(newSelectedValue);
                onChange(newSelectedValue);
            }
        } else {
            const updatedList = selectedValue.filter((item) => item.value !== value);
            setSelectedValue(updatedList);
            onChange(updatedList);
        }
    };

    const isChecked = (data) => {
        return selectedValue.some((item) => item.value === data.value);
    };

    const setRadioChange = (e) => {
        const checkedValue = options.find(item => item.id === e); // `find` returns the first matching object, not an array
        setSelectedValue(checkedValue); // Set the selected value as an object, not an array
        onChange(checkedValue); // Call onChange to notify parent component if needed
        setShowMenu(false)
    };

    const isRadioChecked = (data) => {
        return selectedValue !== null && selectedValue.id === data.id;
    };

    return (
        <div className="custom--dropdown-container" ref={inputRef}>

            <div onClick={handleInputClick} className="dropdown-input position-relative">
                {isMulti && <div className={"dropdown-selected-value placeholder"}>{placeHolder}</div>}
                {dropDownType === "scanInfo" && <div className={`dropdown-selected-value ${!selectedValue || selectedValue.length === 0 ? 'placeholder' : ''}`}>{displaySelectedValue()}</div>}
                {!isMulti && dropDownType !== "scanInfo" && <div className={`dropdown-selected-value ${!selectedValue || selectedValue.length === 0 ? 'placeholder' : ''}`}>{getDisplay()}</div>}
                {isMulti && <div className={`position-absolute dropdown-selected-value ${!selectedValue || selectedValue.length === 0 ? 'placeholder' : ''}`}>{getDisplay()}</div>}
                <div className="dropdown-tools position-absolute">
                    <div className="dropdown-tool">
                        <Icon isOpen={showMenu} />
                    </div>
                </div>
            </div>

            {
                showMenu && (
                    <div className={`w-100 dropdown-menu alignment--${align || 'auto'} ${showMenu ? 'active' : ''}`}>
                        {
                            isSearchable && (
                                <div className="search-box position-relative">
                                    <img src={searchIcon} alt="search" className="position-absolute" />
                                    <input className="form-control" onChange={onSearch} value={searchValue} ref={searchRef} placeholder="Search" />
                                </div>
                            )
                        }
                        {
                            getOptions().map((option, index) => {
                                if (isMulti) {
                                    return (
                                        <div key={option.id || index} className="d-flex align-items-center dropdown-item">
                                            <span className="ms-2">{option.label}</span>
                                            <CheckBox
                                                checkBoxId={`checkbox-${index}`}
                                                checkBoxValue={option.value}
                                                handleCheckboxChange={(e) => setCheckboxvalue(e)}
                                                isChecked={isChecked(option)}
                                            />
                                            
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div onClick={() => onItemClick(option)} key={option.value} className={`dropdown-item ${isSelected(option) && "selected"}`} >
                                            {option.label}
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                )
            }
        </div>
    );
}

export default DropDownCh;