import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";


import Button from "../../../ui/Button";
import FileUpload from "../../../ui/FileUpload";
import InputBox from "../../../ui/InputBox";
import RadioButton from "../../../ui/RadioButton";
import DropDown from '../../../../components/ui/DropDown';

import { getloggedInUserType } from "../../../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { getTimeZone } from '../../../../store/actions/settingsAction';
import { editUser } from "../../../../store/actions/usersAction";
import { getCustomersList } from '../../../../store/actions/customerAction';

const EditUserModal = ({ userDetails, closeEditUserModal}) => {

    const timeZonseResponse = useSelector((state) => state.settings?.timeZoneList);
    const editUserResponse = useSelector((state) => state?.users?.editUserResponse);
    const customersListResponse = useSelector((state) => state.customer?.customersListResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { userId } = useParams();

    const [responseStatus, setResponseStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState("");
    const [fileObject, setFileObject] = useState(null);    
    const [timeZoneList, setTimeZoneList] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [roleType, setRoleType] = useState("");
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [selectedTimeZone, setSelectedTimeZone] = useState("");
    const [customersList, setCustomersList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [userType, setUserType] = useState("")

    const getUploadfile = (file) => {
        setFiles(file)
    }

    const roleChange = (value) => {
        setRoleType(value)
    }

    const goBack = () => {
        setResponseStatus(false)
        setIsLoading(false)
        closeEditUserModal()
    }

    const handleSelectUser = (value) => {
        setSelectedTimeZone(value.value)
    }

    const saveList = () => {
        let obj = { user_id: userId };
        // Add only the changed fields to the obj
        if (email !== userDetails.email) obj.email = email;
        if (userName !== userDetails.username) obj.username = userName;
        if (firstName !== userDetails.firstname) obj.firstname = firstName;
        if (lastName !== userDetails.lastname) obj.lastname = lastName;
        if (password && password !== userDetails.password) obj.password = password; // Include password only if entered
        if (selectedTimeZone !== userDetails.timezone) obj.timezone = selectedTimeZone;
        if (notificationStatus !== userDetails.notification) obj.notification = notificationStatus;
        if (roleType !== userDetails.role) obj.role = roleType;
        
        const isValidBase64 = /^(data:image\/[a-zA-Z]+;base64,)?([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        if(files && isValidBase64.test(files)) {
            obj.avatar = files
        }
        if(getloggedInUserType() === "internal") {
            let type = userType === "internal" ? true : false
            if (type !== userDetails?.internal_user) obj.is_internal_user = userType;
            if(userType === "customer") {
                if (selectedCustomer !== userDetails?.customer_detail?.customer_id) obj.customer_id = selectedCustomer;
            }            
        }

        if (Object.keys(obj).length === 1) {
            return;
        }
        setIsLoading(true)
        dispatch(editUser(obj))
    };

    const updatevalues = useCallback(()=>{
        if(userDetails) {
            setFirstName(userDetails.firstname || "");
            setLastName(userDetails.lastname || "");
            setUserName(userDetails.username || "");  
            setEmail(userDetails.email || "");          
            setFiles(userDetails.avatar || "");
            setRoleType(userDetails.role || "");
            setNotificationStatus(userDetails.notification || false);
            setSelectedTimeZone(userDetails.timezone || "");
            let obj = {
                name: userDetails.firstname,
                logo: userDetails.avatar
            }
            setFileObject(obj)
            
            if(getloggedInUserType() === "internal") {             
                if(userDetails?.internal_user) {
                    setUserType("internal");
                } else {                    
                    setUserType("customer");
                    setSelectedCustomer(userDetails?.customer_detail?.customer_id)
                }
            }
        }
    },[userDetails])

    const customerChange = (value) => {
        setSelectedCustomer(value.value);
    }

    const fetchCustomersList = useCallback(()=>{
        dispatch(getCustomersList());
    },[dispatch])

    useEffect(() => {
        if (getloggedInUserType() === "internal") {
            fetchCustomersList();
        }
    }, [fetchCustomersList]);

    useEffect(()=>{
        updatevalues();
        dispatch(getTimeZone())      
    },[updatevalues, dispatch])

    useEffect(()=>{
        if(timeZonseResponse != null) {
            const list = timeZonseResponse?.timezones.map((data)=>{
                return {
                    label: data,
                    value: data
                }
            })
            setTimeZoneList(list)
        }
    },[timeZonseResponse])

    useEffect(()=>{
        if(editUserResponse !== null) {
            setIsLoading(false);
            setResponseStatus(true);
        }
    },[editUserResponse])

    useEffect(() => {
        if (customersListResponse) {
            const list = customersListResponse.results.map((data) => ({
                label: data.name,
                value: data.customer_id,
            }));
            setCustomersList(list);
        }
    }, [customersListResponse]);

    return (
        <div className="add-organization-container add-domain-container">
            {!responseStatus && <>
                <div className="modal-header mt-4">
                    <h2>{t("users.editUserModal.heading")}</h2>
                </div>
                <div className="add-organization-step-one edit-user-container mb-5">
                    <FileUpload onFilesSelected={getUploadfile} label={'Upload an Avatar'} filePath={files} place="editUser" fileObject={fileObject} />
                    <Row>
                        <Col lg={6}>
                            <div className="input-container mt-4">
                                <InputBox placeholder={'First Name'} inputType={'text'} inputLabel={'First Name'} inputChange={(val)=>{setFirstName(val)}} inputValue={firstName} />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="input-container mt-4">
                                <InputBox placeholder={'Last Name'} inputType={'text'} inputLabel={'Last Name'} inputChange={(val)=>{setLastName(val)}} inputValue={lastName} />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="input-container mt-2">
                                <InputBox placeholder={'Username'} inputType={'text'} inputLabel={'Username'} inputChange={(val)=>{setUserName(val)}} inputValue={userName} />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="input-container mt-2">
                                <InputBox placeholder={'Password'} inputType={'password'} inputLabel={'Password'} inputChange={(val)=>{setPassword(val)}} inputValue={password} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={12}>
                            <div className="input-container mt-2">
                                <InputBox placeholder={'Email ID'} inputType={'text'} inputLabel={'Email ID'} inputChange={(val)=>{setEmail(val)}} inputValue={email} />
                            </div>
                        </Col>
                    </Row>
                    <div className="organization-type">
                        <p>{t("users.editUserModal.role")}</p>
                        <div className="radio-btn-group d-flex mt-3">
                            <RadioButton radioId={'administrator'} radioLabel={'Administrator'} radioValue={'administrator'} onChange={roleChange}  isChecked={roleType === 'administrator'}/>
                            <RadioButton radioId={'user'} radioLabel={'User'} radioValue={'user'} onChange={roleChange} isChecked={roleType === 'user'}/>
                            <RadioButton radioId={'readOnly'} radioLabel={'Read Only'} radioValue={'readOnly'} onChange={roleChange} isChecked={roleType === 'readOnly'}/>
                        </div>
                    </div>
                    <div className="organization-type mt-4">
                        <p className="mb-3">{t("users.editUserModal.notificationStatus")}</p>
                        <div className={`switch-outer position-relative edit-mode ${notificationStatus ? "active" : ""}`} onClick={()=>{setNotificationStatus(!notificationStatus)}}>
                            <span></span>
                        </div>
                    </div>
                    <div className="details time-zone-width mt-4">
                        <p className="mb-3">{t("users.editUserModal.timeZone")}</p>
                        {timeZoneList !== null && <DropDown
                            options={timeZoneList}
                            placeHolder='Please select time zone'
                            onChange={(e) => handleSelectUser(e)}
                            isSearchable = {true}
                            defaultSelectedValue={selectedTimeZone}
                        />}
                    </div>
                    {getloggedInUserType() === "internal" && <>
                        <div className="organization-type mt-4">
                            <p>User type</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'internal'} radioLabel={'Internal'} radioValue={'internal'} onChange={(value)=>{setUserType(value)}}  isChecked={userType === 'internal'}/>
                                <RadioButton radioId={'customer'} radioLabel={'Customer'} radioValue={'customer'} onChange={(value)=>{setUserType(value)}} isChecked={userType === 'customer'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-4">
                            <p className="mb-2">Select Customer</p>
                                <DropDown
                                    options={customersList}
                                    placeHolder={"Select Customer"}
                                    onChange={customerChange}
                                    isSearchable= {true}
                                    isMulti={false}
                                    defaultSelectedValue={selectedCustomer}
                                    disabled={userType === 'internal'}
                                />
                        </div>
                    </>}
                </div>
                <div className="form-submit-btn-group mt-4">
                    <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{goBack()}}/>
                    {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{saveList()}} />}
                    {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true} />}
                </div>
            </>}
            {responseStatus && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {editUserResponse?.detail?.status === "success" && <>
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{editUserResponse?.detail?.description}</h2>
                    </>}
                    {editUserResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                            <div className="mt-5">
                                <img src={failed} alt="failed" />
                            </div>
                            <h2 className="mb-4 mt-5 fs-4">{editUserResponse?.description}</h2>
                            </div>}
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={()=>{goBack()}} />
                    </div>
                </div>
            </>}
        </div>
    )
}

export default EditUserModal;