import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";

import InputBox from "../../../ui/InputBox";
import RadioButton from '../../../ui/RadioButton';
import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";

import { getloggedInUserType } from "../../../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { inviteUserSingle } from '../../../../store/actions/usersAction';
import { getCustomersList } from '../../../../store/actions/customerAction';

const InviteUserModal = ({closeInviteUser}) => {

    const { t } = useTranslation();

    const inviteUserResponse = useSelector((state) => state.users?.inviteUserResponse);
    const customersListResponse = useSelector((state) => state.customer?.customersListResponse);

    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [roleType, setRoleType] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [responseStatus, setResponseStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [customersList, setCustomersList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [userType, setUserType] = useState("")

    const goBack = () => {
        setResponseStatus(false)
        setIsLoading(false)
        closeInviteUser()
    }

    const roleChange = (value) => {
        setRoleType(value)
    }

    const inviteUser = () => {
        let obj = {
            name: name,
            email: userEmail,
            role: roleType
        }
        if(getloggedInUserType() === "customer") {
            setIsLoading(true)
            dispatch(inviteUserSingle(obj))
        } else {
            if(userType === "internal") {
                obj.is_internal_user = true
            } else {
                obj.is_internal_user = false
                obj.customer_id = selectedCustomer?.value
            }
            setIsLoading(true)
            dispatch(inviteUserSingle(obj))
        }        
    }

    const customerChange = (value) => {
        setSelectedCustomer(value);
    }

    const fetchCustomersList = useCallback(()=>{
        dispatch(getCustomersList());
    },[dispatch])

    useEffect(() => {
        if (getloggedInUserType() === "internal") {
            fetchCustomersList();
        }
    }, [fetchCustomersList]);

    useEffect(()=>{
        if(inviteUserResponse != null) {
            setResponseStatus(true)
            setIsLoading(false)
        }
    },[inviteUserResponse])

    useEffect(() => {
        if (customersListResponse) {
            const list = customersListResponse.results.map((data) => ({
                label: data.name,
                value: data.customer_id,
            }));
            setCustomersList(list);
        }
    }, [customersListResponse]);

    return (
        <>
            <div className="modal-header mt-4">
                <h2>{t("users.inviteUserModal.heading")}</h2>
            </div>
            {!responseStatus && <div className="add-organization-container mt-3">
                <h4>{t("users.inviteUserModal.subHeading")}</h4>
                <div className="add-organization-step-one mt-3">
                    <div className="input-container mt-4">
                        <InputBox placeholder={'Enter Name'} inputType={'text'} inputLabel={'What is the Name of the User?'} inputChange={(val)=>{setName(val)}} inputValue={name} />
                    </div>                      
                    <div className="input-container">
                        <InputBox placeholder={'Enter Email ID'} inputType={'text'} inputLabel={'What is the Email ID?'} inputChange={(val)=>{setUserEmail(val)}} inputValue={userEmail} />
                    </div>
                    <div className="organization-type">
                        <p>{t("users.inviteUserModal.selectRole")}</p>
                        <div className="radio-btn-group d-flex mt-3">
                            <RadioButton radioId={'administrator'} radioLabel={'Administrator'} radioValue={'administrator'} onChange={roleChange}  isChecked={roleType === 'administrator'}/>
                            <RadioButton radioId={'user'} radioLabel={'User'} radioValue={'user'} onChange={roleChange} isChecked={roleType === 'user'}/>
                            <RadioButton radioId={'readOnly'} radioLabel={'Read Only'} radioValue={'readOnly'} onChange={roleChange} isChecked={roleType === 'readOnly'}/>
                        </div>
                    </div>
                    {getloggedInUserType() === "internal" && <>
                        <div className="organization-type mt-4">
                            <p>Select User type</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'internal'} radioLabel={'Internal'} radioValue={'internal'} onChange={(value)=>{setUserType(value)}}  isChecked={userType === 'internal'}/>
                                <RadioButton radioId={'customer'} radioLabel={'Customer'} radioValue={'customer'} onChange={(value)=>{setUserType(value)}} isChecked={userType === 'customer'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-4">
                            <p className="mb-2">Select Customer</p>
                                <DropDown
                                    options={customersList}
                                    placeHolder={"Select Customer"}
                                    onChange={customerChange}
                                    isSearchable= {true}
                                    isMulti={false}
                                    disabled={userType === 'internal'}
                                />
                        </div>
                    </>}
                </div>
                <div className="form-submit-btn-group mt-4">
                    <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{goBack()}}/>
                    {!isLoading && <Button buttonText={"Save"} buttonType="primary" disableButton={userEmail === "" || roleType === ""} buttonClicked={inviteUser}/>}
                    {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                </div>
            </div>}
            {responseStatus && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {inviteUserResponse?.detail?.status === "success" && <>
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{inviteUserResponse?.detail?.description}</h2>
                    </>}
                    {inviteUserResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                            <div className="mt-5">
                                <img src={failed} alt="failed" />
                            </div>
                            <h2 className="mb-4 mt-5 fs-4">{inviteUserResponse?.description}</h2>
                            </div>}
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={()=>{goBack()}} />
                    </div>
                </div>
            </>}
        </>
    )
}

export default InviteUserModal;