
const initialState = {
    userStatsResponse: null,
    userDetails: null,
    organizationListByUserResponse: null,
    inviteUserResponse: null,
    editUserResponse: null,
    userSessionById: null,
    deleteUserResponse: null,
    updateUserStatusSingleResponse: null
}

export default function usersReducers(state=initialState, action) {
    switch(action.type) {
        case 'USER_STATS_SUCCESS':
            return {
                ...state,
                userStatsResponse: action.payload
            }
        case 'USER_DETAILS_SUCCESS' :
            return {
                ...state,
                userDetails: action.payload
            }
        case 'ORGANIZATION_LIST_BY_USER_SUCCESS' :
            return {
                ...state,
                organizationListByUserResponse: action.payload
            }
        case 'INVITE_USER_SINGLE_SUCCESS' :
            return {
                ...state,
                inviteUserResponse: action.payload
            }
        case 'EDIT_USER_SUCCESS' :
            return {
                ...state,
                editUserResponse: action.payload
            }
        case 'GET_USER_SESSION_BY_ID_SUCCESS':
            return {
                ...state,
                userSessionById: action.payload
            }
        case 'DELETE_USER_ID_SUCCESS':
            return {
                ...state,
                deleteUserResponse: action.payload
            }
        case 'UPDATE_USER_STATUS_SINGLE_SUCCESS': 
            return {
                ...state,
                updateUserStatusSingleResponse: action.payload
            }
        case 'RESET_UPDATE_USER_STATUS_RESPONSE':
            return {
                ...state,
                updateUserStatusSingleResponse: null
            }
        default:
            return state;
    }
}