import { apiGet, apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getMonitorStats() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.monitor.monitorStats());
            dispatch({
                type: 'MONITOR_STATS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error);
        }
    }    
}

export function getMonitorList(url="") {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.monitor.monitorList()+url);
            dispatch({
                type: 'MONITOR_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    };
}

export function addMonitor(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.monitor.addMonitorSingle(), obj)
            if(response.status === 200) {
                dispatch({
                    type: 'ADD_MONITOR_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ADD_MONITOR_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {

        }
    }
}

export function resetAddMonitorResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_ADD_MONITOR_RESPONSE'
        })
    }
}

export function getMonitorDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.monitor.monitorDetail(), obj)
            dispatch({
                type: 'MONITOR_DETAILS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export function editMonitor(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.monitor.editMonitor(), obj)
            if(response.status === 200) {
                dispatch({
                    type:  'EDIT_MONITOR_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type:  'EDIT_MONITOR_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetEditMonitorResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_EDIT_MONITOR_RESPONSE'
        })
    }
}

export function deleteMonitor(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.monitor.deleteMonitor(), obj)
            if(response.status === 200) {
                dispatch({
                    type: 'DELETE_MONITOR_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DELETE_MONITOR_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetDeleteMonitorResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_DELETE_MONITOR_RESPONSE'
        })
    }
}

export function customersListForMonitors(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.monitor.customersList()+url)
            if(response.status === 200) {
                dispatch({
                    type: 'CUSTOMERS_LIST_FOR_MONITOR',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'CUSTOMERS_LIST_FOR_MONITOR',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}
