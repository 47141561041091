import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import warning from "../../../../assets/images/common/warning.svg";

import Button from "../../../../components/ui/Button";

import { useSelector, useDispatch } from 'react-redux';
import { enable2FAAuthentication, disable2FAAuthentication } from '../../../../store/actions/settingsAction';

const Setup2FaModal = ({show, onHide, qrCode, isActivate2Fa}) => {

    const enable2FaResponse = useSelector((state) => state.settings?.enable2FaResponse);
    const disable2FaResponse = useSelector((state) => state.settings?.disable2FaResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const otpBoxReference = useRef([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e, index) => {
        const { value } = e.target;

        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);
    
        // Check if there’s a next input box before focusing
        if (value && index < otp.length - 1 && otpBoxReference.current[index + 1]) {
            otpBoxReference.current[index + 1].focus();
        }
    };

    const handleBackspaceAndEnter = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            // Check if there’s a previous input box before focusing
            if (otpBoxReference.current[index - 1]) {
                otpBoxReference.current[index - 1].focus();
            }
        }
        if (e.key === "Enter" && e.target.value && index < otp.length - 1) {
            if (otpBoxReference.current[index + 1]) {
                otpBoxReference.current[index + 1].focus();
            }
        }
    };

    const onKeyDown = (e) => {
        const keyCode = parseInt(e.key);
        if (
            e.key !== 'Backspace' &&
            e.key !== 'Delete' &&
            e.key !== 'Tab' &&
            !(e.metaKey && e.key === 'v') &&
            !(keyCode >= 0 && keyCode <= 9)
        ) {
            e.preventDefault();
        }
    };

    const isDisabledButton = () => {
        return otp.includes("");
    };

    const enable2Fa = () => {
        let obj = {
            totp_code: otp.join("")
        }
        setIsLoading(true)
        dispatch(enable2FAAuthentication(obj));
    }

    const disable2Fa = () => {
        let obj = {
            totp_code: otp.join("")
        }
        setIsLoading(true)
        dispatch(disable2FAAuthentication(obj));
    }

    useEffect(()=>{
        if(enable2FaResponse != null) {
            setOtp(new Array(6).fill(""))
            setIsLoading(false);
        }
    },[enable2FaResponse])

    useEffect(()=>{
        if(disable2FaResponse != null) {
            setOtp(new Array(6).fill(""))
            setIsLoading(false);
        }
    },[disable2FaResponse])


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            >
                <Modal.Body>
                    {!isActivate2Fa && <div className="setup-2fa-container p-3">
                        <h4>{t("accountSettingPage.activate2FaModal.activateHeading")}</h4>
                        <p>{t("accountSettingPage.activate2FaModal.activateDesc")}</p>
                        <div className="qr-section d-flex justify-content-center">
                            <img src={qrCode} alt="qr code" className="w-100 h-100"/>
                        </div>
                        <div className="code-container my-4">
                            <p>{t("accountSettingPage.activate2FaModal.enter6Digit")}</p>
                            <div className="d-flex gap-3 mt-3">
                                {otp.map((digit, index) => (
                                    <input 
                                        key={index} 
                                        value={digit} 
                                        maxLength={1}  
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                        onKeyDown={(e) => onKeyDown(e)}
                                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                                        className="otp-input-box"
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="d-flex gap-3">
                            <Button buttonText={"Cancel"} buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText={"Verify & Continue"} buttonType={"primary"} buttonClicked={()=>{enable2Fa()}} disableButton={isDisabledButton()}/>}
                            {isLoading && <Button buttonText={"Loading..."} buttonType={"primary"} disableButton={true}/>}
                        </div>
                    </div>}
                    {
                        isActivate2Fa && <div className="setup-2fa-container p-3">
                            <h4>{t("accountSettingPage.activate2FaModal.deactivateHeading")}</h4>
                            <p>{t("accountSettingPage.activate2FaModal.deactivateDesc")}</p>
                            <div className="deactivate-warning-container d-flex align-items-center gap-2 rounded my-4">
                                <img src={warning} alt="warning"/>
                                <p>{t("accountSettingPage.activate2FaModal.warningMsg")}</p>
                            </div>
                            <div className="code-container my-4">
                                <p>{t("accountSettingPage.activate2FaModal.authenticationCode")}</p>
                                <div className="d-flex gap-3 mt-3">
                                    {otp.map((digit, index) => (
                                        <input 
                                            key={index} 
                                            value={digit} 
                                            maxLength={1}  
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                            onKeyDown={(e) => onKeyDown(e)}
                                            ref={(reference) => (otpBoxReference.current[index] = reference)}
                                            className="otp-input-box"
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex gap-3">
                                <Button buttonText={"Cancel"} buttonType={"secondary"} buttonClicked={onHide}/>
                                {isDisabledButton() && <Button buttonText={"Deactivate"} buttonType={"primary"} disableButton={isDisabledButton()}/>}
                                {!isDisabledButton() && isLoading && <Button buttonText={"Loading..."} buttonType={"primary"} disableButton={true}/>}
                               { !isDisabledButton() && !isLoading && <button className="deactivate-2fa-button" onClick={disable2Fa}>{t("accountSettingPage.activate2FaModal.deActivate")}</button>}
                            </div>
                        </div>
                    }
                </Modal.Body>
            
        </Modal>
    )
}

export default Setup2FaModal;