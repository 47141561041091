import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


// import domainIcon from "../../../../assets/images/common/domain.svg";

import Button from "../../../../components/ui/Button";
import { toaster } from "../../../ui/Toaster";

import { useDispatch, useSelector } from 'react-redux';
import { deleteMonitor } from '../../../../store/actions/monitorAction';


const DeleteMonitorModal = ({show, onHide, monitorDetails}) => {

    const deleteMonitorResponse = useSelector((state) => state.monitor?.deleteMonitorResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    const confirmDelete = () => {
        let obj = {
            monitor_id: monitorDetails?.monitor_id
        }
        setIsLoading(true)
        dispatch(deleteMonitor(obj))
    }

    useEffect(()=>{        
        if(deleteMonitorResponse !== null) {
            onHide()
            if(deleteMonitorResponse?.detail.status === "success") {    
                navigate('/monitor')            
                toaster.addToast(deleteMonitorResponse?.detail.description, "success")
            } else {
                toaster.addToast(deleteMonitorResponse?.description, "error")
            }
        }
    },[deleteMonitorResponse, onHide, navigate])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>Delete Monitor</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("organizationDetailsPage.deleteOrganization.subText")} {monitorDetails?.monitor_domain?.domain}?</p>
                        </div>
                        <div className="delete-organization-btn d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Delete" buttonType={"primary"} buttonClicked={()=>{confirmDelete()}} />}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default DeleteMonitorModal;