import { React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";

import SideNav from "../../components/PostLogin/SideNav";
import DomainTileView from '../../components/PostLogin/Domain/DomainTileView';
import DomainListView from '../../components/PostLogin/Domain/DomainListView';
import SideModal from '../../components/ui/SideModal';
import AddDomainModal from '../../components/PostLogin/Domain/AddDomainModal';
import Pagination from '../../components/ui/Pagination';
import Spinner from "../../components/ui/spinner";
import NoDataFound from "../../components/ui/NoDataFound";
import { toaster } from "../../components/ui/Toaster";

import { useSelector, useDispatch } from 'react-redux';
import { getDomainList, resetAddDomainResponse, resetDomainListResponse } from '../../store/actions/organizationActions.js';
import { resetDeleteDomainResponse, getDomainStats } from "../../store/actions/domainAction.js";
import { resetStopScanSingleResponse, resetResumeScanSingleResponse, resetDeleteScanSingleResponse } from "../../store/actions/scanqueueAction.js"

const Domain = () => {

    const domainStatusResponse = useSelector((state) => state.domain?.domainStatusResponse);
    const domainListResponse = useSelector((state) => state.organization?.domainList);
    const addDomainResponse = useSelector((state) => state.organization?.addDomainResponse);
    const deleteDomainResponse = useSelector((state) => state.domain?.deleteDomainResponse);

    const stopScanSingleResponse = useSelector((state) => state.scanQueue?.stopScanSingleResponse);
    const resumeScanSingleResponse = useSelector((state) => state.scanQueue?.resumeScanSingleResponse);
    const deleteScanSingleResponse = useSelector((state) => state.scanQueue?.deleteScanSingleResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [viewType, setViewType] = useState('tile');
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isToastShown, setIsToastShown] = useState(false);
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }

    const searchDomain = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setCurrentPage(1);
    }

    const fetchDomainList = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${searchValue}`;
        setIsLoading(true);
        dispatch(getDomainList(url));
    },[searchValue, currentPage, currentPageSize, dispatch])

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchStats = useCallback(()=>{
        setIsStatsLoading(true);
        dispatch(getDomainStats());
    },[dispatch])

    useEffect(() => {
        if(domainListResponse!= null) {
            setIsLoading(false)
        }
    }, [domainListResponse]);

    useEffect(()=>{
        fetchDomainList();
        fetchStats();
    },[fetchDomainList, fetchStats])

    const closeAddDomain = () => {
        closeAddOrgSidePanel()
        if(addDomainResponse != null) {
            if(addDomainResponse?.detail?.status === "success") {
                fetchDomainList()
                fetchStats();
                dispatch(resetAddDomainResponse())
            }
        }
    }

    useEffect(()=>{
        if(deleteDomainResponse != null && !isToastShown) {
            if(deleteDomainResponse?.detail?.status === "success") {
                setIsLoading(true);
                fetchDomainList();
                fetchStats();
                dispatch(resetDeleteDomainResponse())
                toaster.addToast(deleteDomainResponse?.detail?.description, "success");
                setIsToastShown(true);
            }
        }
    },[dispatch, deleteDomainResponse, fetchDomainList, isToastShown, fetchStats])

    useEffect(() => {
        if (deleteDomainResponse === null) {
            setIsToastShown(false); // Reset the flag to allow future toasts
        }
    }, [deleteDomainResponse]);

    useEffect(()=>{
        if(stopScanSingleResponse !== null) {
            if(stopScanSingleResponse?.detail?.status === "success") {
                dispatch(resetDomainListResponse())
                toaster.addToast(stopScanSingleResponse?.detail?.description, "success");
                fetchDomainList();
                fetchStats();
            } else {
                toaster.addToast(stopScanSingleResponse?.description, "error");
            }
            dispatch(resetStopScanSingleResponse())
        }
    },[stopScanSingleResponse, fetchDomainList, dispatch, fetchStats])

    useEffect(()=>{
        if(resumeScanSingleResponse !== null) {
            if(resumeScanSingleResponse?.detail?.status === "success") {
                dispatch(resetDomainListResponse())
                toaster.addToast(resumeScanSingleResponse?.detail?.description, "success");
                fetchDomainList();
                fetchStats();
            } else {
                toaster.addToast(resumeScanSingleResponse?.description, "error");
            }
            dispatch(resetResumeScanSingleResponse())
        }
    },[resumeScanSingleResponse, fetchDomainList, dispatch, fetchStats])

    useEffect(()=>{
        if(deleteScanSingleResponse !== null) {
            if(deleteScanSingleResponse?.detail?.status === "success") {
                dispatch(resetDomainListResponse())
                toaster.addToast(deleteScanSingleResponse?.detail?.description, "success");
                fetchDomainList();
                fetchStats();
            } else {
                toaster.addToast(deleteScanSingleResponse?.description, "error");
            }
            dispatch(resetDeleteScanSingleResponse())
        }
    },[deleteScanSingleResponse, fetchDomainList, dispatch, fetchStats])

    useEffect(()=>{
        if(domainStatusResponse !== null){
            setIsStatsLoading(false);
        }
    },[domainStatusResponse])


    return (
        <div className="domain-container">
            <SideNav />
            <div className="domain-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("domainPage.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={() => { setShowAddOrgSidePanel(true) }}><span>&#43;</span>{t("domainPage.addDomain")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search Domains' className="search-input" onChange={searchDomain}/>
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={() => setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={() => setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>


                <div className="page-main-section p-4">
                    <div className="total-domain-scans d-flex mt-3 rounded">
                        <div className="total-domains-container rounded p-3 margin-right">
                            <h4 className="m-0 mt-1">{isStatsLoading ? "...." : domainStatusResponse?.domains?.total}</h4>
                            <p className='mt-2'>{t("domainPage.totalDomains")}</p>
                        </div>
                        <div className="total-domains-container rounded p-3">
                            <div className='domain-icon'>
                                <h4 className="m-0 mt-1">{isStatsLoading ? "...." : domainStatusResponse?.scans?.total}</h4>
                                <div className="user-count mx-3 mt-2">
                                    <span className='active-icon d-inline-block'></span>
                                    <span className='passive-icon d-inline-block'></span>
                                    <p className='total-scans-text'>{t("domainPage.totalScans")}</p>
                                </div>
                            </div>
                            <div className="p-wrapper d-flex justify-content-between gap-3">
                                <p className='mt-2'><span className='count d-inline-block'>{domainStatusResponse?.scans?.active}</span>{t("domainPage.activeScans")}</p>
                                <p className='mt-2'><span className='count-passive'>{domainStatusResponse?.scans?.active}</span>{t("domainPage.passiveScans")}</p>
                            </div>
                        </div>                        
                    </div>
                    <h2 className="mb-0">{t("domainPage.pageHeading")}</h2>
                    {
                        isLoading ? 
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                        :
                        <>
                            {viewType === 'tile' && domainListResponse?.results.length > 0 && <DomainTileView domainList={domainListResponse}/>}
                            {viewType === 'list' && domainListResponse?.results.length > 0 && <DomainListView domainList={domainListResponse}/>}
                            {domainListResponse != null && domainListResponse?.results.length > 0 && <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        pageSize={currentPageSize}
                                        totalCount={domainListResponse.meta.total_results}
                                        onPageChange={setCurrentPageandRecordSize}
                                    />}
                        </>
                    }
                    {
                        !isLoading && domainListResponse?.meta?.total_results === 0 &&
                        <NoDataFound />
                    }
                    
                </div>
            </div>
            <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                <AddDomainModal closeAddDomain={closeAddDomain}/>
            </SideModal>
        </div>
    )
}

export default Domain;