
const initialState = {
    monitorStatsResponse: null,
    monitorList: null,
    addMonitorResponse: null,
    monitorDetailsResponse: null,
    editMonitorResponse: null,
    deleteMonitorResponse: null,
    customersListForMonitorsResponse: null
}

export default function monitorReducer(state=initialState, action) {
    switch(action.type) {
        case 'MONITOR_STATS_SUCCESS' :
            return {
                ...state,
                monitorStatsResponse: action.payload
            }
        case 'MONITOR_LIST_SUCCESS' :
            return {
                ...state,
                monitorList: action.payload
            }
        case 'ADD_MONITOR_SUCCESS' :
            return {
                ...state,
                addMonitorResponse: action.payload
            }
        case 'RESET_ADD_MONITOR_RESPONSE':
            return {
                ...state,
                addMonitorResponse: null
            }
        case 'MONITOR_DETAILS_SUCCESS':
            return {
                ...state,
                monitorDetailsResponse: action.payload
            }
        case 'EDIT_MONITOR_SUCCESS':
            return {
                ...state,
                editMonitorResponse: action.payload
            }
        case 'RESET_EDIT_MONITOR_RESPONSE':
            return {
                ...state,
                editMonitorResponse: null
            }
        case 'DELETE_MONITOR_SUCCESS':
            return {
                ...state,
                deleteMonitorResponse: action.payload
            }
        case 'RESET_DELETE_MONITOR_RESPONSE': 
            return {
                ...state,
                deleteMonitorResponse: null
            }
        case 'CUSTOMERS_LIST_FOR_MONITOR':
            return {
                ...state,
                customersListForMonitorsResponse: action.payload
            }
        default:
            return state;
    }
}