import { React, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
// import { useNavigate } from "react-router-dom";

import Button from "../../components/ui/Button";


import verify2 from "../../assets/images/common/verify2.svg";
import tickCircleGreen from "../../assets/images/common/tick-transparent.svg"; // Import tick icon

import "./index.scss";
import { useTranslation } from "react-i18next";

import LeftSection from "../../components/PreLogin/LoginPage/LeftSection.js";
import RightFooter from "../../components/PreLogin/RightFooter";

const AccountValidated = () => {
    const { t } = useTranslation();
    // const navigate = useNavigate();

    useEffect(() => {

    }, []);

    return (
        <div className='validated-container'>
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='validated-right-section px-4 mx-3 h-100'>
                        <div className='right-container'>
                            <div>
                                <div> <img src={verify2} alt="progress" /></div>
                                <div>  <img src={tickCircleGreen} alt="confirmation tick" /></div>
                                <h2>{t("registrationPage.accountValidated.validate")}</h2>
                                <p>{`You have successfuly validatet your account.`}</p>
                                <p>{`You can now log into your account.`}</p>
                                <div className='submit-button mt-4'>
                                    <Button buttonText={"Login"} buttonType="primary"   />
                                </div>
                            </div>

                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AccountValidated;
