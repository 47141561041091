import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";
import filterIcon from "../../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../../assets/images/common/search-icon.svg";

import ProfileAvatar from "../../../../../components/ui/ProfileAvatar";

import SSLCertificate from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/AssociatedDomain/SSLCertificate";
import EndPoints from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/Technologies/EndPoints";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getSSLCerificateList } from "../../../../../store/actions/scanInfoAction";

import { formatDate } from "../../../../../utils/util";

const SSLRecords = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const sslCertificateListResponse = useSelector((state) => state.scanInfo?.sslCertificates);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeTab, setActiveTab] = useState("sslInformation");
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTechnology, setSelectedTechnology] = useState("");

    const fetchTechnologyList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=20&domain=${domainId}&scan_id=${scanId}`
        setIsLoading(true);
        dispatch(getSSLCerificateList(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchTechnologyList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, fetchTechnologyList, scanId])

    useEffect(()=>{
        if(sslCertificateListResponse !== null) {
            setSelectedTechnology(sslCertificateListResponse?.results[0])
            setIsLoading(false);
        }
    },[sslCertificateListResponse])


    return (
        <>
            <div className="scan-info-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="sub-domain-type-container web-server-type-container rounded p-3 h-100">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">SSL Issuer</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-records-container">
                                    <p className="content">2.6K <span>Total Records</span></p>
                                </div>
                                <div className="sub-domain-content">
                                    <Row className="mt-3">
                                        <Col lg={4}>
                                            <div className="d-flex align-items-center gap-3">
                                                <ProfileAvatar type="profile" firstName="151" lastName="" secondaryText="lollypop" imageUrl={dummyImage} />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="d-flex align-items-center gap-3">
                                                <ProfileAvatar type="profile" firstName="151" lastName="" secondaryText="lollypop" imageUrl={dummyImage} />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="d-flex align-items-center gap-3">
                                                <ProfileAvatar type="profile" firstName="151" lastName="" secondaryText="lollypop" imageUrl={dummyImage} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">SSL Record</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">1.8K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page ports-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">SSL Expiry</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">3.3K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Ports</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> &lt; 30 days</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> &lt; 60 days</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> &lt; 90 days</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Certificate List</h1>
                                    <img src={filterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container">
                                    {
                                        sslCertificateListResponse?.results.length > 0 &&
                                        sslCertificateListResponse?.results.map((data, index) => {
                                            return (
                                                <div className={`sub-domain-list py-2 px-3 ${selectedTechnology?.id === data?.id ? "active" : ""}`} key={index} onClick={()=>{setSelectedTechnology(data)}}>
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <p className="sub-domain-heading">{data?.fingerprints?.sha256 !== null ? data?.fingerprints?.sha256 : data?.fingerprints?.sha1}</p>
                                                        {data.new_record && <span className="new-subdomain">New</span>}
                                                    </div>
                                                    <p className="sub-domain-name">{data?.domain} | {formatDate(data?.created_at)}</p>
                                                    {data?.expired && <span className="inactive">Expired</span>}
                                                    {data?.self_signed && <span className="potential">Self Signed</span>}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="bg-white-with-shadow sub-domain-main-container p-3 h-100">
                                <div className="tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "sslInformation" ? "active" : ""}`} onClick={()=>{setActiveTab('sslInformation')}}>SSL Record Information</p>
                                    <p className={`rounded text-center ${activeTab === "endpoints" ? "active" : ""}`} onClick={()=>{setActiveTab('endpoints')}}>Present In</p>
                                </div>
                                <div className="tab-details-content mt-3">
                                    {activeTab === "sslInformation" && <SSLCertificate sslCertificateData={selectedTechnology}/>}
                                    {activeTab === "endpoints" && <EndPoints currentPageType={"sslRecord"} selectedTechnology={selectedTechnology} domainId={domainId} scanId={scanId}/>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        
    )
}


export default SSLRecords;