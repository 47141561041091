import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import login from "../../../../assets/images/common/login.svg";
import logout from "../../../../assets/images/common/logout.svg";

import Pagination from '../../../ui/Pagination';
import SideModal from '../../../ui/SideModal';
import Spinner from "../../../ui/spinner";
import { toaster } from "../../../ui/Toaster";
import NoDataFound from "../../../ui/NoDataFound";


import SubDomains from '../../../PostLogin/DomainDetails/AssetsTab/SubDomains';
import IpAddress from '../../../PostLogin/DomainDetails/AssetsTab/IpAddress';
import Emails from '../../../PostLogin/DomainDetails/AssetsTab/Emails';
import AddDomain from "../../../PostLogin/OrganizationDetails/AddDomain";

// const filterCheckboxOption = [
//     { label: "Administrator", value: "Administrator" },
//     { label: "User", value: "User" },
//     { label: "Read Only", value: "Read Only" }
// ];

// const filterRadioOption = [
//     { label: "Both", value: "Both" },
//     { label: "Active", value: "Active" },
//     { label: "Inactive", value: "Inactive" }
// ];

import { useSelector, useDispatch } from 'react-redux';
import { getAssestInventorySubdomain, 
        getAssestInventoryIPAddress, 
        getAssestInventoryEmails, 
        subDomainInclude, 
        subDomainExclude,
        ipAddressInclude,
        ipAddressExclude,
        emailInclude,
        emailExclude,
        resetIncludeExcludeResponse,
        } from '../../../../store/actions/domainAction';

const AssetsTab = ({doaminData}) => {

    const subDomainListResponse = useSelector((state) => state.domain?.assestInventorySubdomainList);
    const ipAddressListResponse = useSelector((state)=> state.domain?.assestInventoryIpAddressList);
    const emailListResponse = useSelector((state)=> state.domain?.assestInventoryEmailList);
    const subDomainIncludeResponse = useSelector((state)=> state.domain?.subDomainIncludeResponse);
    const subDomainExcludeResponse = useSelector((state) => state.domain?.subDomainExcludeResponse);
    const ipAddressIncludeResponse = useSelector((state) => state.domain?.ipAddressIncludeResponse);
    const ipAddressExcludeResponse = useSelector((state) => state.domain?.ipAddressExcludeResponse);
    const emailIncludeResponse = useSelector((state) => state.domain?.emailIncludeResponse);
    const emailExcludeResponse = useSelector((state) => state.domain?.emailExcludeResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const filterWrapperRef = useRef(null);

    const [searchValue, setSearchValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [activeTab, setActiveTab] = useState("subdomains");

    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showAdddomainSidePanel, setShowAdddomainSidePanel] = useState(false);

    const [selectedDomainList, setSelectedDomainList] = useState([]);
    const [selectedIpAddressesList, setSelectedIpAddressesList] = useState([]);
    const [selectedEmailList, setSelectedEmailList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [tabelData, setTabelData] = useState(null);

    const handleCheckboxChange = (value) => {
        if (activeTab === "subdomains") {
            setSelectedDomainList(value);
        } else if (activeTab === "ipAddress") {
            setSelectedIpAddressesList(value);
        } else if (activeTab === "mails") {
            setSelectedEmailList(value);
        }
    };

    const getSelectedLabel = () => {
        if (activeTab === "subdomains" && selectedDomainList.length > 0) {
            return `${selectedDomainList.length} Sub-Domains selected`;
        }
        if (activeTab === "ipAddress" && selectedIpAddressesList.length > 0) {
            return `${selectedIpAddressesList.length} IP Addresses selected`;
        }
        if (activeTab === "mails" && selectedEmailList.length > 0) {
            return `${selectedEmailList.length} Emails selected`;
        }
        return null;
    };

    const [latestScoreFilter, setLatestScoreFilter] = useState("latest score");

    const changeActiveTab = (value) => {
        setActiveTab(value);
        setCurrentPageSize(10);
        setCurrentPage(1);
        setSelectedDomainList([]);
        setSelectedIpAddressesList([]);
        setSelectedEmailList([]);      
    }

    const filterClicked = () => {
        setShowFilter(true);
    };

    const closeAddDomainSidePanel = () => {
        setShowAdddomainSidePanel(false);
    };

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const onSearch = () => {
        setSearchQuery(searchValue)
        setCurrentPageSize(10);
        setCurrentPage(1);
    }

    const isDisableButton = () => {
        const activeList = {
            subdomains: selectedDomainList,
            ipAddress: selectedIpAddressesList,
            mails: selectedEmailList
        };
    
        return (activeList[activeTab]?.length || 0) === 0;
    }

    const includeList = () => {
        let obj = {
            domain: doaminData.domain,
        }
        if (activeTab === "subdomains") {
            obj.subdomains = selectedDomainList.map((data)=>{
                return data.subdomain
            })
            // obj.subdomains = ["40.99.71.216"]
            dispatch(subDomainInclude(obj))
        }
        if (activeTab === "ipAddress") {
            obj.ip_addresses = selectedIpAddressesList.map((data)=>{
                return data.ip_address
            })
            dispatch(ipAddressInclude(obj))
        }
        if (activeTab === "mails") {
            obj.emails = selectedEmailList.map((data)=>{
                return data.email
            })
            dispatch(emailInclude(obj))
        }
    }

    const excludeList = () => {
        let obj = {
            domain: doaminData.domain,
        }
        if (activeTab === "subdomains") {
            obj.subdomains = selectedDomainList.map((data)=>{
                return data.subdomain
            })
            dispatch(subDomainExclude(obj))
        }
        if (activeTab === "ipAddress") {
            obj.ip_addresses = selectedIpAddressesList.map((data)=>{
                return data.ip_address
            })
            dispatch(ipAddressExclude(obj))
        }
        if (activeTab === "mails") {
            obj.emails = selectedEmailList.map((data)=>{
                return data.email
            })
            dispatch(emailExclude(obj))
        }
    }

    const fetchTabelData = useCallback (()=> {
        if(doaminData != null) {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${doaminData?.domain}&query=${searchQuery}`;
            setIsLoading(true)
            if (activeTab === "subdomains") {
                dispatch(getAssestInventorySubdomain(url))
            } else if (activeTab === "ipAddress") {
                dispatch(getAssestInventoryIPAddress(url))
            } else {
                dispatch(getAssestInventoryEmails(url))
            }
        }
    },[dispatch, doaminData, currentPage, currentPageSize, activeTab, searchQuery])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if (showFilter) {
                    setShowFilter(false);
                    setFilterList([]);
                }
            }
        }
    }, [showFilter, filterList]);



    useEffect(()=>{

        fetchTabelData()

    },[fetchTabelData, currentPage, currentPageSize, activeTab, searchQuery])

    useEffect(()=>{
        
        if (activeTab === "subdomains" && subDomainListResponse != null) {
            setIsLoading(false)
            setTabelData(subDomainListResponse);
        } else if (activeTab === "ipAddress" && ipAddressListResponse != null) {
            setIsLoading(false)
            setTabelData(ipAddressListResponse);
        } else if(activeTab === "mails" && emailListResponse != null) {
            setIsLoading(false)
            setTabelData(emailListResponse);
        }

    },[activeTab, subDomainListResponse, ipAddressListResponse, emailListResponse])

    useEffect(()=>{
        const handleResponse = (response, type) => {
            if (response) {
                if (response?.status === "failed") {
                    toaster.addToast(response?.description, "error");
                } else {
                    fetchTabelData();
                    clearSelections();
                    toaster.addToast(response?.detail?.description, "success");
                }
                dispatch(resetIncludeExcludeResponse());
            }
        };

        const clearSelections = () => {
            setSelectedDomainList([]);
            setSelectedIpAddressesList([]);
            setSelectedEmailList([]);
        };

        handleResponse(subDomainIncludeResponse, "subDomainInclude");
        handleResponse(subDomainExcludeResponse, "subDomainExclude");
        handleResponse(ipAddressIncludeResponse, "ipAddressInclude");
        handleResponse(ipAddressExcludeResponse, "ipAddressExclude");
        handleResponse(emailIncludeResponse, "emailInclude");
        handleResponse(emailExcludeResponse, "emailExclude");

        
    },[fetchTabelData, dispatch, subDomainIncludeResponse, subDomainExcludeResponse, ipAddressIncludeResponse, ipAddressExcludeResponse, emailIncludeResponse, emailExcludeResponse])

    return (
        <>
            <div className="assets-container d-flex my-4">
                <p className={`text-center pointer ${activeTab === "subdomains" ? "active" : ""}`} onClick={() => changeActiveTab("subdomains")}>Subdomains</p>
                <p className={`text-center pointer ${activeTab === "ipAddress" ? "active" : ""}`} onClick={() => changeActiveTab("ipAddress")}>IP Address</p>
                <p className={`text-center pointer ${activeTab === "mails" ? "active" : ""}`} onClick={() => changeActiveTab("mails")}>Emails</p>
            </div>

            <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                <div className="search-container d-flex align-items-center gap-3">
                    <div className="position-relative">
                        <input className="search-input rounded" placeholder="Search Sub-Domain" value={searchValue} 
                            onChange={(e) => {setSearchValue(e.target.value)}} />
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                    </div>
                    <button className="search-btn" onClick={onSearch}>{t("organizationDetailsPage.searchBtn")}</button>
                    <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked} />
                </div>
                <div className="sort-edit-container">
                    <div className="container d-flex gap-3 align-items-center">
                        <p className="sort-by d-flex gap-1">
                            {t("organizationDetailsPage.usersTable.sortBy")}
                            <select className="border-0" value={latestScoreFilter} onChange={(e) => setLatestScoreFilter(e.target.value)}>
                                <option value="latest score">Latest Score</option>
                                <option value="last scanned">Last Scanned</option>
                                <option value="created">Created</option>
                            </select>
                        </p>
                        {getSelectedLabel() && (
                            <button className="borderless-btn">
                                {getSelectedLabel()}
                            </button>
                        )}

                        <button className='bg-white' disabled={isDisableButton()} onClick={includeList}>
                            <img src={login} alt="include" className="d-inline-block mx-1" />
                            {t("domainDetailsPage.includeBtn")}

                        </button>
                        <button className='bg-white' disabled={isDisableButton()} onClick={excludeList}>
                            <img src={logout} alt="exclude" className="d-inline-block mx-1" />
                            {t("domainDetailsPage.excludeBtn")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="domain-table-container">
                {
                    isLoading ? 
                        <div className="d-flex align-items-center justify-content-center my-5" style={{minHeight: 200}}>
                            <Spinner />
                        </div>                         
                    :
                    <>
                        {activeTab === "subdomains" && <SubDomains onCheckboxChange={(value) => handleCheckboxChange(value)} subDomainList={subDomainListResponse} />}
                        {activeTab === "ipAddress" && <IpAddress onCheckboxChange={(value) => handleCheckboxChange(value)} ipAddressList={ipAddressListResponse} />}
                        {activeTab === "mails" && <Emails onCheckboxChange={(value) => handleCheckboxChange(value)} emailList={emailListResponse} />}
                        {tabelData !== null && !isLoading && tabelData?.meta?.total_results > 0 && <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            pageSize={currentPageSize}
                            totalCount={tabelData?.meta?.total_results || 0}
                            onPageChange={setCurrentPageandRecordSize}
                        />}
                    </>
                }
                {
                    !isLoading && tabelData?.meta?.total_results === 0 &&
                    <NoDataFound />
                }
            </div>
            <SideModal showSidePanel={showAdddomainSidePanel} hideSidePanel={closeAddDomainSidePanel}>
                <AddDomain />
            </SideModal>
        </>
    )
}

export default AssetsTab;
