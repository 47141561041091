import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
// import "./index.scss";

import ContentBox from "../../../../../../ui/ContentBox";

const IPInformation = ({selectedIpDetails}) => {
    return (
        <>            
            {/* <div className="hacker-score-container p-3 rounded mb-3">
                <p className="hacker-score">846<span>/900</span></p>
                <p className="hacker-text">Latest Hacker Score</p>
            </div> */}
            <div className="ip-info-container">
                <Row>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"IP Address"} value={selectedIpDetails?.ip_address}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Continent"} value={selectedIpDetails?.continent}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Country"} value={selectedIpDetails?.country}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Region"} value={selectedIpDetails?.region}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"City"} value={selectedIpDetails?.city}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Latitude"} value={selectedIpDetails?.latitude}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Logitude"} value={selectedIpDetails?.longitude}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Hosting"} value={selectedIpDetails?.hosting_provider}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"Organization"} value={selectedIpDetails?.organization}/>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <ContentBox label={"AS details"} value={selectedIpDetails?.as_number}/>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default IPInformation;