import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

import downArrow from "../../assets/images/common/down-arrow.svg";
import en from "../../assets/images/multiple-language/en.svg";
import jp from "../../assets/images/multiple-language/jp.svg";

const languageList = [
  {
    image: en,
    text: "Eng",
    value: "en"
  },
  {
    image: jp,
    text: "日本語",
    value: "jp"
  }
]

const LanguageSwitcher = () => {

  const { i18n } = useTranslation();

  const languagRef = useRef(null);

  const [openLanguageListBox, setOpenLanguageListBox] = useState(false);
  const [languages] = useState(languageList);
  const [selectedLanguage, setSelectedLanguage] = useState(languageList[0]);

  const handleClickOutside = (event) => {
    if (languagRef.current && !languagRef.current.contains(event.target)) {
      setOpenLanguageListBox(false);
    }
};

  useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
  }, []);

  useEffect(()=>{

  },[])

  return (
    <div className="multiple-language-container mt-3" ref={languagRef}>
        <div className="language-flag">
          <img className="w-100 h-100" src={selectedLanguage.image} alt="English" />
        </div>
        <div className="language-arrow" onClick={()=>{
          setOpenLanguageListBox(!openLanguageListBox);
        }}>
          <img src={downArrow} alt="down arrow" />
        </div>
        {openLanguageListBox && <div className="language-list-container">
          {
            languages.map((data, index)=>{
              return (
                <div className="language-container" onClick={()=>{
                  setSelectedLanguage(data);
                  setOpenLanguageListBox(false);
                  i18n.changeLanguage(data.value);
                }} key={index}>
                  <div className="language-flag">
                    <img className="w-100 h-100" src={data.image} alt="English" />
                  </div>
                  <div className="language-arrow">
                    <span>{data.text}</span>
                  </div>
                </div>
              )
            })
          }
        </div>}
    </div>
  );
};

export default LanguageSwitcher;