import React,{ useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";
import filterIcon from "../../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../../assets/images/common/search-icon.svg";

import ScanInfo from "../../../../ScanInfo";
import ProfileAvatar from "../../../../../components/ui/ProfileAvatar";

import DomainInformation from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/AssociatedDomain/DomainInformation";
import SSLCertificate from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/AssociatedDomain/SSLCertificate";

const AssociatedDoamin = () => {

    const [activeTab, setActiveTab] = useState("domainInformation")


    return (
        <ScanInfo>
            <div className="scan-info-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="sub-domain-type-container web-server-type-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Hosting Providers</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-records-container">
                                    <p className="content">370 <span>Total Records</span></p>
                                </div>
                                <div className="sub-domain-content">
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage} />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div>                                        
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div> 
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Associated Domains</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">30.8K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Subdomain List</h1>
                                    <img src={filterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container">
                                    <div className="sub-domain-list py-2 px-3 active">
                                        <p className="sub-domain-heading">test.cybermindr.com</p>
                                        <p className="sub-domain-name">cybermindr.com</p>
                                        <span className="inactive">Expired</span>
                                        <span className="blacklisted">Blacklisted</span>
                                        <span className="potential">Self Signed</span>
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <p className="sub-domain-heading">test.cybermindr.com</p>
                                        <p className="sub-domain-name">cybermindr.com</p>
                                        <span className="inactive">Expired</span>
                                        <span className="potential">Self Signed</span>
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <p className="sub-domain-heading">test.cybermindr.com</p>
                                        <p className="sub-domain-name">cybermindr.com</p>
                                        <span className="inactive">Expired</span>
                                        <span className="potential">Self Signed</span>
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <p className="sub-domain-heading">test.cybermindr.com</p>
                                        <p className="sub-domain-name">cybermindr.com</p>
                                        <span className="inactive">Expired</span>
                                        <span className="potential">Self Signed</span>
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <p className="sub-domain-heading">test.cybermindr.com</p>
                                        <p className="sub-domain-name">cybermindr.com</p>
                                        <span className="inactive">Expired</span>
                                        <span className="potential">Self Signed</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="bg-white-with-shadow sub-domain-main-container p-3 h-100">
                                <div className="tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "domainInformation" ? "active" : ""}`} onClick={()=>{setActiveTab('domainInformation')}}>Domain Information</p>
                                    <p className={`rounded text-center ${activeTab === "SSlCertificate" ? "active" : ""}`} onClick={()=>{setActiveTab('SSlCertificate')}}>SSL Certificate</p>
                                </div>
                                <div className="tab-details-content mt-3">
                                    {activeTab === "domainInformation" && <DomainInformation />}
                                    {activeTab === "SSlCertificate" && <SSLCertificate />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </ScanInfo>        
    )
}


export default AssociatedDoamin;