import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";

import arrowCircleLeft from "../../assets/images/common/arrow-circle-left.svg";

import LeftSection from "../../components/PreLogin/LoginPage/LeftSection.js";
import RightFooter from "../../components/PreLogin/RightFooter";
import Button from "../../components/ui/Button";

import { useSelector, useDispatch } from 'react-redux';
import { verify2FaAccount, getLoggedInUserDetails } from '../../store/actions/loginAction.js';

const VerifyAccount = () => {

    const response = useSelector((state) => state.login?.verify2FaResponse);
    const userDetailsResponse = useSelector((state)=> state?.login?.loggedInUserDetailResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [otp, setOtp] = useState(new Array(6).fill(""));
    const otpBoxReference = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [verifyAccountError, setVerifyAccountError] = useState('')

    const handleChange = (e, index) => {
        const { value } = e.target;

        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);
    
        // Check if there’s a next input box before focusing
        if (value && index < otp.length - 1 && otpBoxReference.current[index + 1]) {
            otpBoxReference.current[index + 1].focus();
        }
    };

    const handleBackspaceAndEnter = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            // Check if there’s a previous input box before focusing
            if (otpBoxReference.current[index - 1]) {
                otpBoxReference.current[index - 1].focus();
            }
        }
        if (e.key === "Enter" && e.target.value && index < otp.length - 1) {
            if (otpBoxReference.current[index + 1]) {
                otpBoxReference.current[index + 1].focus();
            }
        }
    };

    const onKeyDown = (e) => {
        const keyCode = parseInt(e.key);
        if (
            e.key !== 'Backspace' &&
            e.key !== 'Delete' &&
            e.key !== 'Tab' &&
            !(e.metaKey && e.key === 'v') &&
            !(keyCode >= 0 && keyCode <= 9)
        ) {
            e.preventDefault();
        }
    };

    const isDisabledButton = () => {
        return otp.includes("");
    };

    const buttonClicked = () => {
        let obj = {
            totp_code: otp.join("")
        };
        setIsLoading(true)
        dispatch(verify2FaAccount(obj));
    };

    const goBack = () => {
        navigate('/auth/login');
    };

    useEffect(()=>{
        if(response?.detail?.status === "success") {
            setIsLoading(false);
            dispatch(getLoggedInUserDetails());           
        }

        if(response?.status === "failed") {
            setVerifyAccountError(response?.description)
            setIsLoading(false)
        } 
    },[response, navigate, dispatch])

    useEffect(()=>{
        if(userDetailsResponse !== null) {
            localStorage.setItem('loggedInUserDetails', JSON.stringify(userDetailsResponse))
            if(userDetailsResponse?.user_type === "internal") {
                navigate("/customers")
            } else {
                navigate("/organization")
            }
        }
    },[userDetailsResponse, navigate])

    return (
        <div className="verifyAccount-container">
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='verify-account-right-section px-4 mx-3 h-100'>
                        <div className='right-container'>
                            <img src={arrowCircleLeft} alt="back" onClick={goBack} className="go-back-icon" />
                            <h1 className='mb-4 mt-5'>{t("loginPage.verifyAccount.heading")}</h1>
                            <p className="description">{t("loginPage.verifyAccount.secondaryText")}</p>
                            {verifyAccountError !== "" && <p className="error-msg text-center mt-3">{verifyAccountError}</p>}
                            <div className='input-container mt-4'>
                                <p className='otp-label mb-3'>2FA Code</p>
                                <div className="d-flex">
                                    {otp.map((digit, index) => (
                                        <input 
                                            key={index} 
                                            value={digit} 
                                            maxLength={1}  
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                            onKeyDown={(e) => onKeyDown(e)}
                                            ref={(reference) => (otpBoxReference.current[index] = reference)}
                                            className="otp-input-box"
                                        />
                                    ))}
                                </div>
                                <div className='submit-button mt-4'>
                                    {!isLoading && <Button 
                                        buttonText={"Continue"} 
                                        buttonType="primary" 
                                        buttonClicked={buttonClicked} 
                                        disableButton={isDisabledButton()}
                                    />}
                                    {isLoading && <Button 
                                        buttonText={"Loading..."} 
                                        buttonType="primary" 
                                        buttonClicked={buttonClicked} 
                                        disableButton={true}
                                    />}
                                </div>
                            </div>
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default VerifyAccount;