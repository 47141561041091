import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';


import domainIcon from "../../../../assets/images/common/domain.svg";

import Button from "../../../../components/ui/Button";

import { formatNumber } from "../../../../utils/util.js";

import { useDispatch } from 'react-redux';
import { deleteCustomer } from '../../../../store/actions/customerAction.js';


const DeleteCustomerModal = ({show, onHide, customerDetails=""}) => {

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false)

    const confirmDelete = () => {
        let obj = {
            customer_id: customerDetails.customer_id
        }
        setIsLoading(true)
        dispatch(deleteCustomer(obj))
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("customerDetails.deleteCustomerModal.heading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("customerDetails.deleteCustomerModal.description")} {customerDetails?.name}?</p>
                            <div className="d-flex justify-content-between mt-4 gap-3">
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("organizationDetailsPage.deleteOrganization.totalDomains")}</p>
                                        <p className="domain-count">{formatNumber(customerDetails?.stats?.domains?.total)}</p>
                                    </div>
                                </div>
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("organizationDetailsPage.deleteOrganization.totalUsers")}</p>
                                        <p className="domain-count">{formatNumber(customerDetails?.stats?.users?.total)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="delete-organization-btn d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Delete" buttonType={"primary"} buttonClicked={()=>{confirmDelete()}} />}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default DeleteCustomerModal;