import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";


import Button from "../../../ui/Button";
import DropDown from '../../../../components/ui/DropDown';

import { useSelector, useDispatch } from 'react-redux';
import { assignAndUnAssign} from '../../../../store/actions/organizationActions';
import apiEndpoints from "../../../../apiEndpoints.js";

const ModifyOrganization = ({assignedOrgsList, closeModifyOrgsModal}) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { userId } = useParams();

    const organizationListResponse = useSelector((state) => state.organization?.organizationList);
    const assignUnAssignResponse = useSelector((state) => state.organization?.assignUnAssignResponse)

    const [responseStatus, setResponseStatus] = useState(false);
    const [activeTab, setActiveTab] = useState('assign');
    const [organizationList, setOrganizationList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [assignedOrganizationList, setAssignedOrganizationList] = useState([]);

    
    const goBack = () => {
        setResponseStatus(false)
        setIsLoading(false)
        closeModifyOrgsModal(assignUnAssignResponse)
    }

    const handleSelectDomain = (value) => {
        setSelectedOrganization(value)
    }



    const changeTab = (value) => {
        setSelectedOrganization([])
        setActiveTab(value)
    }

    const saveList = () => {
        const ids = selectedOrganization.map(data => data.id);
        const isAssign = activeTab === "assign";
        
        const obj = {
            user_id: userId,
            organizations_ids: ids
        };
    
        const url = isAssign ? apiEndpoints.users.assignOrganizationTouser() : apiEndpoints.domains.unAssignOrganizationToUser()
        setIsLoading(true)
        dispatch(assignAndUnAssign(url, obj));
    };

    useEffect(()=>{
        if(organizationListResponse != null) {
            let list = organizationListResponse?.results.map((data)=>{
                return {
                    id: data.organization_id,
                    name: data.name,
                    value: data.organization_id,
                    logo: data.logo,
                    label: data.name
                }
            })
            setOrganizationList(list)
        }
    },[organizationListResponse])

    useEffect(()=>{
        if(assignedOrgsList != null) {
            let list = assignedOrgsList?.results.map((data)=>{
                return {
                    id: data.organization_id,
                    name: data.name,
                    value: data.organization_id,
                    logo: data.logo,
                    label: data.name
                }
            })
            setAssignedOrganizationList(list)
        }
    },[assignedOrgsList])

    useEffect(()=>{
        if(assignUnAssignResponse !== null ) {
            setIsLoading(false)
            setResponseStatus(true)
        }
    },[assignUnAssignResponse])


    return (
        <div className="add-organization-container add-domain-container">
            {!responseStatus && <>
                <div className="modal-header mt-4">
                    <h2>{t('organizationDetailsPage.modify.modifyDomain')}</h2>
                </div>
                <div className="assign-tab-container d-flex rounded my-3">
                    <p className={`rounded text-center ${activeTab === "assign" ? "active" : ""}`} onClick={()=>changeTab('assign')}>{t('organizationDetailsPage.modify.assign')}</p>
                    <p className={`rounded text-center ${activeTab === "unAssign" ? "active" : ""}`} onClick={()=>changeTab("unAssign")}>{t('organizationDetailsPage.modify.unAssign')}</p>
                </div>
                <div className="add-organization-step-one mb-5">

                    {activeTab === "assign" && 
                        <DropDown
                            options={organizationList}
                            placeHolder='Please select Organization'
                            dropDownType={"modifyDomain"}
                            onChange={(e) => handleSelectDomain(e)}
                            isSearchable = {true}
                            isMulti
                        />
                    }
                    {activeTab === "unAssign" && 
                        <DropDown
                            options={assignedOrganizationList}
                            placeHolder='Please select Organization'
                            dropDownType={"modifyDomain"}
                            onChange={(e) => handleSelectDomain(e)}
                            isSearchable = {true}
                            isMulti
                        />
                    }
                </div>
                <div className="form-submit-btn-group mt-4">
                    <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{goBack()}}/>
                    {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{saveList()}} disableButton={selectedOrganization.length === 0} />}
                    {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true} />}
                </div>
            </>}
            {responseStatus && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {assignUnAssignResponse?.detail?.status === "success" && <>
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{assignUnAssignResponse?.detail?.description}</h2>
                    </>}
                    {assignUnAssignResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                            <div className="mt-5">
                                <img src={failed} alt="failed" />
                            </div>
                            <h2 className="mb-4 mt-5 fs-4">{assignUnAssignResponse?.description}</h2>
                            </div>}
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={()=>{goBack()}} />
                    </div>
                </div>
            </>}
        </div>
    )
}

export default ModifyOrganization;