import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import "./index.scss";


import PlusIcon from "../../../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../../../assets/images/common/minus-square.svg";


import ARecord from "../../DNSRecord/ARecord";
import AAARecord from "../../DNSRecord/AAARecord";
import CNameRecord from "../../DNSRecord/CNAMERecord";
import MXRecord from "../../DNSRecord/MXRecord";
import NSRecord from "../../DNSRecord/NSRecord";
import SOARecord from "../../DNSRecord/SOARecord";
import TXTRecord from "../../DNSRecord/TXTRecord";


const DNSRecordInformation = ({selectedSubdomain, domainId, scanId}) => {

    const [seletedInfo, setSelectedInfo] = useState("");

    const changeSelectedInfo = (data) => {
        if(data === seletedInfo ) {
            setSelectedInfo("")
        } else {
            setSelectedInfo(data)
        }
    }

    return (
        <>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('aRecords')}}>
                    <h2 className="heading">A Records</h2>
                    <img src={seletedInfo === "aRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "aRecords" && <div className="table-container record-table">
                    <ARecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"}/>
                </div>}
            </div>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('aaaRecords')}}>
                    <h2 className="heading">AAA Records</h2>
                    <img src={seletedInfo === "aaaRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "aaaRecords" && <div className="table-container record-table">
                    <AAARecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"} />
                </div>}
            </div>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('cnameRecords')}}>
                    <h2 className="heading">CNAME Records</h2>
                    <img src={seletedInfo === "cnameRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "cnameRecords" && <div className="table-container record-table">
                    <CNameRecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"}/>
                </div>}
            </div>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('mxRecords')}}>
                    <h2 className="heading">MX Records</h2>
                    <img src={seletedInfo === "mxRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "mxRecords" && <div className="table-container record-table">
                    <MXRecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"} />
                </div>}
            </div>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('nsRecords')}}>
                    <h2 className="heading">NS Records</h2>
                    <img src={seletedInfo === "nsRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "nsRecords" && <div className="table-container record-table">
                    <NSRecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"}/>
                </div>}
            </div>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('soaRecords')}}>
                    <h2 className="heading">SOA Records</h2>
                    <img src={seletedInfo === "soaRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "soaRecords" && <div className="table-container record-table">
                    <SOARecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"}/>
                </div>}
            </div>
            <div className="record-container-content mt-3 p-2">
                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{changeSelectedInfo('txtRecords')}}>
                    <h2 className="heading">TXT Records</h2>
                    <img src={seletedInfo === "txtRecords" ? MinusIcon : PlusIcon} alt="expand"/>
                </div>
                {seletedInfo === "txtRecords" && <div className="table-container record-table">
                    <TXTRecord domainId={domainId} scanId={scanId} selectedSubdomain={selectedSubdomain} currentpageType={"subdomain"}/>
                </div>}
            </div>
        </>
    )
}

export default DNSRecordInformation;