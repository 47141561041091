import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import InputBox from "../../components/ui/InputBox";
import Button from "../../components/ui/Button";
import CheckBox from "../../components/ui/CheckBox";
import rightArrow from "../../assets/images/common/arrow-circle-left.svg";
import tickCircleGreen from "../../assets/images/common/tick-transparent.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";
import LeftSection from "../../components/PreLogin/LoginPage/LeftSection.js";
import RightFooter from "../../components/PreLogin/RightFooter";
import { useSelector, useDispatch } from 'react-redux';
import { registerUser } from '../../store/actions/registerAction.js';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';

const googleRecaptchaSitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY;

const RegistrationPage = () => {
    const response = useSelector(state => state.register.registerResponse);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [keepLogin, setKeepLogin] = useState(false);
    const [addRegStep, setAddRegStep] = useState(1);
    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const inputChange = (val, type) => {
        const handlers = {
            text: setUserName,
            password: setPassword,
            firstname: setFirstName,
            lastname: setLastName,
            email: setEmail,
        };
        handlers[type] && handlers[type](val);
    };

    const buttonClicked = async () => {
        if (addRegStep === 1) {
            if (!username || !password) return;
            nextStep(); // Move to name input step
        } else if (addRegStep === 2) {
            if (!firstname || !lastname || !email) return;

            const formData = new FormData();
            formData.append('email', email);
            formData.append('username', username);
            formData.append('firstname', firstname);
            formData.append('lastname', lastname);
            formData.append('password', password);

            setIsLoading(true);
            setLoginFailed(false); // Reset error state

            try {
                await dispatch(registerUser(formData));
            } catch (error) {
                setIsLoading(false);
                setLoginFailed(true);
            }
        }
    };

    const isDisabledButton = useCallback(() => {
        if (addRegStep === 1) {
            return !username || !password || !isRecaptchaVerified || !isTermsAccepted; // Add isTermsAccepted
        } else if (addRegStep === 2) {
            return !firstname || !lastname || !email;
        }
        return false;
    }, [addRegStep, username, password, firstname, lastname, email, isRecaptchaVerified, isTermsAccepted]);

    const setCheckboxValue = (e) => {
        setKeepLogin(e.target.checked);
    };

    const nextStep = useCallback(() => {
        setAddRegStep((prevStep) => {
            if (prevStep < 4) {
                setIsRecaptchaVerified(false); // Reset reCAPTCHA status
                return prevStep + 1;
            }
            return 1;
        });
    }, []);


    const maskEmail = (email) => {
        const [name, domain] = email.split('@');
        if (!domain) return email;
        const maskedName = name.length <= 2 ? `${name[0]}****` : `${name[0]}****${name.slice(-1)}`;
        return `${maskedName}@${domain}`;
    };

    useEffect(() => {
        // console.log('Register API Response:', response);
        const registerProcess = () => {
            if (response) {
                if (response?.statusCode === 200) {
                    // console.log('Navigating to step 3 due to conflict (409)'); 
                    setAddRegStep(3);
                } else {
                    // console.log('Registration failed', response); 
                    setIsLoading(false);
                    setLoginFailed(true);
                    // setAddRegStep(3);
                }
            }
        };
        registerProcess();
    }, [response]);



    const prevStep = useCallback(() => {
        setAddRegStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
    }, []);


    return (
        <div className='register-container'>
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='register-right-section px-4 mx-3 h-100'>

                        <div className='right-container'>
                            {addRegStep <= 3 && (
                                <>
                                    <div className='mb-3'>
                                        <img
                                            src={rightArrow}
                                            alt="back arrow"
                                            onClick={prevStep}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className="add-registration-step mb-3">
                                        {[1, 2, 3].map((step) => (
                                            <React.Fragment key={step}>
                                                <div
                                                    className={`d-flex align-items-center justify-content-center step-indicator ${addRegStep === step
                                                        ? 'inprogress'
                                                        : addRegStep > step
                                                            ? 'completed'
                                                            : ''
                                                        }`}
                                                >
                                                    {addRegStep < step ? step : ''}
                                                </div>
                                                {step < 3 && <div className="step-border"></div>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </>

                            )}

                            {loginFailed && <p className="error-msg text-center mb-2">{response?.description}</p>}

                            {addRegStep === 1 && (
                                <>
                                    <h1 className='mb-2'>{t("registrationPage.rightSection.account")}</h1>
                                    <p className='mb-3'>{t("registrationPage.rightSection.secondaryText")}</p>
                                    <div className='input-container mt-2'>
                                        <InputBox placeholder={'Enter username'} inputType={'text'} inputLabel={'User Name'} inputChange={inputChange} inputValue={username} />
                                        <InputBox placeholder={'Password'} inputType={'password'} inputLabel={'Password'} inputChange={inputChange} inputValue={password} />
                                    </div>
                                    <div className='loggedin-checkbox'>
                                        <label style={{ marginBottom: '5px' }}>Captcha</label>
                                        <ReCAPTCHA
                                            sitekey={googleRecaptchaSitekey}
                                            size="normal"
                                            onChange={() => setIsRecaptchaVerified(true)} // Update reCAPTCHA state
                                        />
                                    </div>

                                    <div className='d-flex justify-content-between mt-2'>
                                        <div className='loggedin-checkbox mb-2'>
                                            <CheckBox
                                                inputLabel={
                                                    <>
                                                        I agree to all the{' '}
                                                        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                            Terms and Conditions
                                                        </a>
                                                    </>
                                                }
                                                index={0}
                                                checkBoxId={'0'}
                                                handleCheckboxChange={(e) => setIsTermsAccepted(e.target.checked)} // Update the state
                                                checkBoxValue={isTermsAccepted}
                                                disable={false}
                                            />
                                        </div>

                                    </div>
                                    <div className='submit-button mb-1'>
                                        <Button
                                            buttonText="Proceed"
                                            buttonType="primary"
                                            buttonClicked={buttonClicked}
                                            disableButton={isDisabledButton() || isLoading}
                                        />
                                    </div>
                                    <div className='not-register mb-3 mt-3'>
                                        <div>
                                            <p>Didn't receive the email Link Yet? <Link to="/auth/resend">Resend Link</Link></p>
                                        </div>
                                    </div>
                                </>
                            )}

                            {addRegStep === 2 && (
                                <>
                                    <div>
                                        <h1 className='mb-2'>{t("registrationPage.confirmation.mailId")}</h1>
                                        <p className='mb-3'>{t("registrationPage.confirmation.secondaryText")}</p>
                                        <div className='input-container mt-3'>
                                            <InputBox placeholder={'Enter First Name'} inputType={'firstname'} inputLabel={'First Name'} inputChange={inputChange} inputValue={firstname} />
                                            <InputBox placeholder={'Enter Last Name'} inputType={'lastname'} inputLabel={'Last Name'} inputChange={inputChange} inputValue={lastname} />
                                            <InputBox placeholder={'Enter Email address'} inputType={'email'} inputLabel={'Email Address'} inputChange={inputChange} inputValue={email} />
                                        </div>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <div className='loggedin-checkbox mb-3'>
                                                <label>A confirmation link will be sent to the above mail ID</label>
                                            </div>
                                        </div>
                                        <div className='submit-button'>
                                            <Button buttonText={"Proceed"} buttonType="primary" buttonClicked={buttonClicked} isLoading={isLoading} />
                                        </div>
                                    </div>
                                </>
                            )}

                            {addRegStep === 3 && (
                                <div className="confirmation-success d-flex flex-column align-items-left text-left mt-4">
                                    <div className="tick-icon mb-3">
                                        <img src={tickCircleGreen} alt="confirmation tick" />
                                    </div>
                                    <h2 className="mb-3">{t("registrationPage.confirmation.successMessage")}</h2>
                                    <p>{`Account confirmation link has been sent successfully to your registered mail ID - ${maskEmail(email)}`}</p>
                                    <p className='mt-4'>Didn't receive the email Link Yet? <Link to="/auth/resend">Resend Link</Link></p>
                                </div>
                            )}
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default RegistrationPage;
