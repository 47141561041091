import { React, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import InputBox from "../../components/ui/InputBox";
import Button from "../../components/ui/Button";
import CheckBox from "../../components/ui/CheckBox";

import "./index.scss";
import { useTranslation } from "react-i18next";

import LeftSection from "../../components/PreLogin/LoginPage/LeftSection.js";
import RightFooter from "../../components/PreLogin/RightFooter";

import { useSelector, useDispatch } from 'react-redux';
import { loginUser, getLoggedInUserDetails } from '../../store/actions/loginAction.js';


const LoginPage = () => {

    const response = useSelector((state) => state.login?.loginResponse);
    const userDetailsResponse = useSelector((state)=> state?.login?.loggedInUserDetailResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [keepLogin, setKeepLogin] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    

    const inputChange = (val, type) => {
        if(type==="text"){
            setUserName(val)
        } else {
            setPassword(val)
        }
    }

    const buttonClicked = () => {
        const formData = new FormData();
        formData.append('username', userName);
        formData.append('password', password);
        setIsLoading(true)
        dispatch(loginUser(formData))
    }

    const isDisabledButton = () => {
        return userName === '' || password === '';
    }

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setKeepLogin(!keepLogin)
        }
    }

    const forgetPasswordClicked = () => {
        navigate("/auth/forget-password");
    }

    const reset2Fa = () => {
        navigate("/auth/reset-2fa")
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !isDisabledButton()) {
            buttonClicked();
        }
    }

    useEffect(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('loggedInUserDetails');        
        if (response !== null) {
            if (response?.access_token) {
                localStorage.setItem('accessToken', response.access_token);
                if (response.require_2fa) {
                    navigate("/auth/verify-account")
                } else {
                    dispatch(getLoggedInUserDetails())
                }
            } else {
                setIsLoading(false);
                setLoginFailed(true)
            }           
        }
    }, [response, navigate, dispatch]);

    useEffect(()=>{
        if(userDetailsResponse !== null) {            
            localStorage.setItem('loggedInUserDetails', JSON.stringify(userDetailsResponse))
            if(userDetailsResponse?.user_type === "internal") {
                navigate("/customers")
            } else {
                navigate("/organization")
            }
            
        }
    },[userDetailsResponse, navigate])

    


    return (
        <div className='login-container' onKeyDown={handleKeyDown}>
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='login-right-section px-4 mx-3 h-100'>
                        <div className='right-container'>
                            <h1 className='mb-4'>{t("loginPage.rightSection.login")}</h1>
                            <p className="description">{t("loginPage.rightSection.secondaryText")}</p>
                            {loginFailed && <p className="error-msg text-center mt-3">{response?.description}</p>}
                            <div className='input-container mt-3'>
                                <InputBox placeholder={'Username'} inputType={'text'} inputLabel={'Username'} inputChange={inputChange} inputValue={userName}/>
                                <InputBox placeholder={'Password'} inputType={'password'} inputLabel={'Password'} inputChange={inputChange} inputValue={password}/>
                            </div>
                            <div className='submit-button'>
                                {!isLoading && <Button buttonText={"Login"} buttonType="primary" buttonClicked={buttonClicked} disableButton={isDisabledButton()}/>}
                                {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                            </div>
                            <div className='d-flex justify-content-between mt-3'>
                                <div className='loggedin-checkbox'>
                                    <CheckBox inputLabel={'Keep me logged in'} index={0} checkBoxId={'0'} handleCheckboxChange={setCheckboxvalue} checkBoxValue={'keepLogin'} disable={false} />
                                </div>
                                <div className='forget-password'>
                                    <Button buttonText={"Forget Password?"} buttonType="link" buttonClicked={forgetPasswordClicked} disableButton={isDisabledButton()}/> / <Button buttonText={"Reset 2FA?"} buttonType="link" buttonClicked={reset2Fa} />
                                </div>
                            </div>
                            <div className='not-register mt-5'>
                                <div>
                                    <span>{t('loginPage.rightSection.notRegistered')} </span>
                                    <Button buttonText={"Create an Account"} buttonType="link" buttonClicked={buttonClicked} />
                                </div>
                            </div>
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LoginPage;