import { apiGet } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getCustomersList(url="") {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.customer.customerList()+url)
            dispatch({
                type: 'SCAN_INFO_CUSTOMER_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getOrganizationList (url="") {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.organizations.organizationList()+url);
            dispatch({
                type: 'SCAN_INFO_ORGANIZATION_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    };
}

export function getDomainList(url="") {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.domains.domainList()+url)
            dispatch({
                type: 'SCAN_INFO_DOMAIN_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetDomainListResponse() {
    return (dispatch) => {
        dispatch({
            type: 'SCAN_INFO_RESET_DOMAIN_LIST'
        })
    }
}

export function getWhoisInformation(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.domainInfo.whois()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'WHOIS_INFORMATION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'WHOIS_INFORMATION_SUCCESS',
                    payload: response.detail
                })
            }            
        } catch(error) {
            console.log(error)
        }
    }
}

export function getSubdomainList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.subDomain.subDomainList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'SUBDOMAIN_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SUBDOMAIN_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function getARecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_ARECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_ARECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getAAARecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_AAARECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_AAARECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getCNAMERecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_CNAMERECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_CNAMERECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getMXRecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_MXRECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_MXRECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getNSRecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_NSRECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_NSRECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getSOARecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_SOARECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_SOARECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getTXTRecord(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_TXTRECORD_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_TXTRECORD_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getSubDomainTechnologies(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.subDomain.Technologies()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'SUBDOMAIN_TECHNOLOGIES_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SUBDOMAIN_TECHNOLOGIES_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getSSLCertificate(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'SSL_CERTIFICATE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SSL_CERTIFICATE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getIPAddressList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.ipAddress.ipAddressList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'IP_ADDRESS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'IP_ADDRESS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getIpAddressSubdomain(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.ipAddress.subDomain()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'IP_ADDRESS_SUBDOMAIN_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'IP_ADDRESS_SUBDOMAIN_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getIpAddressPortInfo(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.ipAddress.portList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'IP_ADDRESS_PORT_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'IP_ADDRESS_PORT_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getTechnologyList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.technology.technologyList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'TECHNOLOGY_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'TECHNOLOGY_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getEndPointList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'ENDPOINTS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ENDPOINTS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getSSLCerificateList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.sslCertificate.sslCertificateList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'SSL_CERTIFICATE_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SSL_CERTIFICATE_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getMobileAppsList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.MobileApps.mobileAppsList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'MOBILE_APPS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'MOBILE_APPS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function geCERStats(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.corporateEmailRisk.cerStats()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'CER_STATS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'CER_STATS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getCERData(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(url);
            if(response?.status === 200) {
                dispatch({
                    type: 'CER_DATA_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'CER_DATA_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getGitLeaks(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.concerningExposure.gitLeaks()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'GIT_LEAKS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GIT_LEAKS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getJsSecretsList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.concerningExposure.jsSecretsList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'JS_SECRETS_LEAKS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'JS_SECRETS_LEAKS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getSensitiveExpoList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.concerningExposure.sensitiveExpoList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'SENSITIVE_EXPO_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SENSITIVE_EXPO_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getSensitiveEndpoints(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.concerningExposure.sensitiveEndpoints()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'SENSITIVE_ENDPOINTS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'SENSITIVE_ENDPOINTS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getJsSecretsEndpoints(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.concerningExposure.jsSecretsEndpoint()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'JS_SECRETS_ENDPOINTS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'JS_SECRETS_ENDPOINTS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getLeakedCredentials(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.darkWeb.leakedCredentialsList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'LEAKED_CREDENTALS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'LEAKED_CREDENTALS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getLeakedCredentialsDataList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.darkWeb.leakedCredentialsData()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'LEAKED_CREDENTALS_DATA_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'LEAKED_CREDENTALS_DATA_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getBotnetLeaksList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.darkWeb.botnetLeaksList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'BOTNET_LEAKS_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'BOTNET_LEAKS_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getBotnetLeaksDataList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.darkWeb.botnetLeakData()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'BOTNET_LEAKS_DATA_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'BOTNET_LEAKS_DATA_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getRansomewareGroup(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.darkWeb.ransomwareGroupList()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'RANSOMEWARE_LIST_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'RANSOMEWARE_LIST_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function getRansomweregroupData(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scanInfo.exposureManagemnet.darkWeb.ransomwareGroupLeaks()+url);
            if(response?.status === 200) {
                dispatch({
                    type: 'RANSOMEWARE_DATA_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'RANSOMEWARE_DATA_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}