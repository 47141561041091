import { React, useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import LeftSection from "../../components/PreLogin/LoginPage/LeftSection";
import arrowCircleLeft from "../../assets/images/common/arrow-circle-left.svg";
import successTick from "../../assets/images/common/Success.gif";
import failedResponse from "../../assets/images/common/failed.gif";

import InputBox from '../../components/ui/InputBox';
import Button from "../../components/ui/Button";
import RightFooter from "../../components/PreLogin/RightFooter";

import "./index.scss";

import { useSelector, useDispatch } from 'react-redux';
import { forgetPassword, reset2FaAction, reset2faConfirmation, resetForgetPasswordResponse, resetReset2FaResponse } from '../../store/actions/loginAction.js';

const googleRecaptchaSitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY;

const ForgetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();

    const forgetPasswordResponse = useSelector((state) => state.login?.forgetPasswordResponse);
    const reset2FaResponse = useSelector((state) => state.login?.reset2FaResponse);
    const reset2faConfirmResponse = useSelector((state) => state.login?.reset2FaConfirmationResponse);

    const recaptcha = useRef();

    const [email, setEmail] = useState('');
    const [captchaValue, setCaptchaValue] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isCaptchaRequired, setIsCaptchaRequired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pagetype, setPageType] = useState('');
    const [forgetPasswordSuccess, setForgetPasswordSuccess] = useState(false);
    const [reset2FaSuccess, setReset2FaSuccess] = useState(false);
    const [reset2FaErrorMsg, setReset2FaErrorMsg] = useState("");
    const [reset2FaConfirmFailed, setReset2FaConfirmFailed] = useState(false);

    const goBack = () => {
        navigate('/auth/login');
        setForgetPasswordSuccess(false);
        dispatch(resetForgetPasswordResponse());
        dispatch(resetReset2FaResponse());
    };

    const maskEmail = (email) => {
        if (!email) return ''; // Return an empty string if email is undefined
        const [username, domain] = email.split('@');
        return username.length <= 2 ? `${username}****@${domain}` : `${username.slice(0, 2)}****@${domain}`;
    };

    const handleInputChange = (val) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmail(val);
        setErrorMsg(!emailRegex.test(val) ? 'Please enter a valid email address' : '');
    };

    const isDisabledButton = () => email === '';

    const handleCaptchaChange = (value) => setCaptchaValue(value);

    const handleButtonClick = () => {
        if (captchaValue === '') {
            setIsCaptchaRequired(true);
            return;
        }
        setIsCaptchaRequired(false);
        setIsLoading(true);

        const payload = { email };
        pagetype === "forgetPassword" 
            ? dispatch(forgetPassword(payload))
            : dispatch(reset2FaAction(payload));
    };

    // Set page type based on URL
    useEffect(() => {
        const url = window.location.href;
        if (url.includes("reset-2fa-confirmation")) setPageType("reset2faConfirmation");
        else if (url.includes("reset-2fa")) setPageType("reset2Fa");
        else if (url.includes("forget-password")) setPageType("forgetPassword");
    }, []);

    // Handle reset2fa confirmation dispatch
    useEffect(() => {
        if (pagetype === "reset2faConfirmation") {
            const queryParams = new URLSearchParams(search);
            const userId = queryParams.get("userid");
            const token = queryParams.get("token");
            dispatch(reset2faConfirmation({ user_id: userId, token }));
        }
    }, [pagetype, dispatch, search]);

    // Handle forget password response
    useEffect(() => {
        if (pagetype === "forgetPassword" && forgetPasswordResponse?.detail?.status === "success") {
            setForgetPasswordSuccess(true);
            setIsLoading(false);
        }
    }, [forgetPasswordResponse, pagetype]);

    // Handle reset2Fa response
    useEffect(() => {
        if (pagetype === "reset2Fa") {
            setIsLoading(false);
            if (reset2FaResponse?.detail?.status === "success") {
                setReset2FaSuccess(true);
            } else {
                setReset2FaErrorMsg(reset2FaResponse?.description || '');
            }
        }
    }, [reset2FaResponse, pagetype]);

    // Handle reset2fa confirmation response
    useEffect(() => {
        if (pagetype === "reset2faConfirmation") {
            setReset2FaConfirmFailed(reset2faConfirmResponse?.status === "failed");
        }
    }, [reset2faConfirmResponse, pagetype]);

    // Render components based on page type
    const renderForgetPassword = () => (
        <>
            <img src={arrowCircleLeft} alt="back" onClick={goBack} className="go-back-icon" />
            <h1 className='mb-4 mt-5'>{t("loginPage.forgetPassword.heading")}</h1>
            <p className="description">{t("loginPage.forgetPassword.SecondaryText")}</p>
            {isCaptchaRequired && <p className="error-msg text-center mt-2">Please verify the CAPTCHA!</p>}
            <div className='input-container mt-2'>
                <InputBox placeholder={'Enter E-mail address'} inputType={'email'} inputLabel={'Email'} inputChange={handleInputChange} inputValue={email} errorMsg={errorMsg} />
                <ReCAPTCHA ref={recaptcha} sitekey={googleRecaptchaSitekey} onChange={handleCaptchaChange} />
            </div>
            <div className='submit-button mt-3'>
                {!isLoading ? (
                    <Button buttonText={"Send Reset Link"} buttonType="primary" buttonClicked={handleButtonClick} disableButton={isDisabledButton()} />
                ) : (
                    <Button buttonText={"loading..."} buttonType="primary" disableButton />
                )}
            </div>
        </>
    );

    const renderReset2Fa = () => (
        <>
            <img src={arrowCircleLeft} alt="back" onClick={goBack} className="go-back-icon" />
            <h1 className='mb-4 mt-5'>{t("loginPage.reset2Fa.heading")}</h1>
            <p className="description">{t("loginPage.reset2Fa.SecondaryText")}</p>
            {isCaptchaRequired && <p className="error-msg text-center mt-2">Please verify the CAPTCHA!</p>}
            {reset2FaErrorMsg && <p className="error-msg text-center mt-2">{reset2FaErrorMsg}</p>}
            <div className='input-container mt-2'>
                <InputBox placeholder={'Enter E-mail address'} inputType={'email'} inputLabel={'Email'} inputChange={handleInputChange} inputValue={email} errorMsg={errorMsg} />
                <ReCAPTCHA ref={recaptcha} sitekey={googleRecaptchaSitekey} onChange={handleCaptchaChange} />
            </div>
            <div className='submit-button mt-3'>
                {!isLoading ? (
                    <Button buttonText={"Send Reset Link"} buttonType="primary" buttonClicked={handleButtonClick} disableButton={isDisabledButton()} />
                ) : (
                    <Button buttonText={"loading..."} buttonType="primary" disableButton />
                )}
            </div>
        </>
    );

    const renderReset2FaConfirmation = () => (
        <div className="resend-success-container">
            <img src={arrowCircleLeft} alt="back" onClick={() => navigate("/auth/reset-2fa")} className="go-back-icon" />
            <div className="mt-5">
                <img src={reset2FaConfirmFailed ? failedResponse : successTick} alt={reset2FaConfirmFailed ? "failed" : "success"} />
            </div>
            <h1 className="mb-4 mt-5">{reset2FaConfirmFailed ? "Failed" : t("loginPage.reset2Fa.2faResetConfirmationHeading")}</h1>
            <p className="description">{reset2FaConfirmFailed ? reset2faConfirmResponse?.description : t("loginPage.reset2Fa.2faResetconfirmationSecondaryText")}</p>
            <div className="link-not-received mt-5">
                <Button buttonText={"Back to login Page"} buttonType="primary" buttonClicked={() => navigate("/auth/login")} />
            </div>
        </div>
    );

    const renderSuccessPage = (email) => (
        <div className="resend-success-container">
            <img src={arrowCircleLeft} alt="back" onClick={() => navigate("/auth/login")} className="go-back-icon" />
            <div className="mt-5">
                <img src={successTick} alt="success" />
            </div>
            {forgetPasswordSuccess && (
                <>
                    <h1 className="mb-4 mt-5">{t("loginPage.forgetPassword.resendHeading")}</h1>
                    <div className="description">
                        {`${t("loginPage.forgetPassword.resendSecondaryText")} ${email ? maskEmail(email) : ''}`}
                    </div>
                </>
            )}
            {reset2FaSuccess && (
                <>
                    <h1 className="mb-4 mt-5">{t("loginPage.reset2Fa.2faResetSuccessHeading")}</h1>
                    <div className="description">
                        {t("loginPage.reset2Fa.2faResetSecondaryText")}
                    </div>
                </>
            )}
            <div className="link-not-received mt-5">
                <Button 
                    buttonText={"Back to Login"} 
                    buttonType="primary" 
                    buttonClicked={goBack} 
                />
            </div>
        </div>
    );

    return (
        <div className='forget-password-container'>
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className="forget-password-right-section px-4 mx-3 h-100">
                        <div className="right-container">
                            {pagetype === "forgetPassword" && (!forgetPasswordSuccess ? renderForgetPassword() : renderSuccessPage(email))}
                            {pagetype === "reset2Fa" && (!reset2FaSuccess ? renderReset2Fa() : renderSuccessPage())}
                            {pagetype === "reset2faConfirmation" && renderReset2FaConfirmation()}
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default ForgetPassword;
