import React, { useState } from "react";
import { Row, Col } from 'react-bootstrap';
import './index.scss';

import 'react-datepicker/dist/react-datepicker.css';

import Monthly from "./Monthly";
import Yearly from "./Yearly";

const BubbleChart = () => {

    const [activeTab, setActiveTab] = useState("monthly"); // Initially set to "Monthly"

    return (

        <div className="bg-white-with-shadow p-4 mt-4">
            <p className="heading font-bold">Discovered and Validated Threats</p>
            <div className="d-flex mt-3">
                <div className="discovered-date-selection d-flex gap-3 mt-1">
                    <p
                        className={`rounded text-center ${activeTab === "monthly" ? "active" : ""}`}
                        onClick={() => setActiveTab("monthly")}
                        style={{
                            backgroundColor: activeTab === "monthly" ? "#18E7F8" : "transparent",
                            color: activeTab === "monthly" ? "#000" : "#000",
                            padding: "10px",
                            cursor: "pointer",
                        }}
                    >
                        Monthly
                    </p>
                    <p
                        className={`rounded text-center ${activeTab === "yearly" ? "active" : ""}`}
                        onClick={() => setActiveTab("yearly")}
                        style={{
                            backgroundColor: activeTab === "yearly" ? "#18E7F8" : "transparent",
                            color: activeTab === "yearly" ? "#000" : "#000",
                            padding: "10px",
                            cursor: "pointer",
                        }}
                    >
                        Yearly
                    </p>
                </div>
            </div>

            <div className="discovered-digital d-flex gap-3 mt-3">
                <p><span className="digital digital-blue"></span>Digital Footprints</p>
                <p><span className="assets assets-pink"></span>Assets</p>
                <p><span className="vulnerabilities vulnerabilities-skyblue"></span>Vulnerabilities</p>
                <p><span className="dark-web darkweb-red"></span>Dark Web</p>
                <p className="border-custom"></p>
                <p><span className="resolved resolved-violet"></span>Resolved</p>
            </div>
            <Row className="align-items-start">
                <Col lg={3}>
                    <div
                        className="bg-grey p-4 d-flex"
                        style={{
                            alignItems: "center", // Ensures vertical alignment
                        }}
                    >
                        <h5
                            style={{
                                writingMode: "vertical-rl",
                                transform: "rotate(180deg)",
                                margin: "0px 0px 0px 0px",
                                textAlign: "center",
                                fontSize: "12px",
                                color: "#99A2B4",
                            }}
                        >
                            DIGITAL FOOTPRINTS
                        </h5>
                        <div style={{ marginLeft: "16px", }}>
                            <p>
                                Open Ports
                            </p>
                            <p>SSL Certificates</p>
                            <p>Technologies</p>
                            <p>GitHub</p>
                            <p>DNS Records</p>
                            <p>Web Servers</p>
                        </div>
                    </div>

                    <div className="bg-white-Ipaddress p-4 d-flex"
                        style={{
                            alignItems: "center", // Ensures vertical alignment
                        }}
                    >
                        <h5
                            style={{
                                writingMode: "vertical-rl",
                                transform: "rotate(180deg)",
                                margin: "0px 0px 0px 0px",
                                textAlign: "center",
                                fontSize: "12px",
                                color: "#99A2B4",
                            }}
                        >
                            ASSETS
                        </h5>
                        <div style={{ marginLeft: "16px", }}>
                            <p>IP Address</p>
                            <p>Subdomains</p>
                        </div>
                    </div>
                    <div className="bg-grey p-4 mt-2 d-flex" style={{
                        alignItems: "center", // Ensures vertical alignment
                    }}>
                        <h5
                            style={{
                                writingMode: "vertical-rl",
                                transform: "rotate(180deg)",
                                margin: "0px 0px 0px 0px",
                                textAlign: "center",
                                fontSize: "12px",
                                color: "#99A2B4",
                            }}
                        >
                            VULNERABILITIES
                        </h5>
                        <div style={{ marginLeft: "16px" }}>
                            <p>MisConfigurations</p>
                            <p>CVE's</p>
                            <p >Subdomain Takeovers</p>
                            <p>SSL Vulnerabilities</p>
                            <p>Default Logins</p>
                            <p>Other Vulnerabilities</p>
                        </div>
                    </div>
                    <div className="bg-grey p-4 mt-2 d-flex" style={{
                        alignItems: "center", // Ensures vertical alignment
                    }}>
                        <h5
                            style={{
                                writingMode: "vertical-rl",
                                transform: "rotate(180deg)",
                                margin: "0px 0px 0px 0px",
                                textAlign: "center",
                                fontSize: "12px",
                                color: "#99A2B4",
                            }}
                        >
                            DARK WEB
                        </h5>
                        <div style={{ marginLeft: "16px" }}>
                            <p>Infected by Infostealer</p>
                            <p>Leaked Credentials</p>
                            <p>Ransomware Intels</p>
                        </div>
                    </div>
                </Col>
                <Col lg={9}>
                        {activeTab === "monthly" && <Monthly />}
                        {activeTab === "yearly" && <Yearly />}            
                </Col>
            </Row>

        </div>

    )

};

export default BubbleChart;
