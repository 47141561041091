import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import "./index.scss";
import { Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import PlusIcon from "../../../../../assets/images/common/add-square.svg";
import ViewMore from "../../../../../assets/images/common/more.svg";
// import MinusIcon from "../../../../../assets/images/common/minus-square.svg";

import ScanInfo from "../../../../ScanInfo";
import ContentBox from "../../../../../components/ui/ContentBox";

const SubDomainTakeOver = () => {

    const[activeContent, setActiveContent] = useState("affectedEndpoints");
    const [selectedViewMore, setSelectedViewMore] = useState(true)

    return (
        <ScanInfo>
            <div className="scan-info-main-content sensitive-exposure-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">TakeOver Risk</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">30.8K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> Potential</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Confirmed</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Subdomain Takeover</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">30.8K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page ports-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Status</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">3.3K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Ports</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> Open</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Closed</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <div className="js-secrets-main-content">
                        <Row>
                            <Col lg={3}>
                                <div className="bg-white-with-shadow subdomain-list-container h-100">
                                    <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                        <h1>Js Secrets List</h1>
                                        <img src={FilterIcon} alt="filter" className="filter-icon"/>
                                    </div>
                                    <div className="search-container px-3 position-relative">
                                        <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                        <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                    </div>
                                    <div className="list-main-container js-secrets-list">
                                        <div className="sub-domain-list py-2 px-3 active">
                                            <p className="sub-domain-heading">Cname Dangling</p>
                                            <p className="sub-domain-name">cybermindr.com</p>
                                            <div className="d-flex status-container">
                                                <p className="severity">Severity: <span className="high">High</span></p>
                                                <p className="affected-endpoint">Affected Endpoints: <span>12</span></p>
                                            </div>
                                            <div className="status-container mt-2">
                                                <p className="open">Status: <span>Open</span></p>
                                            </div>
                                        </div>
                                        <div className="sub-domain-list py-2 px-3">
                                            <p className="sub-domain-heading">Cname Dangling</p>
                                            <p className="sub-domain-name">cybermindr.com</p>
                                            <div className="d-flex status-container">
                                                <p className="severity">Severity: <span className="high">High</span></p>
                                                <p className="affected-endpoint">Affected Endpoints: <span>12</span></p>
                                            </div>
                                            <div className="status-container mt-2">
                                                <p className="closed">Status: <span>Closed</span></p>
                                            </div>
                                        </div>
                                        <div className="sub-domain-list py-2 px-3">
                                            <p className="sub-domain-heading">Cname Dangling</p>
                                            <p className="sub-domain-name">cybermindr.com</p>
                                            <div className="d-flex status-container">
                                                <p className="severity">Severity: <span className="high">High</span></p>
                                                <p className="affected-endpoint">Affected Endpoints: <span>12</span></p>
                                            </div>
                                            <div className="status-container mt-2">
                                                <p className="open">Status: <span>Open</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div className="main-content bg-white-with-shadow p-2 h-100">
                                    <div className="record-container-content p-2">
                                        <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{setActiveContent("jsSecretsSummary")}}>
                                            <h2 className="heading">Sensitive Exposure Summary</h2>
                                            <img src={PlusIcon} alt="expand"/>
                                        </div>
                                        {activeContent === "jsSecretsSummary" && <div className="js-secrete-summary mt-3">
                                            <Row>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"Name"} value={"Credentials Disclosure Check"}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"Description"} value={"Exposure of secrets in JavaScript occurs when sensitive data such as API keys, passwords, or cryptographic keys are hard-coded into client-side scripts. This practice can lead to significant security risks since client-side JavaScript is easily accessible to anyone who can view the source code through a web browser."}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <div className="content-box-container p-3 rounded h-100">
                                                        <h4 className="label">Serverity</h4>
                                                        <p className="serverity">High</p>
                                                    </div>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"CVS Score"} value={"8"}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"CVSS Metrics"} value={"CVSS:3.1/AV:N/AC:L/PR:N/UI:N/S:U/C:N/I:N/A:N"}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"CWE ID"} value={"CWE-200"}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"Remediation"} value={"Never store sensitive data directly in client-side code. Instead, retrieve secrets dynamically from secure, server-side environments."}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <div className="content-box-container p-3 rounded h-100">
                                                        <h4 className="label">Reference</h4>
                                                        <p className="value">http://sample1.com</p>
                                                        <p className="value">http://sample1.com</p>
                                                        <p className="value">http://sample1.com</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div> }                               
                                    </div>
                                    <div className="record-container-content p-2 mt-3">
                                        <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{setActiveContent("affectedEndpoints")}}>
                                            <h2 className="heading">Affected Endpoints</h2>
                                            <img src={PlusIcon} alt="expand"/>
                                        </div>
                                        {activeContent === "affectedEndpoints" && <div className="affected-endpoint-container">
                                            <div className="git-leaks-main-content">
                                                <div className="search-header-container d-flex align-items-center gap-3 my-3">
                                                    <div className="search-container position-relative">
                                                        <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                                        <input type="text" placeholder="Search" />
                                                    </div>
                                                    <button className="search-btn">Search</button>
                                                    <img src={FilterIcon} alt="filter" className="filtericon"/>
                                                </div>
                                                <div className="js-secrets-table">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>URL</th>
                                                            <th>Severity</th>
                                                            <th>Status</th>
                                                            <th>First Seen</th>
                                                            <th>Last Seen</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                https://github.com/pxai/angular2-0
                                                            </td>
                                                            <td>
                                                                <p className="severity critical">Critical</p>
                                                            </td>
                                                            <td>
                                                                <p className="status-close">Closed</p>
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                <div className="view-more-div position-relative">
                                                                    <img src={ViewMore} alt="more"/>
                                                                    {!selectedViewMore && <div className="more-option-container position-absolute">
                                                                        <p>Retest</p>
                                                                        <p>Valid</p>
                                                                        <p>Status</p>
                                                                    </div>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                https://github.com/pxai/angular2-0
                                                            </td>
                                                            <td>
                                                                <p className="severity high">High</p>
                                                            </td>
                                                            <td>
                                                                <p className="status-close">Closed</p>
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                <div className="view-more-div position-relative">
                                                                    <img src={ViewMore} alt="more"/>
                                                                    {!selectedViewMore && <div className="more-option-container position-absolute">
                                                                        <p>Retest</p>
                                                                        <p>Valid</p>
                                                                        <p>Status</p>
                                                                    </div>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                https://github.com/pxai/angular2-0
                                                            </td>
                                                            <td>
                                                                <p className="severity medium">Medium</p>
                                                            </td>
                                                            <td>
                                                                <p className="status-open">Open</p>
                                                            </td>
                                                            <td>
                                                                July 21, 2024 
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                <div className="view-more-div position-relative">
                                                                    <img src={ViewMore} alt="more"/>
                                                                    {!selectedViewMore && <div className="more-option-container position-absolute">
                                                                        <p>Retest</p>
                                                                        <p>Valid</p>
                                                                        <p>Status</p>
                                                                    </div>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                https://github.com/pxai/angular2-0
                                                            </td>
                                                            <td>
                                                                <p className="severity low">Low</p>
                                                            </td>
                                                            <td>
                                                                <p className="status-open">Open</p>
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                July 21, 2024
                                                            </td>
                                                            <td>
                                                                <div className="view-more-div position-relative">
                                                                    <img src={ViewMore} alt="more"/>
                                                                    {!selectedViewMore && <div className="more-option-container position-absolute">
                                                                        <p>Retest</p>
                                                                        <p>Valid</p>
                                                                        <p>Status</p>
                                                                    </div>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </ScanInfo>
        
    )
}


export default SubDomainTakeOver;