import { React} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./index.scss"

import cybermindrLogo from "../../../assets/images/common/cybermindr_logo.svg";
import customers from "../../../assets/images/side-nav/customer.svg"
import OrganizationImage from "../../../assets/images/side-nav/organization.svg";
import domain from "../../../assets/images/side-nav/domains.svg";
import scanInfo from "../../../assets/images/side-nav/scan-info.svg";
import Users from "../../../assets/images/side-nav/users.svg";
import monitor from "../../../assets/images/side-nav/monitor.svg";
import scanQueue from "../../../assets/images/side-nav/scan_queue.svg";
// import accountSetting from "../../../assets/images/side-nav/account-setting.svg";

import LanguageSwitcher from '../../../components/LanguageSwitcher';
import SideNavProfile from "../SideNavProfile";

import { getloggedInUserType } from "../../../utils/util";

const SideNav = () => {
    const { t } = useTranslation();
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <div className="side-nav-container pt-3">
            <div className="main-logo-container text-center">
                <img src={cybermindrLogo} alt="logo" />
            </div>
            <div className="side-nav-list">
                <ul type="none">
                    {getloggedInUserType() === "internal" && <li className={splitLocation[1] === "customers" ? "active" : ""}>                        
                        <Link to="/customers">
                            <img src={customers} alt="customer" className="d-block mx-auto"/>
                        {t("sideNav.nav7")}</Link>
                    </li>}
                    <li className={splitLocation[1] === "organization" || splitLocation[2] === "organization" ? "active" : ""}>                        
                        <Link to="/organization">
                            <img src={OrganizationImage} alt="orgaization" className="d-block mx-auto"/>
                            {t("sideNav.nav1")}
                        </Link>
                    </li>
                    <li className={splitLocation[1] === "domain" ? "active" : ""}>                        
                        <Link to="/domain">
                            <img src={domain} alt="domain" className="d-block mx-auto"/>
                            {t("sideNav.nav2")}
                        </Link>
                    </li>
                    <li className={splitLocation[1] === "scan-info" ? "active" : ""}>                        
                        <Link to="/scan-info/exposure/dashboard?customer=all&organization=all&domain=all&scanId=null">
                            <img src={scanInfo} alt="scanInfo" className="d-block mx-auto"/>
                            {t("sideNav.nav3")}
                        </Link>
                    </li>
                    <li className={splitLocation[1] === "users" ? "active" : ""}>                        
                        <Link to="/users">
                            <img src={Users} alt="Users" className="d-block mx-auto"/>
                            {t("sideNav.nav4")}
                        </Link>
                    </li>
                    <li className={splitLocation[1] === "monitor" ? "active" : ""}>                        
                        <Link to="/monitor">
                            <img src={monitor} alt="monitor" className="d-block mx-auto"/>
                            {t("sideNav.nav5")}
                        </Link>
                    </li>
                    <li className={splitLocation[1] === "scan-queue" ? "active" : ""}>                        
                        <Link to="/scan-queue">
                            <img src={scanQueue} alt="scanQueue" className="d-block mx-auto"/>
                            {t("sideNav.nav6")}
                        </Link>
                    </li>
                </ul>
                <div className="bottom-nav-list mt-5">
                    {/* <div className={`account-setting ${splitLocation[1] === "account" ? "active" : ""}`}>
                        <Link to="/account/settings"><img src={accountSetting} alt="account-setting"/></Link>
                    </div> */}
                    <LanguageSwitcher />
                    <SideNavProfile />
                </div>
            </div>
        </div>
    )
}

export default SideNav;