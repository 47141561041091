import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';

import Button from "../../../../components/ui/Button";

import { useDispatch, useSelector } from 'react-redux';

import apiEndpoints from "../../../../apiEndpoints.js";

import { updateUserStatus } from "../../../../store/actions/usersAction.js";

const UpdateUserStatusModal = ({show, onHide, selectedUserData}) => {

    const deleteUserResponse = useSelector((state) => state?.users?.deleteUserResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false)

    const confirmUpdate = () => {
        let url = selectedUserData.status === "active" ? apiEndpoints.users.disableUserSingle() : apiEndpoints.users.activateUserSingle()
        let obj = {
            user_id: selectedUserData?.user_id
        }
        setIsLoading(true)
        console.log("obj", obj)
        dispatch(updateUserStatus(url, obj))
    }

    useEffect(()=>{
        if(deleteUserResponse !== null){
            setIsLoading(false)
        }
    },[deleteUserResponse])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("users.updateUserStatusModal.heading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("users.updateUserStatusModal.warningMsg")} {selectedUserData?.firstname} {selectedUserData?.lastname} {t("users.updateUserStatusModal.status")}?</p>                            
                        </div>
                        <div className="d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Update" buttonType={"primary"} buttonClicked={()=>{confirmUpdate()}} />}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default UpdateUserStatusModal;