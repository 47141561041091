import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import "./index.scss";

import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";
import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import PlusIcon from "../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../assets/images/common/minus-square.svg";
import PasswordCloseIcon from "../../../../../assets/images/LoginPage/eye-slash.svg";
import PasswordOpenIcon from "../../../../../assets/images/LoginPage/eye.svg";


import ProfileAvatar from "../../../../../components/ui/ProfileAvatar";
import ContentBox from "../../../../../components/ui/ContentBox";
import Spinner from "../../../../../components/ui/spinner";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import Pagination from "../../../../../components/ui/Pagination";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getLeakedCredentials, getLeakedCredentialsDataList } from "../../../../../store/actions/scanInfoAction";

import { formatDate } from "../../../../../utils/util";

const LeakedCredentials = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const leakedCredentialsListResponse = useSelector((state) => state.scanInfo?.leakedCredentialsList);
    const leakedCredentialsDataResponse = useSelector((state) => state.scanInfo?.leakedCredentialsData);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeContent, setActiveContent] = useState("jsSecretsSummary");
    const [selectedLeakedCred, setSelectedLeakedCred] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isEndpointLoading, setIsEndpointLoading] = useState(false);
    const [visiblePasswordId, setVisiblePasswordId] = useState(null);


    const hidePassword = (data) => {
        if (data.length <= 2) {
            return data; // Return as is if the length is 2 or less
        }
    
        const firstChar = data[0]; // First character
        const lastChar = data[data.length - 1]; // Last character
        const middle = '*'.repeat(data.length - 2); // Replace middle characters with '*'
    
        return `${firstChar}${middle}${lastChar}`;
    }

    const showPassword = (id) => {
        if (visiblePasswordId === id) {
            setVisiblePasswordId(null); // Hide password if clicked again
        } else {
            setVisiblePasswordId(id); // Show password for the clicked row
        }
    };

    const showContent = (data) => {  
        if(activeContent === data) {
            setActiveContent("")
        } else {
            setActiveContent(data)
            if(data === "affectedEndpoints") {
                fetchAffectedEndPoints()
            }
        }
    }
    
    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchAffectedEndPoints = useCallback(()=>{
        setIsEndpointLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedLeakedCred?.id}`;
        dispatch(getLeakedCredentialsDataList(url))

    },[dispatch, currentPage, currentPageSize, domainId, scanId, selectedLeakedCred])

    const fetchLeakedCredList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=10&domain=${domainId}&scan_id=${scanId}`
        dispatch(getLeakedCredentials(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
            fetchAffectedEndPoints()
    },[selectedLeakedCred, fetchAffectedEndPoints])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchLeakedCredList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, scanId, fetchLeakedCredList])

    useEffect(()=>{
        if(leakedCredentialsListResponse !== null) {
            setSelectedLeakedCred(leakedCredentialsListResponse?.results[0]);
        }
    },[leakedCredentialsListResponse])

    useEffect(()=>{
        if(leakedCredentialsDataResponse !== null) {
            setIsEndpointLoading(false)
        }
    },[leakedCredentialsDataResponse])


    return (
        <>
            <div className="scan-info-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="sub-domain-type-container web-server-type-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Leak Name</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-records-container">
                                    <p className="content">370 <span>Total Records</span></p>
                                </div>
                                <div className="sub-domain-content">
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage} />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div>                                        
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div> 
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Leaked Credentials</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">30.8K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page ports-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Email Status</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">3.3K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Ports</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Leaked Credentials List</h1>
                                    <img src={FilterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container">
                                    {
                                        leakedCredentialsListResponse?.results.length > 0 &&
                                        leakedCredentialsListResponse?.results.map((data, index) => {
                                            return (
                                                <div className={`sub-domain-list py-2 px-3 ${selectedLeakedCred.id === data?.id ? "active" : ""}`} onClick={()=>{setSelectedLeakedCred(data)}} key={index}>
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <p className="sub-domain-heading">{data?.leak_title}</p>
                                                        {data.new_record && <span className="new-subdomain">New</span>}
                                                    </div>
                                                    <p className="sub-domain-name">{data?.domain}</p>
                                                    {/* <p className="sub-domain-heading">Employee: 12/50</p> */}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="main-content bg-white-with-shadow p-2 h-100">
                                <div className="record-container-content p-2">
                                    <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("jsSecretsSummary")}}>
                                        <h2 className="heading">Leaked Credentials Summary</h2>
                                        <img src={activeContent === "jsSecretsSummary" ? MinusIcon : PlusIcon} alt="expand"/>
                                    </div>
                                    {activeContent === "jsSecretsSummary" && <div className="js-secrete-summary mt-3">
                                        <Row>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Name"} value={selectedLeakedCred?.leak_name} />
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Description"} value={selectedLeakedCred?.leak_description} />
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Domain"} value={selectedLeakedCred?.leak_domain} />
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Date"} value={formatDate(selectedLeakedCred?.leak_date, "date")} />
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                {selectedLeakedCred !== "" && <ContentBox label={"Data Classess"} value={selectedLeakedCred?.leak_data_classes.join(", ")} />}
                                            </Col>
                                        </Row>
                                    </div> }                               
                                </div>
                                <div className="record-container-content p-2 mt-3">
                                    <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("affectedEndpoints")}}>
                                        <h2 className="heading">Leaked Data</h2>
                                        <img src={activeContent === "affectedEndpoints" ? MinusIcon : PlusIcon} alt="expand"/>
                                    </div>
                                    {activeContent === "affectedEndpoints" && <div className="affected-endpoint-container">
                                        <div className="git-leaks-main-content">
                                            <div className="search-header-container d-flex align-items-center gap-3 my-3">
                                                <div className="search-container position-relative">
                                                    <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                                    <input type="text" placeholder="Search" />
                                                </div>
                                                <button className="search-btn">Search</button>
                                                <img src={FilterIcon} alt="filter" className="filtericon"/>
                                            </div>
                                            <div className="js-secrets-table">
                                                {
                                                    isEndpointLoading ?
                                                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <Table responsive="lg">
                                                        <thead>
                                                            <tr>
                                                                <th>Email</th>
                                                                <th>Username</th>
                                                                <th>Password/Hash Password</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                leakedCredentialsDataResponse?.results.length > 0 &&
                                                                leakedCredentialsDataResponse?.results.map((data, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                {data?.email}
                                                                            </td>
                                                                            <td>
                                                                            {data?.username !== null && data?.username !== "" ? data?.username : "-"}
                                                                            </td>
                                                                            <td>
                                                                                {data?.password !== null && data?.password !== "" ? <p className="password-data">
                                                                                    {visiblePasswordId === data.id
                                                                                        ? data?.password
                                                                                        : hidePassword(data?.password)}
                                                                                    <img
                                                                                        src={visiblePasswordId === data.id ? PasswordOpenIcon : PasswordCloseIcon}
                                                                                        alt="password"
                                                                                        className="mx-2"
                                                                                        onClick={() => showPassword(data.id)}
                                                                                    />
                                                                                </p> : "-"}
                                                                            </td>
                                                                            <td>
                                                                                {data?.email_status === "active" && <p className="status-close">{data?.email_status}</p>}
                                                                                {data?.email_status === "inactive" && <p className="status-open">{data?.email_status}</p>}
                                                                                {(data?.email_status === null || data?.email_status === "") && "-"}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                                {
                                                    !isEndpointLoading && leakedCredentialsDataResponse !== "" && leakedCredentialsDataResponse?.results.length === 0 && 
                                                    <NoDataFound noDataText={"No Record Found"}/>
                                                }
                                                {leakedCredentialsDataResponse !== "" && leakedCredentialsDataResponse?.results.length > 0 && <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    pageSize={currentPageSize}
                                                    totalCount={leakedCredentialsDataResponse?.meta?.total_results}
                                                    onPageChange={setCurrentPageandRecordSize}
                                                />}                                        
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>        
    )
}


export default LeakedCredentials;