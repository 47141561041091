import { React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";

import Button from "../../../ui/Button";
import DropDown from '../../../ui/DropDown';
import RadioButton from "../../../../components/ui/RadioButton";

import { useSelector, useDispatch } from 'react-redux';
import { getDomainList } from '../../../../store/actions/organizationActions';
import { addMonitor, resetAddMonitorResponse, editMonitor, resetEditMonitorResponse } from "../../../../store/actions/monitorAction";

const AddMonitorModal = ({closeAddmonitorModal, type="", monitorDetails=""}) => {

    const domainListResponse = useSelector((state) => state.organization?.domainList);
    const addMonitorResponse = useSelector((state) => state.monitor?.addMonitorResponse);
    const editMonitorResponse = useSelector((state) => state.monitor?.editMonitorResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [domainList, setDomainList] = useState([]);
    const [scanType, setScanType] = useState(''); // Default to "active"
    const [scanSpeed, setScanSpeed] = useState('');  // Default to "slow"
    const [selectedDomain, setSelectedDomain] = useState(""); // Default to "Option 1"
    const [monitorType, setMonitorType] = useState("");
    const [addMonitorSuccess, setAddMonitorSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDomainChange = (val) => {
        setSelectedDomain(val);
    };

    const disableButton = () => {
        if(type === "edit") {
            return scanType === "" || monitorType === "" || scanSpeed === ""
        } else {
            return selectedDomain === "" || scanType === "" || monitorType === "" || scanSpeed === ""
        }
    }

    const goBack = () => {
        setAddMonitorSuccess(false);       
        setIsLoading(false);
        if(type === "edit") {
            closeAddmonitorModal(editMonitorResponse);
            dispatch(resetEditMonitorResponse())
        } else {
            setScanType("");
            setScanSpeed("");
            setSelectedDomain("");
            setMonitorType("");
            closeAddmonitorModal(addMonitorResponse);        
            dispatch(resetAddMonitorResponse())
        }
        
    };

    const addMonitorSave = () => {
        let obj = {            
            scan_type: scanType,
            scan_speed: scanSpeed,
            monitor_type: monitorType
        }
        if( type === "edit") {
            obj.domain = monitorDetails?.monitor_domain.domain
            setIsLoading(true);
            dispatch(editMonitor(obj));
        } else {
            obj.domain = selectedDomain?.domain
            setIsLoading(true);
            dispatch(addMonitor(obj));
        }
    }

    const updateValue = useCallback(()=>{
        if(monitorDetails !== "") {
            setScanType(monitorDetails?.scan_type);
            setScanSpeed(monitorDetails?.scan_speed);
            setMonitorType(monitorDetails?.monitor_type);
        }
    },[monitorDetails])

    const fetchDomainList = useCallback(()=>{
        dispatch(getDomainList(""));
    },[dispatch])

    useEffect(()=>{
        fetchDomainList();
    },[fetchDomainList])

    useEffect(()=>{
        if(domainListResponse !== null) {
            let list = domainListResponse?.results.map((data)=>{
                return {
                    id: data.domain_id,
                    domain: data.domain,
                    value: data.domain_id,
                    logo: data.logo,
                    label: data.domain
                }
            })
            setDomainList(list)
        }
    },[domainListResponse])

    useEffect(()=>{
        if(addMonitorResponse !== null) {
            setIsLoading(false)
            setAddMonitorSuccess(true)
        }
    },[addMonitorResponse])

    useEffect(()=>{
        if(editMonitorResponse !== null) {
            setIsLoading(false)
            setAddMonitorSuccess(true)
        }
    },[editMonitorResponse])

    useEffect(()=>{
        if(type === "edit") {
            updateValue()
        }
    },[type, updateValue])
    

    return (
        <div className="add-monitor-container">
            {!addMonitorSuccess &&
                <>
                    <div className="modal-header mt-4">
                        <h2>{t("monitorPage.addMonitorModal.addMonitor")}</h2>
                    </div>
                    <h4>{t("monitorPage.addMonitorModal.monitorDetails")}</h4>
                    <div className="add-organization-step-one mt-3">
                        {type !== "edit" && <div className="input-container mb-4">
                            <p className="mb-3">Select the Domain</p>
                            <DropDown
                                options={domainList}
                                placeHolder="Select the Domain"
                                onChange={handleDomainChange}
                                isSearchable={true}
                                isMulti={false}
                                selectedValue={selectedDomain}
                            />
                        </div>}
                        <div className="organization-type">
                            <p>{t("domainPage.scanModal.selectScanType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="active"
                                    radioLabel="Active"
                                    radioValue="active"
                                    onChange={(value)=>{setScanType(value)}}
                                    isChecked={scanType === "active"}
                                />
                                <RadioButton
                                    radioId="passive"
                                    radioLabel="Passive"
                                    radioValue="passive"
                                    onChange={(value)=>{setScanType(value)}}
                                    isChecked={scanType === "passive"}
                                />
                            </div>
                        </div>
                        <div className="organization-type mt-3">
                            <p>{t("monitorPage.editMonitorModal.selectMonitorType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="weekly"
                                    radioLabel="Weekly"
                                    radioValue="weekly"
                                    onChange={(value)=>{setMonitorType(value)}}
                                    isChecked={monitorType === "weekly"}
                                />
                                <RadioButton
                                    radioId="monthly"
                                    radioLabel="Monthly"
                                    radioValue="monthly"
                                    onChange={(value)=>{setMonitorType(value)}}
                                    isChecked={monitorType === "monthly"}
                                />
                                <RadioButton
                                    radioId="quaterly"
                                    radioLabel="Quaterly"
                                    radioValue="Quaterly"
                                    onChange={(value)=>{setMonitorType(value)}}
                                    isChecked={monitorType === "Quaterly"}
                                />
                            </div>
                        </div>
                        <div className="organization-type mt-3">
                            <p>Scan Speed</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="slow"
                                    radioLabel="Slow"
                                    radioValue="slow"
                                    onChange={(value)=>{setScanSpeed(value)}}
                                    isChecked={scanSpeed === "slow"}
                                />
                                <RadioButton
                                    radioId="moderate"
                                    radioLabel="Moderate"
                                    radioValue="moderate"
                                    onChange={(value)=>{setScanSpeed(value)}}
                                    isChecked={scanSpeed === "moderate"}
                                />
                                <RadioButton
                                    radioId="fast"
                                    radioLabel="Fast"
                                    radioValue="fast"
                                    onChange={(value)=>{setScanSpeed(value)}}
                                    isChecked={scanSpeed === "fast"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-submit-btn-group mt-4">
                        <Button buttonType={"secondary"} buttonText={"Cancel"} />
                        {!isLoading && <Button buttonType={"primary"} buttonText={"Save"} buttonClicked={addMonitorSave} disableButton={disableButton()}/>}
                        {isLoading && <Button buttonType={"primary"} buttonText={"Loading..."} disableButton={true}/>}
                    </div>
                </>
            }
            {addMonitorSuccess && (
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {type !== "edit" && <>
                        {
                            addMonitorResponse?.detail?.status === "success" &&
                            <>
                                <div className="mt-5">
                                    <img src={successTick} alt="success" />
                                </div>
                                <h2 className="mb-4 mt-5">{addMonitorResponse?.detail?.description}</h2>
                                {/* <p>{t("organizationDetailsPage.addDomain.successMsg")}</p> */}
                                <div className="btn-container mt-5">
                                    <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                                </div>
                            </>
                        }
                        {
                            addMonitorResponse?.status === "failed" && 
                                <>
                                <div className="mt-5">
                                    <img src={failed} alt="failed" />
                                </div>
                                <h2 className="mb-4 mt-5">{addMonitorResponse?.description}</h2>
                                <div className="btn-container mt-5">
                                    <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                                </div>
                            </>
                        }
                    </>}
                    {type === "edit" && <>
                        {
                            editMonitorResponse?.detail?.status === "success" &&
                            <>
                                <div className="mt-5">
                                    <img src={successTick} alt="success" />
                                </div>
                                <h2 className="mb-4 mt-5">{editMonitorResponse?.detail?.description}</h2>
                                {/* <p>{t("organizationDetailsPage.addDomain.successMsg")}</p> */}
                                <div className="btn-container mt-5">
                                    <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                                </div>
                            </>
                        }
                        {
                            editMonitorResponse?.status === "failed" && 
                                <>
                                <div className="mt-5">
                                    <img src={failed} alt="failed" />
                                </div>
                                <h2 className="mb-4 mt-5">{editMonitorResponse?.description}</h2>
                                <div className="btn-container mt-5">
                                    <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                                </div>
                            </>
                        }
                    </>}
                
                
                </div>
            )}
        </div>
    );
};

export default AddMonitorModal;
