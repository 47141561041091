import React from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
// import "./index.scss";

import ContentBox from "../../../../../../ui/ContentBox";
import { formatDate } from "../../../../../../../utils/util";

const SSLCertificate = ({sslCertificateData}) => {

    return (
        <div className="address-content mt-4">
            <h4 className="heading my-4">Issued By:</h4>
            <Row>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Common Name"} value={sslCertificateData?.issuer?.common_name} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Country"} value={sslCertificateData?.issuer?.country} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"State"} value={sslCertificateData?.issuer?.state} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Locality"} value={sslCertificateData?.issuer?.locality} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Organization"} value={sslCertificateData?.issuer?.organization} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Organization Unit"} value={sslCertificateData?.issuer?.organization_unit} />
                </Col>
            </Row>
            <h4 className="heading my-4">Issued To:</h4>
            <Row>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Common Name"} value={sslCertificateData?.subject?.common_name} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Country"} value={sslCertificateData?.subject?.country} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"State"} value={sslCertificateData?.subject?.state} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Locality"} value={sslCertificateData?.subject?.locality} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Organization"} value={sslCertificateData?.subject?.organization} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Organization Unit"} value={sslCertificateData?.subject?.organization_unit} />
                </Col>
            </Row>
            <h4 className="heading my-4">Validity Period:</h4>
            <Row>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Issued On"} value={formatDate(sslCertificateData?.not_before)} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Expired On"} value={formatDate(sslCertificateData?.not_after)} />
                </Col>                
            </Row>
            <h4 className="heading my-4">FingerPrint:</h4>
            <Row>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"MD5"} value={sslCertificateData?.fingerprints?.md5} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"SHA 1"} value={sslCertificateData?.fingerprints?.sha1} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"SHA256"} value={sslCertificateData?.fingerprints?.sha256} />
                </Col>
                
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Jarm"} value={sslCertificateData?.fingerprints?.jarm} />
                </Col>
            </Row>
            <h4 className="heading my-4">Cipher:</h4>
            <Row>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"version"} value={sslCertificateData?.cipher?.version} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Bits"} value={sslCertificateData?.cipher?.bits} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Name"} value={sslCertificateData?.cipher?.name} />
                </Col>
            </Row>                                        
            <h4 className="heading my-4">Public:</h4>
            <Row>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Key Type"} value={sslCertificateData?.public_key?.key_type} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"Bit Length"} value={sslCertificateData?.public_key?.bit_length} />
                </Col>
                <Col lg="3" className="mb-4">
                    <ContentBox label={"key"} value={sslCertificateData?.public_key?.key} />
                </Col>
            </Row>
            <h4 className="heading my-4">Serial Number:</h4>
            <Row>
                <Col lg="6" className="mb-4">
                    <ContentBox label={"Serial Number"} value={sslCertificateData?.serial_number} />
                </Col>
            </Row>
            <h4 className="heading my-4">Extension:</h4>
            <Row>
                <Col lg="6" className="mb-4">
                    <div className="content-box-container p-3 rounded h-100">
                        <h4 className="label">Authority key Id</h4>
                        <div className="value">
                            <p className="value">{sslCertificateData?.extensions?.authority_key_id !== null ? sslCertificateData?.extensions?.authority_key_id : "-"}</p>
                        </div>
                    </div>
                </Col>
                <Col lg="6" className="mb-4">
                    <div className="content-box-container p-3 rounded h-100">
                        <h4 className="label">Subject key Id</h4>
                        <div className="value">
                            <p className="value">{sslCertificateData?.extensions?.subject_key_id !== null ? sslCertificateData?.extensions?.subject_key_id : "-"}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="6" className="mb-4">
                    <div className="content-box-container p-3 rounded">
                        <h4 className="label">key Usage</h4>
                        <div className="value">
                            <p className="d-flex justify-content-between mb-2"><span>digital_signature</span> <span>{sslCertificateData?.extensions?.key_usage?.digital_signature !== null ? sslCertificateData?.extensions?.key_usage?.digital_signature ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>key_encipherment</span> <span>{sslCertificateData?.extensions?.key_usage?.key_encipherment !== null ? sslCertificateData?.extensions?.key_usage?.key_encipherment ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>key_agreement</span> <span>{sslCertificateData?.extensions?.key_usage?.key_agreement !== null ? sslCertificateData?.extensions?.key_usage?.key_agreement ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>content_commitment</span> <span>{sslCertificateData?.extensions?.key_usage?.content_commitment !== null ? sslCertificateData?.extensions?.key_usage?.content_commitment ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>data_encipherment</span> <span>{sslCertificateData?.extensions?.key_usage?.data_encipherment !== null ? sslCertificateData?.extensions?.key_usage?.data_encipherment ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>certificate_sign</span> <span>{sslCertificateData?.extensions?.key_usage?.certificate_sign !== null ? sslCertificateData?.extensions?.key_usage?.certificate_sign ? "TRUE" : "FALSE": "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>crl_sign</span> <span>{sslCertificateData?.extensions?.key_usage?.crl_sign ? sslCertificateData?.extensions?.key_usage?.crl_sign ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>encipher_only</span> <span>{sslCertificateData?.extensions?.key_usage?.encipher_only !== null ? sslCertificateData?.extensions?.key_usage?.encipher_only ? "TRUE" : "FALSE" : "-"}</span></p>
                            <p className="d-flex justify-content-between mb-2"><span>decipher_only</span> <span>{sslCertificateData?.extensions?.key_usage?.decipher_only !== null ? sslCertificateData?.extensions?.key_usage?.decipher_only ? "TRUE" : "FALSE" : "-"}</span></p>
                        </div>
                    </div>
                </Col>
                <Col lg="6" className="mb-4">
                    <div className="mb-2">
                        <ContentBox label={"Extended key Usage"} value={sslCertificateData?.extensions?.extended_key_usage.join(', ')} />
                    </div>
                    <div className="mb-2">
                        <ContentBox label={"Constraints"} value={sslCertificateData?.extensions?.constraints?.is_ca ? "Is_ca" : "-"} />
                    </div>
                    <div className="mb-2">
                        <ContentBox label={"Subject Alternative Name"} value={sslCertificateData?.extensions?.sans.join(', ')} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="6" className="mb-4">
                    <div className="content-box-container p-3 rounded h-100">
                        <h4 className="label">Authoristy Information Access</h4>
                        <div className="value">
                            <p className="d-flex gap-5 mb-2"><span>OSCP</span> <span>{sslCertificateData?.extensions?.aia_paths?.ocsp}</span></p>
                            <p className="d-flex gap-5 mb-5"><span>Issuer</span> <span>{sslCertificateData?.extensions?.aia_paths?.issuer}</span></p>
                        </div>
                    </div>
                </Col>                
            </Row>
        </div>
    )
}

export default SSLCertificate;