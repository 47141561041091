import { React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import RadioButton from "../../../ui/RadioButton";
import Button from "../../../ui/Button";
import { toaster } from "../../../ui/Toaster";

import { useSelector, useDispatch } from 'react-redux';
import { editDomainPermission, resetEditPermissionResponse } from '../../../../store/actions/customerAction';

const EditPermissionModal = ({ show, onHide, selectedDomain }) => {
    const permissionDetailResponse = useSelector((state) => state?.customer?.permissionDetailResponse);
    const editDomainPermissionResponse = useSelector((state) => state?.customer?.editDomainPermissionResponse);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { customerId } = useParams();

    const [activeScan, setActiveScan] = useState("");
    const [passiveScan, setPassiveScan] = useState("");
    const [internal, setInternal] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const updateSubscription = () => {
        const obj = {
            customer_id: customerId,
            domain: selectedDomain.domain,
            passive_scan: passiveScan === "yes",
            active_scan: activeScan === "yes",
            external: internal === "yes",
        };
        setIsLoading(true);
        dispatch(editDomainPermission(obj));
    };

    const updateValue = useCallback(() => {
        if (permissionDetailResponse) {
            setActiveScan(permissionDetailResponse?.active_scan ? "yes" : "no");
            setPassiveScan(permissionDetailResponse?.passive_scan ? "yes" : "no");
            setInternal(permissionDetailResponse?.external ? "yes" : "no");
        }
    }, [permissionDetailResponse]);

    useEffect(() => {
        if (permissionDetailResponse !== null) {
            updateValue();
        }
    }, [permissionDetailResponse, updateValue]);

    useEffect(() => {
        if (editDomainPermissionResponse != null) {
            setIsLoading(false);
            if (editDomainPermissionResponse?.detail?.status === "success") {
                toaster.addToast(editDomainPermissionResponse?.detail?.description, "success");
            } else {
                toaster.addToast(editDomainPermissionResponse?.description, "error");
            }
            dispatch(resetEditPermissionResponse());
            onHide();
        }
    }, [editDomainPermissionResponse, onHide, dispatch]);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body>
                <div className="delete-organization-container">
                    <h4>{t('customerDetails.editPermissionModal.heading')}</h4>
                    <div className="add-organization-step-one mb-5">
                        <div className="organization-type mt-4">
                            <p>{t('customerDetails.editPermissionModal.passiveScan')}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'passive-yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setPassiveScan(value)}}  isChecked={passiveScan === 'yes'}/>
                                <RadioButton radioId={'passive-no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setPassiveScan(value)}} isChecked={passiveScan === 'no'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-4">
                            <p>{t('customerDetails.editPermissionModal.activeScan')}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'active-yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setActiveScan(value)}}  isChecked={activeScan === 'yes'}/>
                                <RadioButton radioId={'active-no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setActiveScan(value)}} isChecked={activeScan === 'no'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-4">
                            <p>{t('customerDetails.editPermissionModal.internal')}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'internal-yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setInternal(value)}}  isChecked={internal === 'yes'}/>
                                <RadioButton radioId={'internal-no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setInternal(value)}} isChecked={internal === 'no'}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex gap-3 m-2 mt-4">
                        <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                        {!isLoading && <Button buttonText="Edit" buttonType={"primary"} buttonClicked={updateSubscription}/>}
                        {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true}/>}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditPermissionModal;