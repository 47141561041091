import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import "./index.scss";


import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import PlusIcon from "../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../assets/images/common/minus-square.svg";
import ViewMore from "../../../../../assets/images/common/more.svg";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import Spinner from "../../../../../components/ui/spinner";
import Button from "../../../../../components/ui/Button";

import ContentBox from "../../../../../components/ui/ContentBox";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getJsSecretsList, getJsSecretsEndpoints } from "../../../../../store/actions/scanInfoAction";

const JSSecrets = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const jsSecretsListResponse = useSelector((state) => state.scanInfo?.jsSecretsList);
    const jsSecretsEndpointsListResponse = useSelector((state) => state.scanInfo?.jsSecretsEndpointsList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const[activeContent, setActiveContent] = useState("affectedEndpoints");
    const [selectedViewMore, setSelectedViewMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedjsSecretsList, setSelectedJsSecretsList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isEndpointLoading, setIsEndpointLoading] = useState(false);
    const [showExtractDataModal, setShowExtractDataModal] = useState(false);
    const [extractedData, setExtractedData] = useState("")

    const showContent = (data) => {  
        if(activeContent === data) {
            setActiveContent("")
        } else {
            setActiveContent(data)
            if(data === "affectedEndpoints") {
                fetchAffectedEndPoints()
            }
        }
    }

    const fetchAffectedEndPoints = useCallback(()=>{
        setIsEndpointLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedjsSecretsList?.id}`;
        setIsLoading(true);
        dispatch(getJsSecretsEndpoints(url))

    },[currentPage, currentPageSize, domainId, scanId, selectedjsSecretsList, dispatch])
    

    const fetchJsSecretsList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=20&domain=${domainId}&scan_id=${scanId}`
        setIsLoading(true);
        dispatch(getJsSecretsList(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
        fetchAffectedEndPoints()
    },[selectedjsSecretsList, fetchAffectedEndPoints])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchJsSecretsList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, scanId, fetchJsSecretsList])

    useEffect(()=>{
        console.log("jsSecretsListResponse", jsSecretsListResponse)
        if(jsSecretsListResponse !== null) {
            if(jsSecretsListResponse?.results.length > 0) {
                setSelectedJsSecretsList(jsSecretsListResponse?.results[0])
            }
            setIsLoading(false);
        }
    },[jsSecretsListResponse])

    useEffect(()=>{
        if(jsSecretsEndpointsListResponse !== null) {
            setIsEndpointLoading(false);
        }
    },[jsSecretsEndpointsListResponse])

    return (
        <>
            <div className="js-secrets-main-content">
                <Row>
                    <Col lg={3}>
                        <div className="bg-white-with-shadow subdomain-list-container h-100">
                            <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                <h1>Js Secrets List</h1>
                                <img src={FilterIcon} alt="filter" className="filter-icon"/>
                            </div>
                            <div className="search-container px-3 position-relative">
                                <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                <input type="text" placeholder="Search" className="w-100 rounded input"/>
                            </div>
                            <div className="list-main-container js-secrets-list">
                                {
                                    jsSecretsListResponse?.results.length > 0 &&
                                    jsSecretsListResponse?.results.map((data, index)=>{
                                        return (
                                            <div className={`sub-domain-list p-2 ${selectedjsSecretsList?.id === data?.id ? "active" : ""}`} key={index} onClick={()=>{setSelectedJsSecretsList(data)}}>
                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                    <p className="sub-domain-heading">{data?.name}</p>
                                                    {data.new_record && <span className="new-subdomain">New</span>}
                                                </div>                                                
                                                <p className="sub-domain-name">{data?.domain}</p>
                                                <div className="d-flex  status-container">
                                                    <p className="severity">Severity: <span className="high">{data?.severity}</span></p>
                                                    <p className="affected-endpoint">Affected Endpoints: <span>{data?.endpoints?.total}</span></p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                    <Col lg={9}>
                        <div className="main-content bg-white-with-shadow p-2 h-100">
                            <div className="record-container-content p-2">
                                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("jsSecretsSummary")}}>
                                    <h2 className="heading">JS Secrets Summary</h2>
                                    <img src={activeContent === "jsSecretsSummary" ? MinusIcon : PlusIcon} alt="expand"/>
                                </div>
                                {activeContent === "jsSecretsSummary" && <div className="js-secrete-summary mt-3">
                                    <Row>
                                        <Col lg={6} className="mb-3">
                                            <ContentBox label={"Name"} value={selectedjsSecretsList?.name}/>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <ContentBox label={"Description"} value={selectedjsSecretsList?.description}/>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <div className="content-box-container p-3 rounded h-100">
                                                <h4 className="label">Serverity</h4>
                                                <p className="serverity">{selectedjsSecretsList?.severity}</p>
                                            </div>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <ContentBox label={"Remediation"} value={selectedjsSecretsList?.remediation}/>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <div className="content-box-container p-3 rounded h-100">
                                                <h4 className="label">Reference</h4>
                                                { selectedjsSecretsList !== "" && selectedjsSecretsList?.reference.length > 0  ?
                                                    selectedjsSecretsList?.reference.map((data, index) => {
                                                        return (
                                                            <p className="value" key={index}>{data}</p>
                                                        )
                                                    })
                                                    :
                                                    "-"                                                            
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div> }                               
                            </div>
                            <div className="record-container-content p-2 mt-3">
                                <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("affectedEndpoints")}}>
                                    <h2 className="heading">Affected Endpoints</h2>
                                    <img src={activeContent === "affectedEndpoints" ? MinusIcon : PlusIcon} alt="expand"/>
                                </div>
                                {activeContent === "affectedEndpoints" && <div className="affected-endpoint-container">
                                    <div className="git-leaks-main-content">
                                        <div className="search-header-container d-flex align-items-center gap-3 my-3">
                                            <div className="search-container position-relative">
                                                <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                                <input type="text" placeholder="Search" />
                                            </div>
                                            <button className="search-btn">Search</button>
                                            <img src={FilterIcon} alt="filter" className="filtericon"/>
                                        </div>
                                        <div className="js-secrets-table">
                                            {
                                                isEndpointLoading ?
                                                <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                                                    <Spinner />
                                                </div>
                                                :
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>URL</th>
                                                            <th>Extracted Data</th>
                                                            <th>Status</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            jsSecretsEndpointsListResponse?.results.length > 0 &&
                                                            jsSecretsEndpointsListResponse?.results.map((data, index)=>{
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="url-container">
                                                                            <a href={data?.url} target="_blank" className="table-url" rel="noreferrer">{data?.url}</a>
                                                                        </td>
                                                                        <td className="extraction-container">
                                                                            {data?.extracted_data !== null && data?.extracted_data !== "" ? <p className="cursor-pointer" onClick={()=>{
                                                                                setShowExtractDataModal(true)
                                                                                setExtractedData(data?.extracted_data);
                                                                            }}>view</p>: "-"}
                                                                        </td>
                                                                        <td className="status-container">
                                                                            <p className="status-close">Closed</p>
                                                                        </td>
                                                                        <td>
                                                                            <div className="view-more-div position-relative">
                                                                                <img src={ViewMore} alt="more"/>
                                                                                {!selectedViewMore && <div className="more-option-container position-absolute">
                                                                                    <p>Retest</p>
                                                                                    <p>Valid</p>
                                                                                    <p>Status</p>
                                                                                </div>}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            }
                                            {
                                                !isEndpointLoading && jsSecretsEndpointsListResponse !== "" && jsSecretsEndpointsListResponse?.results.length === 0 && 
                                                <NoDataFound noDataText={"No Record Found"}/>
                                            }
                                        
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal
                    show={showExtractDataModal}
                    onHide={()=>setShowExtractDataModal(false)}
                    size="md"
                    centered
                    className=""
                    >
                        <Modal.Body>                                
                            <div className="delete-organization-container">
                                <h4>Extracted Data</h4>
                                <div className="p-4 m-2">
                                    <p>{extractedData}</p>
                                </div>
                                <div className="d-flex justify-content-center gap-3 m-2">
                                    <div className="w-50">                                                
                                        <Button buttonText="Close" buttonType={"secondary"} buttonClicked={()=>setShowExtractDataModal(false)}/>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                </Modal>
            </div>
        </>
        
    )
}


export default JSSecrets;