import { React } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.scss";


import arrowRight from "../../../../assets/images/common/arrow-right-border.svg";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import { formatDate, formatNumber, getDomainImageUrl } from "../../../../utils/util";


const DomainListView = ({domainList}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleViewDetailsClick = (data) => {
        navigate(`/domain/details/${data.domain}`); // Replace '/details' with your target route
    };

    const formatCreatedDate = (data) => {
        let date = formatDate(data.created_at)
        return `Created On : ${date}`
    }

    return (
        <Row>
            <Col md="12">
                <div className="domain-list-view-container organization-list-view-table p-3 mt-4">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t('domainPage.listView.domain')}</th>
                                <th>{t('domainPage.listView.scanStatus')}</th>
                                <th>{t('domainPage.listView.hackerScore')}</th>
                                <th>{t('domainPage.listView.riskRating')}</th>
                                <th>{t('domainPage.listView.totalFinding')}</th>
                                <th>{t('domainPage.listView.lastScan')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                domainList?.results.length > 0 ?
                                domainList?.results.map((data, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <ProfileAvatar type="domain" name={data?.domain} imageUrl={getDomainImageUrl(data)} secondaryText={formatCreatedDate(data)}/>
                                            </td>
                                            <td>
                                                <p>{data.scan_percentage} %</p>
                                                <ProgressBar
                                                    now={data?.scan_percentage}
                                                    style={{ height: '8px', width: '100%' }}
                                                />
                                            </td>
                                            <td>
                                                <p>{data?.stats?.score?.latest_score?.overall_score?.obtained_score}</p>
                                            </td>
                                            <td>
                                                <div className="finding-list">
                                                    <ul type="none" className="m-0 p-0">
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("domainPage.infrastructure")}</span>
                                                            <span>{data?.stats?.score?.latest_score?.infrastructure_score?.obtained_rating}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("domainPage.vulnerabilities")}</span>
                                                            <span>{data?.stats?.score?.latest_score?.vulnerability_score?.obtained_rating}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("domainPage.exposure")}</span>
                                                            <span>{data?.stats?.score?.latest_score?.exposure_score?.obtained_rating}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("domainPage.darkWeb")}</span>
                                                            <span>{data?.stats?.score?.latest_score?.darkweb_score?.obtained_rating}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="finding-list">
                                                    <ul type="none" className="m-0 p-0">
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.assetDiscovery")}</span>
                                                            <span>{formatNumber(data?.stats?.new_findings?.asset_discovery)}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                            <span>{formatNumber(data?.stats?.new_findings?.external_detected_vulnerabilities)}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.DarkWeb")}</span>
                                                            <span>{formatNumber(data?.stats?.new_findings?.darkweb)}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.concerningExposures")}</span>
                                                            <span>{formatNumber(data?.stats?.new_findings?.concerning_exposures)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                <p>{data?.last_scan_detail?.created_at != null ? formatDate(data?.last_scan_detail?.created_at) : "-"}</p>
                                            </td>
                                            <td>
                                                <div className='organization-list-view'>
                                                    <button className="view-details-btn-scan">
                                                        <img src={arrowRight} alt='scan' />
                                                        {t("domainPage.startScanBtn")}
                                                    </button>
                                                    <button className="view-scan-btn" onClick={()=>{handleViewDetailsClick(data)}}>
                                                        {t("organizationPage.viewDetailsBtn")}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }): ""
                            }                            
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    )
}

export default DomainListView;