import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import "./index.scss";

import ContentBox from "../../../../../../ui/ContentBox";

const WhoisRegistrant = ({registrantData}) => {
    return (
        <div className="whois-main-content mt-4">
            <Row>
                <Col lg="3">
                    <ContentBox label={"Registrar"} value={registrantData?.name} />
                </Col>
                <Col lg="3">
                    <ContentBox label={"Organization"} value={registrantData?.organization} />
                </Col>
                <Col lg="3">
                    <ContentBox label={"Email"} value={registrantData?.email} />
                </Col>
                <Col lg="3">
                    <ContentBox label={"Phone"} value={registrantData?.phone} />
                </Col>
            </Row>
            <div className="address-content mt-4">
                <h4 className="heading my-4">Address:</h4>
                <Row className="mb-4">
                    <Col lg="3">
                        <ContentBox label={"Street"} value={registrantData?.address} />
                    </Col>
                    <Col lg="3">
                        <ContentBox label={"State"} value={registrantData?.state} />
                    </Col>
                    <Col lg="3">
                        <ContentBox label={"City"} value={registrantData?.city} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="3">
                        <ContentBox label={"Country"} value={registrantData?.country} />
                    </Col>
                    <Col lg="3">
                        <ContentBox label={"Zipcode"} value={registrantData?.zip_code} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default WhoisRegistrant;