import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";

import Button from "../../../ui/Button";
import DropDown from '../../../ui/DropDown';

import { useSelector, useDispatch } from 'react-redux';
import { assignAndUnAssign} from '../../../../store/actions/organizationActions';
import apiEndpoints from "../../../../apiEndpoints.js";

const ModifyMonitorModal = ({assignedCustomerList, monitorDetails, clsoeModifyModal}) => {

    const customerListResponse = useSelector((state) => state?.customer?.customersListResponse);
    const assignUnAssignResponse = useSelector((state) => state.organization?.assignUnAssignResponse)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("assign");
    const [responseStatus, setResponseStatus] = useState(false);
    const [customerList, setCustomersList] = useState({});
    const [assignedCustomersList, setAssignedCustomersList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const goBack = () => {
        setResponseStatus(false)
        setIsLoading(false)
        clsoeModifyModal(assignUnAssignResponse)
    }

    const handleSelectCustomer = (value) => {
        setSelectedCustomer(value)
    }

    const changeTab = (value) => {
        setSelectedCustomer([])
        setActiveTab(value)
    }

    const saveList = () => {
        const ids = selectedCustomer.map(data => data.id);
        const isAssign = activeTab === "assign";
        
        const obj = {
            monitor_id: monitorDetails?.monitor_id,
            customer_ids: ids
        };
    
        const url = isAssign ? apiEndpoints.monitor.assignCustomerToMonitor() : apiEndpoints.monitor.unAssignCustomerToMonitor()
        setIsLoading(true)
        dispatch(assignAndUnAssign(url, obj));
    };

    useEffect(()=>{
        if(customerListResponse != null) {
            let list = customerListResponse?.results.map((data)=>{
                return {
                    id: data.customer_id,
                    name: data.name,
                    value: data.customer_id,
                    logo: data.logo,
                    label: data.name
                }
            })
            setCustomersList(list)
        }
    },[customerListResponse])

    useEffect(()=>{
        if(assignedCustomerList != null) {
            let list = assignedCustomerList?.results.map((data)=>{
                return {
                    id: data.customer_id,
                    name: data.name,
                    value: data.customer_id,
                    logo: data.logo,
                    label: data.name
                }
            })
            setAssignedCustomersList(list)
        }
    },[assignedCustomerList])

    useEffect(()=>{
        if(assignUnAssignResponse !== null ) {
            setIsLoading(false)
            setResponseStatus(true)
        }
    },[assignUnAssignResponse])


    return (
        <div className="add-monitor-container">
            {!responseStatus && <>
                <div className="modal-header mt-4">
                    <h2>{t("monitorPage.modifyMonitor.heading")}</h2>
                </div>
                <div className="organization-main-container mt-4">
                    <div className="orgs-tab-container d-flex rounded">
                        <p className={`rounded text-center ${activeTab === "assign" ? "active" : ""}`} onClick={()=>changeTab("assign")}>Assign Customer</p>
                        <p className={`rounded text-center ${activeTab === "unAssign" ? "active" : ""}`} onClick={()=>changeTab("unAssign")}>Unassign Customer</p>
                    </div>
                </div>
                <div className="add-organization-step-one mt-3">
                    <div className="input-container mb-4">                   
                            {activeTab === "assign" && <DropDown
                                options={customerList}
                                placeHolder='Please select Organization'
                                dropDownType={"modifyCustomer"}
                                onChange={(e) => handleSelectCustomer(e)}
                                isSearchable = {true}
                                isMulti
                            />}
                            {activeTab === "unAssign" && <DropDown
                                options={assignedCustomersList}
                                placeHolder='Please select Organization'
                                dropDownType={"modifyCustomer"}
                                onChange={(e) => handleSelectCustomer(e)}
                                isSearchable = {true}
                                isMulti
                            />}
                    </div>
                    
                </div>
                <div className="form-submit-btn-group mt-4">
                    <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{goBack()}}/>
                    {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{saveList()}} disableButton={selectedCustomer.length === 0} />}
                    {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true} />}
                </div>
            </>}
            
            {responseStatus && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {assignUnAssignResponse?.detail?.status === "success" && <>
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{assignUnAssignResponse?.detail?.description}</h2>
                    </>}
                    {assignUnAssignResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                            <div className="mt-5">
                                <img src={failed} alt="failed" />
                            </div>
                            <h2 className="mb-4 mt-5 fs-4">{assignUnAssignResponse?.description}</h2>
                            </div>}
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={()=>{goBack()}} />
                    </div>
                </div>
            </>}
        </div>
    );
};

export default ModifyMonitorModal;
