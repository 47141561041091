import { React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
// import "./index.scss";

import InputBox from "../../../ui/InputBox";
import RadioButton from "../../../ui/RadioButton";
import Button from "../../../ui/Button";
import { toaster } from "../../../ui/Toaster";

import { useSelector, useDispatch } from 'react-redux';
import { editSubscription, resetEditSubscriptionResponse } from '../../../../store/actions/customerAction';

const EditSubscriptionModal = ({show, onHide, customerDetails}) => {

    const editSubscriptionResponse = useSelector((state) => state.customer?.editSubscriptionResponse)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [domainLimit, setDomainLimit] = useState("");
    const [thirdPartyRiskManagemnt, setThirdPartyRiskManagemnt] = useState("");
    const [portfolioRiskManagemnt, setPortfolioRiskManagement] = useState("");
    const [exposureManagement, setExposureManagement] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const updateSubscription = () => {
        let obj = {
            subscription_id: customerDetails?.subscription?.subscription_id,
            domain_limit: Number(domainLimit),
            portfolio_management: portfolioRiskManagemnt === "yes" ? true : false,
            third_party_risk_management: thirdPartyRiskManagemnt === "yes" ? true : false,
            exposure_management: exposureManagement === "yes" ? true : false
        }
        setIsLoading(true);
        dispatch(editSubscription(obj))
    }

    const updateValue = useCallback(()=>{
        if(customerDetails) {
            setDomainLimit(customerDetails?.subscription?.resources?.domain_limit || "");
            setThirdPartyRiskManagemnt(customerDetails?.subscription?.resources?.third_party_risk_management ? "yes" : "no" || "")
            setPortfolioRiskManagement(customerDetails?.subscription?.resources?.portfolio_management ? "yes" : "no" || "")
            setExposureManagement(customerDetails?.subscription?.resources?.exposure_management ? "yes" : "no" || "")
        }
    },[customerDetails])

    useEffect(()=>{
        updateValue()
    },[updateValue])

    useEffect(()=>{
        if(editSubscriptionResponse != null) {
            if(editSubscriptionResponse?.detail?.status === "success") {
                setIsLoading(false)
                toaster.addToast(editSubscriptionResponse?.detail?.description, "success")
            } else {
                setIsLoading(false)
                toaster.addToast(editSubscriptionResponse?.description, "error")
            }
            dispatch(resetEditSubscriptionResponse())
            onHide()
        }
    },[editSubscriptionResponse, onHide, dispatch])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("customerDetails.editSubscriptionModal.editSubscription")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <div className="input-container mt-4">
                                <InputBox placeholder={'Enter Domain List'} inputType={'text'} inputLabel={'Domain List'} inputChange={(val)=>{setDomainLimit(val)}} inputValue={domainLimit} />
                            </div>
                            <div className="organization-type">
                                <p>{t("customerDetails.editSubscriptionModal.thirdPartyRiskManagement")}</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setThirdPartyRiskManagemnt(value)}}  isChecked={thirdPartyRiskManagemnt === 'yes'}/>
                                    <RadioButton radioId={'no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setThirdPartyRiskManagemnt(value)}} isChecked={thirdPartyRiskManagemnt === 'no'}/>
                                </div>
                            </div>
                            <div className="organization-type mt-4">
                                <p>{t("customerDetails.editSubscriptionModal.portfolioManagement")}</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'yes1'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setPortfolioRiskManagement(value)}}  isChecked={portfolioRiskManagemnt === 'yes'}/>
                                    <RadioButton radioId={'no1'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setPortfolioRiskManagement(value)}} isChecked={portfolioRiskManagemnt === 'no'}/>
                                </div>
                            </div>
                            <div className="organization-type mt-4">
                                <p>{t("customerDetails.editSubscriptionModal.exposureManagement")}</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'yes2'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setExposureManagement(value)}}  isChecked={exposureManagement === 'yes'}/>
                                    <RadioButton radioId={'no2'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setExposureManagement(value)}} isChecked={exposureManagement === 'no'}/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-3 m-2 mt-4">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Edit" buttonType={"primary"} buttonClicked={updateSubscription}/>}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true}/>}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default EditSubscriptionModal;