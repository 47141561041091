import apiEndpoints from "../apiEndpoints";


// to get logged in user id 
export function getloggedInUserId() {
    let loggedInUserId = JSON.parse(localStorage.getItem('loggedInUserDetails') || '{}');
    return loggedInUserId.user_id
}

// to get logged in user role
export function getloggedInUserRole() {
    let loggedInUserRole = JSON.parse(localStorage.getItem('loggedInUserDetails') || '{}');
    return loggedInUserRole.role
}

// to get logged in user type
export function getloggedInUserType() {
    let loggedInUserType = JSON.parse(localStorage.getItem('loggedInUserDetails') || '{}');
    return loggedInUserType.user_type
}


export function formatNumber(num) {

    if (num === undefined || num === null) {
        return "0"; // Default to "0" or another placeholder if the value is undefined or null
    }

    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M"; // 1M+
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + "K"; // 1K+
    } else {
        return num.toString(); // Less than 1K
    }
}


export function formatDate(dateString, type="dateAndTime") {
    const date = new Date(dateString)

    // format date
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });

    // Format the time part (e.g., "5:48")
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit'
    });

    if(type === 'date') {
        return `${formattedDate}`;
    } else {
        return `${formattedDate} | ${formattedTime}`;
    }
}

export function formatRelativeTime(date) {
    const now = new Date();
    const targetDate = new Date(date);
    const diffInMs = now - targetDate;
    
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    if (diffInHours < 24) return `${diffInHours} hours ago`;
    
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} days ago`;
}

export function getOrganizationImageUrl(data) {
    const timestamp = new Date().getTime(); // to clear browser cache added timestamp
    const imageUrl = data?.logo !== null 
        ? `${apiEndpoints.imageUrl.organization}${data.logo}` 
        : `${apiEndpoints.imageUrl.organization}${data.name}`;
    return `${imageUrl}?t=${timestamp}`;
}

export function getUserImageUrl(data) {
    const timestamp = new Date().getTime(); // to clear browser cache added timestamp
    const imageUrl = data?.avatar !== null 
        ? `${apiEndpoints.imageUrl.users}${data.avatar}` 
        : `${apiEndpoints.imageUrl.users}${data.name}`;
    return `${imageUrl}?t=${timestamp}`;
}

export function getDomainImageUrl(data) {
    const timestamp = new Date().getTime(); // to clear browser cache added timestamp
    const imageUrl = `${apiEndpoints.imageUrl.domain}${data.domain}`;
    return `${imageUrl}?t=${timestamp}`;
}

export function getCustomerImageUrl(data) {
    const timestamp = new Date().getTime(); // to clear browser cache added timestamp
    const imageUrl = data?.logo !== null 
        ? `${apiEndpoints.imageUrl.customer}${data.logo}` 
        : `${apiEndpoints.imageUrl.customer}${data.name}`;
    return `${imageUrl}?t=${timestamp}`;
}

export function getCountryImageUrl(data) {
    const timestamp = new Date().getTime();
    const imageUrl = data?.country !== null 
        ? `${apiEndpoints.imageUrl.country}${data.country}`
        : `${apiEndpoints.imageUrl.country}${data.city}`;
    return `${imageUrl}?t=${timestamp}`;
}

export function getTechnologiesImageUrl(data) {
    const timestamp = new Date().getTime();
    const imageUrl = data?.name !== null 
        ? `${apiEndpoints.imageUrl.technologies}${data.name}`
        : `${apiEndpoints.imageUrl.technologies}${data.domain}`;
    return `${imageUrl}?t=${timestamp}`;
}

