import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';


import demoIcon from "../../../../assets/images/cutomers/demo.svg";
import dateIcon from "../../../../assets/images/cutomers/date.svg"; 

import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import UpdateCustomerStatusModal from "../UpdateCustomerStatusModal";

import { getCustomerImageUrl, formatDate, formatNumber } from "../../../../utils/util";

const CutomersTileView = ({customersList}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [showUpdateCustomerStatusModal, setShowUpdateCustomerStatusModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const totalDomainRisk = (data) => {
        let {critical_risk, high_risk, medium_risk, low_risk } = data
        return formatNumber(critical_risk + high_risk + medium_risk + low_risk)
    }

    const updatedUserStatus = (data) => {
        setSelectedCustomer(data);
        setShowUpdateCustomerStatusModal(true);
    }
    
    return (
        <Row>
            {
                customersList?.results.length > 0 && 
                customersList?.results.map((data, index)=>{
                    return (
                        <Col xl={6} lg={12} md={12} key={index} className="col-xxxl-4">
                            <div className="organization-container mt-4 px-4 py-3 rounded">
                                <div className="organization-profile">
                                    <div className="d-flex justify-content-between">
                                        <div className="organization-details">
                                            <ProfileAvatar pageType={"organizationHomePage"} type="profile" firstName={data?.name} lastName={""} imageUrl={getCustomerImageUrl(data)} tags={true} tagContent={data.status === "active" ? "Active" : "Disabled"} tagColor={data.status === "active" ? "green" : "disabled"}/>
                                        </div>
                                        <div className="organization-actions customers-action-btn">
                                            {data.status !== "active" &&<button className="view-details-btn active-user" onClick={()=>{updatedUserStatus(data)}}>Activate</button>}
                                            {data.status === "active" && <button className="view-details-btn disable-user" onClick={()=>{updatedUserStatus(data)}}>Deactivate</button>}
                                            <button className="view-scan-btn" onClick={()=>{navigate(`/customers/details/${data?.customer_id}`)}}>{t('customers.customersTileView.viewDetails')}</button>
                                        </div>
                                    </div>
                                    <div className="cutomer-status">                                                
                                        <p className="subscription-type">{t('customers.customersTileView.subscriptionType')} <span><img src={demoIcon} alt="demo" />{data?.subscription?.name === "trial" ? "Demo" : "Business"}</span></p>
                                        <p className="subscription-type"><span className="date"><img src={dateIcon} alt="date" />{formatDate(data?.created_at)}</span></p>
                                    </div>
                                </div>
                                <div className="organization-findings mt-3">
                                    <div className="total-domains">
                                        <h2>{t('customers.customersTileView.domainPortfolioRisk')}</h2>
                                        <div className="total-domains-container rounded p-3">
                                            <Row>
                                                <Col lg={3}>
                                                    <h4 className="m-0 mt-4">{totalDomainRisk(data.stats.domain_portfolio_risks)}</h4>
                                                    <p>{t('customers.customersTileView.totalRisk')}</p>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="risk-count">
                                                        <Row>
                                                            <Col md={6}><p className="critical-risk-count mb-4"><span>{formatNumber(data.stats.domain_portfolio_risks.critical_risk)}</span>{t("organizationPage.critical")}</p></Col>
                                                            <Col md={6}><p className="high-risk-count mb-4"><span>{formatNumber(data.stats.domain_portfolio_risks.high_risk)}</span>{t("organizationPage.high")}</p></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}><p className="medium-risk-count mb-2"><span>{formatNumber(data.stats.domain_portfolio_risks.medium_risk)}</span>{t("organizationPage.medium")}</p></Col>
                                                            <Col md={6}><p className="low-risk-count mb-2"><span>{formatNumber(data.stats.domain_portfolio_risks.low_risk)}</span>{t("organizationPage.low")}</p></Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            <UpdateCustomerStatusModal show={showUpdateCustomerStatusModal} onHide={()=>{setShowUpdateCustomerStatusModal(false)}} selectedCustomer={selectedCustomer} />
        </Row>
    )
}

export default CutomersTileView;