import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import organizationReducer from "./organizationReducer";
import domainReducer from "./domainReducer";
import settingsReducer from "./settingsReducer";
import scanQueueReducer from "./scanqueueReducer";
import usersReducers from "./usersReducers";
import customerReducer from "./customerReducer";
import monitorReducer from "./monitorReducer";
import scanInfoReducer from "./scanInfoReducer"


const rootReducer = combineReducers({
    login: loginReducer,
    organization: organizationReducer,
    register: registerReducer,
    domain: domainReducer,
    settings: settingsReducer,
    scanQueue: scanQueueReducer,
    users: usersReducers,
    customer: customerReducer,
    monitor: monitorReducer,
    scanInfo: scanInfoReducer
});
  
export default rootReducer;