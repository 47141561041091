import { React } from 'react';

import './index.scss';



const Button = ({ buttonType, buttonText, buttonClicked, disableButton }) => {
    const triggerClick = (e) => {
        buttonClicked(e)
    }
    return (
        <>
            {buttonType === "primary" && <button className={`primary-button w-100 ${disableButton ? 'disabled' : ''}`} disabled={disableButton} type="button" onClick={triggerClick}>{buttonText}</button>}
            {buttonType === "secondary" && <button className='secondary-button w-100' type="button" onClick={triggerClick}>{buttonText}</button>}
            {buttonType === "link" && <button className='link-button' type="button" onClick={triggerClick}>{buttonText}</button>}
        </>
    )
}

export default Button;