import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

import ProfileAvatar from "../../../ui/ProfileAvatar";
import Button from "../../../ui/Button";

import UpdateCustomerStatusModal from "../UpdateCustomerStatusModal";

import { getCustomerImageUrl, formatDate, formatNumber } from "../../../../utils/util";

const CutomersListView = ({customersList}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [showUpdateCustomerStatusModal, setShowUpdateCustomerStatusModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const updatedUserStatus = (data) => {
        setSelectedCustomer(data);
        setShowUpdateCustomerStatusModal(true);
    }
    
    return (
        <Row>
            <Col md="12">
                <div className="list-view-container organization-list-view-table p-3">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t("customers.customersListView.customerName")}</th>
                                <th>{t("customers.customersListView.subscriptionType")}</th>
                                <th>{t("customers.customersListView.SubscriptionEndDate")}</th>
                                <th>{t("customers.customersListView.totalPortFolioEisk")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customersList.results.length > 0 &&
                                customersList.results.map((data, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <ProfileAvatar pageType={"organizationHomePage"} type="domain" name={data?.name} imageUrl={getCustomerImageUrl(data)} />                                                               
                                            </td>
                                            <td>
                                                {data?.subscription?.name === "trial" ? "Demo" : "Business"}
                                            </td>
                                            <td>
                                                {formatDate(data?.subscription?.end_date)}
                                            </td>
                                            <td>
                                                <div className="risk-count">
                                                    <span className="critical">Critical {formatNumber(data.stats.domain_portfolio_risks.critical_risk)}</span>
                                                    <span className="high">High {formatNumber(data.stats.domain_portfolio_risks.high_risk)}</span>
                                                    <span className="medium">Medium {formatNumber(data.stats.domain_portfolio_risks.medium_risk)}</span>
                                                    <span className="low">Low {formatNumber(data.stats.domain_portfolio_risks.low_risk)}</span>
                                                </div> 
                                            </td>
                                            <td>
                                                <div className='organization-list-view users-list-view-btn customers-action-btn d-flex'>
                                                    {data.status !== "active" &&<button className="view-details-btn active-user w-100" onClick={()=>{updatedUserStatus(data)}}>Activate</button>}
                                                    {data.status === "active" && <button className="view-details-btn disable-user w-100" onClick={()=>{updatedUserStatus(data)}}>Deactivate</button>}
                                                    <Button buttonText={"View Details"} buttonType="primary" buttonClicked={()=>{navigate(`/customers/details/${data?.customer_id}`)}}/>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <UpdateCustomerStatusModal show={showUpdateCustomerStatusModal} onHide={()=>{setShowUpdateCustomerStatusModal(false)}} selectedCustomer={selectedCustomer} />
                </div>
            </Col>
        </Row>
    )
}

export default CutomersListView;