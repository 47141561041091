import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import DatePicker from 'react-datepicker';
import Button from "../../../ui/Button";
import { toaster } from "../../../ui/Toaster/index.js";

import { useSelector, useDispatch } from 'react-redux';
import { extendSubscription } from '../../../../store/actions/customerAction.js';

const ExtendSubscriptionModal = ({show, onHide, customerDetails}) => {

    const extendSubscriptionResponse = useSelector((state) => state.customer?.extendSubscriptionResponse)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    const CalenderIcon = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66663 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3334 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.91663 7.57471H17.0833" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 11.4167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 13.9167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 11.4167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 13.9167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 11.4167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 13.9167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
    }

    const updateSubscriptionDate = () => {
        let obj = {
            subscription_id: customerDetails?.subscription?.subscription_id,
            subscription_expire_date: selectedDate.toISOString().replace("T", " ").split(".")[0],
        }
        setIsLoading(true)
        dispatch(extendSubscription(obj))        
    }

    useEffect(()=>{
        if(customerDetails !== null) {
            setSelectedDate(new Date(customerDetails?.subscription?.end_date))
        }
    },[customerDetails])

    useEffect(()=>{
        if(extendSubscriptionResponse != null) {
            if(extendSubscriptionResponse?.detail?.status === "success") {
                setIsLoading(false)
                toaster.addToast(extendSubscriptionResponse?.detail?.description, "success")
            } else {
                setIsLoading(false)
                toaster.addToast(extendSubscriptionResponse?.description, "error")
            }
            onHide()
        }
    },[extendSubscriptionResponse, onHide])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            className=""
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t('customerDetails.extendSubscriptionModal.heading')}</h4>
                        <div className="organization-related-details p-4 m-2 extend-subscription">
                            <div className="organization-type">
                                <p>{t('customerDetails.extendSubscriptionModal.extendDate')}</p>
                                <div className="radio-btn-group mt-3 scan-info-datepicker">
                                <DatePicker
                                    showIcon
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    placeholderText="Select date"
                                    icon={CalenderIcon()}
                                    dateFormat="MMM dd, yyyy"
                                    minDate={new Date()}
                                    className="custom-width-datepicker"
                                >                                
                                </DatePicker>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-3 m-2 mt-4">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Extend" buttonType={"primary"} buttonClicked={updateSubscriptionDate}/>}
                            {isLoading && <Button buttonText="Loading" buttonType={"primary"} disableButton={true}/>}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default ExtendSubscriptionModal;