import React, { useCallback, useEffect, useState, useRef } from 'react';
// import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';


import ProfileAvatar from "../../../../../../ui/ProfileAvatar";
import Pagination from "../../../../../../ui/Pagination";
import Spinner from "../../../../../../ui/spinner";
import NoDataFound from "../../../../../../ui/NoDataFound";

import { useSelector, useDispatch } from 'react-redux';
import { getAAARecord } from "../../../../../../../store/actions/scanInfoAction";
import { getCountryImageUrl } from "../../../../../../../utils/util";
import apiEndpoints from "../../../../../../../apiEndpoints";

const AAARecord = ({selectedSubdomain, domainId, scanId, currentPageType="subdomain"}) => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const aaaRecord = useSelector((state) => state?.scanInfo.aaaRecord);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null)

    const dispatch = useDispatch();

    const [aaaRecordList, setAAARecordList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchRecordData = useCallback(()=>{
        if(currentPageType === "subdomain") {
            setIsLoading(true)
            let url = `${apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.subDomain.aaaRecord()}&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&subdomain=${selectedSubdomain?.subdomain}`
            dispatch(getAAARecord(url))
        } else {
            setIsLoading(true)
            let url = `${apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.dnsRecord.aaaRecord()}&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}`
            dispatch(getAAARecord(url))
        }

    },[currentPageType, dispatch, selectedSubdomain, currentPage, currentPageSize, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current || selectedSubdomain !== prevDomainIdRef.current?.selectedSubdomain)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchRecordData();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchRecordData, selectedSubdomain]);

    useEffect(()=>{
        if(aaaRecord !== null) {
            setIsLoading(false)
            setAAARecordList(aaaRecord)
        }
    },[aaaRecord])


    return (
        <div className="record-container-content p-2">
            <div className="table-container mt-3 record-table">
                {
                    isLoading ?
                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                        <Spinner />
                    </div>
                    :
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>Record</th>
                                <th>Organisation</th>
                                <th>AS Details</th>
                                <th>Country Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                aaaRecordList !== "" && aaaRecordList?.results.length > 0 && 
                                aaaRecordList?.results.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {data?.record}
                                            </td>
                                            <td>
                                                <p>{data?.organization}</p>
                                            </td>
                                            <td>
                                                <p>{data?.as_number}</p>
                                            </td>
                                            <td>
                                                <ProfileAvatar type={"profile"} firstName={data?.city} lastName={""} secondaryText={`${data.country}, ${data.continent}`} imageUrl={getCountryImageUrl(data)}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                }
            </div>
            {
                !isLoading && aaaRecordList !== "" && aaaRecordList?.results.length === 0 && 
                <NoDataFound noDataText={"No Record Found"}/>
            }
            {aaaRecordList !== "" && aaaRecordList?.results.length > 0 && <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                pageSize={currentPageSize}
                totalCount={aaaRecordList?.meta?.total_results}
                onPageChange={setCurrentPageandRecordSize}
            />}
        </div>
    )
}

export default AAARecord;