import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Button from "../../components/ui/Button";
import InputBox from "../../components/ui/InputBox";
import LeftSection from "../../components/PreLogin/LoginPage/LeftSection";
import RightFooter from "../../components/PreLogin/RightFooter";
import { resend } from "../../store/actions/registerAction";
import tickCircleGreen from "../../assets/images/common/tick-transparent.svg";
import "./index.scss";
import ReCAPTCHA from "react-google-recaptcha";

const googleRecaptchaSitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY;

const Resend = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize useNavigate
    const [email, setEmail] = useState('');
    const [addEmailStep, setAddEmailStep] = useState(1);
    const [loginFailed, setLoginFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    const response = useSelector(state => state.register.resendResponse);

    const inputChange = (val, type) => {
        const handlers = {
            email: setEmail,
        };
        handlers[type] && handlers[type](val);
    };

    useEffect(() => {
        if (response) {
            if (response.detail?.status === "success") {
                setAddEmailStep(2); // Go to success confirmation step
            } else {
                setLoginFailed(true); // Handle failure
            }
        }
    }, [response]);

    const isDisabledButton = useCallback(() => {
        if (addEmailStep === 1) {
            return !email || !captchaVerified; // Enable only if email and ReCAPTCHA are valid
        }
        return false; // Allow button by default for other steps
    }, [addEmailStep, email, captchaVerified]);

    const handleResend = () => {
        if (email) {
            dispatch(resend({ email }));
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaVerified(!!value); // Set to true if value is not null
    };

    const handleLoginRedirect = () => {
        navigate("/auth/login"); // Redirect to the login page
    };

    return (
        <div className='validated-container'>
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='validated-right-section px-3 mx-3 h-100'>
                        <div className='right-container'>
                            {addEmailStep === 1 && (
                                <div>
                                    <h1>{t("registrationPage.resend.heading")}</h1>
                                    <p className='mb-3'>{t("registrationPage.resend.secondaryText")}</p>
                                    {loginFailed && (
                                        <p className="error-msg text-center mt-3">
                                            {response?.description}
                                        </p>
                                    )}
                                    <InputBox
                                        placeholder="Enter Email ID"
                                        inputType="email"
                                        inputLabel="Email ID"
                                        inputValue={email}
                                        inputChange={inputChange}
                                    />
                                    <div className='loggedin-checkbox'>
                                        <label style={{ marginBottom: '5px' }}>Captcha</label>
                                        <ReCAPTCHA
                                            sitekey={googleRecaptchaSitekey}
                                            size="normal"
                                            onChange={handleCaptchaChange} // Capture ReCAPTCHA status
                                        />
                                    </div>
                                    <div className='submit-button mt-4'>
                                        <Button
                                            buttonText={"Resend Confirmation Link"}
                                            buttonType="primary"
                                            buttonClicked={handleResend}
                                            disableButton={isDisabledButton() || isLoading}
                                        />
                                    </div>
                                </div>
                            )}
                            {addEmailStep === 2 && (
                                <div>
                                    <img src={tickCircleGreen} alt="confirmation tick" />
                                    <h1>{t("registrationPage.accountValidated.validate")}</h1>
                                    <p>You have successfully validated your account.</p>
                                    <p>You can now log into your account.</p>
                                    <div className='submit-button mt-4'>
                                        <Button 
                                            buttonText={"Login"} 
                                            buttonType="primary" 
                                            buttonClicked={handleLoginRedirect} // Redirect to login
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Resend;
