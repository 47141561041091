import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";


//pre login page
import RegistrationPage from "../pages/RegistrationPage";
import Resend from "../pages/Resend";
import AccountValidated from "../pages/AccountValidated";
import LoginPage from "../pages/LoginPage";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
import VerifyAccount from "../pages/VerifyAccount";

//Post login pages
import Organization from "../pages/Organization";
import OrganizationDetails from "../pages/OrganizationDetails";
import Monitor from "../pages/Monitor";
import MonitorDetails from "../pages/MonitorDetails";
import Users from "../pages/Users";
import UserDetails from "../pages/UserDetails";
import Domain from "../pages/Domain";
import DomainDetails from "../pages/DomainDetails";
import ScanQueue from "../pages/ScanQueue";
import AccountSettings from "../pages/AccountSettings";
import Cutomers from "../pages/Customers";
import CustomerDetails from "../pages/CustomerDetails";

//scan info pages exposure management pages
import ScanInfo from "../pages/ScanInfo";

// exposure management dashboard
import ExposureDashboard from "../pages/ScanInfoPages/ExposureManagement/Dashboard";

// exposure managemnt assest discovery pages
import DomainInfo from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/DomainInfo";
import SubDomains from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/SubDomain";
import AssociatedDomains from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/AssociatedDomain";
import IpAddress from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/IpAddress";
import DNSRecords from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/DNSRecords";
import Technologies from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/Technologies";
import SSLRecords from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/SSLRecords";
import MobileApps from "../pages/ScanInfoPages/ExposureManagement/AssestDiscovery/MobileApps";

//exposure management corporate email risk page
import CorporateEmailRisk from "../pages/ScanInfoPages/ExposureManagement/CorporateEmailRisk";

// exposure management concerning exposure pages
import GitLeaks from "../pages/ScanInfoPages/ExposureManagement/ConcerningExposure/GitLeaks";
import JsSecrets from "../pages/ScanInfoPages/ExposureManagement/ConcerningExposure/JsSecrets";
import SensitiveExposure from "../pages/ScanInfoPages/ExposureManagement/ConcerningExposure/SensitiveExposure";


// exposure management external detected vulnerabilities pages
import SubDomainTakeOver from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/SubDomainTakeover";
import CVEs from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/CVEs";
import CNVDs from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/CNVDs";
import SSLVulnerabilities from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/SSLVulnerabilities";
import ExposedPanel from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/ExposedPanel";
import DefaultLogin from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/DefaultLogin";
import MisConfiguration from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/Misconfiguration";
import OtherVulnerabilities from "../pages/ScanInfoPages/ExposureManagement/ExternalDetectedVulnerabilities/Othervulnerabilities";

// exposure management dark web pages
import LeakedCredentials from "../pages/ScanInfoPages/ExposureManagement/DarkWeb/LeakedCredentials";
import BotnetLeaks from "../pages/ScanInfoPages/ExposureManagement/DarkWeb/BotnetLeaks";
import RansomewareGroupIntel from "../pages/ScanInfoPages/ExposureManagement/DarkWeb/RansomewareGroup";

// exposure management brand insight pages
import SimilarDomain from "../pages/ScanInfoPages/ExposureManagement/BrandInsights/SimilarDomain";

//portfolio risk dashboard page
import PortfolioRiskDashboard from "../pages/ScanInfoPages/PortfolioRisk/Dashboard";

//reports exposure Mangement page
import ExposureManagement from "../pages/ScanInfoPages/Reports/ExposureManagement";

//componetes page
import ComponentsPage from "../pages/Components";


const RouteComponents = () => {
    return (
        <Routes>
            <Route path="" element={<Navigate replace to={"/auth/login"} />} />
            <Route path="/auth/login" element={<LoginPage />} />
            <Route path="/auth/register" element={<RegistrationPage />} />
            <Route path="/auth/resend" element={<Resend />} />
            <Route path="/accountValidated" element={<AccountValidated />} />
            <Route path="/auth/forget-password" element={<ForgetPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />}/>
            <Route path="/auth/verify-account" element={<VerifyAccount />}/>
            <Route path="/auth/reset-2fa" element={<ForgetPassword />}/>
            <Route path="/auth/reset-2fa-confirmation" element={<ForgetPassword />}/>
            <Route path="/organization" element={<Organization />}/>
            <Route path="/organization/details/:organizationId" element={<OrganizationDetails />}/>
            {/* from customer to organization details Page */}
            <Route path="/:customerId/organization/details/:organizationId" element={<OrganizationDetails />}/>
            <Route path="/monitor" element={<Monitor />}/>
            <Route path="/monitor/details/:monitorId" element={<MonitorDetails />}/>
            <Route path="/users" element={<Users />}/>
            <Route path="/users/details/:userId" element={<UserDetails />}/>
            <Route path="/domain" element={<Domain />}/>
            <Route path="/domain/details/:domainName" element={<DomainDetails />}/>
            <Route path="/scan-queue" element={<ScanQueue />}/>
            <Route path="/account/settings" element={<AccountSettings />}/>
            <Route path="/customers" element={<Cutomers />}/>
            <Route path="/customers/details/:customerId" element={<CustomerDetails />} />

            {/* scan info Pages */}

            {/* exposure management dashboard */}
            <Route path="/scan-info/exposure/dashboard" element={<ExposureDashboard />}/>

            {/* exposure managemnt assest discovery pages */}
            <Route path="/scan-info/exposure/assest-discovery/domain-info/" element={<ScanInfo><DomainInfo /></ScanInfo>} />
            <Route path="/scan-info/exposure/assest-discovery/sub-domain" element={<ScanInfo><SubDomains /></ScanInfo>} />
            <Route path="/scan-info/exposure/assest-discovery/associated-domain" element={<AssociatedDomains />} />
            <Route path="/scan-info/exposure/assest-discovery/ip-address" element={<ScanInfo><IpAddress /></ScanInfo>} />
            <Route path="/scan-info/exposure/assest-discovery/dns-records" element={<ScanInfo><DNSRecords /></ScanInfo>} />
            <Route path="/scan-info/exposure/assest-discovery/technologies" element={<ScanInfo><Technologies /></ScanInfo>} />
            <Route path="/scan-info/exposure/assest-discovery/ssl-records" element={<ScanInfo><SSLRecords /></ScanInfo>} />
            <Route path="/scan-info/exposure/assest-discovery/mobile-apps" element={<ScanInfo><MobileApps /></ScanInfo>} />

            {/* exposure management corporate email risk page */}
            <Route path="/scan-info/exposure/corporate-email-risk" element={<ScanInfo><CorporateEmailRisk /></ScanInfo>} />
            
            {/* exposure management concerning exposure pages */}
            <Route path="/scan-info/exposure/concerning-exposure/git-leaks" element={<ScanInfo><GitLeaks /></ScanInfo>} />
            <Route path="/scan-info/exposure/concerning-exposure/js-secrets" element={<ScanInfo><JsSecrets /></ScanInfo>} />
            <Route path="/scan-info/exposure/concerning-exposure/senstive-exposure" element={<ScanInfo><SensitiveExposure /></ScanInfo>} />

            {/* exposure management external detected vulnerabilities pages */}
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/sub-domain-takeover" element={<SubDomainTakeOver />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/cves" element={<CVEs />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/cnvds" element={<CNVDs />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/ssl-vulnerabilities" element={<SSLVulnerabilities />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/exposed-panel" element={<ExposedPanel />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/default-login" element={<DefaultLogin />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/misconfiguration" element={<MisConfiguration />} />
            <Route path="/scan-info/exposure/external-detected-vulnerabilities/other-vulnerabilities" element={<OtherVulnerabilities />} />

            {/* exposure management dark web pages */}
            <Route path="/scan-info/exposure/dark-web/leaked-credentials" element={<ScanInfo><LeakedCredentials /></ScanInfo>} />
            <Route path="/scan-info/exposure/dark-web/botnet-leaks" element={<ScanInfo><BotnetLeaks /></ScanInfo>} />
            <Route path="/scan-info/exposure/dark-web/ransomeware-group-intel" element={<ScanInfo><RansomewareGroupIntel /></ScanInfo>} />

            {/* exposure management brand insight pages */}
            <Route path="/scan-info/exposure/brand-insight/similar-domain" element={<SimilarDomain />} />

            <Route path="/scan-info/portfolio/dashboard" element={<PortfolioRiskDashboard />} />

            {/* Scan Info reports dark web pages */}
            <Route path="/scan-info/reports/exposure-management" element={<ExposureManagement />} />
            
            <Route path="/components" element={<ComponentsPage />} />
        </Routes>
    )
}

export default RouteComponents;