import { React } from 'react';
import { useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

import leftCornerLogo from "../../../assets/images/LoginPage/login-left-corner-logo.svg";
import mainLogo from "../../../assets/images/LoginPage/main-logo.gif";
import awardCxo from "../../../assets/images/LoginPage/cxo logo.svg";
import awardCyberSecurity from "../../../assets/images/LoginPage/cybersecurity_awards_2024.svg";
import awardAegis from "../../../assets/images/LoginPage/aegis_award.svg";
import { useTranslation } from "react-i18next";

import "./LeftSection.scss";

const LeftSection = () =>{
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='login-left-section p-4 m-3'>
            <div className='left-corner-logo mt-2'>
                <img src={leftCornerLogo} alt="logo" onClick={()=>{navigate('/auth/login')}}/>
            </div>
            <div className='main-logo w-1oo d-flex justify-content-center align-items-center'>
                <img src={mainLogo} alt="main-logo"/>
            </div>
            <div className='d-flex justify-content-center align-items-center caurosel-text mt-3'>
            <Carousel controls={false}>
                <Carousel.Item interval={2000}>
                    <p className='text-center'>{t('loginPage.leftSection.cauroselText1')}</p>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <p className='text-center'>{t('loginPage.leftSection.cauroselText2')}</p>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <p className='text-center'>{t('loginPage.leftSection.cauroselText3')}</p>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <p className='text-center'>{t('loginPage.leftSection.cauroselText4')}</p>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <p className='text-center'>{t('loginPage.leftSection.cauroselText5')}</p>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <p className='text-center'>{t('loginPage.leftSection.cauroselText6')}</p>
                </Carousel.Item>
            </Carousel>
            </div>
            <div className='award-section w-1oo d-flex justify-content-center align-items-center mt-3 pt-5'>
                <img src={awardCxo} alt="award_cxo"/>
                <img src={awardCyberSecurity} alt="award_cyber"/>
                <img className="aegis-award" src={awardAegis} alt="award_aegis"/>
            </div>
            <div className='bottom-text text-center mt-4'>
                <p>{t('loginPage.leftSection.footerText')}</p>
            </div>
        </div>
    )
}

export default LeftSection;