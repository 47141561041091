import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import ScanInfo from "../../../ScanInfo";
import DropDownCh from '../../../../components/ui/DropDownCh';
import DropDown from '../../../../components/ui/DropDown';
import ProfileAvatar from '../../../../components/ui/ProfileAvatar';
import OrganizationDummyImage from "../../../../assets/images/common/organization-dummy-image1.png";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RadioButton from "../../../../components/ui/RadioButton";

const ExposureManagement = () => {
    const { t } = useTranslation();

    const [addOrgStep, setAddOrgStep] = useState(1);
    const [isFormValid, setIsFormValid] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState("opt1"); // Default to "Option 1"
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedReport, setSelectedReport] = useState("opt1");
    const [selectedFormat, setSelectedFormat] = useState("pdf");

    const options = [
        {
            label: (
                <ProfileAvatar
                    type="profile"
                    firstName="lollypop.com"
                    imageUrl={OrganizationDummyImage}
                />
            ), value: "opt1"
        },
        {
            label: (
                <ProfileAvatar
                    type="profile"
                    firstName="lollypop.com"
                    imageUrl={OrganizationDummyImage}
                />
            ), value: "opt2"
        },
        {
            label: (
                <ProfileAvatar
                    type="profile"
                    firstName="lollypop.com"
                    imageUrl={OrganizationDummyImage}
                />
            ), value: "opt3"
        },
    ];


    const optionsExcel = [
        { label: "Asset Discovery", value: "opt1" },
        { label: "BEC", value: "opt2" },
        { label: "Vulnerabilities", value: "opt3" },
        { label: "Concerning Exposures", value: "opt4" },
        { label: "Indicators of Compromise", value: "opt5" },
    ];

    const [optionspdf] = useState([
        {
            value: "opt1",
            label: (
                <div className="d-flex align-items-center">
                    <RadioButton
                        radioId="report1"
                        radioLabel="Executive Summary"
                        radioValue="opt1"
                        isChecked={selectedReport === "opt1"}
                        onChange={() => setSelectedReport("opt1")}
                    />
                </div>
            ),
        },
        {
            value: "opt2",
            label: (
                <div className="d-flex align-items-center">
                    <RadioButton
                        radioId="report2"
                        radioLabel="Management Report"
                        radioValue="opt2"
                        isChecked={selectedReport === "opt2"}
                        onChange={() => setSelectedReport("opt2")}
                    />
                </div>
            ),
        },
        {
            value: "opt3",
            label: (
                <div className="d-flex align-items-center">
                    <RadioButton
                        radioId="report3"
                        radioLabel="Full Report"
                        radioValue="opt3"
                        isChecked={selectedReport === "opt3"}
                        onChange={() => setSelectedReport("opt3")}
                    />
                </div>
            ),
        },
    ]);

    const nextStep = () => {
        if (isFormValid) {
            setAddOrgStep(4); // Move to success step
        }
    };

    const validateForm = (domainOption) => {
        if (domainOption) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handleDomainChange = (selectedOptions) => {
        if (Array.isArray(selectedOptions)) {
            setSelectedDomain(selectedOptions.map((option) => option.value));
        } else {
            setSelectedDomain([]);
        }
        validateForm(selectedOptions);
    };


    const CalenderIcon = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66663 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3334 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.91663 7.57471H17.0833" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.079 11.4167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.079 13.9167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.9962 11.4167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.9962 13.9167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.91197 11.4167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.91197 13.9167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    }

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly // Make the input non-editable
            className="form-control date-height"
            placeholder="Select a date"
        />
    ));

    return (
        <ScanInfo>
            
            <div className="add-exposure-container">
            <h2>Select the options to download the report</h2>
                <>
                    <div className="add-organization-step-one mt-3 d-flex align-items-center gap-3">
                        <div className="input-container" style={{ flex: 1 }}>
                            <p className="mb-1">Organization</p>
                            <DropDownCh
                                options={options}
                                placeHolder="Select Organization"
                                onChange={handleDomainChange}
                                selectedValue={selectedDomain}
                                isSearchable
                            />
                        </div>
                        <div className="input-container" style={{ flex: 1 }}>
                            <p className="mb-1">Domain</p>
                            <DropDownCh
                                options={[
                                    { label: "Select All", value: "selectAll" },
                                    ...options,
                                ]}
                                placeHolder="Select Domain"
                                onChange={(selectedOptions) => {
                                    if (selectedOptions.some((opt) => opt.value === "selectAll")) {
                                        // If "Select All" is selected, select/deselect all
                                        const isSelected = selectedOptions.length === options.length + 1;
                                        const allOptions = isSelected ? [] : options.map((opt) => opt.value);
                                        handleDomainChange(allOptions);
                                    } else {
                                        handleDomainChange(selectedOptions);
                                    }
                                }}
                                selectedValue={selectedDomain}
                                isSearchable
                                isMulti
                            />
                        </div>

                    </div>

                    <div className="add-organization-step-one  d-flex align-items-center gap-3">
                        <div className="input-container date-picker-container report-info-datepicker" style={{ flex: 1, width: '100%' }}>
                            <p className="mb-1">Select Date (only applicable for specific domain)</p>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                placeholderText="Select a date"
                                dateFormat="MMM dd, yyyy"
                                customInput={
                                    <div className="custom-date-input-wrapper">
                                        <CustomDateInput className="custom-date-input" />
                                        <span className="calendar-icon">{CalenderIcon()}</span>
                                    </div>
                                }
                                maxDate={new Date()}
                                className="custom-width-datepicker"
                            />
                        </div>

                        <div className="input-container" style={{ flex: 1 }}>
                            <p className="mb-1">Sub Domain (only applicable after selecting date)</p>
                            <DropDownCh
                                options={options}
                                placeHolder="Select Domain"
                                onChange={handleDomainChange}
                                selectedValue={selectedDomain}
                                isSearchable
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="add-organization-step-one d-flex align-items-center">
                        <div className="input-container">
                            <p>Select Format</p>
                            <div className="radio-btn-group d-flex mt-2 gap-5">
                                <RadioButton
                                    radioId="pdf"
                                    radioLabel="PDF"
                                    radioValue="pdf"
                                    onChange={() => setSelectedFormat("pdf")} // Update state when "PDF" is selected
                                    isChecked={selectedFormat === "pdf"} // Determine if this button is selected
                                />
                                <RadioButton
                                    radioId="excel"
                                    radioLabel="Excel"
                                    radioValue="excel"
                                    onChange={() => setSelectedFormat("excel")} // Update state when "Excel" is selected
                                    isChecked={selectedFormat === "excel"} // Determine if this button is selected
                                />
                            </div>
                        </div>
                    </div>


                    <div className="add-organization-step-one d-flex align-items-center gap-3">
                        <div className="input-container" style={{ flex: 1 }}>
                            <p className="mb-1">PDF Options</p>
                            <DropDown
                                options={optionspdf}
                                placeHolder="Available Reports"
                                selectedValue={selectedReport}
                                onChange={(selectedOption) => {
                                    setSelectedReport(selectedOption.value); // Update state with the selected option
                                }}
                                isMulti={false} // Disable multi-select for radio buttons
                            />

                        </div>
                        <div className="input-container" style={{ flex: 1 }}>
                            <p className="mb-1">Excel Options</p>
                            <DropDownCh
                                options={optionsExcel}
                                placeHolder="Full Report - All Modules"
                                onChange={handleDomainChange}
                                selectedValue={selectedDomain}
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="form-submit-btn-group mt-5">
                        <button
                            className="save-btn"
                            onClick={nextStep}
                            disabled={!isFormValid}>
                            Download Report
                        </button>
                    </div>
                </>
            </div>
        </ScanInfo>
    );
}

export default ExposureManagement;
