import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";

import FileUpload from "../../../ui/FileUpload";
import InputBox from "../../../ui/InputBox";
import RadioButton from "../../../ui/RadioButton";
import Button from "../../../ui/Button";

import { useSelector, useDispatch } from 'react-redux';
import { addCustomer, updateCustomerDetailsDetail } from '../../../../store/actions/customerAction';

const AddCustomerModal = ({backToMainPage, type="", customerDetails=""}) =>{

    const addCustomerResponse = useSelector((state) => state.customer?.addCustomerResponse);
    const updateCustomerResponse = useSelector((state) => state.customer?.updateCustomerResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [files, setFiles] = useState(null);
    const [customerName, setCustomerName] = useState("")
    const [subscriptionType, setSubscriptionType] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [partner, setpartner] = useState("");
    const [addcustomerResponse, setAddCustomerResponse] = useState(false);
    const [fileObject, setFileObject] = useState(null);

    const getUploadfile = (file) => {
        setFiles(file)
    }

    const subscriptionTypeChange = (value) => {
        setSubscriptionType(value)
    }

    const CalenderIcon = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66663 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3334 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.91663 7.57471H17.0833" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 11.4167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 13.9167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 11.4167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 13.9167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 11.4167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 13.9167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
    }

    const saveCustomer = () => {
        let obj = {
            logo: files,
            name: customerName,
            partner: partner === "yes" ? true : false,
            subscription: subscriptionType === "demo" ? "trial" : "standrand",
            subscription_expire_date: selectedDate !== "" ? selectedDate.toISOString().replace("T", " ").split(".")[0] : "",
        }
        setIsLoading(true)
        dispatch(addCustomer(obj))
    }

    const backToPage = () => {
        setCustomerName("");
        setpartner("");
        setSubscriptionType("");
        setFiles("");
        setSelectedDate("");
       setSelectedDate("");
       if(type === "edit") {
            backToMainPage(updateCustomerResponse)
        } else {
            backToMainPage(addCustomerResponse)
        }
    }

    const updateValues = useCallback(()=>{
        if(customerDetails) {    
            setFiles(customerDetails?.logo || "");
            setCustomerName(customerDetails?.name || "");
            setpartner(customerDetails?.partner ? "yes" : "no")
            let obj = {
                name: customerDetails?.name,
                logo: customerDetails?.logo
            }
            setFileObject(obj)
        }
    },[customerDetails])

    const updateCustomer = () => {
        let obj = {
            customer_id: customerDetails.customer_id,
            name: customerName,
            partner: partner,
        }
        const isValidBase64 = /^(data:image\/[a-zA-Z]+;base64,)?([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        if(files &&isValidBase64.test(files)) {
            obj.logo = files
        }
        setIsLoading(true)
        dispatch(updateCustomerDetailsDetail(obj))
    }

    useEffect(()=>{
        if(addCustomerResponse !== null) {
            setIsLoading(false);
            setAddCustomerResponse(true);
        }
    },[addCustomerResponse])

    useEffect(()=>{
        if(type==="edit" && updateCustomerResponse !== null) {
            setIsLoading(false)
            setAddCustomerResponse(true)
        }
    },[updateCustomerResponse, type])

    useEffect(()=>{
        if(type === "edit"){
            updateValues()
        }
    },[type, updateValues])


    return (
        <div className="add-organization-container add-customer-container">
            {type !== "edit" && !addcustomerResponse &&
                <>
                    <div className="modal-header mt-4">
                        <h2>{t("customers.addCustomerModal.heading")}</h2>
                    </div>
                    <h4>{t("customers.addCustomerModal.subHeading")}</h4>
                    <div className="add-organization-step-one mt-3">
                        <FileUpload onFilesSelected={getUploadfile} label={'Upload an Avatar'} />
                        <div className="input-container mt-4">
                            <InputBox placeholder={'Enter Name of the CUstomer'} inputType={'text'} inputLabel={'What is the Name of the Customer'} inputChange={(val)=>{setCustomerName(val)}} inputValue={customerName} />
                        </div>
                        <div className="organization-type">
                            <p>{t("customers.addCustomerModal.subscriptionType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'demo'} radioLabel={'Demo'} radioValue={'demo'} onChange={subscriptionTypeChange}  isChecked={subscriptionType === 'demo'}/>
                                <RadioButton radioId={'Business'} radioLabel={'Business'} radioValue={'standrand'} onChange={subscriptionTypeChange} isChecked={subscriptionType === 'standrand'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-4">
                            <p>{t("customers.addCustomerModal.partner")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setpartner(value)}}  isChecked={partner === 'yes'}/>
                                <RadioButton radioId={'no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setpartner(value)}} isChecked={partner === 'no'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-4">
                            <p>{t("customers.addCustomerModal.endDate")}</p>
                            <div className="radio-btn-group d-flex mt-3 scan-info-datepicker">
                            <DatePicker
                                showIcon
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                placeholderText="Select a Expiry date"
                                icon={CalenderIcon()}
                                dateFormat="MMM dd, yyyy"
                                minDate={new Date()}
                                className="custom-width-datepicker"
                            >                                
                            </DatePicker>
                            </div>
                        </div>
                    </div>
                    <div className="form-submit-btn-group mt-5">
                        <Button buttonText={"Cancel"} buttonType="secondary"/>
                        {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={saveCustomer} disableButton={files === "" || customerName === "" || subscriptionType === "" || selectedDate === ""} />}
                        {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                    </div>
                </>
            } 
            {
                (type === "edit" && updateCustomerResponse == null) &&
                <>
                    <div className="modal-header mt-4">
                        <h2>{t("customers.addCustomerModal.editCustomer")}</h2>
                    </div>
                    <div className="add-organization-step-one mt-3">
                        <FileUpload onFilesSelected={getUploadfile} label={'Upload an Avatar'} filePath={files} place="editCustomer" fileObject={fileObject} />
                        <div className="input-container mt-4">
                            <InputBox placeholder={'Enter Name of the CUstomer'} inputType={'text'} inputLabel={'What is the Name of the Customer'} inputChange={(val)=>{setCustomerName(val)}} inputValue={customerName} />
                        </div>
                        <div className="organization-type mx-2">
                            <p>{t("customers.addCustomerModal.partner")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setpartner(value)}}  isChecked={partner === 'yes'}/>
                                <RadioButton radioId={'no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setpartner(value)}} isChecked={partner === 'no'}/>
                            </div>
                        </div>
                    </div>                    
                    <div className="form-submit-btn-group mt-5">
                        <Button buttonText={"Cancel"} buttonType="secondary"/>
                        {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={updateCustomer} disableButton={files === "" || customerName === "" || partner === ""} />}
                        {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                    </div>
                </>
            }
            {
                (type !== "edit" && addcustomerResponse) && 
                <>
                    {addCustomerResponse?.detail?.status === "success" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{addCustomerResponse?.detail?.description}</h2>
                    </div>}
                    {addCustomerResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                        <div className="mt-5">
                        <img src={failed} alt="failed" />
                        </div>
                        <h2 className="mb-4 mt-5">{addCustomerResponse?.description}</h2>
                    </div>}
                    <div className="btn-container mt-5 w-50 mx-auto">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={backToPage} />
                    </div>
                </>
            }
            {
               ( type === "edit" && updateCustomerResponse !== null) && 
                <>
                    {updateCustomerResponse?.detail?.status === "success" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{updateCustomerResponse?.detail?.description}</h2>
                    </div>}
                    {updateCustomerResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                        <div className="mt-5">
                        <img src={failed} alt="failed" />
                        </div>
                        <h2 className="mb-4 mt-5">{updateCustomerResponse?.description}</h2>
                    </div>}
                    <div className="btn-container mt-5 w-50 mx-auto">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={backToPage} />
                    </div>
                </>
            }
            
        </div>
    )
}

export default AddCustomerModal