import { React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import CallIcon from "../../assets/images/common/call.svg";
import GlobalIcon from "../../assets/images/common/global.svg";
import LogOutIcon from "../../assets/images/common/account-logout.svg";



import SideNav from "../../components/PostLogin/SideNav";
import UserDetailsTab from "../../components/PostLogin/AccountSettings/UserDetailsTab";
import UserSessionTab from "../../components/PostLogin/AccountSettings/UserSessionTab";
import { toaster } from "../../components/ui/Toaster";

import { useSelector, useDispatch } from 'react-redux';
import { getAccountDetails, userLogOut } from "../../store/actions/settingsAction.js";

const AccountSettings = () => {

    const accountDetailsResponse = useSelector((state) => state.settings?.accountDetails);
    const logOutResponse = useSelector((state) => state.settings?.logOutResponse);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('userDetails');

    const logoOut = () => {
        dispatch(userLogOut())
    }

    const fetchAccountDetails = useCallback(()=>{
        dispatch(getAccountDetails())
    },[dispatch])

    useEffect(()=>{
        fetchAccountDetails()
    },[fetchAccountDetails])

    useEffect(()=>{
        if(logOutResponse != null) {
            if(logOutResponse?.detail?.status === "success") {
                toaster.addToast(logOutResponse?.detail?.description, "success");
                // navigate('/auth/login')
                window.location.href = "/auth/login";
            } else {
                toaster.addToast(logOutResponse?.description, "error");
            }
        }
    },[logOutResponse, navigate])

    return (
        <div className="organization-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("accountSettingPage.heading")}</h1>
                    </div>
                </div>
                <div className="page-main-section settings-main-page p-4">
                    <div className="organization-main-container py-3 mb-4">
                        <div className="orgs-tab-container d-flex rounded">
                            <p className={`rounded text-center ${activeTab === "userDetails" ? 'active' : ""}`} onClick={()=>{setActiveTab("userDetails")}}>{t("accountSettingPage.userDetails")}</p>
                            <p className={`rounded text-center ${activeTab === "activeSession" ? 'active' : ""}`} onClick={()=>{setActiveTab("activeSession")}}>{t("accountSettingPage.userSession")}</p>
                        </div>
                    </div>
                    {activeTab === "userDetails" && <UserDetailsTab accountDetails={accountDetailsResponse} />}
                    {activeTab === "activeSession" && <UserSessionTab />}
                    <div className="settings-footer-container mt-4 d-flex align-items-center justify-content-between">
                        <div className="about-us-container d-flex gap-5">
                            <div className="contact d-flex">
                                <p className="d-flex align-items-center gap-2"> <img src={CallIcon} alt="call"/> {t("accountSettingPage.contactUs")}</p>
                            </div>
                            <div className="contact">
                                <p className="d-flex align-items-center gap-2"><img src={GlobalIcon} alt="about cybermindr"/> {t("accountSettingPage.aboutCybermindr")}</p>
                            </div>
                        </div>
                        <div className="log-out-container">
                            <button onClick={()=>{logoOut()}}><img src={LogOutIcon} alt="logout"/>{t("accountSettingPage.logout")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings