import { apiGet, apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getScanQueueStats() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scan.scanQueueStats())
            dispatch({
                type: 'SAN_QUEUE_STATS_SUCCESS',
                payload: response.data
            })
        } catch(error) {
            console.log(error)
        }
    }
}

export function getScanQueueList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.scan.scanQueueList()+url);
            if(response?.detail?.status === "failed") {
                dispatch({
                    type: 'SCAN_QUEUE_LIST_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'SCAN_QUEUE_LIST_SUCCESS',
                    payload: response.data,
                });
            }
        } catch (error){
            console.log(error)
        }
    }
}

export function stopScanSingle(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.scan.stopScanSingle(), obj);
            if(response?.detail?.status === "failed") {
                dispatch({
                    type: 'STOP_SCAN_SINGLE_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'STOP_SCAN_SINGLE_SUCCESS',
                    payload: response.data,
                });
            }
        } catch (error) {

        }
    }
}

export function resumeScanSingle(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.scan.resumeScanSingle(), obj);
            if(response?.detail?.status === "failed") {
                dispatch({
                    type: 'RESUME_SCAN_SINGLE_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'RESUME_SCAN_SINGLE_SUCCESS',
                    payload: response.data,
                });
            }
        } catch (error) {

        }
    }
}

export function stopScanMultiple(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.scan.stopScanMultiple(), obj);
            if(response?.detail?.status === "failed") {
                dispatch({
                    type: 'STOP_SCAN_MULTIPLE_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'STOP_SCAN_MULTIPLE_SUCCESS',
                    payload: response.data,
                });
            }
        } catch (error) {

        }
    }
}

export function deleteScanSingle(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.scan.deleteScanSingle(), obj);
            if(response?.detail?.status === "failed") {
                dispatch({
                    type: 'DELETE_SCAN_SINGLE_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'DELETE_SCAN_SINGLE_SUCCESS',
                    payload: response.data,
                });
            }
        } catch (error) {

        }
    }
}

export function resetStopScanSingleResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_STOP_SCAN_SINGLE_RESPONSE'
        })
    }
}

export function resetResumeScanSingleResponse() {
    return (dispatch) => {
        dispatch ({
            type: 'RESET_RESUME_SCAN_RESPONSE'
        })
    }
}

export function resetDeleteScanSingleResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_DELETE_SCAN_RESPONSE'
        })
    }
}