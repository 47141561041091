import { React, useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";

import InputBox from "../../../ui/InputBox";
import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";

import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationList, addDomain, resetAddDomainResponse } from '../../../../store/actions/organizationActions';

const AddDomainModal = ({closeAddDomain}) => {

    const organizationListResponse = useSelector((state) => state.organization?.organizationList);
    const addDomainResponse = useSelector((state) => state.organization?.addDomainResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [domainName, setDomainName] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [organizationList, setOrganizationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addDomainSuccess,setAddDomainSuccess] = useState(false);


    const handleSelectOrganization = (value) => {
        setSelectedOrganization(value)
    }

    // Input change handlers
    const handleDomainNameChange = (val) => {
        setDomainName(val);
    }

    const goBack = () => {
        setDomainName('')
        setAddDomainSuccess(false)
        setIsLoading(false)
        dispatch(resetAddDomainResponse())
        closeAddDomain()
    }

    const fetchOrganization = useCallback(()=>{
        dispatch(getOrganizationList());
    },[dispatch])

    const saveDomain = () => {
        let obj = {
            domain:domainName,
            organization_id:selectedOrganization.id
        }
        setIsLoading(true)
        dispatch(addDomain(obj))
    }

    useEffect(()=>{
        fetchOrganization();
    },[fetchOrganization])

    useEffect(()=>{
        if(organizationListResponse != null) {
            let list = organizationListResponse?.results.map((data)=>{
                return {
                    id: data.organization_id,
                    name: data.name,
                    value: data.organization_id,
                    logo: data.logo,
                    label: data.name
                }
            })  
            setOrganizationList(list)
        }
    },[organizationListResponse])

    useEffect(()=>{
        if(addDomainResponse != null) {
            setAddDomainSuccess(true)
        }
    },[addDomainResponse, addDomainSuccess])

    return (
        <div className="add-organization-container">
            <div className="modal-header mt-4">
                <h2>{t("domainPage.addDomainModal.addDomain")}</h2>
            </div>

            {/* Conditionally render the form only if the step is not 4 */}
                {!addDomainSuccess && <>
                    <div className="add-organization-step-one mt-3">
                        <div className="input-container mt-4">
                            <InputBox placeholder={'Enter Name of the Domain'}
                                inputType={'text'}
                                inputLabel={'What is the Name of your Domain?'}
                                inputChange={handleDomainNameChange}
                                inputValue={domainName}
                            />
                        </div>
                        <div className="input-container mt-4 organization-select-domainPage">
                            <label>Which Organization to assign?</label>
                            <DropDown
                                options={organizationList}
                                placeHolder='Please select Organization'
                                onChange={(e) => handleSelectOrganization(e)}
                                isSearchable = {true}
                                dropDownType = {'addDomain'}
                            />
                        </div>
                    </div>

                    <div className="form-submit-btn-group mt-4">
                        <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={goBack}/>
                        {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{saveDomain()}} disableButton={domainName === "" || selectedOrganization === ""}/>}
                        {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                    </div>
                </>}
            

            {/* Display success message when addOrgStep is 4 */}
            {addDomainSuccess && (
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {
                        addDomainResponse?.detail?.status === "success" &&
                        <>
                            <div className="mt-5">
                                <img src={successTick} alt="success" />
                            </div>
                            <h2 className="mb-4 mt-5">{t("domainPage.addDomainModal.domainCreated")}</h2>
                            <p>{t("organizationDetailsPage.addDomain.successMsg")}</p>
                            <div className="btn-container mt-5">
                                <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                            </div>
                        </>
                    }
                    {
                        addDomainResponse?.status === "failed" && 
                            <>
                            <div className="mt-5">
                                <img src={failed} alt="failed" />
                            </div>
                            <h2 className="mb-4 mt-5">{addDomainResponse?.description}</h2>
                            <div className="btn-container mt-5">
                                <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                            </div>
                        </>
                    }
                    
                </div>
            )}
        </div>
    )
}

export default AddDomainModal;
