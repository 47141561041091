import { React, useState, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";


import SideNav from "../../components/PostLogin/SideNav";
import UsersTileView from "../../components/PostLogin/Users/UsersTileView";
import UserListView from "../../components/PostLogin/Users/UsersListView";

import Pagination from '../../components/ui/Pagination';
import NoDataFound from "../../components/ui/NoDataFound";
import Spinner from "../../components/ui/spinner";
import SideModal from "../../components/ui/SideModal";
import { toaster } from "../../components/ui/Toaster";


import InviteUserModal from "../../components/PostLogin/Users/InviteUserModal";


import { useSelector, useDispatch } from 'react-redux';
import { getUsersList } from '../../store/actions/organizationActions';
import { resetUpdateUserStatus, getUserStats } from "../../store/actions/usersAction";

const Users = () => {

    const userStatsResponse = useSelector((state) => state.users?.userStatsResponse);
    const usersList = useSelector((state) => state.organization?.usersList);
    const updateUserStatusSingleResponse = useSelector((state) => state.users?.updateUserStatusSingleResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [viewType, setViewType] = useState('tile');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchvalue] = useState("");
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const [isStatsLoading, setIsStatsLoading] = useState(false);


    const closeInviteUserModal = () => {
        setShowInviteUserModal(false)
    }


    const searchUsers = (event) => {
        setSearchvalue(event.target.value);
        setCurrentPage(1);
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchUser = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}`;
        setIsLoading(true)
        dispatch(getUsersList(url));
    },[currentPage, currentPageSize, dispatch])

    const fetchUserStats = useCallback(()=>{
        setIsStatsLoading(true)
        dispatch(getUserStats())
    },[dispatch])
    useEffect(()=>{
        fetchUser();
        fetchUserStats()
    },[fetchUser, fetchUserStats])


    useEffect(()=>{
        if(usersList !== null){
            setIsLoading(false)
        }
    },[usersList])

    useEffect(()=>{
        if(updateUserStatusSingleResponse != null) {
            if(updateUserStatusSingleResponse?.detail?.status === "success") {
                toaster.addToast(updateUserStatusSingleResponse?.detail?.description, "success");
                fetchUser();
                fetchUserStats();
            } else {
                toaster.addToast(updateUserStatusSingleResponse?.description, "error");
            }
            dispatch(resetUpdateUserStatus())
        }
    },[updateUserStatusSingleResponse, dispatch, fetchUser, fetchUserStats])

    useEffect(()=>{
        if(userStatsResponse != null) {
            setIsStatsLoading(false);
        }
    },[userStatsResponse])

    return (
        <div className="users-container">
            <SideNav />
            <div className="users-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("users.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={()=>{setShowInviteUserModal(true)}}><span>&#43;</span>{t("users.inviteUsers")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search User' className="search-input" value={searchValue} onChange={searchUsers}/>
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={()=>setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={()=>setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    <div className="users-header-container p-3 my-3">
                        <div className="d-flex gap-3">
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">{isStatsLoading ? "...." : userStatsResponse?.users?.total}</p>
                                    <div className="user-count mx-3 mt-2">
                                        <span className="active d-inline-block mx-1"></span>
                                        <span className="disbled d-inline-block"></span>
                                        <p>{t("users.totalUsers")}</p>
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"><span className="d-inline-block">{userStatsResponse?.users?.active}</span> {t("users.activeUsers")}</p>
                                    <p className="disabled-user"><span className="d-inline-block">{userStatsResponse?.users?.disabled}</span> {t("users.disabledUsers")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="secondary-heading my-4">{t("users.selectaUser")}</h2>
                    {
                        isLoading ?
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                        :
                        <>
                            {viewType === "tile" && <UsersTileView usersList={usersList} />}
                            {viewType === "list" && <UserListView usersList={usersList} />}
                            {usersList != null && !isLoading && usersList?.meta?.total_results !== 0 && <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                pageSize={currentPageSize}
                                totalCount={usersList?.meta?.total_results}
                                onPageChange={setCurrentPageandRecordSize}
                            />}
                        </>   
                    }
                    {
                        !isLoading && usersList?.meta?.total_results === 0 && 
                        <NoDataFound />
                    }
                </div>
                <SideModal showSidePanel={showInviteUserModal} hideSidePanel={closeInviteUserModal}>
                    <InviteUserModal closeInviteUser={closeInviteUserModal}/>
                </SideModal>
            </div>
        </div>
    )
}

export default Users;