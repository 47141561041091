import { React, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import ProfileAvatar from "../../../ui/ProfileAvatar";


import UpdateUserStatusModal from "../UpdateUserStatusModal";

import { formatDate, getUserImageUrl, formatNumber } from "../../../../utils/util";

import { useSelector } from 'react-redux';

const UsersTileView = ({usersList}) => {

    const updateUserStatusSingleResponse = useSelector((state) => state.users?.updateUserStatusSingleResponse);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [showUpdateUserStatusModal, setShowUpdateUserStatusModal] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(null);

    const formatDateWithText = (data) => {
        let date = formatDate(data.created_at)
        return data.role !== null ? `${data.role} | ${date}` : `Role not assigned | ${date}`
    }

    const updatedUserStatus = (data) => {
        setShowUpdateUserStatusModal(true);
        setSelectedUserData(data)
    }

    useEffect(()=>{
        if(updateUserStatusSingleResponse != null) {
            setShowUpdateUserStatusModal(false)
        }
    },[updateUserStatusSingleResponse])

    return (
        <>
            <Row>
                {
                    usersList?.results.length > 0 &&
                    usersList?.results.map((data, index) =>{
                        return (
                            <Col xl="6" lg="12" md="12" key={index} className="col-xxxl-4">
                                <div className="organization-container mt-4 px-4 py-3 rounded">
                                    <div className="organization-profile">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="organization-details">
                                                <ProfileAvatar type="profile" secondaryText={formatDateWithText(data)} firstName={data.firstname} lastName={data.lastname} imageUrl={getUserImageUrl(data)} tags={true} tagContent={data.status === "active" ? "Active" : "Disabled"} tagColor={data.status === "active" ? "green" : "disabled"} />
                                            </div>
                                            <div className="organization-actions">
                                                {data.status !== "active" &&<button className="view-details-btn active-user" onClick={()=>{updatedUserStatus(data)}}>{t("users.activateuser")}</button>}
                                                {data.status === "active" && <button className="view-details-btn disable-user" onClick={()=>{updatedUserStatus(data)}}>{t("users.disableUser")}</button>}
                                                <button className="view-profile-btn" onClick={()=>{navigate(`/users/details/${data.user_id}`)}}>{t("users.viewBtn")}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-scan-details mt-4">
                                        <Row>
                                            <Col lg={4}>
                                                <div className="scan-count p-3">
                                                    <p className="label">{t("users.totalScan")}</p>
                                                    <p className="value">{formatNumber(data?.stats?.scan?.total)}</p>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="scan-count p-3">
                                                    <p className="label">{t("users.totalActiveScan")}</p>
                                                    <p className="value">{formatNumber(data?.stats?.scan?.active)}</p>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="scan-count p-3">
                                                    <p className="label">{t("users.totalPassiveScan")}</p>
                                                    <p className="value">{formatNumber(data?.stats?.scan?.passive)}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
                <UpdateUserStatusModal show={showUpdateUserStatusModal} selectedUserData={selectedUserData} onHide={() => setShowUpdateUserStatusModal(false)}/>
            </Row>
        </>
    )
}

export default UsersTileView;