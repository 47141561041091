import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col, Table } from 'react-bootstrap';
import "./index.scss";

import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";
import send from "../../../../../assets/images/common/Send-two.svg";

import move from "../../../../../assets/images/common/fi_move.svg";

const TopSubDomains = () => {

    const tooltipContent = () => {
        return (
            <div className="d-flex align-items-center gap-2 tooltip-logo">
                <img src={dummyImage} alt="logo" />
                <p>Google</p>
                <span>600</span>
            </div>
        )
    }

    const [active, setActive] = useState();

    return (
        <>
            <Row>
                <Col lg={6}>
                    <div className="bg-white-with-shadow p-4 mt-4">
                        <div className="d-flex justify-content-between mb-2 ">
                            <p className="heading font-bold">Top Vulnerable sub-domains</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>
                        <div className="dashboard-type-container rounded p-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">01</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">sub.xyz.com</p>
                                            <p className="cred mb-0 ms-3">Credential disclosure check</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="active mb-0">Potential</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>




                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">02</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">sub.xyz.com</p>
                                            <p className="cred mb-0 ms-3">Credential disclosure check</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="active mb-0">Potential</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>


                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">03</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">sub.xyz.com</p>
                                            <p className="cred mb-0 ms-3">Credential disclosure check</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="active mb-0">Potential</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>


                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">04</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">sub.xyz.com</p>
                                            <p className="cred mb-0 ms-3">Credential disclosure check</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="active mb-0 mt-1 ms-3">Potential</p>
                                            <p className="count-cred mb-0 ms-3">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">05</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">sub.xyz.com</p>
                                            <p className="cred mb-0 ms-3">Credential disclosure check</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="active mb-0">Potential</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>


                <Col lg={6}>
                    <div className="bg-white-with-shadow p-4 mt-4">
                        <div className="d-flex justify-content-between mb-2 ">
                            <p className="heading font-bold">Top Similar domains</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>


                        <div className="dashboard-type-container rounded p-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">01</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">1.1.1.1</p>
                                            <p className="blacklisted mb-0 ms-3">Malware</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="comp mb-0">Compromised</p>
                                            {/* <p className="count-cred mb-0">Score: <span className='font-bold'>04</span></p> */}
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">02</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">1.1.1.2</p>
                                            <p className="blacklisted mb-0 ms-3">Malware</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="comp mb-0">Compromised</p>
                                            {/* <p className="count-cred mb-0">Score: <span className='font-bold'>04</span></p> */}
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>


                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">03</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">1.1.1.1</p>
                                            <p className="blacklisted mb-0 ms-3">Malware</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="comp mb-0">Compromised</p>
                                            {/* <p className="count-cred mb-0">Score: <span className='font-bold'>04</span></p> */}
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>


                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">04</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">1.1.1.1</p>
                                            <p className="blacklisted mb-0 ms-3">Malware</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="comp mb-0">Compromised</p>
                                            {/* <p className="count-cred mb-0">Score: <span className='font-bold'>04</span></p> */}
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={1}>
                                        <p className="number-count mb-0">05</p>
                                    </Col>
                                    <Col lg={5} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0 mt-1 ms-3">1.1.1.1</p>
                                            <p className="blacklisted mb-0 ms-3">Malware</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3 mt-2">
                                            <p className="comp mb-0">Compromised</p>
                                            {/* <p className="count-cred mb-0">Score: <span className='font-bold'>04</span></p> */}
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <div className="bg-white-with-shadow p-4 mt-4">
                        <div className="d-flex justify-content-between mb-2 ">
                            <p className="heading font-bold">Top Vulnerabilities in Infrastructure</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>
                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="align-items-center justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top d-flex align-items-center">
                                            <p className="sens mb-0">CVE-2024-4577</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex align-items-center justify-content-end gap-3">
                                            <p className="critical mb-0">Critical</p>
                                            <p className="count-cred mb-0">Count: <span className="font-bold">04</span></p>
                                            <p className="mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="align-items-center justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top d-flex align-items-center">
                                            <p className="sens mb-0">CVE-2024-4577</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex align-items-center justify-content-end gap-3">
                                            <p className="critical mb-0">Critical</p>
                                            <p className="count-cred mb-0">Count: <span className="font-bold">04</span></p>
                                            <p className="mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="align-items-center justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top d-flex align-items-center">
                                            <p className="sens mb-0">CVE-2024-4577</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex align-items-center justify-content-end gap-3">
                                            <p className="critical mb-0">Critical</p>
                                            <p className="count-cred mb-0">Count: <span className="font-bold">04</span></p>
                                            <p className="mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="align-items-center justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top d-flex align-items-center">
                                            <p className="sens mb-0">CVE-2024-4577</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex align-items-center justify-content-end gap-3">
                                            <p className="critical mb-0">Critical</p>
                                            <p className="count-cred mb-0">Count: <span className="font-bold">04</span></p>
                                            <p className="mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="align-items-center justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top d-flex align-items-center">
                                            <p className="sens mb-0">CVE-2024-4577</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex align-items-center justify-content-end gap-3">
                                            <p className="critical mb-0">Critical</p>
                                            <p className="count-cred mb-0">Count: <span className="font-bold">04</span></p>
                                            <p className="mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>


                <Col lg={6}>
                    <div className="bg-white-with-shadow p-4 mt-4">
                        <div className="d-flex justify-content-between mb-2 ">
                            <p className="heading font-bold">Top Vulnerabilities in Web applications</p>
                            {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                        </div>


                        <div className="dashboard-type-container rounded p-3">
                            <div className="align-items-center">
                                <Row className="align-items-center justify-content-between">

                                    <Col lg={6} className="text-start">
                                        <div className="count-top align-items-center">
                                            <p className="sens mb-0">AEM Package Manager-</p>
                                            <p className="sens mb-0">Authentication ByPass</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex align-items-center justify-content-end gap-3">
                                            <p className="medium mb-0">Medium</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">

                                    <Col lg={6} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0">AEM Package Manager-</p>
                                            <p className="sens mb-0">Authentication ByPass</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3">
                                            <p className="medium mb-0">Medium</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>


                        <div className="dashboard-type-container rounded p-2 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">

                                    <Col lg={6} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0">AEM Package Manager-</p>
                                            <p className="sens mb-0">Authentication ByPass</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3">
                                            <p className="medium mb-0">Medium</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>


                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0">AEM Package Manager-</p>
                                            <p className="sens mb-0">Authentication ByPass</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3">
                                            <p className="medium mb-0">Medium</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="dashboard-type-container rounded p-3 mt-3">
                            <div className="align-items-center">
                                <Row className="justify-content-between">
                                    <Col lg={6} className="text-start">
                                        <div className="count-top">
                                            <p className="sens mb-0">AEM Package Manager-</p>
                                            <p className="sens mb-0">Authentication ByPass</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <div className="count-top d-flex justify-content-end gap-3">
                                            <p className="medium mb-0">Medium</p>
                                            <p className="count-cred mb-0">Count: <span className='font-bold'>04</span></p>
                                            <p className="mt-1 mb-0">
                                                <img src={send} alt="send" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </>

    )
}


export default TopSubDomains;