import { React, useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Table from 'react-bootstrap/Table';

import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import addIcon from "../../../../assets/images/common/add.svg";
import editIcon from "../../../../assets/images/common/edit-icon.svg";
import closeIcon from "../../../../assets/images/common/close-circle.svg";
import NodataFound from "../../../../assets/images/common/no_data_found.svg";

import ProfileAvatar from '../../../ui/ProfileAvatar';
import Pagination from '../../../ui/Pagination';
import CheckBox from "../../../ui/CheckBox";
import Button from "../../../ui/Button";
import RadioButton from "../../../ui/RadioButton";
import SideModal from '../../../ui/SideModal';
import Spinner from "../../../ui/spinner";

import ModifyDomain from "../ModifyDomain";
import AddDomain from "../../../../components/PostLogin/OrganizationDetails/AddDomain";

import { getloggedInUserType } from "../../../../utils/util.js";

import { useSelector, useDispatch } from 'react-redux';
import { getUsersListByOrganization, getUsersList, resetAddDomainResponse, resetUsersList, resetAssignUnassignResponse } from '../../../../store/actions/organizationActions.js';
import { formatDate, getUserImageUrl } from "../../../../utils/util.js";

const filterCheckboxOption = [
    {
        label: "Adminstrator",
        value: "Adminstrator"
    },
    {
        label: "User",
        value: "User"
    },
    {
        label: "Read Only",
        value: "Read Only"
    }
]

const filterRadioOption = [
    {
        label: "Both",
        value: "Both"
    },
    {
        label: "Active",
        value: "Active"
    },
    {
        label: "Inactive",
        value: "Inactive"
    }
]


const UsersTab = ({organizationDetail = "", customerDetails="", currentPageType="organizationDetails"}) => {

    const userListResponse = useSelector((state) => state.organization?.userListByOrganization);
    const addDomainResponse = useSelector((state) => state.organization?.addDomainResponse);
    const assignUnAssignResponse = useSelector((state) => state.organization?.assignUnAssignResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const filterWrapperRef = useRef(null);

    const { customerId } = useParams();

    const [userSearchValue, setUserSearchValue] = useState("");
    const [filterList, setFilterList] = useState([]);
    const [filterStatusValue, setFilterStatusvalue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [showAdddomainSidePanel, setShowAdddomainSidePanel] = useState(false);
    const [userListState, setUsersListState] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [showModifySidePanel, setShowModifySidePanel] = useState(false);

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    const filterStatusChanges = (value) => {
        setFilterStatusvalue(value)
    }

    const removeStatus = () => {
        setFilterStatusvalue("")
    }

    const closeAddDomainSidePanel = () => {
        setShowAdddomainSidePanel(false)
    }

    const closeModifyModal = () => {
        closeModifySidePanel()
        dispatch(resetAssignUnassignResponse())
        if(assignUnAssignResponse?.detail?.status === "success") {
            dispatch(resetUsersList())
            fetchUser()
        }
    }

    const closeAddDomain = () => {
        closeAddDomainSidePanel()
        if(addDomainResponse != null) {
            dispatch(resetAddDomainResponse())
        }
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const searchUser = () => {
        if(currentPageType === "organizationDetails") {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&organization_id=${organizationDetail?.organization_id}&query=${userSearchValue}`;
            setIsLoading(true)
            dispatch(getUsersListByOrganization(url))
        } else {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&customer_id=${customerDetails?.customer_id}&query=${userSearchValue}`;
            setIsLoading(true)
            dispatch(getUsersListByOrganization(url))
        }        
    }

    const fetchUser = useCallback(()=>{
        if(currentPageType === "organizationDetails") {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&organization_id=${organizationDetail?.organization_id}`;
            setIsLoading(true)
            dispatch(getUsersListByOrganization(url));
        } else {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&customer_id=${customerDetails?.customer_id}`;
            setIsLoading(true)
            dispatch(getUsersListByOrganization(url));
        }
        
    },[currentPage, currentPageSize, dispatch, organizationDetail, customerDetails, currentPageType])

    const closeModifySidePanel = () => {
        setShowModifySidePanel(false)
    }

    useEffect(()=>{
        fetchUser()
    },[fetchUser])

    useEffect(()=>{
        if(userListResponse !== null){
            setUsersListState(userListResponse);
            setIsLoading(false)
        }
    },[userListResponse])

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList])

    return (
        <>
            <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                <div className="search-container d-flex align-items-center gap-3">
                    <div className="position-relative">
                        <input className="search-input rounded" placeholder="Search User" value={userSearchValue} onChange={(e)=>setUserSearchValue(e.target.value)}/>
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                    </div>
                    <button className="search-btn" onClick={()=>{searchUser()}}>{t("organizationDetailsPage.searchBtn")}</button>
                    <div className="position-relative">
                        <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                        {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                            <h6>{t("organizationDetailsPage.usersTable.sortBy")}</h6>
                            {filterCheckboxOption.map((data,index)=>{
                                return (
                                    <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                        <p>{data.label}</p>
                                        <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                    </div>
                                )
                            })}
                            <h6 className="mt-4">{t("organizationDetailsPage.usersTable.status")}</h6>
                            <div className="filter-user-status d-flex mb-4 gap-4">
                                {
                                    filterRadioOption.map((data,index)=>{
                                        return (
                                            <RadioButton radioId={data.label} radioValue={data.value} radioLabel={data.label} handleRadioChange={filterStatusChanges} key={index}/>
                                        )
                                    })
                                }
                            </div>
                            <div className="filter-apply-btn mt-3">
                                <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 && filterStatusValue === '' ? true : false} buttonClicked={applyFilter}/>
                            </div>
                        </div>}
                    </div>
                    {!showFilter && <p className="selected-filters">
                        {
                            filterList.map((data,index)=>{
                                return (
                                    <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                )
                            })
                        }
                        {filterStatusValue !== '' && <span className="d-inline-block mx-2" >{filterStatusValue} <img src={closeIcon} alt="close" onClick={removeStatus}/></span>}
                    </p>}
                </div>
                <div className="sort-edit-container">
                    <div className="container d-flex gap-3 align-items-center">
                        <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.usersTable.sortBy")} 
                            <select className="border-0">
                                <option value="latest score">Latest Score</option>
                                <option value="last scanned">Last Scanned</option>
                                <option value="created">Created</option>
                            </select>
                        </p>
                        <button onClick={()=>{
                            if(getloggedInUserType() === "internal" && currentPageType === "organizationDetails") {
                                console.log("customerId", customerId)
                                let url = `&customer_id=${customerId}`
                                dispatch(getUsersList(url));
                            } else {
                                dispatch(getUsersList(""))
                            }                            
                            setShowModifySidePanel(true)
                        }}>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.modifyBtn")}
                        </button>
                        {/* <button onClick={()=>{setShowAdddomainSidePanel(true)}}>
                            <img src={addIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.domainBtn")}
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="domain-table-container users-tab-table">
                
                {
                    isLoading ? <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%'}}>
                            <Spinner />
                        </div>
                    :
                        <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th>{t("organizationDetailsPage.usersTable.userDetails")}</th>
                                    <th>{t("organizationDetailsPage.usersTable.joined")}</th>
                                    <th>{t("organizationDetailsPage.usersTable.role")}</th>
                                    <th>{t("organizationDetailsPage.usersTable.status")}</th>
                                    <th>{t("organizationDetailsPage.usersTable.LastLogin")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userListState?.results.length > 0 && 
                                    userListState?.results.map((data,index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <ProfileAvatar type="profile" firstName={data.firstname} lastName={data.lastname} secondaryText={data.email} imageUrl={getUserImageUrl(data)} />
                                                </td>
                                                <td>
                                                    <p>{formatDate(data.created_at)}</p>
                                                </td>
                                                <td>
                                                    <p>{data.role}</p>
                                                </td>
                                                <td>
                                                    <label className={`user-status ${data.status === "active" ? "active" : "disabled"}`}>{data.status}</label>
                                                </td>
                                                <td>
                                                    <p>{formatDate(data.last_login)}</p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                }
                {
                    !isLoading && userListState?.results.length === 0 && 
                    <div className="d-flex align-items-center justify-content-center flex-column gap-3 my-5">
                        <img src={NodataFound} alt="no-data-found"/>
                        <h2>No Data found</h2>
                    </div>
                }
                
                {userListResponse != null && !isLoading && userListResponse.meta.total_results !== 0 && <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    pageSize={currentPageSize}
                    totalCount={userListResponse.meta.total_results}
                    onPageChange={setCurrentPageandRecordSize}
                />}
            </div>
            <SideModal showSidePanel={showAdddomainSidePanel} hideSidePanel={closeAddDomainSidePanel}>
                <AddDomain closeAddDomain={closeAddDomain}/>
            </SideModal>
            <SideModal showSidePanel={showModifySidePanel} hideSidePanel={closeModifySidePanel}>
                <ModifyDomain selectedTab={"users"} clsoeModifyModal={closeModifyModal} customerDetails={customerDetails} assignedUsers={userListState} currentPageType={currentPageType}/>
            </SideModal>
        </>
    )
}

export default UsersTab;