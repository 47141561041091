import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import CheckBox from "../../../../../components/ui/CheckBox";
import { formatDate } from "../../../../../utils/util";

const Emails = ({ onCheckboxChange, emailList }) => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState([]);

    const changeSelectAll = (e) => {
        if(e.target.checked) {
            setSelectedValue(emailList?.results)
            onCheckboxChange(emailList?.results)
        } else {
            setSelectedValue([])
            onCheckboxChange([])
        }
    }

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            let checkedValue = emailList?.results.filter(item => item.email === e.target.value);
            setSelectedValue([...selectedValue, ...checkedValue])
            onCheckboxChange([...selectedValue, ...checkedValue]);
        } else {
            const updatedList = selectedValue.filter(item => item.email !== e.target.value);
            setSelectedValue(updatedList);
            onCheckboxChange(updatedList);
        }
    }

    const isChecked = (data) => {
        if(selectedValue.length > 0 ){
            return selectedValue.some(item => item.email === data.email);
        }
        return false
    }

    return (
        <>
            <Table responsive="lg">
                <thead>
                    <tr>
                        <th className="select-all-checkbox">
                            <CheckBox checkBoxId={0} checkBoxValue={"select all"} handleCheckboxChange={changeSelectAll} />
                        </th>
                        <th>{t("domainDetailsPage.subDomainTable.emails")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.clientProvided")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.firstSeen")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.lastSeen")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        emailList?.results.length > 0 && 
                        emailList?.results.map((data, index)=>{
                            return (
                                <tr key={index}>
                                    <td>
                                        <CheckBox checkBoxId={index} checkBoxValue={data.email} handleCheckboxChange={setCheckboxvalue} isChecked={isChecked(data)} />
                                    </td>
                                    <td>
                                        <p>{data.email}</p>
                                    </td>
                                    <td>
                                        <p>{data.subdomain ? "Yes" : "No"}</p>
                                    </td>
                                    <td>
                                        <p>{formatDate(data.first_seen)}</p>
                                    </td>
                                    <td>
                                        <p>{formatDate(data.last_seen)}</p>
                                    </td>
                                    <td>
                                        <label className={`asset-status ${data.excluded ? 'disabled' : 'active'}`}>{data.excluded ? "Exclude" : "Include"}</label>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    );
}

export default Emails;
