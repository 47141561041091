import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import "./index.scss";


import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import ViewMore from "../../../../../assets/images/common/more.svg";
import InfoIcon from "../../../../../assets/images/common/Info_blue.svg";

import Pagination from "../../../../../components/ui/Pagination";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import Spinner from "../../../../../components/ui/spinner";
import CustomToolTip from "../../../../../components/ui/Tooltip";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getGitLeaks } from "../../../../../store/actions/scanInfoAction";

const GitLeaks = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const gitleaksResponse = useSelector((state) => state.scanInfo?.gitLeaksList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [searchValue, setSearchValue] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const onSearch = () => {
        setIsLoading(true)
        setCurrentPage(1)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&query=${searchValue}`
        dispatch(getGitLeaks(url))
    }

    const fetchdData = useCallback(()=>{
        setIsLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}`
        dispatch(getGitLeaks(url))
    },[dispatch, currentPage, currentPageSize, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchdData();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchdData]);

    useEffect(()=>{
        console.log("gitleaksResponse", gitleaksResponse)
        if(gitleaksResponse !== null) {
            setIsLoading(false)
        }
    },[gitleaksResponse])


    return (
        <>
            <div className="git-leaks-main-content bg-white-with-shadow p-3 ">
                <div className="search-header-container d-flex align-items-center gap-3">
                    <div className="search-container position-relative">
                        <img src={SearchIcon} alt="Search" className="position-absolute"/>
                        <input type="text" placeholder="Search" value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}}/>
                    </div>
                    <button className="search-btn" onClick={onSearch}>Search</button>
                    <img src={FilterIcon} alt="filter" className="filtericon"/>
                </div>
                <div className="table-container git-laks-table mt-3">
                    {
                        isLoading ?
                        <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                            <Spinner />
                        </div>
                        :
                        <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th>Repository Details</th>
                                    <th>Git URL</th>
                                    <th>Authors/Commiters</th>
                                    <th>Status</th>
                                    <th>Retest</th>
                                    <th>Source</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    gitleaksResponse?.results.length > 0 &&
                                    gitleaksResponse?.results.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="repo-details">
                                                    <ul type="none" className="m-0 p-0">
                                                        <li className="d-flex justify-content-between gap-3">
                                                            <span>Owner: </span>
                                                            <span>{data?.owner}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between gap-3">
                                                            <span>Repo: </span>
                                                            <span>{data?.repo_name}</span>
                                                        </li>
                                                    </ul>
                                                    {data?.new_record && <p className="d-inline-block new-data my-2">New</p>}
                                                    {data?.false_positive && <p className="d-inline-block false-positive">False positive</p>}
                                                </td>
                                                <td className="repo-url">
                                                    <a className="text-overflow" href={data?.url} target="_blank"  rel="noreferrer">{data?.url}</a>
                                                </td>
                                                <td className="author-details">
                                                    <p className="heading-text mb-2">Authors:</p>
                                                    <span>{data?.authors.join(", ")}</span>
                                                    <p className="heading-text text-bold my-2">Commiters:</p>
                                                    <span>{data?.committers.join(",")}</span>
                                                    
                                                </td>
                                                <td className="status-container">
                                                    {data.status === "open" && <p className="status open">{data?.status}</p>}
                                                    {data.status === "closed" && <p className="status closed">{data?.status}</p>}
                                                    {data.status === "wont_fix" && <p className="status warn">{data?.status}</p>}
                                                </td>
                                                <td className="retest-summary">
                                                    <div className="finding-list">
                                                        <ul type="none" className="m-0 p-0">
                                                            <li className="d-flex justify-content-between gap-3">
                                                                <span>Status: </span>
                                                                {data?.retest?.status === "passed" && 
                                                                    <span className="retest-status passed">{data?.retest?.status}
                                                                        <CustomToolTip content={data?.retest?.summary } position={"top"}>
                                                                            <img src={InfoIcon} alt="info" className="info-icon"/>
                                                                        </CustomToolTip>
                                                                    </span>
                                                                    
                                                                }
                                                                {data?.retest?.status === "failed" && 
                                                                    <span className="retest-status failed">{data?.retest?.status}
                                                                        <CustomToolTip content={data?.retest?.summary } position={"top"}>
                                                                            <img src={InfoIcon} alt="info" className="info-icon"/>
                                                                        </CustomToolTip>
                                                                    </span>
                                                                }
                                                                {data?.retest?.status === "inprogress" && 
                                                                    <span className="retest-status inprogress"> {data?.retest?.status}                                                                        
                                                                        <CustomToolTip content={data?.retest?.summary } position={"top"}>
                                                                            <img src={InfoIcon} alt="info" className="info-icon"/>
                                                                        </CustomToolTip>
                                                                    </span>
                                                                }
                                                                {
                                                                    (data?.retest?.status === "" || data?.retest?.status === null) && <span>-</span>
                                                                }
                                                            </li>
                                                            <li className="d-flex justify-content-between gap-3">
                                                                <span>Fixed: </span>
                                                                <span>{(data?.retest?.is_fixed !== null && data?.retest?.is_fixed !== "") ? data?.retest?.is_fixed ? "Yes" : "No" : "-"}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td className="source-containe">
                                                    {data?.source}
                                                </td>
                                                <td>
                                                    <img src={ViewMore} alt="more"/>
                                                </td>
                                            </tr>
                                        )                                        
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                    {
                        !isLoading && gitleaksResponse !== "" && gitleaksResponse?.results.length === 0 && 
                        <NoDataFound noDataText={"No Record Found"}/>
                    }
                    {gitleaksResponse !== "" && gitleaksResponse?.results.length > 0 && <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        pageSize={currentPageSize}
                        totalCount={gitleaksResponse?.meta?.total_results}
                        onPageChange={setCurrentPageandRecordSize}
                    />}            
                </div>
            </div>
        </>        
    )
}


export default GitLeaks;