import React from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col, Table } from 'react-bootstrap';
import "./index.scss";

import dummyImage from "../../../../assets/images/common/organization-dummy-image.png";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../assets/images/common/search-icon.svg";

import ScanInfo from "../../../ScanInfo";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";
import CustomTooltip from "../../../../components/ui/Tooltip";

const PortfolioDashboard = () => {

    const tooltipContent = () => {
        return (
            <div className="d-flex align-items-center gap-2 tooltip-logo">
                <img src={dummyImage} alt="logo" />
                <p>Google</p>
                <span>600</span>
            </div>
        )
    }

    const tooltipContentmany = () => {
        return (
            <>
                <div className="d-flex align-items-center gap-2 mb-3 tooltip-logo">
                    <img src={dummyImage} alt="logo" />
                    <p>Google</p>
                    <span>600</span>
                </div>
                <div className="d-flex align-items-center gap-2 mb-3 tooltip-logo">
                    <img src={dummyImage} alt="logo" />
                    <p>Google</p>
                    <span>600</span>
                </div>
                <div className="d-flex align-items-center gap-2 mb-3 tooltip-logo">
                    <img src={dummyImage} alt="logo" />
                    <p>Google</p>
                    <span>600</span>
                </div>
                <div className="d-flex align-items-center gap-2 mb-3 tooltip-logo">
                    <img src={dummyImage} alt="logo" />
                    <p>Google</p>
                    <span>600</span>
                </div>
                <div className="d-flex align-items-center gap-2 mb-3 tooltip-logo">
                    <img src={dummyImage} alt="logo" />
                    <p>Google</p>
                    <span>600</span>
                </div>
                <div className="d-flex align-items-center gap-2 mb-3 tooltip-logo">
                    <img src={dummyImage} alt="logo" />
                    <p>Google</p>
                    <span>600</span>
                </div>
            </>
            
        )
    }

    return (
        <ScanInfo>
            <div className="scan-info-main-content portfolio-risk-main-content">
                <div className="bg-white-with-shadow p-4">
                    <div className="heat-map-container d-flex gap-5">
                        <div className="risk-text-level d-flex justify-content-between">
                            <p>High</p>
                            <p>Risk Levels</p>
                            <p>Low</p>
                        </div>
                        <div className="risk-graph">
                            <div className="row-first-div d-flex align-items-center main-row-container">
                                <div className="score-value-div d-flex align-items-center">
                                    <p>0-250</p>
                                </div>
                                <div className="row-common-div row-one-one d-flex align-items-center gap-2 p-2">
                                    <CustomTooltip content={tooltipContent()} position="top">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip content={tooltipContentmany()} position="bottom">
                                        <div className="additional-container d-flex align-items-center justify-content-center">
                                            <p>+3</p>
                                        </div>
                                    </CustomTooltip>                                    
                                </div>
                                <div className="row-common-div row-one-two d-flex align-items-center gap-2 p-2">
                                    <CustomTooltip content={tooltipContent()} position="right">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip content={tooltipContent()} position="left">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                </div>
                                <div className="row-common-div row-one-three d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                                <div className="row-common-div row-one-four d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                            </div>
                            <div className="row-two-div d-flex align-items-center main-row-container">
                                <div className="score-value-div d-flex align-items-center">
                                    <p>251-500</p>
                                </div>
                                <div className="row-common-div row-two-one d-flex align-items-center gap-2 p-2">
                                    <CustomTooltip content={tooltipContent()} position="bottom">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                </div>
                                <div className="row-common-div row-two-two d-flex align-items-center gap-2 p-2">
                                    <CustomTooltip content={tooltipContent()} position="bottom">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip content={tooltipContent()} position="bottom">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                </div>
                                <div className="row-common-div row-two-three d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                                <div className="row-common-div row-two-four d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                            </div>
                            <div className="row-three-div d-flex align-items-center main-row-container">
                                <div className="score-value-div d-flex align-items-center">
                                    <p>501-800</p>
                                </div>
                                <div className="row-common-div row-three-one d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                                <div className="row-common-div row-three-two d-flex align-items-center gap-2 p-2">
                                    <CustomTooltip content={tooltipContent()} position="bottom">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                </div>
                                <div className="row-common-div row-three-three d-flex align-items-center gap-2 p-2">
                                    <CustomTooltip content={tooltipContent()} position="bottom">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip content={tooltipContent()} position="bottom">
                                        <div className="image">
                                            <img src={dummyImage} alt="logo"/>
                                        </div>
                                    </CustomTooltip>
                                </div>
                                <div className="row-common-div row-three-four d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                            </div>
                            <div className="row-four-div d-flex align-items-center main-row-container">
                                <div className="score-value-div d-flex align-items-center">
                                    <p>801-900</p>
                                </div>
                                <div className="row-common-div row-four-one d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                                <div className="row-common-div row-four-two d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                                <div className="row-common-div row-four-three d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                                <div className="row-common-div row-four-four d-flex align-items-center gap-2 p-2">
                                    
                                </div>
                            </div>
                            <div className="row-four-div d-flex align-items-center main-row-container">
                                <div className="score-value-div d-flex align-items-center">
                                    
                                </div>
                                <div className="row-common-div d-flex align-items-center justify-content-center gap-2 p-2">
                                    <p>Low</p>
                                </div>
                                <div className="row-common-div d-flex align-items-center justify-content-center gap-2 p-2">
                                    <p>Medium</p>
                                </div>
                                <div className="row-common-div d-flex align-items-center justify-content-center gap-2 p-2">
                                    <p>High</p>
                                </div>
                                <div className="row-common-div d-flex align-items-center justify-content-center gap-2 p-2">
                                    <p>Critical</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Organization List</h1>
                                    <img src={filterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container pb-5">
                                    <div className="sub-domain-list py-2 px-3 active">
                                        <ProfileAvatar type={"domain"} name="Google" imageUrl={dummyImage} />
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <ProfileAvatar type={"domain"} name="Google" imageUrl={dummyImage} />
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <ProfileAvatar type={"domain"} name="Google" imageUrl={dummyImage} />
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <ProfileAvatar type={"domain"} name="Google" imageUrl={dummyImage} />
                                    </div>
                                    <div className="sub-domain-list py-2 px-3">
                                        <ProfileAvatar type={"domain"} name="Google" imageUrl={dummyImage} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="bg-white-with-shadow sub-domain-main-container p-3 h-100">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Doamin</th>
                                            <th>Risk Score</th>
                                            <th>Severity</th>
                                            <th>Infrastructure</th>
                                            <th>Exposure</th>
                                            <th>Vulnerabilities</th>
                                            <th>PII</th>
                                            <th>PII</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>lollypop.design</td>
                                            <td>620/900</td>
                                            <td>High</td>
                                            <td>F</td>
                                            <td>A+</td>
                                            <td>C</td>
                                            <td>F</td>
                                            <td>July 21, 2024</td>
                                        </tr>
                                        <tr>
                                            <td>lollypop.design</td>
                                            <td>620/900</td>
                                            <td>High</td>
                                            <td>F</td>
                                            <td>A+</td>
                                            <td>C</td>
                                            <td>F</td>
                                            <td>July 21, 2024</td>
                                        </tr>
                                        <tr>
                                            <td>lollypop.design</td>
                                            <td>620/900</td>
                                            <td>High</td>
                                            <td>F</td>
                                            <td>A+</td>
                                            <td>C</td>
                                            <td>F</td>
                                            <td>July 21, 2024</td>
                                        </tr>
                                        <tr>
                                            <td>lollypop.design</td>
                                            <td>620/900</td>
                                            <td>High</td>
                                            <td>F</td>
                                            <td>A+</td>
                                            <td>C</td>
                                            <td>F</td>
                                            <td>July 21, 2024</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </ScanInfo>         
    )
}


export default PortfolioDashboard;