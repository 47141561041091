import { apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function registerUser (obj) {
  return async (dispatch) => {
      try {
          const response = await apiPost(apiEndpoints.register.register(), obj)
          if(response?.data?.detail?.status === "success") {
              dispatch({
                  type: 'REGISTER_SUCCESS',
                  payload: response.data
              })
          } else {
              dispatch({
                  type: 'REGISTER_SUCCESS',
                  payload: response.detail
              })
          }
      } catch (error) {
          console.log(error)
      }
  }
}

export function resend (obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.register.resend(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'RESEND_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'RESEND_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
  }

