import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";
import scannerIcon from "../../assets/images/common/scanner.svg";

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import ScanHistoryTab from "../../components/PostLogin/UserDetails/ScanHistory";
import AssetsTab from "../../components/PostLogin/DomainDetails/AssetsTab";
import DeleteDomainModal from "../../components/PostLogin/Domain/DeleteDomainModal";
import SideModal from '../../components/ui/SideModal';
import ScanProgressModal from '../../components/PostLogin/DomainDetails/ScanProgressModal';
import EditDomainModal from '../../components/PostLogin/Domain/EditDomainModal'; // Import the EditDomainModal
import Spinner from "../../components/ui/spinner";

import { useSelector, useDispatch } from 'react-redux';
import { getDomainDetails, resetUpdateDomainResponse } from '../../store/actions/domainAction.js';

import { formatDate, getDomainImageUrl, getUserImageUrl } from "../../utils/util.js";
import { toaster } from "../../components/ui/Toaster";

const DomainDetails = () => {

    const domainDetailResponse = useSelector((state) => state.domain?.domainDetails);
    const deleteDomainResponse = useSelector((state) => state.domain?.deleteDomainResponse);
    const updateDomainResponse = useSelector((state) => state.domain?.updateDomainResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { domainName } = useParams();

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('scanHistory');
    const [modalShow, setModalShow] = useState(false);
    const [showScanProgressModal, setShowScanProgressModal] = useState(false);
    const [pausedDomainId, setPausedDomainId] = useState(null);
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }

    const showModal = () => {
        setModalShow(true);
    };

    const fetchDomainDetails = useCallback(()=>{
        let obj = {
            domain: domainName
        }
        setIsLoading(true);
        dispatch(getDomainDetails(obj));
    },[dispatch, domainName])

    const openScanProgressModal = () => {
        setShowScanProgressModal(true);
    };

    const closeScanProgressModal = () => {
        setShowScanProgressModal(false);
    };

    const handlePauseResume = (id) => {
        setPausedDomainId((prev) => (prev === id ? null : id));
    };

    const backTodomainListPage = () => {
        navigate(`/domain`)
    }

    useEffect(()=>{
        fetchDomainDetails()
    },[fetchDomainDetails])


    useEffect(()=>{
        if(domainDetailResponse != null){
            setIsLoading(false);
        }
    },[domainDetailResponse])

    useEffect(()=>{
        if(deleteDomainResponse != null) {
            if(deleteDomainResponse?.detail?.status === "success") {
                setModalShow(false)
                navigate(`/domain`)
            } else {
                setModalShow(false)
                toaster.addToast(deleteDomainResponse?.description, "error")
            }
        }
    },[deleteDomainResponse, dispatch, navigate])

    useEffect(()=>{
        if(updateDomainResponse != null) {
            closeAddOrgSidePanel()
            fetchDomainDetails()
            if(updateDomainResponse?.detail?.status === "success") {
                toaster.addToast(updateDomainResponse?.detail?.description, "success");
            } else {
                toaster.addToast(updateDomainResponse?.description, "error");
            }
            dispatch(resetUpdateDomainResponse())
        }
    },[updateDomainResponse, dispatch, fetchDomainDetails])

    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1 onClick={()=>{backTodomainListPage()}}>{t("domainPage.heading")}</h1>
                        <img src={arrowRight} alt="arrow-right" />
                        {domainDetailResponse !== null && <ProfileAvatar type="domain" name={domainDetailResponse?.domain} imageUrl={getDomainImageUrl(domainDetailResponse)} />}
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button className='viewScan-btn' onClick={openScanProgressModal}>
                            <img src={scannerIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("domainDetailsPage.viewScanBtn")}
                        </button>
                        <button onClick={() => { setShowAddOrgSidePanel(true) }}> {/* Edit button to open modal */}
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.editBtn")}
                        </button>
                        <button onClick={() => showModal()}>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.deleteBtn")}
                        </button>
                    </div>
                </div>
                {
                    isLoading ? 
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                    :
                        <div className="page-main-section p-4">
                            <div className="organization-created d-flex justify-content-between">
                                <h4>{t("domainDetailsPage.heading")}</h4>
                                {domainDetailResponse?.created_by && <ProfileAvatar pageType={"organizationDetails"} type="profile" firstName={domainDetailResponse?.created_by?.name} lastName={""} imageUrl={getUserImageUrl(domainDetailResponse?.created_by)} />}
                            </div>
                            <div className="organization-header-container p-3 my-3 rounded">
                                <div className="d-flex justify-content-between">
                                    <div className="header-details d-flex gap-3">
                                        <p>{t("organizationDetailsPage.createdOn")} <span>{formatDate(domainDetailResponse?.created_at)}</span></p>
                                        {domainDetailResponse?.updated_at !== null && <p>{t("organizationDetailsPage.updatedOn")} <span>{formatDate(domainDetailResponse?.updated_at)}</span></p>}
                                        {domainDetailResponse?.last_scan_detail?.created_at !== null && <p>{t("domainDetailsPage.lastScanOn")} <span>{formatDate(domainDetailResponse?.last_scan_detail?.created_at)}</span></p>}
                                    </div>
                                </div>
                                <div className="d-flex gap-3 mt-3">
                                    <div className="hacker-score-container p-3 rounded text-center">
                                        <p className="hacker-score">{domainDetailResponse?.stats?.score?.latest_score?.overall_score?.obtained_score}<span>/{domainDetailResponse?.stats?.score?.latest_score?.overall_score?.max_score}</span></p>
                                        <p className="hacker-text">{t("organizationDetailsPage.latestHackerScore")}</p>
                                    </div>
                                    <p className="border-right mx-2"></p>
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-user-count">{domainDetailResponse?.stats?.scans?.total}</p>
                                            <div className="user-count mx-3 mt-2">
                                                <span className="active-icon d-inline-block mx-1"></span>
                                                <span className="passive-icon d-inline-block"></span>
                                                <p>{t("domainPage.totalScans")}</p>
                                            </div>
                                        </div>
                                        <div className="active-disabled-count d-flex gap-3">
                                            <p><span className="count d-inline-block">{domainDetailResponse?.stats?.scans?.active}</span> {t("domainPage.activeScans")}</p>
                                            <p><span className="count-passive d-inline-block">{domainDetailResponse?.stats?.scans?.passive}</span> {t("domainPage.passiveScans")}</p>
                                        </div>
                                    </div>
                                    <div className="total-domains-container rounded">
                                        <p className="total-domain">{domainDetailResponse?.stats?.scans?.monitor}</p>
                                        <p className="domain-text">{t("domainDetailsPage.autoSchedule")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="organization-main-container p-3">
                                <div className="orgs-tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "scanHistory" ? "active" : ""}`} onClick={() => setActiveTab("scanHistory")}>{t("domainDetailsPage.scanTab")}</p>
                                    <p className={`rounded text-center ${activeTab === "assets" ? "active" : ""}`} onClick={() => setActiveTab("assets")}>{t("domainDetailsPage.assetTab")}</p>
                                </div>
                                {activeTab === "scanHistory" && <ScanHistoryTab scanHistoryData={domainDetailResponse} currentPageType={"domainDetails"} />}
                                {activeTab === "assets" && <AssetsTab doaminData={domainDetailResponse}/>}
                            </div>
                            <DeleteDomainModal show={modalShow} onHide={() => setModalShow(false)} domainData={domainDetailResponse}/>
                            {showScanProgressModal && (
                                <ScanProgressModal
                                    show={showScanProgressModal}
                                    onHide={closeScanProgressModal}
                                    // scanningDomainId={scanningDomainId}
                                    pausedDomainId={pausedDomainId}
                                    handlePauseResume={handlePauseResume}
                                />
                            )}
                            <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                                <EditDomainModal domainData={domainDetailResponse}/>
                            </SideModal>
                        </div>
                }
            </div>
        </div>
    );
};

export default DomainDetails;
