import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import PlusIcon from "../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../assets/images/common/minus-square.svg";
import PasswordOpenIcon from "../../../../../assets/images/common/eye_white.svg";

import ContentBox from "../../../../../components/ui/ContentBox";
import Spinner from "../../../../../components/ui/spinner";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import Pagination from "../../../../../components/ui/Pagination";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getRansomewareGroup, getRansomweregroupData } from "../../../../../store/actions/scanInfoAction";
import { formatDate } from "../../../../../utils/util";

const RansomewareGroup = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const ransomwareGroupListResponse = useSelector((state) => state.scanInfo?.ransomwareGroupListList);
    const ransomwereGroupDataResponse = useSelector((state) => state.scanInfo?.ransomwereGroupData);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeContent, setActiveContent] = useState("jsSecretsSummary");
    const [selectedRansomware, setSelectedRansomware] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isEndpointLoading, setIsEndpointLoading] = useState(false);
    const [showScreenshotModal, setShowScreenshotModal] = useState(false);
    const [screenShotSrc, setScreenShotSrc] = useState("")

    const showContent = (data) => {  
        if(activeContent === data) {
            setActiveContent("")
        } else {
            setActiveContent(data)
            if(data === "affectedEndpoints") {
                fetchAffectedEndPoints()
            }
        }
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchAffectedEndPoints = useCallback(()=>{
        setIsEndpointLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedRansomware?.id}`;
        dispatch(getRansomweregroupData(url))

    },[dispatch, currentPage, currentPageSize, domainId, scanId, selectedRansomware])

    const fetchRansomwareList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=10&domain=${domainId}&scan_id=${scanId}`
        dispatch(getRansomewareGroup(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchRansomwareList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, scanId, fetchRansomwareList])

    useEffect(()=>{
        if(ransomwareGroupListResponse !== null) {
            setSelectedRansomware(ransomwareGroupListResponse?.results[0]);
        }
    },[ransomwareGroupListResponse])

    useEffect(() => {
        console.log("ransomwereGroupDataResponse", ransomwereGroupDataResponse)
        if(ransomwereGroupDataResponse !== null) {
            setIsEndpointLoading(false)
        }
    },[ransomwereGroupDataResponse])


    return (
        <>
            <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Ransomware Group List</h1>
                                    <img src={FilterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container">
                                    {
                                        ransomwareGroupListResponse?.results.length > 0 &&
                                        ransomwareGroupListResponse?.results.map((data, index) => {
                                            return (
                                                <div className={`sub-domain-list py-2 px-3 ${selectedRansomware?.id === data?.id ? "active" : ""}`} key={index} onClick={()=>{setSelectedRansomware(data)}}>
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <p className="sub-domain-heading">{data?.group_name}</p>
                                                        {data.new_record && <span className="new-subdomain">New</span>}
                                                    </div>                                                    
                                                    <p className="sub-domain-name">{data?.domain}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="main-content bg-white-with-shadow p-2 h-100">
                                <div className="record-container-content p-2">
                                    <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("jsSecretsSummary")}}>
                                        <h2 className="heading">Group Summary</h2>
                                        <img src={activeContent === "jsSecretsSummary" ? MinusIcon : PlusIcon} alt="expand"/>
                                    </div>
                                    {activeContent === "jsSecretsSummary" && <div className="js-secrete-summary mt-3">
                                        <Row>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Group Name"} value={selectedRansomware?.group_name}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Group Description"} value={selectedRansomware?.group_description}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Leak Domain"} value={selectedRansomware?.domain}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Group Link"} value={selectedRansomware?.group_url}/>
                                            </Col>
                                        </Row>
                                    </div> }                                                                   
                                </div>
                                <div className="record-container-content p-2 mt-3">
                                    <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("affectedEndpoints")}}>
                                        <h2 className="heading">Leaked Data</h2>
                                        <img src={activeContent === "affectedEndpoints" ? MinusIcon : PlusIcon} alt="expand"/>
                                    </div>
                                    {activeContent === "affectedEndpoints" && <div className="affected-endpoint-container">
                                        <div className="git-leaks-main-content">
                                            <div className="search-header-container d-flex align-items-center gap-3 my-3">
                                                <div className="search-container position-relative">
                                                    <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                                    <input type="text" placeholder="Search" />
                                                </div>
                                                <button className="search-btn">Search</button>
                                                <img src={FilterIcon} alt="filter" className="filtericon"/>
                                            </div>
                                            <div className="js-secrets-table ransomware-group-table">
                                                {
                                                    isEndpointLoading ?
                                                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <Table responsive="lg">
                                                        <thead>
                                                            <tr>
                                                                <th>Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                ransomwereGroupDataResponse?.results.length > 0 &&
                                                                ransomwereGroupDataResponse?.results.map((data, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="leak-details">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div>
                                                                                        <p className="heading-text mb-2">Leak Title/Domain:</p>
                                                                                        <span className="details">{data?.leak_title}</span> - <span className="details">{data?.leak_domain}</span> {data?.leak_published ? <span className="published">Published</span> : <span className="published">DeadLine - {formatDate(data?.leak_deadline, "date")}</span>}

                                                                                        <p className="heading-text mb-2">Leak Date:</p>
                                                                                        <span className="details">{formatDate(data?.leak_date, "date")}</span>                                                                                        
                                                                                    </div>
                                                                                    <div className="screenshot-image">
                                                                                        <p className="overlay" onClick={()=>{
                                                                                            setScreenShotSrc(data?.leak_screenshot)
                                                                                            setShowScreenshotModal(true);
                                                                                        }}>
                                                                                            <img src={PasswordOpenIcon} alt="open-icon"/>
                                                                                        </p>
                                                                                        <img src={data?.leak_screenshot} alt="screenshot" />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <p className="heading-text my-2">Description:</p>
                                                                                    <span className="details">{data?.leak_description}</span>

                                                                                    <p className="heading-text my-2">Post Url</p>
                                                                                    <a className="text-overflow table-url" href={data?.leak_post_url} target="_blank"  rel="noreferrer">{data?.leak_post_url}</a>

                                                                                    <p className="heading-text my-2">Leak download Link</p>
                                                                                    <span className="details">{(data?.leak_download_url !== null && data?.leak_download_url !== "") ? data?.leak_download_url : "-"}</span>
                                                                            </div> 
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                                {
                                                    !isEndpointLoading && ransomwereGroupDataResponse !== "" && ransomwereGroupDataResponse?.results.length === 0 && 
                                                    <NoDataFound noDataText={"No Record Found"}/>
                                                }
                                                {ransomwereGroupDataResponse !== "" && ransomwereGroupDataResponse?.results.length > 0 && <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    pageSize={currentPageSize}
                                                    totalCount={ransomwereGroupDataResponse?.meta?.total_results}
                                                    onPageChange={setCurrentPageandRecordSize}
                                                />}
                                                
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <Modal
                                    show={showScreenshotModal}
                                    onHide={()=>setShowScreenshotModal(false)}
                                    size="lg"
                                    centered
                                    className=""
                                    >
                                        <Modal.Body>                                
                                            <div className="Screenshot-modal-container">
                                                <div className="p-4 m-2">
                                                    <img src={screenShotSrc} alt="screenshot" />
                                                </div>
                                            </div>
                                        </Modal.Body>
                                </Modal>
                            </div>
                        </Col>
                    </Row>
                </div>
        </>        
    )
}


export default RansomewareGroup;