import { React, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import Button from "../../../ui/Button";

import CheckBox from "../../../ui/CheckBox";
import DatePicker from 'react-datepicker';

import { useSelector, useDispatch } from 'react-redux';
import { generateAPIKey } from '../../../../store/actions/settingsAction.js';

const GenerateAPIKeyModal = ({show, onHide}) => {

    const generateAPIKeyResponse = useSelector((state) => state.settings?.generateAPIKeyResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState(null);
    const [noExpiry, setNoExpiry] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const generateKey = () => {
        let date = selectedDate !== "" ? selectedDate.toISOString().replace("T", " ").split(".")[0] : "";
        let obj = {
            expire_on: date,
            never_expire: noExpiry
        }
        setIsLoading(true)
        dispatch(generateAPIKey(obj));
    }

    useEffect(()=>{
        if(generateAPIKeyResponse != null) {
            setNoExpiry(false);
            setSelectedDate("");
            setIsLoading(false);
        }
    },[generateAPIKeyResponse])

    const CalenderIcon = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66663 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3334 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.91663 7.57471H17.0833" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 11.4167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 13.9167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 11.4167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 13.9167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 11.4167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 13.9167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
    }

    // useEffect(()=>{
    //     if(updateProfileDetailsResponse != null) {
    //         setPassword("")
    //         setIsLoading(false);
    //     }
    // },[updateProfileDetailsResponse, dispatch])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            >
                <Modal.Body>
                    <div className="setup-2fa-container p-3">
                        <h4>{t("accountSettingPage.generateAPIKeyModal.heading")}</h4>
                        <p>{t("accountSettingPage.generateAPIKeyModal.desc")}</p>
                        <div className="my-4 scan-info-datepicker api-key-generate">
                            <label className="expiry-date">Expiry date</label>
                            <div className="mt-3 w-100">
                            <DatePicker
                                showIcon
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                placeholderText="Select a Expiry date"
                                icon={CalenderIcon()}
                                dateFormat="MMM dd, yyyy"
                                minDate={new Date()}
                                className="custom-width-datepicker"
                                disabled={noExpiry}
                            >                                
                            </DatePicker>
                            </div>
                        </div>
                        <div className="no-expiry">
                            <CheckBox inputLabel={"No Expiry Date"} checkBoxValue={noExpiry} isChecked={noExpiry} handleCheckboxChange={(e)=>{setNoExpiry(e.target.checked)}}/>
                        </div>
                        <div className="d-flex gap-3 my-3 mt-5">
                            <Button buttonText={"Cancel"} buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText={"Save"} buttonType={"primary"} buttonClicked={generateKey}/>}
                            {isLoading && <Button buttonText={"Loading..."} buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
            
        </Modal>
    )
}

export default GenerateAPIKeyModal;