import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import PlusIcon from "../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../assets/images/common/minus-square.svg";

import Spinner from "../../../../../components/ui/spinner";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import ContentBox from "../../../../../components/ui/ContentBox";
import Button from "../../../../../components/ui/Button";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getSensitiveExpoList, getSensitiveEndpoints } from "../../../../../store/actions/scanInfoAction";
import { formatDate } from "../../../../../utils/util";

const SensitiveExposure = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const sensitiveExpoListResponse = useSelector((state) => state.scanInfo?.sensitiveExpoList);
    const sensitiveEndpointsListResponse = useSelector((state) => state.scanInfo?.sensitiveEndpointsList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeContent, setActiveContent] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedViewMore, setSelectedViewMore] = useState(true);
    const [selectedSensitiveExpo, setSelectedSensitiveExpo] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndpointLoading, setIsEndpointLoading] = useState(false);
    const [showExtractDataModal, setShowExtractDataModal] = useState(false);
    const [extractedData, setExtractedData] = useState("")


    const showContent = (data) => {  
        if(activeContent === data) {
            setActiveContent("")
        } else {
            setActiveContent(data)
            if(data === "affectedEndpoints") {
                fetchAffectedEndPoints()
            }
        }
    }

    const fetchAffectedEndPoints = useCallback(()=>{
        setIsEndpointLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedSensitiveExpo?.id}`;
        setIsLoading(true);
        dispatch(getSensitiveEndpoints(url))

    },[dispatch, currentPage, currentPageSize, domainId, scanId, selectedSensitiveExpo])

    const fetchSensitiveExpoList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=20&domain=${domainId}&scan_id=${scanId}`
        setIsLoading(true);
        dispatch(getSensitiveExpoList(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
        fetchAffectedEndPoints()
    },[selectedSensitiveExpo, fetchAffectedEndPoints])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchSensitiveExpoList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, scanId, fetchSensitiveExpoList])

    useEffect(()=>{
        if(sensitiveExpoListResponse !== null) {
            setSelectedSensitiveExpo(sensitiveExpoListResponse?.results[0])
            setIsLoading(false);
        }
    },[sensitiveExpoListResponse])

    useEffect(()=>{
        console.log("sensitiveEndpointsListResponse", sensitiveEndpointsListResponse)
        if(sensitiveEndpointsListResponse !== null) {
            setIsEndpointLoading(false)
        }
    },[sensitiveEndpointsListResponse])


    return (
        <>
            <div className="scan-info-main-content sensitive-exposure-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3 h-100">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Severity</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex align-items-baseline">
                                        <p className="total-score-count">52</p>
                                        <div className="score-count mx-3">
                                            <p>Total Risk</p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex align-items-center justify-content-between gap-3">
                                        <p className="critical"><span className="d-inline-block">17</span> Critical</p>
                                        <p className="high"><span className="d-inline-block">20</span> High</p>
                                        <p className="medium"><span className="d-inline-block">10</span> Medium</p>
                                        <p className="low"><span className="d-inline-block">02</span> Low</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3 h-100">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Sensitive Exposure</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-score-count">51</p>
                                        <div className="score-count mx-3">
                                            <span className="new-record d-inline-block mx-1"></span>
                                            <span className="existing-record d-inline-block"></span>
                                            <p>Total Records</p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex gap-3">
                                        <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                        <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page ports-container rounded p-3 h-100">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Status</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-score-count">51</p>
                                        <div className="score-count mx-3">
                                            <span className="new-record d-inline-block mx-1"></span>
                                            <span className="existing-record d-inline-block"></span>
                                            <p>Total Ports</p>
                                        </div>
                                    </div>
                                    <div className="new-exist-count d-flex gap-3">
                                        <p className="new-record"><span className="d-inline-block">17</span> Open</p>
                                        <p className="existing-record"><span className="d-inline-block">16</span> Closed</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-3">
                    <div className="js-secrets-main-content">
                        <Row>
                            <Col lg={3}>
                                <div className="bg-white-with-shadow subdomain-list-container h-100">
                                    <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                        <h1>Sensive Exposure List</h1>
                                        <img src={FilterIcon} alt="filter" className="filter-icon"/>
                                    </div>
                                    <div className="search-container px-3 position-relative">
                                        <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                        <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                    </div>
                                    <div className="list-main-container js-secrets-list sensitive-exposure-list">
                                        {
                                            sensitiveExpoListResponse?.results.length > 0 &&
                                            sensitiveExpoListResponse?.results.map((data, index)=>{
                                                return (
                                                    <div className={`sub-domain-list py-2 px-3 ${selectedSensitiveExpo?.id === data?.id ? "active" : ""}`} key={index} onClick={()=>{setSelectedSensitiveExpo(data)}}>
                                                        <div className="d-flex align-items-center gap-2 mb-2">
                                                            <p className="sub-domain-heading">{data?.name}</p>
                                                            {data.new_record && <span className="new-subdomain">New</span>}
                                                        </div>                                               
                                                        <p className="sub-domain-name">{data?.domain}</p>
                                                        <div className="status-container">
                                                            <p className="severity">Severity:  
                                                                {data?.severity === "critical" && <span className="critical">{data?.severity}</span>}
                                                                {data?.severity === "high" && <span className="high">{data?.severity}</span>}
                                                                {data?.severity === "medium" && <span className="medium">{data?.severity}</span>}
                                                                {data?.severity === "low" && <span className="low">{data?.severity}</span>}
                                                                {data?.severity === "informational" && <span className="informational">{data?.severity}</span>}
                                                            </p>
                                                            <p className="affected-endpoint">Affected Endpoints: <span>{data?.endpoints?.total}</span></p>
                                                        </div>
                                                        <div className="status-container">
                                                            {data.status === "open" && <p className="open">Status: <span>Open</span></p>}
                                                            {data.status === "closed" && <p className="close">Status: <span>Closed</span></p>}
                                                            {data.status === "inprogress" && <p className="inprogress">Status: <span>Inprogress</span></p>}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div className="main-content bg-white-with-shadow p-2 h-100">
                                    <div className="record-container-content p-2">
                                        <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("jsSecretsSummary")}}>
                                            <h2 className="heading">Sensitive Exposure Summary</h2>
                                            <img src={activeContent === "jsSecretsSummary" ? MinusIcon : PlusIcon} alt="expand"/>
                                        </div>
                                        {activeContent === "jsSecretsSummary" && <div className="js-secrete-summary mt-3">
                                            <Row>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"Name"} value={selectedSensitiveExpo?.name}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"Description"} value={selectedSensitiveExpo?.description}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <div className="content-box-container p-3 rounded h-100">
                                                        <h4 className="label">Serverity</h4>
                                                        <p className="serverity">{selectedSensitiveExpo?.severity}</p>
                                                    </div>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <ContentBox label={"Remediation"} value={selectedSensitiveExpo?.remediation}/>
                                                </Col>
                                                <Col lg={6} className="mb-3">
                                                    <div className="content-box-container p-3 rounded h-100">
                                                        <h4 className="label">Reference</h4>
                                                        { selectedSensitiveExpo !== "" && selectedSensitiveExpo?.reference.length > 0  ?
                                                             selectedSensitiveExpo?.reference.map((data, index) => {
                                                                return (
                                                                    <p className="value" key={index}>{data}</p>
                                                                )
                                                            })
                                                            :
                                                            "-"                                                            
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div> }                               
                                    </div>
                                    <div className="record-container-content p-2 mt-3">
                                        <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("affectedEndpoints")}}>
                                            <h2 className="heading">Affected Endpoints</h2>
                                            <img src={activeContent === "affectedEndpoints" ? MinusIcon : PlusIcon} alt="expand"/>
                                        </div>
                                        {activeContent === "affectedEndpoints" && <div className="affected-endpoint-container">
                                            <div className="git-leaks-main-content">
                                                <div className="search-header-container d-flex align-items-center gap-3 my-3">
                                                    <div className="search-container position-relative">
                                                        <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                                        <input type="text" placeholder="Search" />
                                                    </div>
                                                    <button className="search-btn">Search</button>
                                                    <img src={FilterIcon} alt="filter" className="filtericon"/>
                                                </div>
                                                <div className="js-secrets-table">
                                                {
                                                    isEndpointLoading ?
                                                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>URL</th>
                                                                <th>Extracted Data</th>
                                                                <th>Severity</th>
                                                                <th>Status</th>
                                                                <th>First / Last Seen</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                sensitiveEndpointsListResponse?.results.length > 0 &&
                                                                sensitiveEndpointsListResponse?.results.map((data, index)=>{
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="url-container">
                                                                                <a href={data?.url} target="_blank" className="table-url" rel="noreferrer">{data?.url}</a>
                                                                            </td>
                                                                            <td className="extraction-container">
                                                                                {data?.extracted_data !== null && data?.extracted_data !== "" ? <p className="cursor-pointer" onClick={()=>{
                                                                                    setShowExtractDataModal(true)
                                                                                    setExtractedData(data?.extracted_data);
                                                                                }}>view</p>: "-"}
                                                                            </td>
                                                                            <td className="severity-container">
                                                                                {data?.severity === "critical" && <p className="severity critical">{data?.severity}</p>}
                                                                                {data?.severity === "high" && <p className="severity high">{data?.severity}</p>}
                                                                                {data?.severity === "medium "&& <p className="severity medium">{data?.severity}</p>}
                                                                                {data?.severity === "low" && <p className="severity low">{data?.severity}</p>}
                                                                                {data?.severity === "informational" && <p className="severity informational">{data?.severity}</p>}
                                                                            </td>
                                                                            <td className="status-container">
                                                                                <p className="status-close">Closed</p>
                                                                            </td>
                                                                            <td className="first-seen-container">
                                                                                {formatDate(data?.first_seen)} / {formatDate(data?.last_seen)}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                                {
                                                    !isEndpointLoading && sensitiveEndpointsListResponse !== "" && sensitiveEndpointsListResponse?.results.length === 0 && 
                                                    <NoDataFound noDataText={"No Record Found"}/>
                                                }
                                                
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Modal
                            show={showExtractDataModal}
                            onHide={()=>setShowExtractDataModal(false)}
                            size="md"
                            centered
                            className=""
                            >
                                <Modal.Body>                                
                                    <div className="delete-organization-container">
                                        <h4>Extracted Data</h4>
                                        <div className="p-4 m-2">
                                            <p>{extractedData}</p>
                                        </div>
                                        <div className="d-flex justify-content-center gap-3 m-2">
                                            <div className="w-50">                                                
                                                <Button buttonText="Close" buttonType={"secondary"} buttonClicked={()=>setShowExtractDataModal(false)}/>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div> 
        </>
        
    )
}


export default SensitiveExposure;