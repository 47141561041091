import React, { useState } from "react";
import "./index.scss";

const CustomTooltip = ({ children, content, position = "top" }) => {
    const [visible, setVisible] = useState(false);

    const toggleTooltip = (show) => {
        setVisible(show);
    };

    return (
        <div
            className="tooltip-container"
            onMouseEnter={() => toggleTooltip(true)}
            onMouseLeave={() => toggleTooltip(false)}
        >
            {children}
            {visible && (
                <div className={`tooltip-box tooltip-${position}`}>
                    <div className="scrollabel-div">
                        {content}
                    </div>                    
                    <div className="tooltip-arrow"></div>
                </div>
            )}
        </div>
    );
};

export default CustomTooltip;