import { React, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';

import "./index.scss";

import Button from "../../../../components/ui/Button";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import UpdateUserStatusModal from "../UpdateUserStatusModal";

import { formatDate, getUserImageUrl, formatNumber } from "../../../../utils/util";

import { useSelector } from 'react-redux';


const UserListView = ({usersList}) => {

    const updateUserStatusSingleResponse = useSelector((state) => state.users?.updateUserStatusSingleResponse);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [showUpdateUserStatusModal, setShowUpdateUserStatusModal] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(null);

    const updatedUserStatus = (data) => {
        setShowUpdateUserStatusModal(true);
        setSelectedUserData(data)
    }

    useEffect(()=>{
        if(updateUserStatusSingleResponse != null) {
            setShowUpdateUserStatusModal(false)
        }
    },[updateUserStatusSingleResponse])

    return (
        <Row>
            <Col md="12">
                <div className="list-view-container users-list-view-table users-tab-table p-3">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t("users.userListView.users")}</th>
                                <th>{t("users.userListView.status")}</th>
                                <th>{t("users.userListView.role")}</th>
                                <th>{t("users.userListView.totalScan")}</th>
                                <th>{t("users.userListView.totalActiveScan")}</th>
                                <th>{t("users.userListView.totalPassiveScan")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usersList?.results.length > 0 &&
                                usersList.results.map((data, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <ProfileAvatar type="profile" secondaryText={formatDate(data.created_at)} firstName={data.firstname} lastName={data.lastname} imageUrl={getUserImageUrl(data)} />
                                            </td>
                                            <td>
                                                <label className={`user-status ${data.status === "active" ? "active" : "disabled"}`}>{data.status}</label>
                                            </td>
                                            <td>
                                                <p>{data.role}</p>
                                            </td>
                                            <td>
                                                <p>{formatNumber(data?.stats?.scan?.total)}</p>
                                            </td>
                                            <td>
                                                <p>{formatNumber(data?.stats?.scan?.active)}</p>
                                            </td>
                                            <td>
                                                <p>{formatNumber(data?.stats?.scan?.passive)}</p>
                                            </td>
                                            <td>
                                                <div className='organization-list-view d-flex gap-3 users-list-view-btn'>
                                                    {data.status !== "active" &&<button className="view-details-btn active-user w-100" onClick={()=>{updatedUserStatus(data)}}>{t("users.activateuser")}</button>}
                                                    {data.status === "active" && <button className="view-details-btn disable-user w-100" onClick={()=>{updatedUserStatus(data)}}>{t("users.disableUser")}</button>}
                                                    <Button buttonText={"View"} buttonType="primary" buttonClicked={()=>{navigate(`/users/details/${data.user_id}`)}}/>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <UpdateUserStatusModal show={showUpdateUserStatusModal} selectedUserData={selectedUserData} onHide={() => setShowUpdateUserStatusModal(false)}/>
                </div>
            </Col>
        </Row>
    )
}

export default UserListView;