import { getloggedInUserType } from "./utils/util";

const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'

const apiEndpoints = {
    imageUrl: {
        organization: `${process.env.REACT_APP_API_BASE_URL}/avatar/organization/`,
        users: `${process.env.REACT_APP_API_BASE_URL}/avatar/user/`,
        domain: `${process.env.REACT_APP_API_BASE_URL}/avatar/domain/`,
        country: `${process.env.REACT_APP_API_BASE_URL}/avatar/country/`,
        customer: `${process.env.REACT_APP_API_BASE_URL}/avatar/customer/`,
        technologies: `${process.env.REACT_APP_API_BASE_URL}/avatar/technology/`
    },
    common: {
        login: () => `/user/signin?lang=${language}`,
        loggedInUserDetails: () => `/account/me?lang=${language}`,
        forgotPassword: () => `/user/forgot_password?lang=${language}`,
        reset2Fa: () => `/user/2fa/reset?lang=${language}`,
        resetPassword: () => `/user/reset_password?lang=${language}`,
        VerifyAccount: () => `/account/2fa/verify?lang=${language}`,
        reset2FaConfirmation: () => `/user/2fa/reset/confirmation?lang=${language}`
    },
    register: {
        register: () => `/user/signup?lang=${language}`,
        resend: () => `/user/confirmation/resend?lang=${language}`
    },
    customer: {
        customerStats: () => `/${getloggedInUserType()}/customer/stats?lang=${language}`,
        customerList: () => `/${getloggedInUserType()}/customer/list?lang=${language}`,
        addCustomer: () => `/${getloggedInUserType()}/customer/add?lang=${language}`,
        customerDetails: () => `/${getloggedInUserType()}/customer/getbyid?lang=${language}`,
        editCustomer: () => `/${getloggedInUserType()}/customer/edit?lang=${language}`,
        deleteCustomer: () => `/${getloggedInUserType()}/customer/delete/single?lang=${language}`,
        editSubscription: () => `/${getloggedInUserType()}/subscription/edit?lang=${language}`,
        extendSubscription: () => `/${getloggedInUserType()}/subscription/extend?lang=${language}`,
        cancelSubscription: () => `/${getloggedInUserType()}/subscription/cancel?lang=${language}`,
        premissionDetails: () => `/${getloggedInUserType()}/domain/customer/permission?lang=${language}`,
        editDomainPermission: () => `/${getloggedInUserType()}/domain/customer/permission/edit?lang=${language}`,
        activateCustomerSingle: () => `/${getloggedInUserType()}/customer/activate/single?lang=${language}`,
        deActivateCustomerSingle: () => `/${getloggedInUserType()}/customer/deactivate/single?lang=${language}`,
        assignDomainToCustomer: () => `/${getloggedInUserType()}/customer/domain/assign/multiple?lang=${language}`,
        unAssignDomainToCustomer: () => `/${getloggedInUserType()}/customer/domain/revoke/multiple?lang=${language}`,
        assignUsersToCustomer: () => `/${getloggedInUserType()}/customer/user/assign/multiple?lang=${language}`,
        unAssignUsersToCustomer: () => `/${getloggedInUserType()}/customer/user/revoke/multiple?lang=${language}`,
        assignDomainToOrganization: () => `/${getloggedInUserType()}/organization/domain/assign/multiple?lang=${language}`,
        unAssignDomainToOrganization: () => `/${getloggedInUserType()}/organization/domain/revoke/multiple?lang=${language}`,
        assignUserToOrganization: () => `/${getloggedInUserType()}/organization/user/assign/multiple?lang=${language}`,
        unAssignUserToOrganization: () => `/${getloggedInUserType()}/organization/user/revoke/multiple?lang=${language}`,        
    },
    organizations: {
        organizationStats: () => `/${getloggedInUserType()}/organization/stats?lang=${language}`,
        organizationList: () => `/${getloggedInUserType()}/organization/list?lang=${language}`,
        addOrganization: () => `/${getloggedInUserType()}/organization/add?lang=${language}`,
        organizationDetails: () => `/${getloggedInUserType()}/organization/getbyid?lang=${language}`,
        updateOrganizationDetail: () => `/${getloggedInUserType()}/organization/edit?lang=${language}`,
        deleteOrganization: () => `/${getloggedInUserType()}/organization/delete/single?lang=${language}`
    },
    users: {
        userStats: () => `/${getloggedInUserType()}/user/stats?lang=${language}`,
        usersList: () => `/${getloggedInUserType()}/user/list?lang=${language}`,
        assignUser: () => `/${getloggedInUserType()}/user/organization/assign/multiple?lang=${language}`,
        unAssignUser: () => `/${getloggedInUserType()}/user/organization/revoke/multiple?lang=${language}`,
        userDetails: () => `/${getloggedInUserType()}/user/getbyid?lang=${language}`,
        inviteUserSingle: () => `/${getloggedInUserType()}/user/invite?lang=${language}`,
        editUser: () => `/${getloggedInUserType()}/user/edit?lang=${language}`,
        userSessionById: () => `/${getloggedInUserType()}/user/sessions/getbyuserid?lang=${language}`,
        deleteUserSingle: () => `/${getloggedInUserType()}/user/delete/single?lang=${language}`,
        activateUserSingle: () => `/${getloggedInUserType()}/user/activate/single?lang=${language}`,
        disableUserSingle: () => `/${getloggedInUserType()}/user/deactivate/single?lang=${language}`,
        assignOrganizationTouser: () => `/${getloggedInUserType()}/user/organization/assign/multiple?lang=${language}`,
        unAssignOrganizationToUser: () => `/${getloggedInUserType()}/user/organization/revoke/multiple?lang=${language}`
    },
    domains: {
        domainStats: () => `/${getloggedInUserType()}/domain/stats?lang=${language}`,
        domainList: () => `/${getloggedInUserType()}/domain/list?lang=${language}`,
        addDoamin: () => `/${getloggedInUserType()}/domain/add?lang=${language}`,
        assignDomain: () => `/${getloggedInUserType()}/domain/organization/assign/multiple?lang=${language}`,
        unAssignDomain: () => `/${getloggedInUserType()}/domain/organization/revoke/multiple?lang=${language}`,
        domainDetails: () => `/${getloggedInUserType()}/domain/getbydomain?lang=${language}`,
        deleteDomain: () => `/${getloggedInUserType()}/domain/delete/single?lang=${language}`,
        editDoamin: () => `/${getloggedInUserType()}/domain/edit?lang=${language}`
    },
    scan: {
        scanQueueStats: () => `/${getloggedInUserType()}/scan/stats?lang=${language}`,
        stratScan: () => `/${getloggedInUserType()}/scan/start/single?lang=${language}`,
        scanQueueList: () => `/${getloggedInUserType()}/scan/queue/list?lang=${language}`,
        stopScanSingle: () => `/${getloggedInUserType()}/scan/stop/single?lang=${language}`,
        resumeScanSingle: () => `/${getloggedInUserType()}/scan/resume/single?lang=${language}`,
        stopScanMultiple: () => `/${getloggedInUserType()}/scan/stop/multiple?lang=${language}`,
        deleteScanSingle: () => `/${getloggedInUserType()}/scan/delete/single?lang=${language}`,
        scanHistory: () => `/${getloggedInUserType()}/scan/history/list?lang=${language}`
    },
    assestInventory: {
        subDomainList: () => `/${getloggedInUserType()}/inventory/subdomain/list?lang=${language}`,
        ipAddressList: () => `/${getloggedInUserType()}/inventory/ip_address/list?lang=${language}`,
        emailList: () => `/${getloggedInUserType()}/inventory/email/list?lang=${language}`,
        subDomainInclude: () => `/${getloggedInUserType()}/inventory/subdomain/include/multiple?lang=${language}`,
        subDomainExclude: () => `/${getloggedInUserType()}/inventory/subdomain/exclude/multiple?lang=${language}`,
        ipAddressInclude: () => `/${getloggedInUserType()}/inventory/ip_address/include/multiple?lang=${language}`,
        ipAddressExclude: () =>  `/${getloggedInUserType()}/inventory/ip_address/exclude/multiple?lang=${language}`,
        emailInclude: () => `/${getloggedInUserType()}/inventory/email/include/multiple?lang=${language}`,
        emailExclude: () => `/${getloggedInUserType()}/inventory/email/exclude/multiple?lang=${language}`
    },
    settings: {
        timeZone: () => `/timezones?lang=${language}`,
        updateDetails: () => `/account/update?lang=${language}`,
        generateAPIKey: () => `/account/api_key/generate?lang=${language}`,
        generate2FaQr: () => `/account/2fa/setup?lang=${language}`,
        enable2Fa: () => `/account/2fa/enable?lang=${language}`,
        disable2Fa: () => `/account/2fa/disable?lang=${language}`,
        userSession: () => `/account/sessions?lang=${language}`,
        deleteSession: () => `/account/session/destroy/single?lang=${language}`,
        logOut: () => `/user/signout?lang=${language}`
    },
    monitor: {
        monitorStats: () => `/${getloggedInUserType()}/monitor/stats?lang=${language}`,
        monitorList: () => `/${getloggedInUserType()}/monitor/list?lang=${language}`,
        addMonitorSingle: () => `/${getloggedInUserType()}/monitor/add/single?lang=${language}`,
        monitorDetail: () => `/${getloggedInUserType()}/monitor/getbyid?lang=${language}`,
        editMonitor: () => `/${getloggedInUserType()}/monitor/edit/single?lang=${language}`,
        deleteMonitor: () => `/${getloggedInUserType()}/monitor/delete/single?lang=${language}`,
        customersList: () => `/${getloggedInUserType()}/monitor/customer/list?lang=${language}`,
        assignCustomerToMonitor: () => `/${getloggedInUserType()}/monitor/customer/assign/multiple?lang=${language}`,
        unAssignCustomerToMonitor: () => `/${getloggedInUserType()}/monitor/customer/revoke/multiple?lang=${language}`
    },
    scanInfo: {
        exposureManagemnet: {
            assestDiscovery : {
                domainInfo : {
                    whois: () => `/${getloggedInUserType()}/exposure_management/whois?lang=${language}`,
                },
                subDomain: {
                    subDomainList: () => `/${getloggedInUserType()}/exposure_management/subdomain/list?lang=${language}`,
                    aRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/a_records?lang=${language}`,
                    aaaRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/aaaa_records?lang=${language}`,
                    cnameRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/cname_records?lang=${language}`,
                    mxRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/mx_records?lang=${language}`,
                    nsRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/ns_records?lang=${language}`,
                    soaRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/soa_records?lang=${language}`,
                    txtRecord: () => `/${getloggedInUserType()}/exposure_management/subdomain/txt_records?lang=${language}`,
                    Technologies: () => `/${getloggedInUserType()}/exposure_management/subdomain/technologies?lang=${language}`,
                    sslCertificate: () => `/${getloggedInUserType()}/exposure_management/subdomain/ssl_certificates?lang=${language}`
                },
                ipAddress: {
                    ipAddressList: () => `/${getloggedInUserType()}/exposure_management/ip_address/list?lang=${language}`,
                    subDomain: () => `/${getloggedInUserType()}/exposure_management/ip_address/subdomains?lang=${language}`,
                    portList: () => `/${getloggedInUserType()}/exposure_management/ip_address/ports?lang=${language}`,
                    sslCertificate: () => `/${getloggedInUserType()}/exposure_management/ip_address/ssl_certificates?lang=${language}`
                },
                dnsRecord: {
                    aRecord: () => `/${getloggedInUserType()}/exposure_management/a_record/list?lang=${language}`,
                    aaaRecord: () => `/${getloggedInUserType()}/exposure_management/aaaa_record/list?lang=${language}`,
                    cnameRecord: () => `/${getloggedInUserType()}/exposure_management/cname_record/list?lang=${language}`,
                    mxRecord: () => `/${getloggedInUserType()}/exposure_management/mx_record/list?lang=${language}`,
                    nsRecord: () => `/${getloggedInUserType()}/exposure_management/ns_record/list?lang=${language}`,
                    soaRecord: () => `/${getloggedInUserType()}/exposure_management/soa_record/list?lang=${language}`,
                    txtRecord: () => `/${getloggedInUserType()}/exposure_management/txt_record/list?lang=${language}`
                },
                technology: {
                    technologyList: () => `/${getloggedInUserType()}/exposure_management/technology/list?lang=${language}`,
                    endPoints: () => `/${getloggedInUserType()}/exposure_management/technology/endpoints?lang=${language}`
                },
                sslCertificate: {
                    sslCertificateList: () => `/${getloggedInUserType()}/exposure_management/ssl_certificate/list?lang=${language}`,
                    endPoints: () => `/${getloggedInUserType()}/exposure_management/ssl_certificate/endpoints?lang=${language}`
                },
                MobileApps: {
                    mobileAppsList: () => `/${getloggedInUserType()}/exposure_management/mobile_application/list?lang=${language}`
                }
            },
            corporateEmailRisk: {
                cerStats: () => `/${getloggedInUserType()}/exposure_management/bec?lang=${language}`,
                dmarcCheckList: () => `/${getloggedInUserType()}/exposure_management/bec/dmarc?lang=${language}`,
                spfCheckList: () => `/${getloggedInUserType()}/exposure_management/bec/spf?lang=${language}`,
                dkimCheckList: () => `/${getloggedInUserType()}/exposure_management/bec/dkim?lang=${language}`,
                bimiCheckList: () => `/${getloggedInUserType()}/exposure_management/bec/bimi?lang=${language}`
            },
            concerningExposure: {
                gitLeaks: () => `/${getloggedInUserType()}/exposure_management/gitleak/list?lang=${language}`,
                jsSecretsList: () => `/${getloggedInUserType()}/exposure_management/js_secret/list?lang=${language}`,
                jsSecretsEndpoint: () => `/${getloggedInUserType()}/exposure_management/js_secret/endpoints?lang=${language}`,
                sensitiveExpoList: () => `/${getloggedInUserType()}/exposure_management/sensitive_exposure/list?lang=${language}`,
                sensitiveEndpoints: () => `/${getloggedInUserType()}/exposure_management/sensitive_exposure/endpoints?lang=${language}`
            },
            darkWeb: {
                leakedCredentialsList: () => `/${getloggedInUserType()}/exposure_management/leaked_credential/list?lang=${language}`,
                leakedCredentialsData: () => `/${getloggedInUserType()}/exposure_management/leaked_credential/credentials?lang=${language}`,
                botnetLeaksList: () => `/${getloggedInUserType()}/exposure_management/botnet_leak/list?lang=${language}`,
                botnetLeakData: () => `/${getloggedInUserType()}/exposure_management/botnet_leak/credentials?lang=${language}`,
                ransomwareGroupList: () => `/${getloggedInUserType()}/exposure_management/ransomware_group/list?lang=${language}`,
                ransomwareGroupLeaks: () => `/${getloggedInUserType()}/exposure_management/ransomware_group/leaks?lang=${language}`
            }
        }
    }
};

export default apiEndpoints;