import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

import ARecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/ARecord";
import AAARecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/AAARecord";
import CNAMERecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/CNAMERecord";
import MXRecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/MXRecord";
import NSRecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/NSRecord";
import SOARecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/SOARecord";
import TXTRecord from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DNSRecord/TXTRecord";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

const DNSRecords = () => {

    const { domainId, scanId } = useScanInfoContext();

    const [activeTab, setActiveTab] = useState("aRecord");


    return (
        <>
            <div className="scan-info-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="sub-domain-type-container web-server-type-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">DNS Records Type</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-records-container">
                                    <p className="content">370 <span>Total Records</span></p>
                                </div>
                                <div className="sub-domain-content mt-3">
                                    <Row>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">A</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">100</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">AAA</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">32</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">CNAME</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">12</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">NS</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3 px-0">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">23</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">MX</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">20</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">SOA</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">100</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={2} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-type">TXT</p>
                                            </div>                                        
                                        </Col>
                                        <Col lg={1} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="count-value">24</p>
                                            </div>                                        
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container associated-doamin-page rounded p-3 h-100">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">DNS Record</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">402</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <Row>                        
                        <Col lg={12}>
                            <div className="bg-white-with-shadow sub-domain-main-container p-3 h-100">
                                <div className="tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "aRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('aRecord')}}>A Record</p>
                                    <p className={`rounded text-center ${activeTab === "aaaRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('aaaRecord')}}>AAA Record</p>
                                    <p className={`rounded text-center ${activeTab === "cnameRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('cnameRecord')}}>CNAME Record</p>
                                    <p className={`rounded text-center ${activeTab === "mxRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('mxRecord')}}>MX Record</p>
                                    <p className={`rounded text-center ${activeTab === "nsRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('nsRecord')}}>NS Record</p>
                                    <p className={`rounded text-center ${activeTab === "soaRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('soaRecord')}}>SOA Record</p>
                                    <p className={`rounded text-center ${activeTab === "txtRecord" ? "active" : ""}`} onClick={()=>{setActiveTab('txtRecord')}}>TXT Record</p>
                                </div>
                                <div className="tab-details-content mt-3">
                                    {activeTab === "aRecord" && <ARecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"} /> }
                                    {activeTab === "aaaRecord" && <AAARecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"} /> }
                                    {activeTab === "cnameRecord" && <CNAMERecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"} /> }
                                    {activeTab === "mxRecord" && <MXRecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"} /> }
                                    {activeTab === "nsRecord" && <NSRecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"} /> }
                                    {activeTab === "soaRecord" && <SOARecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"} /> }
                                    {activeTab === "txtRecord" && <TXTRecord domainId={domainId} scanId={scanId} currentPageType={"dnsRecord"}/> }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
        
    )
}


export default DNSRecords;