import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";

import SideModal from '../../components/ui/SideModal';
import Pagination from '../../components/ui/Pagination';
import Spinner from "../../components/ui/spinner";
import NoDataFound from "../../components/ui/NoDataFound";
import DropDown from "../../components/ui/DropDown";
import { toaster } from "../../components/ui/Toaster";

import SideNav from "../../components/PostLogin/SideNav";
import OrganizationTileView from '../../components/PostLogin/Organization/OrganizationTileView';
import OrganizationListView from "../../components/PostLogin/Organization/OrganizationListView";
import AddOrganizationModal from '../../components/PostLogin/Organization/AddOrganizationModel';

import { getloggedInUserType } from "../../utils/util.js";

import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationStats, getOrganizationList } from '../../store/actions/organizationActions.js';
import { getCustomersList } from '../../store/actions/customerAction.js';

const Organization = () => {

    const organizationStatsResponse = useSelector((state) => state.organization?.organizationStatsResponse);
    const organizationListResponse = useSelector((state) => state.organization?.organizationList);
    const deleteOrganizationResponse = useSelector((state)=> state.organization?.deleteOrganizationResponse);
    const customersListResponse = useSelector((state) => state.customer?.customersListResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [viewType, setViewType] = useState('tile');
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [customersList, setCustomersList] = useState([]);
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }

    const customerChange = (value) => {
        setSelectedCustomer(value);
        setCurrentPage(1);
        setCurrentPageSize(10);
    }
    
    const searchOrganization = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setCurrentPage(1);
    }

    const fetchOrganizations = useCallback(() => {
        const userType = getloggedInUserType();
    
        if (userType === "internal" && selectedCustomer && Object.keys(selectedCustomer).length > 0) {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&customer_id=${selectedCustomer?.value}&query=${searchValue}`;
            setIsLoading(true);
            dispatch(getOrganizationList(url));
        } else if (userType !== "internal") {
            const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${searchValue}`;
            setIsLoading(true);
            dispatch(getOrganizationList(url));
        }
    }, [searchValue, currentPage, currentPageSize, dispatch, selectedCustomer]);

    const fetchCustomersList = useCallback(()=>{
        dispatch(getCustomersList());
    },[dispatch])

    const fetchOrganizationStats = useCallback(()=>{
        setIsStatsLoading(false);
        dispatch(getOrganizationStats())
    },[dispatch])
    

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const backToMainPage = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            setCurrentPage(1);
            setCurrentPageSize(10);
            fetchOrganizations()
        }
        closeAddOrgSidePanel()
    }

    useEffect(() => {
        const userType = getloggedInUserType();
        if (userType === "internal" && Object.keys(selectedCustomer).length === 0) {
            fetchCustomersList();
        } else if (userType !== "internal") {
            fetchOrganizations();
        }
    }, [fetchOrganizations, selectedCustomer, fetchCustomersList]);

    useEffect(() => {
        if(organizationListResponse!= null) {
            setIsLoading(false)
        }
    }, [organizationListResponse]);

    useEffect(() => {
        if(deleteOrganizationResponse !== null) {
            if (deleteOrganizationResponse?.detail?.status === "success") {
                toaster.addToast(deleteOrganizationResponse?.detail?.description, "success");
                fetchOrganizations();
            } else {
                toaster.addToast(deleteOrganizationResponse?.description, "error");
            }
        }
    }, [deleteOrganizationResponse, fetchOrganizations]);

    useEffect(() => {
        if (customersListResponse) {
            const list = customersListResponse.results.map((data) => ({
                id: data.customer_id,
                name: data.name,
                value: data.customer_id,
                logo: data.logo,
                label: data.name
            }));
            setCustomersList(list);
            if (Object.keys(selectedCustomer).length === 0) {
                // Set the first customer by default only if no customer is selected
                setSelectedCustomer(list[0]);
            }
        }
    }, [customersListResponse, selectedCustomer]);

    useEffect(() => {
        if (Object.keys(selectedCustomer).length > 0) {
            fetchOrganizations();
        }
        if(getloggedInUserType() === "customer") {
            fetchOrganizationStats()
        }        
    }, [selectedCustomer, fetchOrganizations, fetchOrganizationStats]);

    useEffect(() => {
        if(organizationStatsResponse !== null) {
            setIsStatsLoading(false)
        }
    },[organizationStatsResponse])

   
    return (
        <div className="organization-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("organizationPage.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={()=>{setShowAddOrgSidePanel(true)}}><span>&#43;</span>{t("organizationPage.addOrganization")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container d-flex align-items-center gap-4 organization-page-customer-list-dropdown">
                                             
                        {getloggedInUserType() === "internal" &&
                            <>
                                <div className="customer-view">
                                    <p>Select Customer</p>
                                    <DropDown
                                        options={customersList}
                                        placeHolder={"Select Customer"}
                                        onChange={customerChange}
                                        isSearchable= {true}
                                        isMulti={false}
                                        dropDownType = {'scanInfo'}
                                        imageType={"customer"}
                                        defaultSelectedValue={selectedCustomer.value}
                                    />
                                </div>
                                <p className="border-right"></p>
                            </>
                            
                        }
                        <div className="search-container position-relative">
                            <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                            <input type='text' placeholder='Search Organization' className="search-input" value={searchValue} onChange={searchOrganization}/>
                        </div>
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={()=>setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={()=>setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    {getloggedInUserType() === "customer" && <div className="total-domain-scans d-flex mt-3 rounded">                        
                        <div className="total-domains-container rounded p-3">
                            <div className='domain-icon'>
                                <h4 className="m-0 mt-1">{isStatsLoading ? "...." : organizationStatsResponse?.organizations?.total}</h4>
                                <div className="user-count mx-3 mt-2">
                                    <span className='active-icon d-inline-block'></span>
                                    <span className='passive-icon d-inline-block'></span>
                                    <p className='total-scans-text'>{t('organizationPage.totalOrganization')}</p>
                                </div>
                            </div>
                            <div className="p-wrapper d-flex justify-content-between gap-3">
                                <p className='mt-2'><span className='count d-inline-block'>{organizationStatsResponse?.organizations?.internal}</span>{t('organizationPage.internal')}</p>
                                <p className='mt-2'><span className='count-passive'>{organizationStatsResponse?.organizations?.portfolio}</span>{t('organizationPage.portfolio')}</p>
                            </div>
                        </div> 
                        <div className="total-domains-container rounded p-3 margin-right">
                            <h4 className="m-0 mt-1">{isStatsLoading ? "...." : organizationStatsResponse?.organizations?.third_party}</h4>
                            <p className='mt-2'>{t('organizationPage.thirdParty')}</p>
                        </div>                       
                    </div>}
                    <h2>{t("organizationPage.pageHeading")}</h2>
                    {
                        isLoading ? 
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                        :
                        <>
                            {viewType === 'tile' && organizationListResponse?.results.length > 0 && <OrganizationTileView organizationList={organizationListResponse} selectedCustomer={selectedCustomer}/>}
                            {viewType === 'list' && organizationListResponse?.results.length > 0 && <OrganizationListView organizationList={organizationListResponse} selectedCustomer={selectedCustomer}/>}
                            {organizationListResponse != null && organizationListResponse?.results.length > 0 && <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                pageSize={currentPageSize}
                                totalCount={organizationListResponse.meta.total_results}
                                onPageChange={setCurrentPageandRecordSize}
                            />}
                        </>
                    }
                    {
                        !isLoading && organizationListResponse?.meta?.total_results === 0 && 
                        <NoDataFound />
                    }                    
                </div>
            </div>
            <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                <AddOrganizationModal backToMainPage={backToMainPage} selectedCustomer={selectedCustomer} />
            </SideModal>
        </div>
    )
}

export default Organization;