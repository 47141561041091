import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './index.scss';

import OrganizationDummyImage from '../../../../../assets/images/common/organization-dummy-image1.png';
import move from '../../../../../assets/images/common/fi_move.svg';
import Gauge from './Gauge';
import ProfileAvatar from '../../../../../components/ui/ProfileAvatar';

const OrganizationHacker = () => {
    const tooltipContent = () => (
        <div className="d-flex align-items-center gap-2 tooltip-logo">
            <img src={OrganizationDummyImage} alt="logo" />
            <p>Google</p>
            <span>600</span>
        </div>
    );

    return (
        <>
            <div className="bg-white-with-shadow p-4 mt-4">
                <Row>
                    <Col lg={12}>
                        <div className="d-flex justify-content-between mb-2">
                            <p className="heading font-bold">Organization Hacker Score</p>
                            <p className="view-all">
                                {/* <img src={move} alt="search" className="position-absolute search-icon" /> */}
                            </p>
                        </div>
                        <Row className="g-2"> {/* No gaps between columns */}
                            <Col lg={2} className="border-right-custom">
                                <div className="dashboard-type-container d-flex flex-column align-items-center justify-content-center rounded p-4 custom-height">
                                    <p className="text-center mb-2 font-bold">Overall score</p>
                                    <div className="total-records-container text-center">
                                        <p className="count-content mb-0">900</p>
                                        <p className="mb-2">Hacker score</p>
                                        <Gauge />
                                    </div>
                                </div>
                            </Col>

                            <Col lg={2} className="custom-width">
                                <div className="dashboard-type-container rounded p-3 bg-critical custom-height">
                                    <p className="mb-2 font-bold">Critical (0-250)</p>
                                    <div className="total-records-container">
                                        <p className="content">07 <span>Domains</span></p>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2 ">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                        <p className="more">+3 More</p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={2} className="custom-width">
                                <div className="dashboard-type-container rounded p-3 bg-high custom-height">
                                    <p className="mb-2 font-bold">High (251-500)</p>
                                    <div className="total-records-container">
                                        <p className="content">00 <span>Domains</span></p>
                                    </div>
                                    <div className="d-flex flex-row align-items-center justify-content-between rounded p-2">
                                        <p>-</p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={2} className="custom-width">
                                <div className="dashboard-type-container rounded p-3 bg-medium custom-height">
                                    <p className="mb-2 font-bold">Medium (501-800)</p>
                                    <div className="total-records-container">
                                        <p className="content">03 <span>Domains</span></p>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={2} className="custom-width">
                                <div className="dashboard-type-container rounded p-3 bg-low custom-height">
                                    <p className="mb-2 font-bold">Low (801-900)</p>
                                    <div className="total-records-container">
                                        <p className="content">02 <span>Domains</span></p>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between rounded py-2">
                                            <img
                                                src={OrganizationDummyImage}
                                                alt="lollipop"
                                                width="20px"
                                            />
                                            <p className='text-profile'>Lollipop.design</p>
                                            <p className="mb-0 ms-3 font-bold">100</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default OrganizationHacker;
