const initialState = {
    scanQueueStatsResponse: null,
    scanQueueList: null,
    stopScanSingleResponse: null,
    resumeScanSingleResponse: null,
    stopScanMultipleResponse: null,
    deleteScanSingleResponse: null,
};
  
export default function scanQueueReducer(state = initialState, action) {
    switch (action.type) {
        case 'SAN_QUEUE_STATS_SUCCESS':
            return {
                ...state,
                scanQueueStatsResponse: action.payload
            }
        case 'SCAN_QUEUE_LIST_SUCCESS':
            return {
              ...state,
              scanQueueList: action.payload,
            };
        case 'STOP_SCAN_SINGLE_SUCCESS':
            return {
                ...state,
                stopScanSingleResponse: action.payload
            }
        case 'RESUME_SCAN_SINGLE_SUCCESS':
            return {
                ...state,
                resumeScanSingleResponse: action.payload
            }
        case 'STOP_SCAN_MULTIPLE_SUCCESS':
            return {
                ...state,
                stopScanMultipleResponse: action.payload
            }
        case 'DELETE_SCAN_SINGLE_SUCCESS':
            return {
                ...state,
                deleteScanSingleResponse: action.payload
            }
        case 'RESET_STOP_SCAN_SINGLE_RESPONSE':
            return {
                ...state,
                stopScanSingleResponse: null
            }
        case 'RESET_RESUME_SCAN_RESPONSE':
            return {
                ...state,
                resumeScanSingleResponse: null
            }
        case 'RESET_DELETE_SCAN_RESPONSE':
            return {
                ...state,
                deleteScanSingleResponse: null
            }
        default:
            return state;
    }
}