import { React, useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import MonitorTab from "../../components/PostLogin/Monitor/MonitorTab";
import ScanHistoryTab from "../../components/PostLogin/UserDetails/ScanHistory";
import SideModal from "../../components/ui/SideModal";
import Spinner from "../../components/ui/spinner";

import AddMonitorModal from "../../components/PostLogin/Monitor/AddMonitorModal"
import DeleteMonitorModal from "../../components/PostLogin/Monitor/DeleteMonitorModal";

import { formatDate, formatNumber, getDomainImageUrl, getloggedInUserType, getUserImageUrl } from "../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { getMonitorDetails } from '../../store/actions/monitorAction';

const MonitorDetails = () => {

    const monitorDetailsResponse = useSelector((state) => state.monitor?.monitorDetailsResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { monitorId } = useParams();

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("customers");
    const [showAddMonitorModal, setShowAddMonitorModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddMonitorModal(false)
    }

    const backtoMainPage = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            fetchMonitorDetails();
        }
        closeAddOrgSidePanel()
    }

    const fetchMonitorDetails = useCallback(()=>{
        let obj = {
            monitor_id: monitorId
        }
        setIsLoading(true);
        dispatch(getMonitorDetails(obj));
    },[dispatch, monitorId])

    useEffect(()=>{
        fetchMonitorDetails()
    },[fetchMonitorDetails])

    useEffect(()=>{
        if(monitorDetailsResponse != null){
            setIsLoading(false);
        }
    },[monitorDetailsResponse])



    return (
        <div className="organization-details-container monitor-details-container">
            <SideNav />
            <div className="organization-page-container users-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1 onClick={()=>{navigate('/monitor')}}>Monitor</h1>
                        <img src={arrowRight} alt="arrow-right"/>
                        {monitorDetailsResponse !== null && <ProfileAvatar type="profile" firstName={monitorDetailsResponse?.monitor_domain?.domain} imageUrl={getDomainImageUrl(monitorDetailsResponse?.monitor_domain)} />}
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button onClick={()=>{setShowAddMonitorModal(true)}}>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            Edit
                        </button>
                        <button onClick={()=>{setModalShow(true)}}>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            Delete
                        </button>
                    </div>
                </div>
                {
                    isLoading ?
                    <div className="d-flex align-items-center justify-content-center orgaization-loader">
                        <Spinner />
                    </div>
                    :
                    <div className="page-main-section p-4">
                        <div className="organization-created d-flex justify-content-between">
                            <h4>Monitor Details</h4>
                            {monitorDetailsResponse !== null && <ProfileAvatar type="profile" pageType={"organizationDetails"} firstName={monitorDetailsResponse?.created_by?.name} lastName={""}  imageUrl={getUserImageUrl(monitorDetailsResponse?.created_by)} />}
                        </div>
                        <div className="user-header-container p-3 my-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="header-details d-flex gap-3">
                                    <p>Created on: <span>{formatDate(monitorDetailsResponse?.created_at)}</span></p>
                                </div>
                                <div className="user-role-status d-flex gap-3">
                                    <p className="d-flex align-items-center"><span className="d-inline-block mx-1"></span><span className='grey'>Monitor Type:</span> {monitorDetailsResponse?.monitor_type}</p>
                                    <p className="d-flex align-items-center"><span className="d-inline-block mx-1"></span>Scan Type:  <span className='active'> {monitorDetailsResponse?.scan_type}</span></p>
                                </div>
                            </div>
                            <div className="d-flex gap-3 mt-3">
                                <div className="total-domain-scans mb-0">
                                    <div className="total-domains-container m-0 rounded p-3">
                                        <div className='domain-icon'>
                                            <h4 className="m-0 mt-1">{monitorDetailsResponse?.stats?.scans?.total}</h4>
                                            <div className="user-count mx-3 mt-2">
                                                <span className='active-icon d-inline-block'></span>
                                                <span className='passive-icon d-inline-block'></span>
                                                <p className='total-scans-text'>{t("domainPage.totalScans")}</p>
                                            </div>
                                        </div>
                                        <div className="p-wrapper d-flex justify-content-between gap-3">
                                            <p className='mt-2'><span className='count d-inline-block'>{monitorDetailsResponse?.stats?.scans?.active}</span>{t("domainPage.activeScans")}</p>
                                            <p className='mt-2'><span className='count-passive'>{monitorDetailsResponse?.stats?.scans?.passive}</span>{t("domainPage.passiveScans")}</p>
                                        </div>
                                    </div>
                                </div>                            
                                <div className="total-domains-container rounded">
                                    <p className="total-domain">{formatNumber(monitorDetailsResponse?.stats?.monitor_by?.customer)}</p>
                                    <p className="domain-text">Total Customers</p>
                                </div>
                            </div>
                        </div>
                        <div className="organization-main-container p-3">
                            {getloggedInUserType() === "internal" ?
                                <>
                                    <div className="orgs-tab-container d-flex rounded">
                                        <p className={`rounded text-center ${activeTab === "customers" ? "active" : ""}`} onClick={()=>setActiveTab("customers")}>Customers</p>
                                        <p className={`rounded text-center ${activeTab === "scanHistory" ? "active" : ""}`} onClick={()=>setActiveTab("scanHistory")}>Scan History</p>
                                    </div>
                                    {activeTab === "customers" && <MonitorTab monitorData={monitorDetailsResponse} />}
                                    {activeTab === "scanHistory" && <ScanHistoryTab scanHistoryData={monitorDetailsResponse} currentPageType={"monitorDetails"} />}
                                </>
                                :
                                <>
                                    <div className="orgs-tab-container d-flex rounded">                                        
                                        <p className="rounded text-center active">Scan History</p>
                                    </div>
                                    <ScanHistoryTab scanHistoryData={monitorDetailsResponse} currentPageType={"monitorDetails"} />
                                </>
                            }
                            
                        </div>
                        <SideModal showSidePanel={showAddMonitorModal} hideSidePanel={closeAddOrgSidePanel}>
                            <AddMonitorModal closeAddmonitorModal={backtoMainPage} type={"edit"} monitorDetails={monitorDetailsResponse} />
                        </SideModal>

                        <DeleteMonitorModal show={modalShow} monitorDetails={monitorDetailsResponse} onHide={() => setModalShow(false)}/>
                    </div>
                }                
            </div>
        </div>
    )
}

export default MonitorDetails