import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";


import Button from "../../../ui/Button";
import DropDown from '../../../ui/DropDown';
import RadioButton from "../../../ui/RadioButton";

import { getloggedInUserType } from "../../../../utils/util.js";

import { useSelector, useDispatch } from 'react-redux';
import { assignAndUnAssign} from '../../../../store/actions/organizationActions';

import apiEndpoints from "../../../../apiEndpoints.js";

const ModifyDomain = ({selectedTab, clsoeModifyModal, customerDetails="", assignedDomain, assignedUsers, currentPageType}) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { organizationId, customerId } = useParams();

    const usersListResponse = useSelector((state) => state.organization?.usersList);
    const domainListResponse = useSelector((state) => state.organization?.domainList);
    const assignUnAssignResponse = useSelector((state) => state.organization?.assignUnAssignResponse)

    const [responseStatus, setResponseStatus] = useState(false);
    const [activeTab, setActiveTab] = useState('assign');
    const [selectedUser, setSelectedUser] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [assignedUsersList, setAssignedUsersList] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const [assignedDomainList, setAssignedDomainList] = useState([])
    const [selectedDomain, setSelectedDomain] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resetAssignUserSelectedList, setResetAssignUserSelectedList] = useState(false);
    const [resetUnAssignUserSelectedList, setResetUnAssignUserSelectedList] = useState(false);
    const [resetAssignDomainSelectedList, setResetAssignDomainSelectedList] = useState(false);
    const [resetUnAssignDomainSelectedList, setResetUnAssignDomainSelectedList] = useState(false);
    const [activeScan, setActiveScan] = useState("");
    const [passiveScan, setPassiveScan] = useState("");
    const [internal, setInternal] = useState("");

    
    const goBack = () => {
        setResponseStatus(false);
        setIsLoading(false);
        clsoeModifyModal();
        setActiveScan("");
        setPassiveScan("");
        setInternal("");

    }

    const handleSelectUser = (value) => {
        setSelectedUser(value)
    }

    const handleSelectDomain = (value) => {
        setSelectedDomain(value)
    }

    // const updateUserList = useCallback(()=>{
    //     dispatch(getUsersList(""))
    // },[dispatch])

    // const updateDomainList = useCallback(()=>{

    //     if(getloggedInUserType === "internal" && currentPageType === "organizationDetails") {
    //         let url = `customer_id=${customerId}`
    //         dispatch(getDomainList(url))
    //     } else {
    //         dispatch(getDomainList(""))
    //     }
        
    // },[dispatch])


    const changeTab = (value) => {
        // let url = `?organization_id=${organizationId}`
        if(selectedTab === "domain") {
            setSelectedDomain([])
            if(value === "assign") {
                setResetAssignUserSelectedList(false)
                setResetUnAssignUserSelectedList(true)
            } else {
                setResetAssignUserSelectedList(true)
                setResetUnAssignUserSelectedList(false)
            }
        }

        if(selectedTab === "users") {
            setSelectedUser([])
            if(value === "assign") {
                setResetAssignDomainSelectedList(false)
                setResetUnAssignDomainSelectedList(true)
            } else {
                setResetAssignDomainSelectedList(true)
                setResetUnAssignDomainSelectedList(false)
            }
        }
        // if(selectedTab === "domain" && value === "unAssign") {            
        //     dispatch(getDomainList(url))
        // } 
        // if(selectedTab === "users" && value === "unAssign") {
        //     dispatch(getUsersList(url))
        // }
        setActiveTab(value)
    }

    const saveList = () => {
        const ids = selectedTab === "domain" ? selectedDomain.map(data => data.id) : selectedUser.map(data => data.id);
        const isDomain = selectedTab === "domain";
        const isAssign = activeTab === "assign";
        
        const obj = {
            organization_id: organizationId,
            ...(isDomain ? { domains: ids } : { user_ids: ids })
        };
    
        const url = isDomain
            ? (isAssign ? apiEndpoints.domains.assignDomain() : apiEndpoints.domains.unAssignDomain())
            : (isAssign ? apiEndpoints.users.assignUser() : apiEndpoints.users.unAssignUser());
            setIsLoading(true)
        dispatch(assignAndUnAssign(url, obj));
    };

    const internalSaveList = () => {
        const ids = selectedTab === "domain" ? selectedDomain.map(data => data.name) : selectedUser.map(data => data.id);
        const isDomain = selectedTab === "domain";
        const isAssign = activeTab === "assign";

        if(currentPageType === "customerDetails") {
            let obj = {
                customer_id: customerDetails !== "" ? customerDetails.customer_id : customerId,        
                ...(isDomain ? { domains: ids } : { user_ids: ids })
            };
    
            if(isDomain && isAssign) {
                obj.passive_scan = passiveScan === "yes" ? true : false
                obj.active_scan = activeScan === "yes" ? true : false
                obj.external = internal === "yes" ? true: false
            }
    
            const url = isDomain
                ? (isAssign ? apiEndpoints.customer.assignDomainToCustomer() : apiEndpoints.customer.unAssignDomainToCustomer())
                : (isAssign ? apiEndpoints.customer.assignUsersToCustomer() : apiEndpoints.customer.unAssignUsers());
                setIsLoading(true)
            dispatch(assignAndUnAssign(url, obj));
        } else if (currentPageType === "organizationDetails") {

            const obj = {
                organization_id: organizationId,
                ...(isDomain ? { domains: ids } : { user_ids: ids })
            };

            const url = isDomain
            ? (isAssign ? apiEndpoints.customer.assignDomainToOrganization() : apiEndpoints.customer.unAssignDomainToOrganization())
            : (isAssign ? apiEndpoints.customer.assignUserToOrganization() : apiEndpoints.customer.unAssignUserToOrganization());
            setIsLoading(true)
            dispatch(assignAndUnAssign(url, obj));
        }
    }

    // useEffect(()=>{
    //     if(selectedTab === "domain") {
    //         updateDomainList()
    //     } else {            
    //         updateUserList()
    //     }
    // },[selectedTab, updateDomainList, updateUserList])

    useEffect(()=>{        
        if(usersListResponse?.results){
            let list = usersListResponse?.results.map((data)=>{
                return {
                    id: data.user_id,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    value: data.user_id,
                    email: data.email,
                    logo: data.avatar,
                    label: data.firstname + " " + data.lastname
                }
            })
            setUsersList(list)
        }
    },[usersListResponse])

    useEffect(()=>{        
        if(assignedUsers?.results){
            let list = assignedUsers?.results.map((data)=>{
                return {
                    id: data.user_id,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    value: data.user_id,
                    email: data.email,
                    logo: data.avatar,
                    label: data.firstname + " " + data.lastname
                }
            })
            setAssignedUsersList(list)
        }
    },[assignedUsers])
    

    useEffect(()=>{
        if(domainListResponse != null) {
            let list = domainListResponse?.results.map((data)=>{
                return {
                    id: data.domain_id,
                    name: data.domain,
                    value: data.domain_id,
                    logo: data.logo,
                    label: data.domain
                }
            })
            setDomainList(list)
        }
    },[domainListResponse])

    useEffect(()=>{
        if(assignedDomain != null) {
            let list = assignedDomain?.results.map((data)=>{
                return {
                    id: data.domain_id,
                    name: data.domain,
                    value: data.domain_id,
                    logo: data.logo,
                    label: data.domain
                }
            })
            setAssignedDomainList(list)
        }
    },[assignedDomain])

    useEffect(()=>{
        if(assignUnAssignResponse !== null ) {
            setIsLoading(false)
            setResponseStatus(true)
        }
    },[assignUnAssignResponse])


    return (
        <div className="add-organization-container add-domain-container">
            {!responseStatus && <>
                <div className="modal-header mt-4">
                    {selectedTab === "domain" ? <h2>{t('organizationDetailsPage.modify.modifyDomain')}</h2> : <h2>{t('organizationDetailsPage.modify.modifyUsers')}</h2>}
                </div>
                <div className="assign-tab-container d-flex rounded my-3">
                    <p className={`rounded text-center ${activeTab === "assign" ? "active" : ""}`} onClick={()=>changeTab('assign')}>{t('organizationDetailsPage.modify.assign')}</p>
                    <p className={`rounded text-center ${activeTab === "unAssign" ? "active" : ""}`} onClick={()=>changeTab("unAssign")}>{t('organizationDetailsPage.modify.unAssign')}</p>
                </div>
                <div className="add-organization-step-one mb-5">
                    {selectedTab === "users" && activeTab === "assign" && 
                        <DropDown
                            options={usersList}
                            placeHolder='Please select User'
                            dropDownType={"modifyUser"}
                            onChange={(e) => handleSelectUser(e)}
                            isSearchable = {true}
                            resetValue={resetAssignUserSelectedList}
                            isMulti
                        />
                    }
                    {selectedTab === "users" && activeTab === "unAssign" && 
                        <DropDown
                            options={assignedUsersList}
                            placeHolder='Please select User'
                            dropDownType={"modifyUser"}
                            onChange={(e) => handleSelectUser(e)}
                            isSearchable = {true}
                            resetValue={resetUnAssignUserSelectedList}
                            isMulti
                        />
                    }
                    {selectedTab === "domain" && activeTab === "assign" && 
                        <DropDown
                            options={domainList}
                            placeHolder='Please select Domain'
                            dropDownType={"modifyDomain"}
                            onChange={(e) => handleSelectDomain(e)}
                            isSearchable = {true}
                            resetValue={resetAssignDomainSelectedList}
                            isMulti
                        />
                    }
                    {selectedTab === "domain" && activeTab === "unAssign" && 
                        <DropDown
                            options={assignedDomainList}
                            placeHolder='Please select Domain'
                            dropDownType={"modifyDomain"}
                            onChange={(e) => handleSelectDomain(e)}
                            isSearchable = {true}
                            resetValue={resetUnAssignDomainSelectedList}
                            isMulti
                        />
                    }
                    {getloggedInUserType() === "internal" && selectedTab === "domain" && activeTab === "assign"  && currentPageType === "customerDetails" &&
                        <>
                            <div className="organization-type mt-4">
                                <p>Passive Scan</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'yes'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setPassiveScan(value)}}  isChecked={passiveScan === 'yes'}/>
                                    <RadioButton radioId={'no'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setPassiveScan(value)}} isChecked={passiveScan === 'no'}/>
                                </div>
                            </div>
                            <div className="organization-type mt-4">
                                <p>Active Scan</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'yes1'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setActiveScan(value)}}  isChecked={activeScan === 'yes'}/>
                                    <RadioButton radioId={'no1'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setActiveScan(value)}} isChecked={activeScan === 'no'}/>
                                </div>
                            </div>
                            <div className="organization-type mt-4">
                                <p>Internal</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'yes2'} radioLabel={'Yes'} radioValue={'yes'} onChange={(value)=>{setInternal(value)}}  isChecked={internal === 'yes'}/>
                                    <RadioButton radioId={'no2'} radioLabel={'No'} radioValue={'no'} onChange={(value)=>{setInternal(value)}} isChecked={internal === 'no'}/>
                                </div>
                            </div>
                        </>                        
                    }
                </div>
                <div className="form-submit-btn-group mt-4">
                    { getloggedInUserType() === "internal" &&
                        <>
                            <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{goBack()}}/>
                            {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{internalSaveList()}} disableButton={selectedTab === "domain" ? selectedDomain.length === 0 : selectedUser.length === 0} />}
                            {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true} />}
                        </>
                    }
                    { getloggedInUserType() === "customer" &&
                        <>
                            <Button buttonText={"Cancel"} buttonType="secondary" buttonClicked={()=>{goBack()}}/>
                            {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{saveList()}} disableButton={selectedTab === "domain" ? selectedDomain.length === 0 : selectedUser.length === 0} />}
                            {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true} />}
                        </>
                    }
                </div>
            </>}
            {responseStatus && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {assignUnAssignResponse?.detail?.status === "success" && <>
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{assignUnAssignResponse?.detail?.description}</h2>
                    </>}
                    {assignUnAssignResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                            <div className="mt-5">
                                <img src={failed} alt="failed" />
                            </div>
                            <h2 className="mb-4 mt-5 fs-4">{assignUnAssignResponse?.description}</h2>
                            </div>}
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={()=>{goBack()}} />
                    </div>
                </div>
            </>}
        </div>
    )
}

export default ModifyDomain;