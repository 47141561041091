import React,{ useCallback, useEffect, useState, useMemo } from 'react';
// import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./index.scss";


// import ArrowRight from "../../../../assets/images/scan-info/arrow-right.svg";
import DropDown from '../../../../components/ui/DropDown';


import { useSelector, useDispatch } from 'react-redux';
import { getCustomersList, getOrganizationList, getDomainList, resetDomainListResponse } from "../../../../store/actions/scanInfoAction";

const Header = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const organizationListResponse = useSelector((state) => state.scanInfo?.organizationList);
    const customersListResponse = useSelector((state) => state.scanInfo?.customerList);

    const dispatch = useDispatch();

    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;
    const queryParams = new URLSearchParams(location.search);

    const customerId = queryParams.get('customer');
    const organizationId = queryParams.get('organization');
    const domainId = queryParams.get('domain');
    const scanId = queryParams.get('scanId');

    //Javascript split method to get the name of the path in array
    

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedDate, setSelectedDate] = useState(null);
    const [organizationList, setOrganizationList] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const [customersList, setCustomersList] = useState([]);
    const [pageHeading, setPageHeading] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [selectedDomain, setSelectedDomain] = useState("");

    //search value for customer, organization and domain
    const [customerSearch, setCustomerSearch] = useState("");
    const [organizationSearch, setOrganizationSearch] = useState("");
    const [domainSearch, setDomainSearch] = useState("");

    const allValue = useMemo(() => ({
        id: "all",
        name: "All",
        value: "all",
        logo: null,
        label: "All",
        domain: "All"
    }), []);

    const availableDates = [
        new Date(2024, 9, 15),
        new Date(2024, 9, 18), 
        new Date(2024, 8, 11),
        new Date(2024, 7, 5),
    ];

    const isAvailableDate = (date) => {
        return availableDates.some(
            (availableDate) => availableDate.toDateString() === date.toDateString()
        );
    };

    // Highlight available dates
    const highlightAvailableDates = (date) => {
        return isAvailableDate(date) ? "highlighted-date" : undefined;
    };

    

    const CalenderIcon = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66663 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3334 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.91663 7.57471H17.0833" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 11.4167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 13.9167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 11.4167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 13.9167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 11.4167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 13.9167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
    }

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly // Make the input non-editable
            className="form-control"
            placeholder="Select a date"
        />
    ));

    const handleSelectCustomer = (value) => {
        searchParams.set('customer', value.value);
        setSearchParams(searchParams);
        setSelectedCustomer(value.value)
    }

    const handleSelectOrganization = (value) => {
        searchParams.set('organization', value.value);        
        setSearchParams(searchParams);
        setSelectedOrganization(value.value);
    }

    const handleSelectDomain = (value) => {
        searchParams.set('domain', value.label);
        searchParams.set('scanId', value?.lastScanDetails?.scan_id);
        setSearchParams(searchParams);
    }

    const domainSearchValue = (data) => {
        setDomainSearch(data);
    }

    const organizationSearchValue = (data) => {
        setOrganizationSearch(data)
    }

    const customerSearchValue = (data) => {
        setCustomerSearch(data)
    }

    const fetchDomainList = useCallback(()=>{
        dispatch(resetDomainListResponse())
        if(customerId === "all" && organizationId === "all") {
            dispatch(getDomainList(`&page=1&page_size=5&query=${domainSearch}`))
        } else if (customerId !== "all" && organizationId === "all") {
            let url = `&page=1&page_size=5&customer_id=${customerId}&query=${domainSearch}`
            dispatch(getDomainList(url))
        } else if (customerId === "all" && organizationId !== "all") {
            let url = `&page=1&page_size=5&organization_id=${organizationId}&query=${domainSearch}`
            dispatch(getDomainList(url))
        } else {
            let url = `&page=1&page_size=5&customer_id=${customerId}&organization_id=${organizationId}&query=${domainSearch}`
            dispatch(getDomainList(url))
        }
    },[dispatch, customerId, organizationId, domainSearch])

    const fetchOrganization = useCallback((data)=>{
        if(data?.value === "all") {
            setOrganizationList([allValue]);
            setSelectedOrganization(allValue.value)
            fetchDomainList();
        } else {
            let url = `&page=1&page_size=5&customer_id=${data.value}&query=${organizationSearch}`
            dispatch(getOrganizationList(url));
        }        
    },[allValue, dispatch, fetchDomainList, organizationSearch])

    const fetchCustomersList = useCallback(()=>{
        let url = `&page=1&page_size=5&query=${customerSearch}`
        dispatch(getCustomersList(url))
    },[dispatch, customerSearch])

    useEffect(()=>{
        fetchCustomersList();        
    },[fetchCustomersList])

    useEffect(()=>{
        if(customersListResponse !== null) {
            let list = customersListResponse?.results.map((data)=>{
                return {
                    id: data.customer_id,
                    name: data.name,
                    value: data.customer_id,
                    logo: data.logo,
                    label: data.name
                }
            })
            let finalList = [allValue, ...list]
            setCustomersList(finalList);
            const selectedValue = finalList.find(
                (option) => option.value === customerId
            );
            setSelectedCustomer(selectedValue.value);
            fetchOrganization(selectedValue);            
        }
    },[customersListResponse, allValue, customerId, fetchOrganization])


    useEffect(() => {
        if (organizationListResponse !== null) {
            const list = organizationListResponse?.results.map((data) => ({
                id: data.organization_id,
                name: data.name,
                value: data.organization_id,
                logo: data.logo,
                label: data.name,
            }));

            let finalList = [allValue, ...list];
            const selectedValue = finalList.find((option) => option.value === organizationId) || finalList[0];
            setOrganizationList(finalList);
            setSelectedOrganization(selectedValue.value);
            if(customerId === "all") {
                setSelectedOrganization(allValue.value);
                setOrganizationList([allValue]);
                searchParams.set('organization', allValue.value);    
                setSearchParams(searchParams);
            }
            fetchDomainList();
        }
    }, [organizationListResponse, allValue, customerId, organizationId, fetchDomainList, searchParams, setSearchParams]);

    useEffect(() => {
        if (domainListResponse !== null) {
            const list = domainListResponse?.results.map((data) => ({
                id: data.domain_id,
                name: data.domain,
                value: data.domain_id,
                logo: data.logo,
                label: data.domain,
                domain: data.domain,
                lastScanDetails: data.last_scan_detail
            }));
            const finalList = organizationId === "all" ? list : [allValue, ...list];
            const selectedValue = finalList.find((option) => option.label === domainId) || finalList[0];
            setDomainList(finalList);
            if (finalList.length > 0) {
                setSelectedDomain(selectedValue.value);
                searchParams.set('domain', selectedValue.label);
                searchParams.set('scanId', selectedValue?.lastScanDetails?.scan_id)
                setSearchParams(searchParams);
            }
            if(customerId !== "all" && finalList.length === 0) {
                setDomainList([allValue]);
                setSelectedDomain(allValue.value);
                searchParams.set('domain', allValue.label);    
                setSearchParams(searchParams);
            }
            if(finalList.length > 0 && customerId === "all" && organizationId === "all") {
                setDomainList(finalList);
                setSelectedDomain(selectedValue.value);
                searchParams.set('domain', selectedValue.label);  
                searchParams.set('scanId', selectedValue?.lastScanDetails?.scan_id)
                setSearchParams(searchParams);
            }
        }
    }, [domainListResponse, organizationId, allValue, customerId, domainId, searchParams, setSearchParams]);

    useEffect(() => {
        // Parse `splitLocation` only when `pathname` changes
        const splitLocation = pathname.split("/");
        if (splitLocation.length === 5) {
            const heading = splitLocation[4].split("-");
            const str = `${heading[0]} ${heading[1]}`;
            setPageHeading(str);
        } else {
            if(splitLocation[splitLocation.length - 1] === "corporate-email-risk") {
                setPageHeading("Corporate Email Risk")
            } else {
                setPageHeading(splitLocation[splitLocation.length - 1])
            }            
        }
    }, [pathname]);

    return (
        <>
            <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                <div className="header-left d-flex align-items-center gap-3">
                    <h1>{pageHeading}</h1>
                </div>
            </div>
            <div className="page-header-container px-4 py-3">
                <div className="d-flex gap-3 justify-content-between align-items-center">
                    <div className="d-flex switch-container">
                        <div className="switch-organization">
                            <p>Switch Customer</p>
                            <DropDown
                                options={customersList}
                                placeHolder='Please select customer'
                                onChange={(e) => handleSelectCustomer(e)}
                                isSearchable = {true}
                                dropDownType = {'scanInfo'}
                                imageType={"customer"}
                                defaultSelectedValue={selectedCustomer}
                                searchType={"server"}
                                updateSearchValue={customerSearchValue}
                            />
                        </div>
                        <div className="border-between"></div>
                        <div className="switch-organization">
                            <p>Switch Organization</p>
                            <DropDown
                                options={organizationList}
                                placeHolder='Please select Organization'
                                onChange={(e) => handleSelectOrganization(e)}
                                isSearchable = {true}
                                dropDownType = {'scanInfo'}                                
                                imageType={"organization"}
                                defaultSelectedValue={selectedOrganization}
                                searchType={"server"}
                                updateSearchValue={organizationSearchValue}
                            />
                        </div>
                        <div className="border-between"></div>
                        <div className="switch-organization">
                            <p>Switch Domain</p>
                            <DropDown
                                options={domainList}
                                placeHolder='Please select Domain'
                                onChange={(e) => handleSelectDomain(e)}
                                isSearchable = {true}
                                dropDownType = {'scanInfo'}
                                imageType={"domain"}
                                defaultSelectedValue={selectedDomain}
                                searchType={"server"}
                                updateSearchValue={domainSearchValue}
                            />
                        </div>
                    </div>
                    <div className="scan-info-datepicker">
                        <DatePicker
                            showIcon
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            filterDate={isAvailableDate} // Enable only available dates
                            dayClassName={highlightAvailableDates} // Highlight available dates
                            placeholderText="Select an date"
                            icon={CalenderIcon()}
                            dateFormat="MMM dd, yyyy"
                            customInput={<CustomDateInput />}
                            maxDate={new Date()}
                            className="custom-width-datepicker"
                        >
                            <div className="additional-content">
                                <div className="selected-date"><span></span>Selected Date</div>
                                <div className="scanned-date"><span></span>Scanned Date</div>
                            </div>                                    
                        </DatePicker>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;