import { React } from 'react';

import './index.scss';



const CheckBox = ({inputLabel, checkBoxValue, handleCheckboxChange, disable, isChecked}) => {
    
    const handleOnChange = (e)=> {
        handleCheckboxChange(e)
    }

    return (
        <div>
            <label className="checkbox-component">
                {inputLabel}
                <input type="checkbox" disabled={disable} checked={isChecked} value={checkBoxValue} onChange={handleOnChange}/>
                <span className="checkmark"></span>
            </label>
        </div>
    )
}

export default CheckBox;