import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";


import SideNav from "../../components/PostLogin/SideNav";
import CustomersTileView from '../../components/PostLogin/Cutomers/CustomersTileView';
import CutomersListView from "../../components/PostLogin/Cutomers/CustomersListView/index.js";
import SideModal from '../../components/ui/SideModal';
import AddCustomerModal from '../../components/PostLogin/Cutomers/AddCustomer';
import Pagination from '../../components/ui/Pagination';
import Spinner from "../../components/ui/spinner";
import NoDataFound from "../../components/ui/NoDataFound";

import { useSelector, useDispatch } from 'react-redux';
import { getCustomersList, getCustomerStats, resetUpdateCustomerStatusResponse } from '../../store/actions/customerAction.js';


const Cutomers = () => {

    const customerStatsResponse = useSelector((state) => state.customer?.customerStatsResponse);
    const customersListResponse = useSelector((state) => state.customer?.customersListResponse);
    const deleteOrganizationResponse = useSelector((state)=> state.organization?.deleteOrganizationResponse);
    const updateCustomerStatusResponse = useSelector((state) => state.customer?.updateCustomerStatusResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [viewType, setViewType] = useState('tile');
    const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    const closeAddCutomerModal = () => {
        setShowAddCustomerModal(false)
    }
    
    const searchOrganization = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setCurrentPage(1);
    }

    const fetchCustomersList = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${searchValue}`;
        setIsLoading(true);
        dispatch(getCustomersList(url));
    },[searchValue, currentPage, currentPageSize, dispatch])

    const fetchCustomerStats = useCallback(()=> {
        setIsStatsLoading(true);
        dispatch(getCustomerStats());
    },[dispatch])
    

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const backToMainPage = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            setCurrentPage(1);
            setCurrentPageSize(10);
            fetchCustomersList();
            fetchCustomerStats();
        }
        closeAddCutomerModal()
    }

    useEffect(()=>{
        fetchCustomersList();
        fetchCustomerStats();
    },[fetchCustomersList, fetchCustomerStats])

    useEffect(() => {
        if(customersListResponse!= null) {
            setIsLoading(false)
        }
    }, [customersListResponse]);
    
    useEffect(()=>{
        if(deleteOrganizationResponse != null) {
            if(deleteOrganizationResponse?.detail?.status === "success") {
                setIsLoading(true)
                fetchCustomersList();
                fetchCustomerStats();
            }
        }
    },[deleteOrganizationResponse, fetchCustomersList, fetchCustomerStats])

    useEffect(()=>{
        if(updateCustomerStatusResponse !== null) {
            if(updateCustomerStatusResponse?.detail?.status === "success") {
                fetchCustomersList();
                fetchCustomerStats();
                dispatch(resetUpdateCustomerStatusResponse());
            }
        }
    },[updateCustomerStatusResponse, fetchCustomersList, fetchCustomerStats, dispatch])

    useEffect(()=>{
        if(customerStatsResponse !== null) {
            setIsStatsLoading(false);
        }
    },[customerStatsResponse])

   
    return (
        <div className="organization-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("customers.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={()=>{setShowAddCustomerModal(true)}}><span>&#43;</span>{t("customers.addCustomer")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search Customer' className="search-input" value={searchValue} onChange={searchOrganization}/>
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={()=>setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={()=>setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>
                <div className="page-main-section p-4 cutomers-page">
                    <div className="total-domain-scans d-flex mt-3 rounded">
                        <div className="total-domains-container total-customers-container rounded p-3">
                            <div className='domain-icon'>
                                <h4 className="m-0 mt-1">{isStatsLoading ? "...." : customerStatsResponse?.customers?.total}</h4>
                                <div className="user-count mx-3 mt-2">
                                    <span className='active-icon d-inline-block'></span>
                                    <span className='passive-icon d-inline-block'></span>
                                    <p className='total-scans-text'>{t('customers.totalCustomers')}</p>
                                </div>
                            </div>
                            <div className="p-wrapper d-flex justify-content-between gap-3">
                                <p className='mt-2'><span className='count d-inline-block'>{customerStatsResponse?.customers?.active}</span>{t('customers.activeCustomers')}</p>
                                <p className='mt-2'><span className='count-passive'>{customerStatsResponse?.customers?.disable}</span>{t('customers.disabledCustomers')}</p>
                            </div>
                        </div>
                        <div className="total-domains-container total-customers-subscription rounded p-3">
                            <div className='domain-icon'>
                                <h4 className="m-0 mt-1">{isStatsLoading ? "...." : customerStatsResponse?.subscriptions?.total}</h4>
                                <div className="user-count mx-3 mt-2">
                                    <span className='active-icon d-inline-block'></span>
                                    <span className='passive-icon d-inline-block'></span>
                                    <p className='total-scans-text'>{t('customers.totalSubscription')}</p>
                                </div>
                            </div>
                            <div className="p-wrapper d-flex justify-content-between gap-3">
                                <p className='mt-2'><span className='count d-inline-block'>{customerStatsResponse?.subscriptions?.trial}</span>{t('customers.demo')}</p>
                                <p className='mt-2'><span className='count-passive'>{customerStatsResponse?.subscriptions?.standard}</span>{t('customers.standard')}</p>
                            </div>
                        </div>                        
                    </div>
                    <h2>{t("customers.selectCustomer")}</h2>
                    {
                        isLoading ? 
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                        :
                        <>                            
                            {viewType === 'tile' && <CustomersTileView customersList={customersListResponse}/>}
                            {viewType === 'list' && <CutomersListView customersList={customersListResponse}/>}
                            {customersListResponse != null && customersListResponse?.results.length > 0 && <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                pageSize={currentPageSize}
                                totalCount={customersListResponse?.meta?.total_results}
                                onPageChange={setCurrentPageandRecordSize}
                            />}
                        </>
                    }
                    {
                        !isLoading && customersListResponse?.meta?.total_results === 0 && 
                        <NoDataFound />
                    }                    
                </div>
            </div>
            <SideModal showSidePanel={showAddCustomerModal} hideSidePanel={closeAddCutomerModal}>
                <AddCustomerModal backToMainPage={backToMainPage} />
            </SideModal>
        </div>
    )
}

export default Cutomers;