import React, { useCallback, useEffect, useState, useRef } from 'react';
// import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import "./index.scss";

import Pagination from "../../../../../../ui/Pagination";
import Spinner from "../../../../../../ui/spinner";
import NoDataFound from "../../../../../../ui/NoDataFound";

import { useSelector, useDispatch } from 'react-redux';
import { getIpAddressSubdomain } from "../../../../../../../store/actions/scanInfoAction";

const SubDomains = ({selectedIpDetails, domainId, scanId}) => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const ipAddressSubDomainList = useSelector((state) => state?.scanInfo.ipAddressSubDomainList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef= useRef(null)

    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchIpAddressSubDomainList = useCallback(()=>{
        setIsLoading(true)
        let url = `&page=1&page_size=50&domain=${domainId}&scan_id=${scanId}&ip_address=${selectedIpDetails?.ip_address}`
        dispatch(getIpAddressSubdomain(url))

    },[dispatch, selectedIpDetails, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current || selectedIpDetails !== prevDomainIdRef.current?.selectedIpDetails)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchIpAddressSubDomainList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchIpAddressSubDomainList, selectedIpDetails]);

    useEffect(()=>{
        console.log("ipAddressSubDomainList",ipAddressSubDomainList)
        if(ipAddressSubDomainList !== null) {
            setIsLoading(false)
        }
    },[ipAddressSubDomainList])

    return (
        <>
            <div className="ip-subdomain-table">
                {
                    isLoading ? 
                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                        <Spinner />
                    </div>
                    :
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>Subdomain</th>
                                <th>Type</th>
                                <th>HTTP/HTTPS Status</th>
                                <th>Webserver</th>
                                <th>Force HTTPS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ipAddressSubDomainList?.results.length > 0 &&
                                ipAddressSubDomainList?.results.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data?.subdomain}</td>
                                            <td>{data?.subdomain_type}</td>
                                            <td>
                                                {<span className={`http-status ${data?.http_status.http ? 'active' : 'inactive'}`}>{data?.http_status.http ? "HTTP Active" : "HTTP Inactive"}</span>}
                                                <span className={`http-status ${data?.http_status.https ? 'active' : 'inactive'}`}>{data?.http_status.https ? "HTTPS Active" : "HTTP Inactive"}</span>
                                            </td>
                                            <td>{data?.webserver !== null ? data?.webserver : "-"}</td>
                                            <td>{data?.forced_https !== null ? data?.forced_https ? "True" : "false" : "-"}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                }                
                {
                    !isLoading && ipAddressSubDomainList !== null && ipAddressSubDomainList?.results.length === 0 && 
                    <NoDataFound noDataText={"No Record Found"}/>
                }
                {ipAddressSubDomainList !== "" && ipAddressSubDomainList?.results.length > 0 && <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    pageSize={currentPageSize}
                    totalCount={ipAddressSubDomainList?.meta?.total_results}
                    onPageChange={setCurrentPageandRecordSize}
                />}
            </div>
        </>
    )
}

export default SubDomains;