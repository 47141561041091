import React from 'react';
import { useTranslation } from "react-i18next";

import './index.scss';

const ProfileAvatar = ({imageUrl, type, firstName, lastName, name, tags, tagContent, tagColor, pageType, secondaryText, imageType="round"}) => {

    const { t } = useTranslation();

    // const isImageUrlNotAavilable = !imageUrl && type ==='domain' && name;

    return (
        <div className='profile-avatar-container'>
            <div className={`image-container ${imageType === "square" ? "square" : ""}`}>
                {imageUrl && (
                    <img className="avatarImage" alt="avatar" src={imageUrl} />
                )}
                {/* {!imageUrl && type==="profile" && lastName === "" && (
                    <div className="avatartext">{firstName?.substring(0, 2)}</div>
                )}
                {!imageUrl && type==="profile" && lastName !== "" && (
                    <div className="avatartext">{firstName?.substring(0, 1)}{lastName.substring(0, 1)}</div>
                )}
                {isImageUrlNotAavilable && type==="domain" && (
                    <div className={`avatartext ${imageType === "square" ? "square" : ""}`}>{name?.substring(0, 2)}</div>
                )} */}
            </div>
            <div className="name-container">
                {type==="profile" && pageType === "organizationDetails" && <p className="organization-details-createdby">{t("organizationDetailsPage.createdBy")}</p>}
                {type==="profile" && <p className="avatar-primary--text">{firstName} {lastName} 
                    {tags && <span className={`tag-content ${tagColor}`}>
                        <span>{tagContent}</span>
                    </span>}
                </p>}
                {type==="domain" && <p className="avatar-primary--text">{name}</p>}
                {secondaryText !== "" && <p className="avatar-secondary--text">{secondaryText}</p>}
                {/* {pageType === "organizationHomePage" && 
                    <p className="domain-list-section d-flex">
                        <span className="domain-list">google.com</span>
                        <span className="domain-border"></span>
                        <span className="domain-list">abc.xyz</span>
                        <span className="more-domains">+3</span>
                    </p>
                } */}
                {/* {pageType === "domainTable" && <p className="domain-table">{domainDetail}</p>} */}
            </div>
        </div>
    )
}

export default ProfileAvatar;