import React from 'react';
import "./index.scss";
// import CustomTooltip from "../Tooltip";

const ContentBox = ({label, value}) => {
    return (
        <div className="content-box-container p-3 rounded">
            <h4 className="label">{label}</h4>
            {/* <CustomTooltip content={value} position="top"> */}
                <p className="value">{value !== null && value !== "" ? value : "-"}</p>
            {/* </CustomTooltip>             */}
        </div>
    )
}

export default ContentBox;