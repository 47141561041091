
const initialState = {
    domainStatusResponse: null,
    domainDetails: null,
    startScanResponse: null,
    assestInventorySubdomainList:null,
    assestInventoryIpAddressList:null,
    assestInventoryEmailList:null,
    subDomainIncludeResponse: null,
    subDomainExcludeResponse: null,
    ipAddressIncludeResponse: null,
    ipAddressExcludeResponse: null,
    emailIncludeResponse: null,
    emailExcludeResponse: null,
    deleteDomainResponse: null,
    updateDomainResponse: null,
    scanHistoryList: null
}

export default function domainReducer(state=initialState, action) {
    switch(action.type) {
        case 'DOMAIN_STATS_SUCCESS' :
            return {
                ...state,
                domainStatusResponse: action.payload
            }
        case 'DOMAIN_DETAILS_SUCCESS' :
            return {
                ...state,
                domainDetails: action.payload
            }
        case 'START_SCAN_SUCCESS' : 
            return {
                ...state,
                startScanResponse: action.payload
            }
        case 'ASSEST_INVENTORY_SUBDOMAIN_LIST' :
            return {
                ...state,
                assestInventorySubdomainList: action.payload
            }
        case 'ASSEST_INVENTORY_IP_ADDRESS_LIST' :
            return {
                ...state,
                assestInventoryIpAddressList: action.payload
            }
        case 'ASSEST_INVENTORY_EMAILS_LIST' :
            return {
                ...state,
                assestInventoryEmailList: action.payload
            }
        case 'SUBDOMAIN_INCLUDE_SUCCESS' : 
            return {
                ...state,
                subDomainIncludeResponse: action.payload
            }
        case 'SUBDOMAIN_EXCLUDE_SUCCESS' : 
            return {
                ...state,
                subDomainExcludeResponse: action.payload
            }
        case 'IP_ADDRESS_INCLUDE_SUCCESS' : 
            return {
                ...state,
                ipAddressIncludeResponse: action.payload
            }
        case 'IP_ADDRESS_EXCLUDE_SUCCESS' : 
            return {
                ...state,
                ipAddressExcludeResponse: action.payload
            }
        case 'EMAIL_INCLUDE_SUCCESS' : 
            return {
                ...state,
                emailIncludeResponse: action.payload
            }
        case 'EMAIL_EXCLUDE_SUCCESS' : 
            return {
                ...state,
                emailExcludeResponse: action.payload
            }
        case 'RESET_INCLUDE_EXCLUDE_RESPONSE' : 
            return {
                ...state,
                subDomainIncludeResponse: null,
                subDomainExcludeResponse: null,
                ipAddressIncludeResponse: null,
                ipAddressExcludeResponse: null,
                emailIncludeResponse: null,
                emailExcludeResponse: null
            }
        case 'DELETE_DOMAIN_SUCCESS' :
            return {
                ...state,
                deleteDomainResponse: action.payload
            }
        case 'RESET_DELETE_DOMAIN_RESPONSE' :
            return {
                ...state,
                deleteDomainResponse: null
            }
        case 'UPDATE_DOMAIN_SUCCESS' : {
            return {
                ...state,
                updateDomainResponse: action.payload
            }
        }
        case 'RESET_UPDATE_DOMAIN_RESPONSE': 
            return {
                ...state,
                updateDomainResponse: null
            }
        case 'SCAN_HISTORY_SUCCESS':
            return {
                ...state,
                scanHistoryList: action.payload
            }
        default:
            return state;
    }
}