import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createPortal } from "react-dom";
import "./index.scss";

import SuccessTick from "../../../assets/images/common/tick-circle-green.svg";
import Close from "../../../assets/images/common/close.svg";
import InfoIcon from "../../../assets/images/common/info.svg";
import InfoRed from "../../../assets/images/common/info_red.svg";

let toasterInstance = null;

const ToasterManager = () => {
    const [toasts, setToasts] = useState([]);

    // Add a toast to the list
    const addToast = (message, type = "info", duration = 3000) => {
        const id = Date.now(); // Unique ID
        const newToast = { id, message, type };

        setToasts((prevToasts) => [...prevToasts, newToast]);

        // Remove the toast after the specified duration
        setTimeout(() => removeToast(id), duration);
    };

    // Remove a toast from the list
    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    // Expose instance globally
    useEffect(() => {
        toasterInstance = { addToast };
        return () => {
            toasterInstance = null;
        };
    });

    return createPortal(
        <div className="toaster-container">
            {toasts.map((toast) => (
                <div key={toast.id} className={`toaster ${toast.type}`}>
                    <p>
                        {toast.type === "success" && <img src={SuccessTick} alt="success" className="icon"/>}
                        {toast.type === "info" && <img src={InfoIcon} alt="info" className="icon"/>}
                        {toast.type === "error" && <img src={InfoRed} alt="failure" className="icon"/>}
                        {toast.message}
                    </p>
                    <button onClick={() => removeToast(toast.id)}><img src={Close} alt={"close"} /></button>
                </div>
            ))}
        </div>,
        document.body // Attach the portal to the body
    );
};

// Expose a global function for adding toasts
export const toaster = {
    addToast: (message, type = "info", duration = 3000) => {
        if (toasterInstance) {
            toasterInstance.addToast(message, type, duration);
        }
    },
};

// Ensure the ToasterManager is rendered globally
const toasterContainer = document.createElement("div");
document.body.appendChild(toasterContainer);

// Render the ToasterManager using React 18's createRoot
const root = ReactDOM.createRoot(toasterContainer);
root.render(<ToasterManager />);

export default ToasterManager;