import React, { useCallback, useEffect, useState, useRef } from 'react';

import PlusIcon from "../../../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../../../assets/images/common/minus-square.svg";

import Pagination from "../../../../../../ui/Pagination";
import Spinner from "../../../../../../ui/spinner";
import NoDataFound from "../../../../../../ui/NoDataFound";

import SSLCertificate from "../../AssociatedDomain/SSLCertificate";

import { useSelector, useDispatch } from 'react-redux';
import { getSSLCertificate } from "../../../../../../../store/actions/scanInfoAction";

import apiEndpoints from "../../../../../../../apiEndpoints";

const PortInformation = ({sslData, domainId, scanId, currentPageType="ipAddress"}) => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const sslCertificateListResponse = useSelector((state) => state?.scanInfo.sslCertificateList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null)

    const dispatch = useDispatch();

    const [selecetedPortInfo, setSelectedPortInfo] = useState('');
    const [sslCertificateList, setSSLCertificateList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const selectPortInfo = (data) => {
        if(data === selecetedPortInfo) {
            setSelectedPortInfo("")
        } else {
            setSelectedPortInfo(data)
        }
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchRecordData = useCallback(()=>{
        setIsLoading(true)
        if(currentPageType === "subdomain") {
            let url = `${apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.subDomain.sslCertificate()}&page=1&page_size=50&domain=${domainId}&scan_id=${scanId}&subdomain=${sslData?.subdomain}`
            dispatch(getSSLCertificate(url))
        } else {
            let url = `${apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.ipAddress.sslCertificate()}&page=1&page_size=50&domain=${domainId}&scan_id=${scanId}&ip_address=${sslData?.ip_address}`
            dispatch(getSSLCertificate(url))
        }
        

    },[dispatch, sslData, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current  || sslData !== prevDomainIdRef.current?.sslData)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchRecordData();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchRecordData, sslData]);

    useEffect(()=>{
        if(sslCertificateListResponse !== null) {
            setIsLoading(false)
            setSSLCertificateList(sslCertificateListResponse)
        }
    },[sslCertificateListResponse])

    return (
        <>
            {
                isLoading ? 
                <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                    <Spinner />
                </div>
                :
                sslCertificateList !== "" && sslCertificateList.results.length > 0 &&
                sslCertificateList.results.map((data, index) => {
                    return (
                        <div className="port-information-container mb-3" key={index}>
                            <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{selectPortInfo(data?.port)}}>
                                <h2 className="heading">Port: {data?.port}</h2>
                                <div className="port-tag-container">                     
                                    {data?.self_signed && <span className="tag-content expired">Self Signed</span>}
                                    {data?.expired && <span className="tag-content expired">Expired</span>}
                                    <img src={selecetedPortInfo === data?.port ? MinusIcon : PlusIcon} alt="expand"/>
                                </div>                                               
                            </div>
                            {selecetedPortInfo === data?.port && 
                                <div className="p-3">
                                    <SSLCertificate sslCertificateData={data}/>
                                </div>
                            }
                        </div>
                    )
                })
            }
            {
                !isLoading && sslCertificateList !== "" && sslCertificateList?.results.length === 0 && 
                <NoDataFound noDataText={"No Record Found"}/>
            }
            {sslCertificateList !== "" && sslCertificateList?.results.length > 0 && <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                pageSize={currentPageSize}
                totalCount={sslCertificateList?.meta?.total_results}
                onPageChange={setCurrentPageandRecordSize}
            />}
            
        </>
    )
}

export default PortInformation