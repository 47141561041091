import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

import ScanInfo from "../../../ScanInfo";

import Findings from "./Findings";

import BrandInsights from "./BrandInsights";

import OrganizationHacker from "./OrganizationHacker";
import TopVulnerabilities from "./TopVulnerabilities";
import TopSubDomains from './TopSubDomains';
import MapChart from './MapChart';
import BubbleChart from './BubbleChart';

const Dashboard = () => {

    const [activeTab, setActiveTab] = useState("subDomainInformation")


    return (
        <ScanInfo>
            <div className="dashboard-main-content">

                <Row>
                    <Col lg={4}>
                        <div className="bg-white-with-shadow p-4">
                            <div className="dashboard-type-container dashboard-bg rounded">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Digital Assets</p>
                                    {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                                </div>
                                <div className="total-records-container">
                                    <p className="content">15 <span>Total </span></p>
                                </div>

                            </div>
                            <div className="dashboard-type-container rounded p-3 mt-4">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Domains</p>
                                    <p className="view-all">05</p>
                                </div>

                            </div>
                            <div className="dashboard-type-container rounded p-3 mt-2">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Sub Domains</p>
                                    <p className="view-all">05</p>
                                </div>

                            </div>
                            <div className="dashboard-type-container rounded p-3 mt-2">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">IP Address</p>
                                    <p className="view-all">05</p>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="bg-white-with-shadow p-4">
                            <div className="dashboard-type-container dashboard-bg rounded">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Digital Foot-prints</p>
                                    {/* <p className="view-all"><img src={move} alt="search" className="position-absolute search-icon" /></p> */}
                                </div>
                                <div className="total-records-container">
                                    <p className="content">138 <span>Total </span></p>
                                </div>
                            </div>

                            <Row>
                                <Col lg={4}>
                                    <div className="dashboard-type-container rounded p-3 mt-4">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Open Ports</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                    <div className="dashboard-type-container rounded p-3 mt-2">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Github</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                    <div className="dashboard-type-container rounded p-3 mt-2">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Associated Domains</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="dashboard-type-container rounded p-3 mt-4">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">SSL Certificates</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                    <div className="dashboard-type-container rounded p-3 mt-2">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">DNS Records</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                    <div className="dashboard-type-container rounded p-3 mt-2">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Hosting Providers</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="dashboard-type-container rounded p-3 mt-4">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Technologies</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                    <div className="dashboard-type-container rounded p-3 mt-2">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Web Servers</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                    <div className="dashboard-type-container rounded p-3 mt-2">
                                        <div className="header-content d-flex justify-content-between">
                                            <p className="heading">Asset Locations</p>
                                            <p className="view-all">05</p>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Findings />
                <BrandInsights />
                <OrganizationHacker />
                <BubbleChart />
                <TopVulnerabilities />
                <MapChart />
                <TopSubDomains />

            </div>
        </ScanInfo>

    )
}


export default Dashboard;
