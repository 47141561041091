import { React} from 'react';

import './index.scss';



const RadioButton = ({radioId, radioLabel, radioValue, disabled, onChange, isChecked=false}) => {
    
    const radioChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <p className="move-position">
            <input className="radiobutton" type="radio" id={radioId} value={radioValue} onChange={radioChange} disabled={disabled} checked={isChecked}/>
            <label htmlFor={radioId}>{radioLabel}</label>
        </p>
        
    )
}

export default RadioButton;