import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import CheckBox from "../../../../../components/ui/CheckBox";
import { formatDate } from "../../../../../utils/util";


const SubDomains = ({ onCheckboxChange, subDomainList }) => {

    

    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState([]);

    const changeSelectAll = (e) => {
        if(e.target.checked) {
            setSelectedValue(subDomainList?.results)
            onCheckboxChange(subDomainList?.results)
        } else {
            setSelectedValue([])
            onCheckboxChange([])
        }
    }

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            let checkedValue = subDomainList?.results.filter(item => item.subdomain === e.target.value);
            setSelectedValue([...selectedValue, ...checkedValue])
            onCheckboxChange([...selectedValue, ...checkedValue]);
        } else {
            const updatedList = selectedValue.filter(item => item.subdomain !== e.target.value);
            setSelectedValue(updatedList);
            onCheckboxChange(updatedList);
        }
    }

    const isChecked = (data) => {
        if(selectedValue.length > 0 ){
            return selectedValue.some(item => item.subdomain === data.subdomain);
        }
        return false
    }

    return (
        <>
            <Table responsive="lg">
                <thead>
                    <tr>
                        <th className="select-all-checkbox">
                            <CheckBox checkBoxId={0} checkBoxValue={"select all"} handleCheckboxChange={changeSelectAll} />
                        </th>
                        <th>{t("domainDetailsPage.subDomainTable.subDomain")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.clientProvided")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.firstSeen")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.lastSeen")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        subDomainList?.results.length > 0 && 
                        subDomainList?.results.map((data, index)=>{
                            return (
                                <tr key={index}>
                                    <td>
                                        <CheckBox checkBoxId={index} checkBoxValue={data.subdomain} handleCheckboxChange={setCheckboxvalue} isChecked={isChecked(data)} />
                                    </td>
                                    <td>
                                        <p className="checkbox">{data.subdomain}</p>
                                    </td>
                                    <td>
                                        <p>{data.subdomain ? "Yes" : "No"}</p>
                                    </td>
                                    <td>
                                        <p>{formatDate(data.first_seen)}</p>
                                    </td>
                                    <td>
                                        <p>{formatDate(data.last_seen)}</p>
                                    </td>
                                    <td>
                                        <label className={`asset-status ${data.excluded ? 'disabled' : 'active'}`}>{data.excluded ? "Exclude" : "Include"}</label>
                                    </td>

                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    );
}

export default SubDomains;
