import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './index.scss';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    className,
    pageSize
  } = props;

  const [showRecords, setShowRecords] = useState(pageSize);

  // Calculate pagination range
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize: showRecords
  });

  // const totalPageCount = Math.ceil(totalCount / showRecords);

  // Handle page bounds when `showRecords` or `totalCount` changes
  useEffect(() => {
    onPageChange(currentPage, showRecords);
  }, [showRecords, totalCount, currentPage, onPageChange]);

  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => onPageChange(currentPage + 1, showRecords);

  const onPrevious = () => onPageChange(currentPage - 1, showRecords);

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <>
      <div className="mt-5 d-flex align-items-center">
        <div className="show-records-container">
          <label className="d-inline-block">Show records</label>
          <select value={showRecords} onChange={(e) => setShowRecords(Number(e.target.value))}>
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>
        <ul
          className={classnames('pagination-container', { [className]: className })}
        >
          <li
            className={classnames('pagination-item', {
              disabled: currentPage === 1
            })}
            onClick={onPrevious}
          >
            <span className="arrow left">&#8592;</span>
          </li>
          {paginationRange.map(pageNumber => {
            if (pageNumber === DOTS) {
              return <li className="pagination-item dots" key="dots">&#8230;</li>;
            }
            return (
              <li
                className={classnames('pagination-item', {
                  selected: pageNumber === currentPage // Ensure selected class
                })}
                onClick={() => onPageChange(pageNumber, showRecords)}
                key={pageNumber}
              >
                {pageNumber}
              </li>
            );
          })}
          <li
            className={classnames('pagination-item', {
              disabled: currentPage === lastPage
            })}
            onClick={onNext}
          >
            <span className="arrow right">&#8594;</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;