import React, { useState } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import GaugeChart from 'react-gauge-chart'

import arrowRight from "../../../../assets/images/common/arrow-right-border.svg";
import trash from "../../../../assets/images/common/trash.svg";
import pause from "../../../../assets/images/domain/pause.svg";

import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import ScanModal from "../../Domain/ScanModal";
import DeleteScanModal from "../../ScanQueue/DeleteScanModal";


import { formatDate, formatNumber, getDomainImageUrl } from "../../../../utils/util";

import { useDispatch } from 'react-redux';
import { stopScanSingle, resumeScanSingle } from "../../../../store/actions/scanqueueAction.js";

const DomainTileView = ({domainList}) => {


    const dispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [scanningDomain, setScanningDomain] = useState(null);
    const [showStartScanModal, setShowStartScanModal] = useState(false); // Modal visibility state
    const [showdeleteScanModal, setShowdeleteScanModal] = useState(false);
    const [deleteDomainData, setDeleteDomainData] = useState(null)

    const handleStartScan = (data) => {
        setScanningDomain(data);
        setShowStartScanModal(true); // Open the modal
    };

    const handleViewDetailsClick = (data) => {
        navigate(`/domain/details/${data.domain}`); // Replace '/details' with your target route
    };

    const handleCloseModal = () => setShowStartScanModal(false); // Close modal function

    const handleCloseDeleteDomainModal = () => setShowdeleteScanModal(false);

    const formatCreatedDate = (data) => {
        let date = formatDate(data.created_at)
        return `Created On : ${date}`
    }

    const totalNewFindings = (data) => {
        let {asset_discovery, external_detected_vulnerabilities, darkweb, concerning_exposures } = data
        return formatNumber(asset_discovery + external_detected_vulnerabilities + darkweb + concerning_exposures)
    }

    const getMeterPercent = (data) => {
        let value = data?.stats?.score?.percentage/100 
        return value
    }

    const chartStyle = {
        height: 50,
        width: 100,
    }

    const updateScan = (data, type) => {
        let obj = {
            scan_id : data?.scan_queue_detail?.scan_id
        }
        if( type === "stop") {
            dispatch(stopScanSingle(obj))
        }
        if( type === "resume") {
            dispatch(resumeScanSingle(obj))
        }
        if(type === "delete") {
            setDeleteDomainData(data)
            setShowdeleteScanModal(true)
        }
    }

    return (
        <Row>
            
            {domainList?.results.length > 0 && 
                domainList?.results.map((data, index)=>{
                    return (
                        <Col xl="6" lg="12" md="12" className="mt-4" key={index}>
                            <div className="domain-container p-4 rounded h-100">
                                <div className="organization-profile">
                                    <div className="organization-profile-header d-flex justify-content-between">
                                        <div className="organization-details">
                                            <ProfileAvatar pageType={"domainHomePage"} type="domain" name={data.domain} imageUrl={getDomainImageUrl(data)} secondaryText={formatCreatedDate(data)}/>
                                        </div>
                                        <div className="organization-actions">
                                            <button
                                                className={`view-details-btn ${(data.scan_status === "in_progress" || data.scan_status === "pending" || data.scan_status === "stopped") ? "disabled-btn" : ""}`}
                                                onClick={() => handleStartScan(data)}
                                                disabled={(data.scan_status === "in_progress" || data.scan_status === "pending" || data.scan_status === "stopped")}
                                            >
                                                <img src={arrowRight} alt="arrowRight" />
                                                {t("domainPage.startScanBtn")}
                                            </button>
                                            <button className="view-scan-btn" onClick={()=>{handleViewDetailsClick(data)}}>{t("organizationPage.viewDetailsBtn")}</button>
                                        </div>
                                    </div>

                                    <div className="my-3">
                                        <p className="scan-status-text"><span>{t("domainPage.scanStatus")}:</span> {data?.scan_queue_detail?.scan_status === "in_progress" ? `${data?.scan_queue_detail?.scan_percentage}%` : data?.scan_queue_detail?.scan_status === "new" ? "Yet To Start" : data?.scan_queue_detail?.scan_status === "pending" ? `In Queue (${data?.scan_queue_detail?.scan_percentage}%)` : `Stopped (${data?.scan_queue_detail?.scan_percentage}%)`}</p>
                                        {data?.scan_queue_detail?.scan_status === "completed" && <p className="scan-status-text">{t("domainPage.lastScan")}: {formatDate(data?.last_scan_detail?.created_at)}</p>}
                                        <div
                                            className={`${data?.scan_queue_detail?.scan_status === "in_progress" ? "scan-progress my-3" : "my-3"}`}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <ProgressBar
                                                now={data?.scan_queue_detail?.scan_status === "in_progress" || data?.scan_queue_detail?.scan_status === "pending" || data?.scan_queue_detail?.scan_status === "stopped" ? data?.scan_queue_detail?.scan_percentage : 0}
                                                style={{ height: '8px', width: '100%' }}
                                                className={`custom-progress-bar ${data?.scan_queue_detail?.scan_status === "pending" || data?.scan_queue_detail?.scan_status === "stopped" ? 'paused-progress' : ''}`} // Add conditional class
                                            />

                                            { data?.scan_queue_detail?.scan_status === "in_progress" && 
                                                <>
                                                    <button className="view-details-btn-scan" onClick={()=>{updateScan(data, 'stop')}}>
                                                        <img src={pause} alt="pause" />
                                                    </button>
                                                    <button
                                                        className="view-details-btn-trash disabled-btn-grey"
                                                        disabled={true}
                                                    >
                                                        <img src={trash} alt="trash" />
                                                    </button>
                                                </>
                                            }
                                            { data?.scan_queue_detail?.scan_status === "pending" && 
                                                <>
                                                    <button className="view-details-btn-scan disabled-btn-grey" disabled={true}>
                                                        <img src={arrowRight} alt="pause" />
                                                    </button>
                                                    <button className="view-details-btn-trash" onClick={()=>{updateScan(data, 'delete')}}>
                                                        <img src={trash} alt="trash" />
                                                    </button>
                                                </>
                                            }
                                            { data?.scan_queue_detail?.scan_status === "stopped" && 
                                                <>
                                                    <button className="view-details-btn-scan" onClick={()=>{updateScan(data, 'resume')}}>
                                                        <img src={arrowRight} alt="pause" />
                                                    </button>
                                                    <button className="view-details-btn-trash" onClick={()=>{updateScan(data, 'delete')}}>
                                                        <img src={trash} alt="trash" />
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Domain Findings and Ratings Section */}
                                <div className="organization-findings">
                                    <Row>
                                        <Col md="6">
                                            <div className="total-domains">
                                                <div className="total-domains-container rounded p-4">
                                                    <h2>{t("domainPage.riskRatings")}</h2>
                                                    <div className="d-flex border-bottom">
                                                        <div className="gauge-container">
                                                            <GaugeChart 
                                                                id="gauge-chart1"
                                                                nrOfLevels={4} 
                                                                colors={["#ee6862", "#f2a16b", "#F4DA6B", "#8dd761"]}
                                                                percent={getMeterPercent(data)}
                                                                arcWidth={0.2}
                                                                arcPadding={0}
                                                                cornerRadius={0}
                                                                hideText={true}
                                                                style={chartStyle}
                                                            />
                                                        </div>
                                                        <div className="align-items-center">
                                                            <div className="hacker-sec mb-2">
                                                                <h5>{data?.stats?.score?.latest_score?.overall_score?.obtained_score}</h5>
                                                                <p>Hacker Score</p>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    
                                                    {/* <GaugePro hackerScore={data?.stats?.score?.latest_score?.overall_score?.obtained_score}/> */}
                                                    <div className="domain-align d-flex align-items-center justify-content-between mt-4 mb-3">
                                                        <div className='domain-bottom'>
                                                            <div>
                                                                <h2>{data?.stats?.score?.latest_score?.infrastructure_score?.obtained_rating}</h2>
                                                                <p>Infrastructure</p>
                                                            </div>
                                                            <div className='domain-sec'>
                                                                <h2>{data?.stats?.score?.latest_score?.exposure_score?.obtained_rating}</h2>
                                                                <p>Exposure</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <h2>{data?.stats?.score?.latest_score?.vulnerability_score?.obtained_rating}</h2>
                                                                <p>Vulnerabilities</p>
                                                            </div>
                                                            <div className='domain-sec'>
                                                                <h2>{data?.stats?.score?.latest_score?.darkweb_score?.obtained_rating}</h2>
                                                                <p>Dark Web</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="new-findings-container">
                                                <div className="new-findings-list rounded p-4">
                                                    <h2>{t("domainPage.totalFindings")}</h2>
                                                    <h4 className="m-0">{totalNewFindings(data?.stats?.new_findings)}</h4>
                                                    <p>{t("organizationPage.newFindings")}</p>
                                                    <div className="finding-list">
                                                        <ul className="m-0 p-0">
                                                            <li className="d-flex justify-content-between">
                                                                <span>{t("organizationPage.assetDiscovery")}</span>
                                                                <span>{formatNumber(data?.stats?.new_findings?.asset_discovery)}</span>
                                                            </li>
                                                            <li className="d-flex justify-content-between">
                                                                <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                                <span>{formatNumber(data?.stats?.new_findings?.external_detected_vulnerabilities)}</span>
                                                            </li>
                                                            <li className="d-flex justify-content-between">
                                                                <span>{t("organizationPage.DarkWeb")}</span>
                                                                <span>{formatNumber(data?.stats?.new_findings?.darkweb)}</span>
                                                            </li>
                                                            <li className="d-flex justify-content-between">
                                                                <span>{t("organizationPage.concerningExposures")}</span>
                                                                <span>{formatNumber(data?.stats?.new_findings?.concerning_exposures)}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            <ScanModal show={showStartScanModal} onHide={handleCloseModal} domainData={scanningDomain}/>
            <DeleteScanModal show={showdeleteScanModal} onHide={handleCloseDeleteDomainModal} deleteDomainData={deleteDomainData}/>
        </Row>
    );
};

export default DomainTileView;
