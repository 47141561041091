import { apiPost, apiGet } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getUserStats() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.users.userStats())
            dispatch({
                type: 'USER_STATS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export function getUserDetails(obj){
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.users.userDetails(), obj)
            if(response?.status === 200) {
                dispatch({
                    type: 'USER_DETAILS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'USER_DETAILS_SUCCESS',
                    payload: response.detail
                })
            }  
        } catch (error) {
            console.log(error)
        }
    }
}

export function getOrganizationByUser(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.organizations.organizationList()+url)
            if(response?.status === 200) {
                dispatch({
                    type: 'ORGANIZATION_LIST_BY_USER_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ORGANIZATION_LIST_BY_USER_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function inviteUserSingle(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.users.inviteUserSingle(), obj)
            if(response?.status === 200) {
                dispatch({
                    type: 'INVITE_USER_SINGLE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'INVITE_USER_SINGLE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {

        }
    }
}

export function editUser(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.users.editUser(), obj)
            if(response?.status === 200) {
                dispatch({
                    type: 'EDIT_USER_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'EDIT_USER_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {

        }
    }
}

export function getUserSessionById(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.users.userSessionById()+url)
            if(response?.status === 200) {
                dispatch({
                    type: 'GET_USER_SESSION_BY_ID_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'GET_USER_SESSION_BY_ID_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {

        }
    }
}

export function deleteUserSingle(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.users.deleteUserSingle(), obj)
            if(response?.status === 200) {
                dispatch({
                    type: 'DELETE_USER_ID_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DELETE_USER_ID_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateUserStatus(url, obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(url, obj)
            if(response?.status === 200) {
                dispatch({
                    type: 'UPDATE_USER_STATUS_SINGLE_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'UPDATE_USER_STATUS_SINGLE_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {

        }
    }
}

export function resetUpdateUserStatus() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_UPDATE_USER_STATUS_RESPONSE'
        })
    }
}