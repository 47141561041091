import { React, useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import editIcon from "../../../../assets/images/common/edit-icon.svg";
import closeIcon from "../../../../assets/images/common/close-circle.svg";

import ProfileAvatar from '../../../ui/ProfileAvatar';
import CheckBox from "../../../ui/CheckBox";
import Button from "../../../ui/Button";
import Pagination from "../../../ui/Pagination";
import SideModal from '../../../ui/SideModal';
import NoDataFound from "../../../ui/NoDataFound";
import Spinner from "../../../ui/spinner";

import ModifyMonitorModal from '../../../PostLogin/Monitor/ModifyMonitorModal';

import { useSelector, useDispatch } from 'react-redux';
import { customersListForMonitors } from '../../../../store/actions/monitorAction';
import { getCustomersList } from '../../../../store/actions/customerAction';

import { formatDate, getCustomerImageUrl } from "../../../../utils/util";

const filterOption = [
    {
        label: "Critical",
        value: "critical"
    },
    {
        label: "High",
        value: "high"
    },
    {
        label: "Medium",
        value: "medium"
    },
    {
        label: "Low",
        value: "low"
    }
]


const MonitorTab = ({monitorData}) => {

    const customersListForMonitorsResponse = useSelector((state) => state.monitor?.customersListForMonitorsResponse)

    const { t } = useTranslation();

    const filterWrapperRef = useRef(null);

    const dispatch = useDispatch();

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);
    

    const setCheckboxvalue = (e) => {
        if (e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }

    const closeModifyModal = (data) => {
        if(data?.detail?.status === "success") {
            fetchCustomersList()
        }
        closeAddOrgSidePanel()
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchCustomersList = useCallback(()=>{
        let url = `&monitor_id=${monitorData?.monitor_id}&page=${currentPage}&page_size=${currentPageSize}`
        setIsLoading(true)
        dispatch(customersListForMonitors(url))
    },[dispatch, monitorData, currentPage, currentPageSize])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if (showFilter) {
                    setShowFilter(false)
                    setFilterList([])
                }
            }

        }
    }, [showFilter, filterList])

    useEffect(()=>{
        fetchCustomersList()
    },[fetchCustomersList])

    useEffect(()=>{
        if(customersListForMonitorsResponse !== null) {
            setIsLoading(false)
        }
    },[customersListForMonitorsResponse])


    return (
        <Row>
            <Col md={12}>
                <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                    <div className="search-container d-flex align-items-center gap-3">
                        <div className="position-relative">
                            <input className="search-input rounded" placeholder="Search Monitor" value={domainSearchValue} onChange={(e) => setDomainSearchValue(e.target.value)} />
                            <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        </div>
                        <button className="search-btn">Search</button>
                        <div className="position-relative">
                            <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked} />
                            {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                <h6>Business Impact</h6>
                                {filterOption.map((data, index) => {
                                    return (
                                        <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                            <p>{data.label}</p>
                                            <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue} />
                                        </div>
                                    )
                                })}
                                <div className="filter-apply-btn mt-3">
                                    <Button buttonText={"Apply"} buttonType={"primary"} disableButton={filterList.length === 0 ? true : false} buttonClicked={applyFilter} />
                                </div>
                            </div>}
                        </div>
                        {!showFilter && <p className="selected-filters">
                            {
                                filterList.map((data, index) => {
                                    return (
                                        <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)} /></span>
                                    )
                                })
                            }
                        </p>}
                    </div>
                    <div className="sort-edit-container">
                        <div className="container d-flex gap-3 align-items-center">
                            <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")}
                                <select className="border-0">
                                    <option value="latest score">Latest Score</option>
                                    <option value="last scanned">Last scanned</option>
                                    <option value="created">Created</option>
                                </select>
                            </p>
                            <button onClick={() => {
                                dispatch(getCustomersList(""))
                                setShowAddOrgSidePanel(true);
                            }}>
                                <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                                {t("organizationDetailsPage.modifyBtn")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="domain-table-container monitor-page-org-table">
                    {
                        isLoading ?
                        <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%'}}>
                            <Spinner />
                        </div>
                        :
                        <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th>Customer Details</th>
                                    <th>Subscription Type</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customersListForMonitorsResponse?.results.length > 0 &&
                                    customersListForMonitorsResponse?.results.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <ProfileAvatar type="profile" firstName={data?.name} lastName={""} secondaryText={formatDate(data?.created_at)} imageUrl={getCustomerImageUrl(data)} />
                                                </td>
                                                <td>
                                                    {data?.subscription.name === "trial" ? "Demo" : "Business"}
                                                </td>
                                                <td>
                                                    <p className="business-impact critical">{data?.status}</p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }                          
                            </tbody>
                        </Table>
                    }
                    
                    {
                        !isLoading && customersListForMonitorsResponse?.results.length === 0 && 
                        <NoDataFound />
                    }
                    {customersListForMonitorsResponse !== null && !isLoading && customersListForMonitorsResponse?.meta?.total_results !== 0 && <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        pageSize={currentPageSize}
                        totalCount={customersListForMonitorsResponse?.meta?.total_results}
                        onPageChange={setCurrentPageandRecordSize}
                    />}
                </div>
            </Col>
            <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                <ModifyMonitorModal assignedCustomerList={customersListForMonitorsResponse} monitorDetails={monitorData} clsoeModifyModal={closeModifyModal}/>
            </SideModal>
        </Row>
    )
}

export default MonitorTab;