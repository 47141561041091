// ScanModal.js
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import Button from "../../../../components/ui/Button";
import FileUpload from "../../../../components/ui/FileUpload";
import InputBox from "../../../../components/ui/InputBox";
import RadioButton from "../../../../components/ui/RadioButton";
import "./index.scss";
import domainIcon from "../../../../assets/images/common/domain.svg";

const MonitorModal = (props) => {
    const { t } = useTranslation();

    // State variables
    const [organizationName, setOrganizationName] = useState('');
    const [scanType, setScanType] = useState('active');  // Default to "active"
    const [scanSpeed, setScanSpeed] = useState('slow');   // Default to "slow"
    const [subdomainList, setSubdomainList] = useState('');
    const [ipAddressList, setIpAddressList] = useState('');
    const [emailList, setEmailList] = useState('');

    // Handlers
    const scanTypeChange = (e) => {
        if (e?.target?.value) setScanType(e.target.value);
    };
    
    const scanSpeedChange = (e) => {
        if (e?.target?.value) setScanSpeed(e.target.value);
    };

    const handleInputChange = (setter) => (e) => {
        if (e?.target?.value !== undefined) setter(e.target.value);
    };

   

    return (
        <Modal {...props} centered className="delete-monitor-modal">
            <Modal.Body>
                <div className="delete-organization-container">
                    <h4>{t("monitorPage.editMonitorModal.heading")}</h4>
                    <div className="organization-related-details p-4 m-2">
                        <div className="organization-type">
                            <p>{t("domainPage.scanModal.selectScanType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="active"
                                    radioLabel="Active"
                                    radioValue="active"
                                    onChange={scanTypeChange}
                                    isChecked={scanType === 'active'}
                                />
                                <RadioButton
                                    radioId="passive"
                                    radioLabel="Passive"
                                    radioValue="passive"
                                    onChange={scanTypeChange}
                                    isChecked={scanType === 'passive'}
                                />
                            </div>
                        </div>
                        <div className="organization-type mt-3">
                            <p>{t("monitorPage.editMonitorModal.selectMonitorType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="slow"
                                    radioLabel="Weekly"
                                    radioValue="slow"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'slow'}
                                />
                                <RadioButton
                                    radioId="moderate"
                                    radioLabel="Monthly"
                                    radioValue="moderate"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'moderate'}
                                />
                            </div>
                        </div>     
                    </div>
                    <div className="form-submit-btn-group d-flex gap-3 pt-4">
                            <Button buttonText="Cancel" buttonType="secondary" buttonClicked={props.onHide} />
                            <Button buttonText="Save" buttonType="primary" className="save-btn" />
                        </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default MonitorModal;
