import { React } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";


import domainIcon from "../../../../assets/images/common/domain.svg";

import Button from "../../../../components/ui/Button";

import { useDispatch } from 'react-redux';
import { deleteDoamin } from '../../../../store/actions/domainAction.js';


const DeleteDomainModal = ({show, onHide, domainData}) => {

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const confirmDelete = () => {
        let obj = {
            domain: domainData.domain
        }
        dispatch(deleteDoamin(obj))
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("domainDetailsPage.deleteDomain.detailsHeading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("organizationDetailsPage.deleteOrganization.subText")} {domainData?.domain}?</p>
                            <div className="d-flex justify-content-between mt-4 gap-3">
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("domainDetailsPage.deleteDomain.activeScan")}</p>
                                        <p className="domain-count">100</p>
                                    </div>
                                </div>
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("domainDetailsPage.deleteDomain.passiveScan")}</p>
                                        <p className="domain-count">24</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="delete-organization-btn d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            <Button buttonText="Delete" buttonType={"primary"} buttonClicked={confirmDelete} />
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default DeleteDomainModal;