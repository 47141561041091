
const initialState = {
    customerStatsResponse: null,
    customersListResponse: null,
    addCustomerResponse: null,
    customerDetailResponse: null,
    updateCustomerResponse: null,
    deleteCustomerResponse: null,
    editSubscriptionResponse: null,
    extendSubscriptionResponse: null,
    cancelSubscriptionResponse: null,
    permissionDetailResponse: null,
    editDomainPermissionResponse: null,
    updateCustomerStatusResponse: null
}

export default function customerReducer(state=initialState, action) {
    switch(action.type) {
        case 'CUSTOMER_STATS_SUCCESS':
            return {
                ...state,
                customerStatsResponse: action.payload
            }
        case 'CUSTOMER_LIST_SUCCESS' :
            return {
                ...state,
                customersListResponse: action.payload
            }
        case 'ADD_CUSTOMER_SUCCESS' :
            return {
                ...state,
                addCustomerResponse: action.payload
            }
        case 'CUSTOMER_DETAILS_SUCCESS' :
            return {
                ...state,
                customerDetailResponse: action.payload
            }
        case 'RESET_CUSTOMER_DETAIL_RESPONSE': 
            return {
                ...state,
                customerDetailResponse: null
            }
        case 'UPDATE_CUSTOMER_SUCCESS':
            return {
                ...state,
                updateCustomerResponse: action.payload
            }
        case 'RESET_UPDATE_CUSTOMER_DETAIL_RESPONSE':
            return {
                ...state,
                updateCustomerResponse: null
            }
        case 'DELETE_CUSTOMER_SUCCESS' :
            return {
                ...state,
                deleteCustomerResponse: action.payload
            }
        case 'RESET_CUSTOMER_LIST_RESPONSE':
            return {
                ...state,
                customersListResponse: null
            }
        case 'RESET_DELETE_CUSTOMER_RESPONSE':
            return {
                ...state,
                deleteCustomerResponse: null
            }
        case 'EDIT_SUBSCRIPTION_SUCCESS' :
            return {
                ...state,
                editSubscriptionResponse: action.payload
            }
        case 'RESET_EDIT_SUBSCRIPTION_RESPONSE' :
            return {
                ...state,
                editSubscriptionResponse: null
            }
        case 'EXTEND_SUBSCRIPTION_SUCCESS' :
            return {
                ...state,
                extendSubscriptionResponse: action.payload
            }
        case 'CANCEL_SUBSCRIPTION_SUCCESS':
            return {
                ...state,
                cancelSubscriptionResponse: action.payload
            }
        case 'PERMISSION_DETAILS_SUCCESS':
            return {
                ...state,
                permissionDetailResponse: action.payload
            }
        case 'EDIT_DOMAIN_PERMISSION_DETAILS_SUCCESS':
            return {
                ...state,
                editDomainPermissionResponse: action.payload
            }
        case 'RESET_EDIT_DOMAIN_PERMISSION_RESPONSE' :
            return {
                ...state,
                editDomainPermissionResponse: null
            }
        case 'UPDATE_CUSTOMER_STATUS_SUCCESS' :
            return {
                ...state,
                updateCustomerStatusResponse: action.payload
            }
        case 'RESET_UPDATE_CUSTOMER_STATUS_RESPONSE':
            return {
                ...state,
                updateCustomerStatusResponse: null
            }
        default:
            return state;
    }
}