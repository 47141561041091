import React, { useCallback, useEffect, useState, useRef } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

import PlusIcon from "../../../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../../../assets/images/common/minus-square.svg";

import Pagination from "../../../../../../ui/Pagination";
import Spinner from "../../../../../../ui/spinner";
import NoDataFound from "../../../../../../ui/NoDataFound";
import ContentBox from "../../../../../../ui/ContentBox";

import { useSelector, useDispatch } from 'react-redux';
import { getIpAddressPortInfo } from "../../../../../../../store/actions/scanInfoAction";

const PortInformation = ({selectedIpDetails, domainId, scanId}) => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const ipAddressPortInfoList = useSelector((state) => state?.scanInfo.ipAddressPortList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef= useRef(null)

    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [selecetedPortInfo, setSelectedPortInfo] = useState('');

    const selectPortInfo = (data) => {
        if(selecetedPortInfo === data) {
            setSelectedPortInfo("")
        } else {
            setSelectedPortInfo(data)
        }
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchIpAddressPortInfoList = useCallback(()=>{
        setIsLoading(true)
        let url = `&page=1&page_size=50&domain=${domainId}&scan_id=${scanId}&ip_address=${selectedIpDetails?.ip_address}`
        dispatch(getIpAddressPortInfo(url))

    },[dispatch, selectedIpDetails, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current || selectedIpDetails !== prevDomainIdRef.current?.selectedIpDetails)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchIpAddressPortInfoList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchIpAddressPortInfoList, selectedIpDetails]);

    useEffect(()=>{
        if(ipAddressPortInfoList !== null) {
            setIsLoading(false)
        }
    },[ipAddressPortInfoList])

    return (
        <>
            {
                isLoading ? 
                <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                    <Spinner />
                </div>
                :
                ipAddressPortInfoList !== null && ipAddressPortInfoList?.results.length > 0 &&
                ipAddressPortInfoList?.results.map((data, index) => {
                    return (
                        <div className="port-information-container mb-3" key={index}>
                            <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{selectPortInfo(data?.port)}}>
                                <h2 className="heading">Port: {data?.port}</h2>
                                <div className="port-tag-container">
                                    {data?.malware?.is_malware && <span className="tag-content expired">Infected</span>}
                                    {data?.compromised?.is_compromised && <span className="tag-content expired">Compromised</span>}
                                    {data?.risky_port && <span className="tag-content expired">Risky Port</span>}
                                    <img src={PlusIcon} alt="expand"/>
                                </div>                                               
                            </div>
                            {selecetedPortInfo === data?.port && <div className="tab-details-container p-2">
                                <Row>                                   
                                    <Col lg={3}>
                                        <ContentBox label={"Transport"} value={data?.transport} />
                                    </Col>
                                    <Col lg={3}>
                                        <ContentBox label={"Service"} value={data?.service} />
                                    </Col>
                                    <Col lg={3}>
                                        <ContentBox label={"Product"} value={data?.product} />
                                    </Col>
                                    <Col lg={3}>
                                        <ContentBox label={"Product Version"} value={data?.version} />
                                    </Col>
                                </Row>
                            </div>}
                        </div>
                    )
                })
            }
            {
                !isLoading && ipAddressPortInfoList !== null && ipAddressPortInfoList?.results.length === 0 && 
                <NoDataFound noDataText={"No Record Found"}/>
            }
            {ipAddressPortInfoList !== "" && ipAddressPortInfoList?.results.length > 0 && <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                pageSize={currentPageSize}
                totalCount={ipAddressPortInfoList?.meta?.total_results}
                onPageChange={setCurrentPageandRecordSize}
            />}
            
        </>
    )
}

export default PortInformation