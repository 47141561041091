import React, { useCallback, useEffect, useState, useRef } from 'react'; 
import Table from 'react-bootstrap/Table';
import "./index.scss";

import ProfileAvatar from "../../../../../../ui/ProfileAvatar";
import Pagination from "../../../../../../ui/Pagination";
import Spinner from "../../../../../../ui/spinner";
import NoDataFound from "../../../../../../ui/NoDataFound";

import { getTechnologiesImageUrl } from "../../../../../../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { getSubDomainTechnologies } from "../../../../../../../store/actions/scanInfoAction";

const Technologies = ({selectedSubdomain, domainId, scanId}) => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const subDomainTechnologies = useSelector((state) => state?.scanInfo.subDomainTechnologies);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null)

    const dispatch = useDispatch();

    const [technologiesList, setTechnologiesList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchRecordData = useCallback(()=>{
        setIsLoading(true)
        let url = `&page=1&page_size=50&domain=${domainId}&scan_id=${scanId}&subdomain=${selectedSubdomain?.subdomain}`
        dispatch(getSubDomainTechnologies(url))

    },[dispatch, selectedSubdomain, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current || selectedSubdomain !== prevDomainIdRef.current?.selectedSubdomain)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchRecordData();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchRecordData, selectedSubdomain]);

    useEffect(()=>{
        if(subDomainTechnologies !== null) {
            setIsLoading(false)
            setTechnologiesList(subDomainTechnologies)
        }
    },[subDomainTechnologies])

    return (
        <div className="record-container-content">
            <div className="table-container mt-3 record-table p-2 subdomain-technologies-table">
                {
                    isLoading ?
                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                        <Spinner />
                    </div>
                    :
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>Port</th>
                                <th>Name</th>
                                <th>Version</th>
                                <th>Description</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                technologiesList !== "" && technologiesList?.results.length > 0 && 
                                technologiesList?.results.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {data?.port}
                                            </td>
                                            <td>
                                                <ProfileAvatar type={"profile"} firstName={data?.name} lastName={""} imageUrl={getTechnologiesImageUrl(data)}/>
                                            </td>
                                            <td>
                                                <p>{data?.version !== null ? data?.version : "-"}</p>
                                            </td>
                                            <td>
                                                <p>{data?.description}</p>
                                            </td>
                                            <td>
                                                <p className="technologies-category">
                                                    {
                                                        data.categories.length > 0 ?
                                                        data.categories.map((cat, i)=>{
                                                            return (
                                                                <span key={i}>{cat}</span>
                                                            )                                                            
                                                        }) : "-"
                                                    }
                                                </p>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                }
                {
                    !isLoading && technologiesList !== "" && technologiesList?.results.length === 0 && 
                    <NoDataFound noDataText={"No Record Found"}/>
                }
                {technologiesList !== "" && technologiesList?.results.length > 0 && <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    pageSize={currentPageSize}
                    totalCount={technologiesList?.meta?.total_results}
                    onPageChange={setCurrentPageandRecordSize}
                />}                
            </div>            
        </div>
    )
}

export default Technologies;