import { React, useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";
import demoIcon from "../../assets/images/cutomers/demo.svg";
import downArrow from "../../assets/images/common/down-arrow.svg";


import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import Spinner from "../../components/ui/spinner";
import SideModal from "../../components/ui/SideModal";
import { toaster } from "../../components/ui/Toaster";

import DomainTab from "../../components/PostLogin/OrganizationDetails/DomainTab";
import UsersTab from "../../components/PostLogin/OrganizationDetails/UsersTab";
import OrganizationTab from "../../components/PostLogin/UserDetails/OrganizationTab";
import EditSubscriptionModal from "../../components/PostLogin/CustomerDetails/EditSubscriptionModal";
import ExtendSubscriptionModal from "../../components/PostLogin/CustomerDetails/ExtendSubscriptionModal";
import CancelSubscriptionModal from "../../components/PostLogin/CustomerDetails/CancelSubscriptionModal";
import AddCustomerModal from "../../components/PostLogin/Cutomers/AddCustomer";
import DeleteCustomerModal from "../../components/PostLogin/CustomerDetails/DeleteCustomerModal";

import { getCustomerImageUrl, getUserImageUrl, formatDate, formatNumber } from "../../utils/util.js";

import { useSelector, useDispatch } from 'react-redux';
import { getCustomerDetails, resetCustomerDetailResponse, resetUpdateCustomerDetailsResponse, resetCustomerListResponse, resetDeleteCustomerResponse } from '../../store/actions/customerAction.js';

const CustomerDetails = () => {

    const customerDetailResponse = useSelector((state) => state.customer?.customerDetailResponse);
    const deleteCustomerResponse = useSelector((state) => state.customer?.deleteCustomerResponse);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { customerId } = useParams();

    const navigate = useNavigate();

    const editSubscriptionWrapperRef = useRef(null);

    const [activeTab, setActiveTab] = useState("domain");
    const [showSubscriptionOption, setShowSubscriptionOption] = useState(false);
    const [showEditSubscriptionModal, setShowEditSubscriptionModal] = useState(false);
    const [showExtendSubscriptionModal, setShowExtendSubscriptionModal] = useState(false);
    const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
    const [showCancelSubscription, setShowCancelSubscriptionModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);

    const backToMainPage = (data) =>{
        setShowAddCustomerModal(false)
        if(data?.detail?.status === "success") {
            dispatch(resetCustomerDetailResponse())
            dispatch(resetUpdateCustomerDetailsResponse())
            setIsLoading(true)
            fetchCustomerDetails()
        }      
    }

    const fetchCustomerDetails = useCallback(()=>{
        let obj = {
            customer_id: customerId
        }
        setIsLoading(true)
        dispatch(getCustomerDetails(obj))
    },[dispatch, customerId])

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (editSubscriptionWrapperRef.current && !editSubscriptionWrapperRef.current.contains(event.target)) {
                if(showSubscriptionOption){
                    setShowSubscriptionOption(false)
                }
            }
        
        }
    },[showSubscriptionOption])

    const backToCustomerPage = () => {
        dispatch(resetCustomerDetailResponse());
        navigate('/customers')
    }

    useEffect(()=>{
        fetchCustomerDetails()
    },[fetchCustomerDetails])

    useEffect(()=>{
        if(customerDetailResponse !== null) {
            setIsLoading(false)
        }
    },[customerDetailResponse])

    useEffect(()=>{
        if(deleteCustomerResponse != null) {
            if(deleteCustomerResponse?.detail?.status === "success") {
                toaster.addToast(deleteCustomerResponse?.detail?.description, "success")
                dispatch(resetCustomerListResponse())
                setShowDeleteCustomerModal(false)
                navigate(`/customers`)
            } else {
                setShowDeleteCustomerModal(false)
                toaster.addToast(deleteCustomerResponse?.description, "error")
            }
            dispatch(resetDeleteCustomerResponse())
        }
    },[deleteCustomerResponse, dispatch, navigate])

    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1 onClick={()=>{backToCustomerPage()}}>Customers</h1>
                        <img src={arrowRight} alt="arrow-right"/>
                        {customerDetailResponse !== null && <ProfileAvatar type="domain" name={customerDetailResponse?.name} imageUrl={getCustomerImageUrl(customerDetailResponse)} />}
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button onClick={()=>{setShowAddCustomerModal(true)}}>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.editBtn")}
                        </button>
                        <button onClick={()=>{setShowDeleteCustomerModal(true)}}>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.deleteBtn")}
                        </button>
                    </div>
                </div>
                {
                    isLoading ? 
                    <div className="d-flex align-items-center justify-content-center orgaization-loader">
                        <Spinner />
                    </div>
                    :
                    <div className="page-main-section p-4 cutomer-details-container">
                        <div className="organization-created d-flex justify-content-between">
                            <h4>{t('customerDetails.heading')}</h4>
                            {customerDetailResponse !== null && <ProfileAvatar pageType={"organizationDetails"} type="profile" firstName={customerDetailResponse?.name} lastName={""} imageUrl={getUserImageUrl(customerDetailResponse?.created_by)} />}
                        </div>
                        <div className="organization-header-container p-3 my-3 rounded">
                            <div className="d-flex justify-content-between">
                                <div className="header-details d-flex gap-3">
                                    <p>{t('customerDetails.createdOn')} <span>{formatDate(customerDetailResponse?.created_at)}</span></p>
                                    {customerDetailResponse?.updated_at !== null && <p>{t('customerDetails.updatedOn')} <span>{formatDate(customerDetailResponse?.updated_at)}</span></p>}
                                </div>
                                <div className="organization-status position-relative d-flex gap-3">                                
                                    <p className="porfolio" onClick={()=>{setShowSubscriptionOption(!showSubscriptionOption)}}>{t('customerDetails.subscription')} <span><img src={demoIcon} alt="demo" />Demo</span> <img src={downArrow} alt="drop-down"/></p>
                                    {showSubscriptionOption && <div className="position-absolute subscription-edit-option-container" ref={editSubscriptionWrapperRef}>
                                        <div className="subscription-option">
                                            <p onClick={()=>{setShowEditSubscriptionModal(true)}}>{t('customerDetails.edit')}</p>
                                            <p onClick={()=>{setShowExtendSubscriptionModal(true)}}>{t('customerDetails.extend')}</p>
                                            <p onClick={()=>{setShowCancelSubscriptionModal(true)}}>{t('customerDetails.cancel')}</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="d-flex gap-3 mt-3">
                                <div className="total-domains-container rounded">
                                    <p className="total-domain">{formatNumber(customerDetailResponse?.stats.domains?.total)}</p>
                                    <p className="domain-text">{t("organizationDetailsPage.totalDomain")}</p>
                                </div>
                                <div className="total-score-container rounded">
                                    <div className="users-totol-count d-flex">
                                        <p className="total-user-count">{formatNumber(customerDetailResponse?.stats.users?.total)}</p>
                                        <div className="user-count mx-3 mt-2">
                                            <span className="active d-inline-block mx-1"></span>
                                            <span className="disbled d-inline-block"></span>
                                            <p>{t("organizationDetailsPage.totalUser")}</p>
                                        </div>
                                    </div>
                                    <div className="active-disabled-count d-flex gap-3">
                                        <p className="active-user"><span className="d-inline-block">{formatNumber(customerDetailResponse?.stats.users?.active)}</span> {t("organizationDetailsPage.activeUser")}</p>
                                        <p className="disabled-user"><span className="d-inline-block">{formatNumber(customerDetailResponse?.stats.users?.disabled)}</span> {t("organizationDetailsPage.disabledUser")}</p>
                                    </div>
                                </div>
                                <div className="total-domains-container rounded">
                                    <p className="total-domain">{formatNumber(customerDetailResponse?.stats.organizations?.total)}</p>
                                    <p className="domain-text">{t("customerDetails.totalOrganizaton")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="organization-main-container p-3">
                            <div className="orgs-tab-container d-flex rounded">
                                <p className={`rounded text-center ${activeTab === "domain" ? "active" : ""}`} onClick={()=>setActiveTab("domain")}>{t("customerDetails.domain")}</p>
                                <p className={`rounded text-center ${activeTab === "organization" ? "active" : ""}`} onClick={()=>setActiveTab("organization")}>{t("customerDetails.organization")}</p>
                                <p className={`rounded text-center ${activeTab === "user" ? "active" : ""}`} onClick={()=>setActiveTab("user")}>{t("customerDetails.users")}</p>
                            </div>
                            {activeTab === "domain" && <DomainTab customerDetails={customerDetailResponse} currentPageType={"customerDetails"} />}
                            {activeTab === "organization" && <OrganizationTab customerDetails={customerDetailResponse} currentPageType={"customerDetails"}/>}
                            {activeTab === "user" && <UsersTab customerDetails={customerDetailResponse} currentPageType={"customerDetails"} />}
                        </div>
                        <SideModal showSidePanel={showAddCustomerModal} hideSidePanel={()=>{setShowAddCustomerModal(false)}}>
                            <AddCustomerModal type="edit" customerDetails={customerDetailResponse} backToMainPage={backToMainPage}/>
                        </SideModal>
                        <EditSubscriptionModal show={showEditSubscriptionModal} customerDetails={customerDetailResponse}  onHide={() => setShowEditSubscriptionModal(false)} />
                        <ExtendSubscriptionModal show={showExtendSubscriptionModal} customerDetails={customerDetailResponse} onHide={() => setShowExtendSubscriptionModal(false)} />
                        <CancelSubscriptionModal show={showCancelSubscription} customerDetails={customerDetailResponse} onHide={()=>{setShowCancelSubscriptionModal(false)}} />
                        <DeleteCustomerModal show={showDeleteCustomerModal} customerDetails={customerDetailResponse} onHide={()=>{setShowDeleteCustomerModal(false)}} />
                    </div>
                }
                
            </div>
        </div>
    )
}

export default CustomerDetails