import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Row, Col } from 'react-bootstrap';
import './index.scss';

import 'react-datepicker/dist/react-datepicker.css';

const Yearly = () => {

    const data = [
        { title: "Port 1", type: "OpenPort", color: "#FFC0CB", x: 0.5, y: 0.4, value: 90 },
        { title: "Subdomain 1", type: "Subdomain", color: "#fecbd4", x: 1.8, y: 0.9, value: 110 },
        { title: "JS Secret 1", type: "JS Secret", color: "#45eaf8", x: 3.2, y: 2.3, value: 90 },
        { title: "Infected 1", type: "Infected", color: "#18E7F8", x: 4.5, y: 2.3, value: 80 },

        { title: "Port 2", type: "Web Server", color: "#FFC0CB", x: 0.5, y: 0.9, value: 100 },
        { title: "Subdomain 2", type: "Subdomain", color: "#18E7F8", x: 1.8, y: 2.3, value: 100 },
        { title: "JS Secret 2", type: "JS Secret", color: "#FFC0CB", x: 3.2, y: 0.9, value: 110 },
        { title: "Infected 2", type: "Infected", color: "#FFC0CB", x: 4.5, y: 0.9, value: 102 },

        { title: "Port 3", type: "Other Vulnerabilities", color: "#18E7F8", x: 0.5, y: 2.3, value: 125 },
        { title: "Subdomain 3", type: "Subdomain", color: "#fecbd4", x: 1.8, y: 1.5, value: 110 },
        { title: "JS Secret 3", type: "JS Secret", color: "#FFC0CB", x: 3.2, y: 0.4, value: 115 },
        { title: "Infected 3", type: "Infected", color: "#FFC0CB", x: 4.5, y: 0.4, value: 70 },

        { title: "Port 4", type: "Web Server", color: "#FFC0CB", x: 0.5, y: 1.5, value: 150 },
        { title: "Subdomain 4", type: "Subdomain", color: "#fecbd4", x: 1.8, y: 0.4, value: 90 },
        { title: "JS Secret 4", type: "JS Secret", color: "#FFC0CB", x: 3.2, y: 1.5, value: 125 },
        { title: "Infected 4", type: "Infected", color: "#FFC0CB", x: 4.5, y: 1.5, value: 130 },

        { title: "Web Server 5", type: "Web Server", color: "#18E7F8", x: 0.5, y: 3.3, value: 120 },
        { title: "Web Server 6", type: "Web Server", color: "#18E7F8", x: 0.5, y: 2.8, value: 95 },
        { title: "Web Server 7", type: "Web Server", color: "#18E7F8", x: 0.5, y: 3.8, value: 125 },
        { title: "Web Server 8", type: "Web Server", color: "#18E7F8", x: 0.5, y: 4.3, value: 80 },
        { title: "Web Server 9", type: "Web Server", color: "#F66C93", x: 0.5, y: 6.1, value: 95 },
        { title: "Web Server 10", type: "Web Server", color: "#F66C93", x: 0.5, y: 5.7, value: 125 },
        { title: "Web Server 11", type: "Web Server", color: "#56aaf6", x: 0.5, y: 7.9, value: 95 },
        { title: "Web Server 12", type: "Web Server", color: "#56aaf6", x: 0.5, y: 6.8, value: 120 },
        { title: "Web Server 13", type: "Web Server", color: "#56aaf6", x: 0.5, y: 7.4, value: 105 },
        { title: "Port 6", type: "Technologies", color: "#56aaf6", x: 0.5, y: 8.4, value: 95 },
        { title: "Port", type: "OpenPort", color: "#56aaf6", x: 0.5, y: 9.6, value: 130 },
        { title: "SSL Certificate", type: "SSL Certificate", color: "#56aaf6", x: 0.5, y: 9, value: 150 },

        { title: "Subdomain 5", type: "Subdomain", color: "#45eaf8", x: 1.8, y: 3.3, value: 120 },
        { title: "Subdomain 6", type: "Subdomain", color: "#45eaf8", x: 1.8, y: 2.8, value: 105 },
        { title: "Subdomain 7", type: "Subdomain", color: "#45eaf8", x: 1.8, y: 4.3, value: 110 },
        { title: "Subdomain 8", type: "Subdomain", color: "#45eaf8", x: 1.8, y: 3.8, value: 120 },
        { title: "Subdomain 9", type: "Subdomain", color: "#f688a7", x: 1.8, y: 5.7, value: 105 },
        { title: "Subdomain 10", type: "Subdomain", color: "#f688a7", x: 1.8, y: 6.1, value: 75 },
        { title: "Subdomain 11", type: "Subdomain", color: "#56aaf6", x: 1.8, y: 6.8, value: 95 },
        { title: "Subdomain 12", type: "Subdomain", color: "#56aaf6", x: 1.8, y: 7.4, value: 100 },
        { title: "Subdomain 13", type: "Subdomain", color: "#56aaf6", x: 1.8, y: 7.9, value: 80 },
        { title: "Subdomain 14", type: "Subdomain", color: "#56aaf6", x: 1.8, y: 8.4, value: 105 },
        { title: "Subdomain 15", type: "Subdomain", color: "#56aaf6", x: 1.8, y: 9.6, value: 130 },
        { title: "Subdomain 16", type: "Subdomain", color: "#56aaf6", x: 1.8, y: 9, value: 95 },

        { title: "JS Secret 5", type: "JS Secret", color: "#45eaf8", x: 3.2, y: 3.3, value: 120 },
        { title: "JS Secret 6", type: "JS Secret", color: "#45eaf8", x: 3.2, y: 2.8, value: 100 },
        { title: "JS Secret 7", type: "JS Secret", color: "#45eaf8", x: 3.2, y: 4.3, value: 90 },
        { title: "JS Secret 8", type: "JS Secret", color: "#45eaf8", x: 3.2, y: 3.8, value: 97 },
        { title: "JS Secret 9", type: "JS Secret", color: "#f688a7", x: 3.2, y: 5.7, value: 103 },
        { title: "JS Secret 10", type: "JS Secret", color: "#f688a7", x: 3.2, y: 6.1, value: 120 },
        { title: "JS Secret 11", type: "JS Secret", color: "#56aaf6", x: 3.2, y: 6.8, value: 70 },
        { title: "JS Secret 12", type: "JS Secret", color: "#56aaf6", x: 3.2, y: 7.4, value: 80 },
        { title: "JS Secret 13", type: "JS Secret", color: "#56aaf6", x: 3.2, y: 7.9, value: 120 },
        { title: "JS Secret 14", type: "JS Secret", color: "#56aaf6", x: 3.2, y: 8.4, value: 110 },
        { title: "JS Secret 15", type: "JS Secret", color: "#56aaf6", x: 3.2, y: 9.6, value: 120 },
        { title: "JS Secret 16", type: "JS Secret", color: "#56aaf6", x: 3.2, y: 9, value: 90 },

        { title: "Infected 5", type: "Infected", color: "#18E7F8", x: 4.5, y: 3.3, value: 140 },
        { title: "Infected 6", type: "Infected", color: "#18E7F8", x: 4.5, y: 2.8, value: 90 },
        { title: "Infected 7", type: "Infected", color: "#18E7F8", x: 4.5, y: 4.3, value: 100 },
        { title: "Infected 8", type: "Infected", color: "#18E7F8", x: 4.5, y: 3.8, value: 120 },
        { title: "Infected 9", type: "Infected", color: "#f69eb7", x: 4.5, y: 6.1, value: 100 },
        { title: "Infected 10", type: "Infected", color: "#f69eb7", x: 4.5, y: 5.7, value: 80 },
        { title: "Infected 11", type: "Infected", color: "#76baf6", x: 4.5, y: 6.8, value: 95 },
        { title: "Infected 12", type: "Infected", color: "#76baf6", x: 4.5, y: 7.4, value: 120 },
        { title: "Infected 13", type: "Infected", color: "#76baf6", x: 4.5, y: 7.9, value: 95 },
        { title: "Infected 14", type: "Infected", color: "#76baf6", x: 4.5, y: 8.4, value: 110 },
        { title: "Infected 15", type: "Infected", color: "#76baf6", x: 4.5, y: 9, value: 85 },
        { title: "Infected 16", type: "Infected", color: "#76baf6", x: 4.5, y: 9.6, value: 120 },

        { title: "Ports 8", type: "Infected", color: "#46ebf9", x: 0.5, y: 4.8, value: 120 },
        { title: "Ports 9", type: "Infected", color: "#46ebf9", x: 1.8, y: 4.8, value: 120 },
        { title: "Port 10", type: "Infected", color: "#46ebf9", x: 3.2, y: 4.8, value: 120 },
        { title: "Port 11", type: "Infected", color: "#46ebf9", x: 4.5, y: 4.8, value: 120 },
    ];

    useEffect(() => {
        const bubbleChartId = "bubble-chartdiv";

        // Dispose of existing root instance for this chart
        const existingRoot = am5.registry.rootElements.find(
            (root) => root.dom && root.dom.id === bubbleChartId
        );
        if (existingRoot) {
            existingRoot.dispose();
        }

        // Create root element
        const root = am5.Root.new(bubbleChartId);

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelY: "none",
                pinchZoomX: false,
                pinchZoomY: false,
            })
        );

        // Disable "Created with amCharts 5" label
        root._logo.dispose();

        // Create axes
        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                max: 5,
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        // Remove background grid lines and labels for X-axis
        xAxis.get("renderer").grid.template.set("visible", false);
        xAxis.get("renderer").labels.template.set("visible", false);

        // xAxis.children.moveValue(
        //     am5.Label.new(root, {
        //         text: "Weeks",
        //         x: am5.p50,
        //         centerX: am5.p50,
        //     }),
        //     xAxis.children.length - 1
        // );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                max: 10,
                strictMinMax: true,
                renderer: am5xy.AxisRendererY.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        // Remove background grid lines and labels for Y-axis
        yAxis.get("renderer").grid.template.set("visible", false);
        yAxis.get("renderer").labels.template.set("visible", false);

        // yAxis.children.moveValue(
        //     am5.Label.new(root, {
        //         rotation: -90,
        //         text: "Categories",
        //         y: am5.p50,
        //         centerX: am5.p50,
        //     }),
        //     0
        // );

        // Create series
        const series = chart.series.push(
            am5xy.LineSeries.new(root, {
                calculateAggregates: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y",
                valueXField: "x",
                valueField: "value",
                seriesTooltipTarget: "bullet",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText:
                        // "[bold]{title}[/]\nType: {type}\nX: {valueX.formatNumber('#.0')}\nWeek: {valueY.formatNumber('#')}\nCount: {value}",
                        "[bold]Ports[/]\nCount: {value}",
                }),
            })
        );

        series.strokes.template.set("visible", false);

        // Add bullet with border
        const circleTemplate = am5.Template.new({});
        circleTemplate.adapters.add("fill", (fill, target) => {
            const dataItem = target.dataItem;
            if (dataItem && dataItem.dataContext && dataItem.dataContext.color) {
                return am5.Color.fromString(dataItem.dataContext.color);
            }
            return fill || am5.Color.fromString("#ccc");
        });

        circleTemplate.adapters.add("stroke", (stroke, target) => {
            const dataItem = target.dataItem;
            if (dataItem && dataItem.dataContext && dataItem.dataContext.color) {
                return am5.Color.fromString(dataItem.dataContext.color);
            }
            return stroke || am5.Color.fromString("#ccc");
        });

        series.bullets.push(() =>
            am5.Bullet.new(root, {
                sprite: am5.Circle.new(
                    root,
                    {
                        radius: 5,
                        fill: series.get("fill"),
                        fillOpacity: 0.8,
                        stroke: series.get("fill"),
                        strokeWidth: 2,
                    },
                    circleTemplate
                ),
            })
        );

        // Add heat rule
        series.set("heatRules", [
            {
                target: circleTemplate,
                min: 10,
                max: 40,
                dataField: "value",
                key: "radius",
            },
        ]);

        // Set data
        series.data.setAll(data);

        // Add cursor
        // Add cursor
        chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                snapToSeries: [series],
                behaviors: [], // Optional: disable zooming or panning behaviors
            })
        );

        // Hide horizontal and vertical lines
        const cursor = chart.get("cursor");
        cursor.lineX.set("visible", false);
        cursor.lineY.set("visible", false);


        // Add scrollbars
        // chart.set(
        //     "scrollbarX",
        //     am5.Scrollbar.new(root, { orientation: "horizontal" })
        // );
        // chart.set(
        //     "scrollbarY",
        //     am5.Scrollbar.new(root, { orientation: "vertical" })
        // );

        // Animate on load
        series.appear(1000);
        chart.appear(1000, 80);

        // Clean up on component unmount
        return () => {
            root.dispose();
        };
    }, []);


    return (

        <>
            <div className="mt-5">
                <Row>
                    <Col lg={3}>
                        <div className="weekly-bubble text-center">
                            <h4>September</h4>
                            <p>sep 6, 2024</p>
                        </div>
                    </Col>
                    <Col lg={3} >
                        <div className="weekly-bubble text-center">
                            <h4>October</h4>
                            <p>Oct 14, 2024</p>
                        </div>

                    </Col>
                    <Col lg={3}>
                        <div className="weekly-bubble text-center">
                            <h4>November</h4>
                            <p>Nov 20, 2024</p>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="weekly-bubble text-center">
                            <h4>December</h4>
                            <p>Dec 26, 2024</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div id="bubble-chartdiv" style={{ width: "100%", height: "1300px" }}></div>
        </>
    )

};

export default Yearly;
