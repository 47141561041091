import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';

import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";

import Pagination from "../../../../../components/ui/Pagination";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import Spinner from "../../../../../components/ui/spinner";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getMobileAppsList } from "../../../../../store/actions/scanInfoAction";

const MobileApps = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const mobileAppsListResponse = useSelector((state) => state.scanInfo?.mobileAppsList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [searchValue, setSearchValue] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const onSearch = () => {
        setIsLoading(true)
        setCurrentPage(1)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&query=${searchValue}`
        dispatch(getMobileAppsList(url))
    }

    const fetchRecordData = useCallback(()=>{
        setIsLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}`
        dispatch(getMobileAppsList(url))
    },[dispatch, currentPage, currentPageSize, domainId, scanId])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchRecordData();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchRecordData]);

    useEffect(()=>{
        if(mobileAppsListResponse !== null) {
            setIsLoading(false)
        }
    },[mobileAppsListResponse])

    return (
        <>
            <div className="git-leaks-main-content bg-white-with-shadow p-3">
                <div className="search-header-container d-flex align-items-center gap-3">
                    <div className="search-container position-relative">
                        <img src={SearchIcon} alt="Search" className="position-absolute"/>
                        <input type="text" placeholder="Search" value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}}/>
                    </div>
                    <button className="search-btn" onClick={onSearch}>Search</button>
                    <img src={FilterIcon} alt="filter" className="filtericon"/>
                </div>
                <div className="table-container mt-3">
                    {
                        isLoading ?
                        <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                            <Spinner />
                        </div>
                        :
                        <Table>
                            <thead>
                                <tr>
                                    <th>Platform</th>
                                    <th>Name</th>
                                    <th>App ID</th>
                                    <th>Developer</th>
                                    <th>URL</th>
                                    <th>Summary</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mobileAppsListResponse?.results.length > 0 &&
                                    mobileAppsListResponse?.results.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {data?.platform}
                                                </td>
                                                <td>
                                                    {data?.app_name}
                                                </td>
                                                <td>
                                                    {data?.app_id}
                                                </td>
                                                <td>
                                                    {data?.app_developer}
                                                </td>
                                                <td>
                                                    {data?.app_url}
                                                </td>
                                                <td>
                                                    {data?.app_summary}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table> 
                    }
                                       
                </div>
                {
                    !isLoading && mobileAppsListResponse !== "" && mobileAppsListResponse?.results.length === 0 && 
                    <NoDataFound noDataText={"No Record Found"}/>
                }
                {mobileAppsListResponse !== "" && mobileAppsListResponse?.results.length > 0 && <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    pageSize={currentPageSize}
                    totalCount={mobileAppsListResponse?.meta?.total_results}
                    onPageChange={setCurrentPageandRecordSize}
                />}
            </div>
        </>        
    )
}


export default MobileApps;