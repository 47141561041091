const initialState = {
    loginResponse: null,
    forgetPasswordResponse: null,
    reset2FaResponse: null,
    resetPasswordResponse: null,
    verify2FaResponse: null,
    reset2FaConfirmationResponse: null,
    loggedInUserDetailResponse: null,
};
  
export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
              ...state,
              loginResponse: action.payload,
            };
        case 'FORGET_PASSWORD_SUCCESS':
            return {
              ...state,
              forgetPasswordResponse: action.payload,
            };
        case 'RESET_2FA_SUCCESS':
            return {
                ...state,
                reset2FaResponse: action.payload,
            };
        case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                resetPasswordResponse: action.payload,
            }
        case 'VERIFY_ACCOUNT_SUCCESS':
            return {
                ...state,
                verify2FaResponse: action.payload,
            }
        case 'RESET_2FA_CONFIRMATION_SUCCESS':
            return {
                ...state,
                reset2FaConfirmationResponse: action.payload,
            }
        case 'RESET_FORGET_PASSWORD_RESPONSE':
            return { 
                ...state, 
                forgetPasswordResponse: action.payload, 
            }
        case 'RESET_RESET2FA_RESPONSE':
            return { 
                ...state, 
                reset2FaResponse: null 
            };
        case 'LOGGED_IN_USER_DETAILS_SUCCESS':
            return {
                ...state,
                loggedInUserDetailResponse: action.payload
            }
        case 'RESET_USER_DETAILS_RESPONSE' :
            return {
                ...state,
                loggedInUserDetailResponse: null
            }
        default:
            return state;
    }
}