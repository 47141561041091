import { React } from 'react';

import { useTranslation } from "react-i18next";

import "./index.scss";

const RightFooter = () => {

    const { t } = useTranslation();

    return (
        <div className="right-footer mt-5">
            <ul type="none">
                <li>{t('loginPage.rightSection.contact')}</li>
                <li>{t('loginPage.rightSection.PrivacyPolicy')}</li>
                <li>{t('loginPage.rightSection.aboutCybermindr')}</li>
            </ul>
        </div>
    )
}

export default RightFooter;