import { React } from 'react';

import { Oval } from 'react-loader-spinner';

const Spinner = () => {

    return (
        <Oval
            visible={true}
            height="50"
            width="50"
            color="#0064FF"
            strokeWidth="4"
            strokeWidthSecondary="4"
            secondaryColor="#18e7f8b2"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    )
}

export default Spinner;