import React, { createContext, useContext } from 'react';

export const ScanInfoContext = createContext();

export const useScanInfoContext = () => useContext(ScanInfoContext);

const ScanInfoProvider = ({ children, domainId, scanId }) => {
    return (
        <ScanInfoContext.Provider value={{ domainId, scanId }}>
            {children}
        </ScanInfoContext.Provider>
    );
};

export default ScanInfoProvider;