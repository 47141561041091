import { apiGet, apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function getCustomerStats() {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.customer.customerStats())
            dispatch({
                type: 'CUSTOMER_STATS_SUCCESS',
                payload: response.data
            })
        } catch(error) {
            console.log(error)
        }
    }
}

export function getCustomersList(url="") {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.customer.customerList()+url)
            dispatch({
                type: 'CUSTOMER_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function addCustomer(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.addCustomer(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'ADD_CUSTOMER_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ADD_CUSTOMER_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function getCustomerDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.customerDetails(), obj)
            dispatch({
                type: 'CUSTOMER_DETAILS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetCustomerDetailResponse() {
    return (disptch) =>{
        disptch({
            type: 'RESET_CUSTOMER_DETAIL_RESPONSE'
        })
    }
}

export function updateCustomerDetailsDetail(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.editCustomer(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'UPDATE_CUSTOMER_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'UPDATE_CUSTOMER_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetUpdateCustomerDetailsResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_UPDATE_CUSTOMER_DETAIL_RESPONSE'
        })
    }
}

export function deleteCustomer(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.deleteCustomer(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'DELETE_CUSTOMER_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DELETE_CUSTOMER_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {   
            console.log(error)
        }
    }
}

export function resetCustomerListResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_CUSTOMER_LIST_RESPONSE'
        })
    }
}

export function resetDeleteCustomerResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_DELETE_CUSTOMER_RESPONSE'
        })
    }
}

export function editSubscription(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.editSubscription(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'EDIT_SUBSCRIPTION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'EDIT_SUBSCRIPTION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error){
            console.log(error)
        }
    }
}

export function resetEditSubscriptionResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_EDIT_SUBSCRIPTION_RESPONSE'
        })
    }
}

export function extendSubscription(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.extendSubscription(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'EXTEND_SUBSCRIPTION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'EXTEND_SUBSCRIPTION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function cancelSubscription(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.cancelSubscription(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function getPermissionDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.premissionDetails(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'PERMISSION_DETAILS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'PERMISSION_DETAILS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function editDomainPermission (obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.customer.editDomainPermission(), obj)
            if(response.status === 200){
                dispatch({
                    type: 'EDIT_DOMAIN_PERMISSION_DETAILS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'EDIT_DOMAIN_PERMISSION_DETAILS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetEditPermissionResponse() {
    return async (dispatch) => {
        dispatch({
            type: 'RESET_EDIT_DOMAIN_PERMISSION_RESPONSE'
        })
    }
}

export function updateCustomerStatus(url, obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(url, obj)
            if(response.status === 200){
                dispatch({
                    type: 'UPDATE_CUSTOMER_STATUS_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'UPDATE_CUSTOMER_STATUS_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetUpdateCustomerStatusResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_UPDATE_CUSTOMER_STATUS_RESPONSE'
        })
    }
}