import React from 'react';

import { Row, Col } from 'react-bootstrap';

import ContentBox from "../../../../../../ui/ContentBox";

const TechnologyInformation = ({selectedTechnology}) => {
    return (
        <div className="mt-3">
            <Row>
                <Col lg={6} className="mb-3">
                    <ContentBox label={"name"} value={selectedTechnology?.name} />
                </Col>
                <Col lg={6} className="mb-3">
                    <ContentBox label={"Description"} value={selectedTechnology?.description} />
                </Col>
                <Col lg={6} className="mb-3">
                    <ContentBox label={"Website"} value={selectedTechnology?.website} />
                </Col>
                <Col lg={6} className="mb-3">
                    {selectedTechnology !== "" && <ContentBox label={"Categories"} value={selectedTechnology?.categories.join(", ")} />}
                </Col>
            </Row>
        </div>
    )
}

export default TechnologyInformation;