import { React, useState, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import "./index.scss";

import Delete from "../../../../assets/images/common/delete-session.svg";

import Pagination from '../../../ui/Pagination';
import Spinner from "../../../ui/spinner";
import { toaster } from "../../../ui/Toaster";
import ProfileAvatar from "../../../ui/ProfileAvatar";
import NoDataFound from "../../../ui/NoDataFound";

import { formatDate, formatRelativeTime, getCountryImageUrl } from "../../../../utils/util.js";


import { useSelector, useDispatch } from 'react-redux';
import { getUserSession, deleteUserSession } from '../../../../store/actions/settingsAction.js';

const UserSessionTab = () => {

    const userSessionList = useSelector((state) => state.settings?.userSessionList);
    const deleteSessionResponse = useSelector((state) => state.settings?.deleteSessionResponse)

    const { t } = useTranslation()
    
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false)

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchUserSession = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}`;
        setIsLoading(true)
        dispatch(getUserSession(url))
    },[currentPage, currentPageSize, dispatch])

    const deleteSession = (data) => {
        let obj = {
            session_id: data.session_id
        }
        dispatch(deleteUserSession(obj))
    }

    useEffect(()=>{
        fetchUserSession()
    },[fetchUserSession])

    useEffect(()=>{
        if(userSessionList !== null){
            setIsLoading(false)
        }
    },[userSessionList])

    useEffect(()=>{
        if(deleteSessionResponse != null) {
            if(deleteSessionResponse?.detail?.status === "success") {
                toaster.addToast(deleteSessionResponse?.detail?.description, "success");
                fetchUserSession()
            } else {
                toaster.addToast(deleteSessionResponse?.description, "error");
            }
        }
    },[deleteSessionResponse, fetchUserSession])
    
    return (
        <>
            <div className="active-session-container">
                <div className="active-session-tabel">
                    <div className="list-view-container user-session-list p-3">
                        {
                            isLoading ?
                            <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%',marginBottom:'10%'}}>
                                <Spinner />
                            </div>
                            :
                            <>
                                <Table responsive="lg">
                                    <thead>
                                        <tr>
                                            <th>{t("accountSettingPage.userSessionTab.userAgent")}</th>
                                            <th>{t("accountSettingPage.userSessionTab.lastAccessed")}</th>
                                            <th>{t("accountSettingPage.userSessionTab.lastLogin")}</th>
                                            <th>{t("accountSettingPage.userSessionTab.ipAddress")}</th>
                                            <th>{t("accountSettingPage.userSessionTab.address")}</th>
                                            <th>{t("accountSettingPage.userSessionTab.actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userSessionList?.results.length > 0 ? 
                                            userSessionList?.results.map((data, index)=>{
                                                return (
                                                    <tr key={index}>
                                                        <td className="system-name">{data.user_agent} {data.current_session ? <span>CURRENT SESSION</span> : ""}</td>
                                                        <td>{formatRelativeTime(data?.last_accessed)}</td>
                                                        <td>{formatDate(data?.last_accessed)}</td>
                                                        <td>
                                                            <p>{data.ip_address}</p>
                                                        </td>
                                                        <td>
                                                            <ProfileAvatar type={"profile"} firstName={`${data?.city}, ${data?.region}`} lastName={""} secondaryText={`${data.country}, ${data.continent}`} imageUrl={getCountryImageUrl(data)}/>
                                                        </td>
                                                        <td className="delete-session"><img src={Delete} alt="delete session" onClick={()=>{deleteSession(data)}}/></td>
                                                    </tr>
                                                )
                                            }) 
                                            
                                            :                                             
                                                <tr>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                                        <NoDataFound />
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                                {userSessionList != null && userSessionList?.results.length > 0 && <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        pageSize={currentPageSize}
                                        totalCount={userSessionList.meta.total_results}
                                        onPageChange={setCurrentPageandRecordSize}
                                    />}
                            </>
                        } 
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserSessionTab;