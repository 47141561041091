import React, { useCallback, useEffect, useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';

import Pagination from "../../../../../../ui/Pagination";
import Spinner from "../../../../../../ui/spinner";
import NoDataFound from "../../../../../../ui/NoDataFound";

import { useSelector, useDispatch } from 'react-redux';
import { getEndPointList } from "../../../../../../../store/actions/scanInfoAction";
import apiEndpoints from "../../../../../../../apiEndpoints";

const PresentIn = ({selectedTechnology, domainId, scanId, currentPageType="sslRecord"}) => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const EndpointListResponse = useSelector((state) => state?.scanInfo.endPointsList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null)

    const dispatch = useDispatch();

    const [endPointList, setEndPointList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchEndPointsList = useCallback(()=>{
        if(currentPageType === "sslRecord") {
            setIsLoading(true)
            let url = `${apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.sslCertificate.endPoints()}&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedTechnology?.id}`
            dispatch(getEndPointList(url))
        } else {
            setIsLoading(true)
            let url = `${apiEndpoints.scanInfo.exposureManagemnet.assestDiscovery.technology.endPoints()}&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedTechnology?.id}`
            dispatch(getEndPointList(url))
        }
    },[dispatch, selectedTechnology, currentPageSize, currentPage, domainId, scanId, currentPageType])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current || selectedTechnology !== prevDomainIdRef.current?.selectedTechnology)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchEndPointsList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId
            }
        }
    },[domainListResponse, domainId, scanId, fetchEndPointsList, selectedTechnology]);

    useEffect(()=>{
        console.log("EndpointListResponse",EndpointListResponse)
        if(EndpointListResponse !== null) {
            setIsLoading(false)
            setEndPointList(EndpointListResponse)
        }
    },[EndpointListResponse])

    return (
        <div className="mt-3">
            {
                isLoading ?
                <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                    <Spinner />
                </div>
                :
                <Table>
                    <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Host</th>
                            <th>Port</th>
                            <th>Version</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            endPointList !== "" && endPointList?.results.length > 0 &&
                            endPointList.results.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {data?.domain}
                                        </td>
                                        <td>
                                            {data?.host}
                                        </td>
                                        <td>
                                            <p>{data?.port}</p>
                                        </td>
                                        <td>
                                            {data?.version != null ? data?.version : "-"}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
            {
                !isLoading && endPointList !== "" && endPointList?.results.length === 0 && 
                <NoDataFound noDataText={"No Record Found"}/>
            }
            {endPointList !== "" && endPointList?.results.length > 0 && <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                pageSize={currentPageSize}
                totalCount={endPointList?.meta?.total_results}
                onPageChange={setCurrentPageandRecordSize}
            />}
        </div>
    )
}

export default PresentIn;