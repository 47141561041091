import React, { useCallback, useEffect, useState, useRef } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";


import ContentBox from "../../../../../components/ui/ContentBox";
import Spinner from "../../../../../components/ui/spinner";
import NoDataFound from "../../../../../components/ui/NoDataFound";

import WhoisRegistrant from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DomainInfo/WhoisRegistrant";
import AdminContact from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DomainInfo/AdminContact";
import TechnicalContact from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/DomainInfo/TechnicalContact";

import { formatDate } from "../../../../../utils/util";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getWhoisInformation } from "../../../../../store/actions/scanInfoAction";

const DomainInfo = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const whoisInformation = useSelector((state) => state.scanInfo?.whoisInformation);

    // Ref to track the previous domainId
    const prevDomainIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeTab, setActiveTab] = useState("whoisRegistrant");
    const [isLoading, setIsLoading] = useState(false);

    const fetchWhoisInfomation = useCallback((data)=>{
        let url = `&domain=${data?.domain}&scan_id=${data?.last_scan_detail?.scan_id}`;
        setIsLoading(true);
        dispatch(getWhoisInformation(url))
    },[dispatch])

    useEffect(()=>{
        if(domainListResponse !== null && domainId !== prevDomainIdRef.current) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchWhoisInfomation(selectedDomain[0]);
                prevDomainIdRef.current = domainId;
            }            
        }      
    },[domainId, domainListResponse, fetchWhoisInfomation, scanId])

    useEffect(()=>{
        if(whoisInformation !== null) {
            setIsLoading(false)
        }
    },[whoisInformation])

    return (
        <>
            {
                isLoading ?
                <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%',marginBottom:'10%'}}>
                    <Spinner />
                </div>
                :
                <div className="scan-info-main-content">
                    {
                        whoisInformation?.status === "failed" ?
                        <>
                            <NoDataFound noDataText={whoisInformation.description}/>
                        </>
                        :
                        <>
                            <div className="bg-white-with-shadow content-main-box p-4">
                                <h2 className="content-heading">Domain Registrar</h2>
                                <Row>
                                    <Col lg="3">
                                        <ContentBox label={"Registrar"} value={whoisInformation?.domain_registrar?.name} />
                                    </Col>
                                    <Col lg="3">
                                        <ContentBox label={"Created On"} value={whoisInformation?.domain_registrar?.create_date !== null ? formatDate(whoisInformation?.domain_registrar?.create_date, 'date') : "-"} />
                                    </Col>
                                    <Col lg="3">
                                        <ContentBox label={"Last Updated On"} value={whoisInformation?.domain_registrar?.update_date !== null ? formatDate(whoisInformation?.domain_registrar?.update_date, 'date') : "-"} />
                                    </Col>
                                    <Col lg="3">
                                        <ContentBox label={"Expiry Date"} value={whoisInformation?.domain_registrar?.expiry_date !== null ? formatDate(whoisInformation?.domain_registrar?.expiry_date, 'date') : "-"} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="bg-white-with-shadow mt-4 p-4">
                                <div className="tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "whoisRegistrant" ? "active" : ""}`} onClick={()=>{setActiveTab('whoisRegistrant')}}>WHOIS Registrant</p>
                                    <p className={`rounded text-center ${activeTab === "adminContact" ? "active" : ""}`} onClick={()=>{setActiveTab('adminContact')}}>Admin Contact</p>
                                    <p className={`rounded text-center ${activeTab === "technicalContact" ? "active" : ""}`} onClick={()=>{setActiveTab('technicalContact')}}>Technical Contact</p>
                                </div>
                                {activeTab === "whoisRegistrant" && <WhoisRegistrant registrantData={whoisInformation?.registrant_contact}/>}
                                {activeTab === "adminContact" && <AdminContact registrantData={whoisInformation?.administrative_contact}/>}
                                {activeTab === "technicalContact" && <TechnicalContact registrantData={whoisInformation?.technical_contact}/>}
                            </div>
                        </>
                    }                                       
                </div>
            }            
        </>
        
    )
}


export default DomainInfo;