import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';


import domainIcon from "../../../../assets/images/common/domain.svg";

import Button from "../../../../components/ui/Button";

import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSingle } from '../../../../store/actions/usersAction';


const DeleteUserModal = ({show, onHide, userDetails}) => {

    const deleteUserResponse = useSelector((state) => state?.users?.deleteUserResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false)

    const confirmDelete = () => {
        let obj = {
            user_id: userDetails?.user_id
        }
        setIsLoading(true)
        dispatch(deleteUserSingle(obj))
    }

    useEffect(()=>{
        if(deleteUserResponse !== null){
            setIsLoading(false)
        }
    },[deleteUserResponse])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("users.deleteUserModal.heading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("organizationDetailsPage.deleteOrganization.subText")} {userDetails?.firstname} {userDetails?.lastname}?</p>
                            <div className="d-flex justify-content-between mt-4 gap-3">
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("users.deleteUserModal.totalScan")}</p>
                                        <p className="domain-count">{userDetails?.stats?.scans?.total}</p>
                                    </div>
                                </div>
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("users.deleteUserModal.totalOrganization")}</p>
                                        <p className="domain-count">{userDetails?.stats?.scans?.total}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="delete-organization-btn d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            {!isLoading && <Button buttonText="Delete" buttonType={"primary"} buttonClicked={()=>{confirmDelete()}} />}
                            {isLoading && <Button buttonText="Loading..." buttonType={"primary"} disableButton={true} />}
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default DeleteUserModal;