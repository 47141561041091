import { React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";
import userRole from "../../assets/images/common/user-role.svg"

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import SideModal from "../../components/ui/SideModal";
import Spinner from "../../components/ui/spinner";
import { toaster } from "../../components/ui/Toaster";

import OrganizationTab from "../../components/PostLogin/UserDetails/OrganizationTab";
import ScanHistoryTab from "../../components/PostLogin/UserDetails/ScanHistory";
import UserSessionTab from "../../components/PostLogin/UserDetails/UserSessionTab";
import EditUserModal from "../../components/PostLogin/UserDetails/EditUserModal";
import DeleteUserModal from "../../components/PostLogin/UserDetails/DeleteUserModal";

import { getUserImageUrl,formatDate } from "../../utils/util";

import { useSelector, useDispatch } from 'react-redux';
import { getUserDetails } from '../../store/actions/usersAction';

const UserDetails = () => {

    const userDetailResponse = useSelector((state) => state.users.userDetails);
    const editUserResponse = useSelector((state) => state?.users?.editUserResponse);
    const deleteUserResponse = useSelector((state) => state?.users?.deleteUserResponse)

    const { t } = useTranslation();

    const { userId } = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("organization");
    const [showEditUserModal, setShowEditUsermodal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const backToUsersPage = () => {
        navigate(`/users`)
    }

    const closeEditUserModal = () => {
        if(editUserResponse?.detail?.status === "success") {
            fetchUserDetails()
        }
        setShowEditUsermodal(false)
    }

    const fetchUserDetails = useCallback (()=>{
        let obj = {
            user_id: userId
        }
        setIsLoading(true)
        dispatch(getUserDetails(obj))
    },[dispatch, userId])

    useEffect(()=>{
        fetchUserDetails()
    },[fetchUserDetails])

    useEffect(()=>{
        if(userDetailResponse != null) {
            setIsLoading(false)
        }
    },[userDetailResponse])

    useEffect(()=>{
        if(deleteUserResponse !== null) {
            setShowDeleteUserModal(false)
            if(deleteUserResponse?.detail?.status === "success") {
                toaster.addToast(deleteUserResponse?.detail?.description, "success");
                navigate('/users')
            } else {
                toaster.addToast(deleteUserResponse?.description, "error");
            }
        }
    },[deleteUserResponse, navigate])

    return (
        <div className="users-details-container">
            <SideNav />
            <div className="users-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1 onClick={()=>{backToUsersPage()}}>{t("userDetailsPage.heading")}</h1>
                        <img src={arrowRight} alt="arrow-right"/>
                        <ProfileAvatar type="profile" firstName={userDetailResponse?.firstname} lastName={userDetailResponse?.lastname} imageUrl={userDetailResponse !== null ? getUserImageUrl(userDetailResponse) : ""} />
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button onClick={()=>{setShowEditUsermodal(true)}}>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("userDetailsPage.editBtn")}
                        </button>
                        <button onClick={()=>{setShowDeleteUserModal(true)}}>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("userDetailsPage.deleteBtn")}
                        </button>
                    </div>
                </div>
                {
                    isLoading ?
                    <div className="d-flex align-items-center justify-content-center orgaization-loader">
                        <Spinner />
                    </div>
                    :
                    <div className="page-main-section p-4">
                        <div className="organization-created d-flex justify-content-between">
                            <h4>{t("userDetailsPage.subHeading")}</h4>
                        </div>
                        <div className="user-header-container p-3 my-3">
                            <div className="d-flex justify-content-between">
                                <div className="header-details d-flex gap-3">
                                    <p>{t("userDetailsPage.timeZone")} <span>{userDetailResponse?.timezone}</span></p>
                                    <p>{t("userDetailsPage.joinedOn")} <span>{formatDate(userDetailResponse?.created_at)}</span></p>
                                    <p>{t("userDetailsPage.2fa")} <span>{userDetailResponse?.two_factor_enabled ? "Enabled" : "Disabled"}</span></p>
                                    {/* <p>{t("userDetailsPage.lastScannedOn")} <span>July 21, 2004 5:48</span></p> */}
                                </div>
                                <div className="user-role-status d-flex gap-3">
                                    <p className="d-flex align-items-center"><span className="d-inline-block mx-1"><img src={userRole} alt="critical" /></span>{t("userDetailsPage.role")} {userDetailResponse?.role}</p>
                                </div>
                            </div>
                            <div className="d-flex gap-3 mt-3">
                                <div className="total-domains-container rounded">
                                    <p className="total-domain">100</p>
                                    <p className="domain-text">{t("userDetailsPage.totalScan")}</p>
                                </div>
                                <div className="total-domains-container rounded">
                                    <p className="total-domain">20</p>
                                    <p className="domain-text">{t("userDetailsPage.totalAutoScan")}</p>
                                </div><div className="total-domains-container rounded">
                                    <p className="total-domain">40</p>
                                    <p className="domain-text">{t("userDetailsPage.totalOrganization")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="organization-main-container p-3">
                            <div className="orgs-tab-container d-flex rounded">
                                <p className={`rounded text-center ${activeTab === "organization" ? "active" : ""}`} onClick={()=>setActiveTab("organization")}>{t("userDetailsPage.organizationTab")}</p>
                                <p className={`rounded text-center ${activeTab === "scanHistory" ? "active" : ""}`} onClick={()=>setActiveTab("scanHistory")}>{t("userDetailsPage.scanHistoryTab")}</p>
                                <p className={`rounded text-center ${activeTab === "userSession" ? "active" : ""}`} onClick={()=>setActiveTab("userSession")}>{t("userDetailsPage.userSessionTab")}</p>
                            </div>
                            {activeTab === "organization" && <OrganizationTab userDetails={userDetailResponse} currentPageType={"usersDetails"} />}
                            {activeTab === "scanHistory" && <ScanHistoryTab scanHistoryData={userDetailResponse} currentPageType={"usersDetails"} />}
                            {activeTab === "userSession" && <UserSessionTab/>}
                        </div>
                    </div>
                }                
                <SideModal showSidePanel={showEditUserModal} hideSidePanel={closeEditUserModal}>
                    <EditUserModal userDetails={userDetailResponse} closeEditUserModal={closeEditUserModal}/>
                </SideModal>
                <DeleteUserModal show={showDeleteUserModal} userDetails={userDetailResponse} onHide={() => setShowDeleteUserModal(false)}/>
            </div>
        </div>
    )
}

export default UserDetails