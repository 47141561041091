import { React, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import filterIcon from "../../assets/images/common/filter-icon.svg";
import closeIcon from "../../assets/images/common/close-circle.svg";
import StartIcon from "../../assets/images/scan-queue/strat-icon.svg";
import StartDisabledIcon from "../../assets/images/scan-queue/start-disabled.svg";
import PauseIcon from "../../assets/images/scan-queue/pause-icon.svg";
// import PauseDisabledIcon from "../../assets/images/scan-queue/pause-disabled.svg";
import DeleteIcon from "../../assets/images/scan-queue/delete-icon.svg";
import DeleteDisabledIcon from "../../assets/images/scan-queue/delete-disabled.svg";
import SelectAllPauseIcon from "../../assets/images/scan-queue/pause.svg";

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import Pagination from "../../components/ui/Pagination";
import CheckBox from "../../components/ui/CheckBox";
import RadioButton from "../../components/ui/RadioButton";
import Button from "../../components/ui/Button";
import Spinner from "../../components/ui/spinner";
import NoDataFound from "../../components/ui/NoDataFound";

import DeleteScanModal from "../../components/PostLogin/ScanQueue/DeleteScanModal";

import { useSelector, useDispatch } from 'react-redux';
import { getScanQueueStats, getScanQueueList, stopScanSingle, resumeScanSingle, stopScanMultiple, resetStopScanSingleResponse, resetResumeScanSingleResponse, resetDeleteScanSingleResponse } from '../../store/actions/scanqueueAction.js';

import { getDomainImageUrl, getUserImageUrl } from "../../utils/util.js";
import { toaster } from "../../components/ui/Toaster";

const filterOption = [
    {
        label: "In Progress",
        value: "In Progress"
    },
    {
        label: "Paused",
        value: "Paused"
    },
    {
        label: "Queued",
        value: "Queued"
    }
]

const filterRadioOption = [
    {
        label: "Both",
        value: "Both"
    },
    {
        label: "Active",
        value: "Active"
    },
    {
        label: "Passive",
        value: "Passive"
    }
]

const ScanQueue = () => {

    const scanQueueStatsResponse = useSelector((state) => state.scanQueue?.scanQueueStatsResponse);
    const scanQueueListResponse = useSelector((state) => state.scanQueue?.scanQueueList);
    const stopScanSingleResponse = useSelector((state) => state.scanQueue?.stopScanSingleResponse);
    const resumeScanSingleResponse = useSelector((state) => state.scanQueue?.resumeScanSingleResponse);
    const stopScanMultipleResponse = useSelector((state) => state.scanQueue?.stopScanMultipleResponse);
    const deleteScanSingleResponse = useSelector((state) => state.scanQueue?.deleteScanSingleResponse)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const filterWrapperRef = useRef(null);

    const [filterList, setFilterList] = useState([]);
    const [filterStatusValue, setFilterStatusvalue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    const filterStatusChanges = (value) => {
        setFilterStatusvalue(value)
    }

    const removeStatus = () => {
        setFilterStatusvalue("")
    }

    const showModal = (data) => {
        setSelectedData(data)
        setModalShow(true);
    };

    const resumeScan = (data) => {
        let obj = {
            scan_id: data.scan_id
        }
        dispatch(resumeScanSingle(obj));
    }

    const stopScan = (data) => {
        let obj = {
            scan_id: data.scan_id
        }
        dispatch(stopScanSingle(obj));
    }

    const PauseMultiple = () => {
        let ids = selectedValue.map((data)=>{
            return data.scan_id
        })
        let obj = {
            scan_ids: [...ids]
        }
        dispatch(stopScanMultiple(obj))
    }

    const changeSelectAll = (e) => {
        if(e.target.checked) {
            setSelectedValue(scanQueueListResponse?.results)
        } else {
            setSelectedValue([])
        }
    }

    const isChecked = (data) => {
        if(selectedValue.length > 0 ){
            return selectedValue.some(item => item.scan_id === data.scan_id);
        }
        return false
    }

    const setselectedCheckboxValue = (e) => {
        if(e.target.checked) {
            let checkedValue = scanQueueListResponse?.results.filter(item => item.scan_id === e.target.value);
            setSelectedValue([...selectedValue, ...checkedValue])
        } else {
            const updatedList = selectedValue.filter(item => item.scan_id !== e.target.value);
            setSelectedValue(updatedList);
        }
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const searchScan = () => {
        const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${searchValue}`;
        setIsLoading(true)
        dispatch(getScanQueueList(url))
    }

    const fetchScanQueueList = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}`;
        setIsLoading(true);
        dispatch(getScanQueueList(url));
    },[currentPage, currentPageSize, dispatch])

    const fetchScanQueueStats = useCallback(()=>{
        setIsStatsLoading(true);
        dispatch(getScanQueueStats());
    },[dispatch])

    useEffect(()=>{
        fetchScanQueueList();
        fetchScanQueueStats();
    },[fetchScanQueueList, fetchScanQueueStats])

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList]);


    useEffect(()=>{
        if(scanQueueListResponse !== null) {
            setIsLoading(false)
        }
    },[scanQueueListResponse])

    useEffect(()=>{
        if(stopScanSingleResponse !== null) {
            if(stopScanSingleResponse?.detail?.status === "success") {
                toaster.addToast(stopScanSingleResponse?.detail?.description, "success");
                fetchScanQueueList();
                fetchScanQueueStats();
            } else {
                toaster.addToast(stopScanSingleResponse?.description, "error");
            }
            dispatch(resetStopScanSingleResponse())
        }
    },[stopScanSingleResponse, fetchScanQueueList, dispatch, fetchScanQueueStats])

    useEffect(()=>{
        if(resumeScanSingleResponse !== null) {
            if(resumeScanSingleResponse?.detail?.status === "success") {
                toaster.addToast(resumeScanSingleResponse?.detail?.description, "success");
                fetchScanQueueList();
                fetchScanQueueStats();
            } else {
                toaster.addToast(resumeScanSingleResponse?.description, "error");
            }
            dispatch(resetResumeScanSingleResponse())
        }
    },[resumeScanSingleResponse, dispatch, fetchScanQueueList, fetchScanQueueStats])

    useEffect(()=>{
        if(stopScanMultipleResponse !== null) {
            if(stopScanMultipleResponse?.detail?.status === "success") {
                toaster.addToast(stopScanMultipleResponse?.detail?.description, "success");
                setSelectedValue([]);
                fetchScanQueueList();
                fetchScanQueueStats();
            } else {
                toaster.addToast(stopScanMultipleResponse?.description, "error");
            }
        }
    },[stopScanMultipleResponse, fetchScanQueueList, fetchScanQueueStats])

    useEffect(()=>{
        if(deleteScanSingleResponse !== null) {
            if(deleteScanSingleResponse?.detail?.status === "success") {
                toaster.addToast(deleteScanSingleResponse?.detail?.description, "success");
                setSelectedValue([]);
                fetchScanQueueList();
                fetchScanQueueStats();
            } else {
                toaster.addToast(deleteScanSingleResponse?.description, "error");
            }
            setModalShow(false)
            dispatch(resetDeleteScanSingleResponse())
        }
    },[deleteScanSingleResponse, fetchScanQueueList, fetchScanQueueStats, dispatch])

    useEffect(()=>{
        if(scanQueueStatsResponse !== null) {
            setIsStatsLoading(false)
        }
    },[scanQueueStatsResponse])

    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1>{t("scanQueuePage.heading")}</h1>
                    </div>
                </div>
                <div className="page-main-section px-4 py-3">
                    <div className="organization-header-container p-3 my-3 rounded">
                        <div className="d-flex gap-3 mt-3">
                            <div className="total-score-container rounded scan-queue-count">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">{isStatsLoading ? "...." : scanQueueStatsResponse?.scans?.total}</p>
                                    <div className="user-count mx-3 mt-2">
                                        <span className="active d-inline-block mx-1"></span>
                                        <span className="disbled d-inline-block"></span>
                                        <p>{t("scanQueuePage.scans")}</p>
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"><span className="d-inline-block">{isStatsLoading ? "...." : scanQueueStatsResponse?.scans?.active}</span> {t("scanQueuePage.activeScans")}</p>
                                    <p className="disabled-user"><span className="d-inline-block">{isStatsLoading ? "...." : scanQueueStatsResponse?.scans?.passive}</span> {t("scanQueuePage.passiveScans")}</p>
                                </div>
                            </div>
                            <div className="total-domains-container scan-queue-page rounded">
                                <p className="total-domain">{isStatsLoading ? "...." : scanQueueStatsResponse?.scans?.normal}</p>
                                <p className="domain-text">{t("scanQueuePage.adhocScan")}</p>
                            </div>
                            <div className="total-domains-container scan-queue-page rounded">
                                <p className="total-domain">{isStatsLoading ? "...." : scanQueueStatsResponse?.scans?.monitor}</p>
                                <p className="domain-text">{t("scanQueuePage.autoScan")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="organization-main-container p-3">
                        <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                            <div className="search-container d-flex align-items-center gap-3">
                                <div className="position-relative">
                                    <input className="search-input rounded" placeholder="Search Scan" value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}/>
                                    <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                                </div>
                                <button className="search-btn" onClick={()=>{searchScan()}}>{t("scanQueuePage.search")}</button>
                                <div className="position-relative">
                                    <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                                    {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                        <h6>{t("organizationDetailsPage.domainTable.filterBy")}</h6>
                                        {filterOption.map((data,index)=>{
                                            return (
                                                <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                                    <p>{data.label}</p>
                                                    <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                                </div>
                                            )
                                        })}
                                        <h6 className="mt-4">Status</h6>
                                        <div className="filter-user-status d-flex mb-4 gap-4">
                                            {
                                                filterRadioOption.map((data,index)=>{
                                                    return (
                                                        <RadioButton radioId={data.label} radioValue={data.value} radioLabel={data.label} handleRadioChange={filterStatusChanges} key={index}/>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="filter-apply-btn mt-3">
                                            <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 && filterStatusValue === '' ? true : false} buttonClicked={applyFilter}/>
                                        </div>
                                    </div>}
                                </div>
                                {!showFilter && <p className="selected-filters">
                                    {
                                        filterList.map((data,index)=>{
                                            return (
                                                <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                            )
                                        })
                                    }
                                    {filterStatusValue !== '' && <span className="d-inline-block mx-2" >{filterStatusValue} <img src={closeIcon} alt="close" onClick={removeStatus}/></span>}
                                </p>}
                            </div>
                            <div className="sort-edit-container scan-queue-page">
                                <div className="container d-flex gap-3 align-items-center">
                                    <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")} 
                                        <select className="border-0">
                                            <option value="latest score">Latest Score</option>
                                            <option value="last scanned">Last scanned</option>
                                            <option value="created">Created</option>
                                        </select>
                                    </p>
                                    <button className="pause-scan-btn" disabled={selectedValue.length === 0} onClick={()=>{PauseMultiple()}}>
                                        <img src={SelectAllPauseIcon} alt="pause" className="d-inline-block mx-1" />
                                        {t("scanQueuePage.pauseScan")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="domain-table-container scan-queue-table">
                            {
                                isLoading ? 
                                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%'}}>
                                        <Spinner />
                                    </div>
                                :
                                <Table responsive="lg">
                                    <thead>
                                        <tr>
                                            <th className="select-all-checkbox"><CheckBox checkBoxId={0} checkBoxValue={"select all"} handleCheckboxChange={changeSelectAll}/></th>
                                            <th>{t("scanQueuePage.domainDetails")}</th>
                                            <th>{t("scanQueuePage.scanDetails")}</th>
                                            <th>{t("scanQueuePage.scanType")}</th>
                                            <th>{t("scanQueuePage.scanStatus")}</th>
                                            <th>{t("scanQueuePage.checkpoint")}</th>
                                            <th>{t("scanQueuePage.scanStoped")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            scanQueueListResponse?.results.length > 0 ?
                                            scanQueueListResponse?.results.map((data, index)=>{
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <CheckBox checkBoxId={index} checkBoxValue={data.scan_id} handleCheckboxChange={setselectedCheckboxValue} isChecked={isChecked(data)}/>
                                                        </td>
                                                        <td>
                                                            <ProfileAvatar type="domain" name={data.domain} imageUrl={getDomainImageUrl(data)} />
                                                        </td>
                                                        <td>
                                                            <ProfileAvatar pageType={"domainTable"} type="profile" firstName={data?.created_by?.name} lastName={""} imageUrl={getUserImageUrl(data.created_by)} />
                                                        </td>
                                                        <td>
                                                            <label className={`scan-type ${data.scan_type === "passive" ? "passive" : 'active'}`}>{data.scan_type}</label></td>
                                                        <td>
                                                            {data.scan_status === "in_progress" && <div className="scan-progress inprogress">
                                                                <h4>In progress - {data.scan_percentage}%</h4>
                                                                <div className="progress-container"> 
                                                                    <div style={{width:`${data.scan_percentage}%`}}></div> 
                                                                </div>
                                                            </div>}
                                                            {data.scan_status === "pending" && 
                                                                <div className="scan-progress queued">
                                                                <h4>Queued - {data.scan_percentage}%</h4>
                                                                <div className="progress-container"> 
                                                                    <div style={{width:`${data.scan_percentage}%`}}></div> 
                                                                </div>
                                                            </div>}
                                                            {data.scan_status === "stopped" && 
                                                                <div className="scan-progress queued">
                                                                <h4>Stopped - {data.scan_percentage}%</h4>
                                                                <div className="progress-container"> 
                                                                    <div style={{width:`${data.scan_percentage}%`}}></div> 
                                                                </div>
                                                            </div>}
                                                        </td>
                                                        <td>
                                                            <p className="score">{data.scan_checkpoint}</p>
                                                        </td>
                                                        <td>
                                                            { data.scan_status === "stopped" ? 
                                                                <ProfileAvatar pageType={"domainTable"} type="profile" firstName={data?.stopped_by?.name} lastName={" "} imageUrl={getUserImageUrl(data?.stopped_by)} /> 
                                                                : 
                                                                <p>-</p> 
                                                            }
                                                        </td>
                                                        <td>
                                                            {data.scan_status === "in_progress" && <div className="d-flex gap-2 scan-action-btn">
                                                                <img src={PauseIcon} alt="pause-disabled" onClick={()=>{stopScan(data)}} />
                                                                <img src={DeleteDisabledIcon} alt="delete-disabled" />
                                                            </div>}
                                                            {data.scan_status === "pending" && <div className="d-flex gap-2 scan-action-btn">
                                                                <img src={StartDisabledIcon} alt="pause-disabled" />
                                                                <img src={DeleteIcon} alt="delete-disabled" onClick={()=>{showModal(data)}} />
                                                            </div>}
                                                            {data.scan_status === "stopped" && <div className="d-flex gap-2 scan-action-btn">
                                                                <img src={StartIcon} alt="pause-disabled" onClick={()=>{resumeScan(data)}} />
                                                                <img src={DeleteIcon} alt="delete-disabled" onClick={()=>{showModal(data)}} />
                                                            </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :                                             
                                                <tr>
                                                    <td colSpan="8" style={{ textAlign: 'center' }}>
                                                        <NoDataFound />
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            }
                            
                            {scanQueueListResponse != null && scanQueueListResponse?.results.length > 0 && <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                pageSize={currentPageSize}
                                totalCount={scanQueueListResponse.meta.total_results}
                                onPageChange={setCurrentPageandRecordSize}
                            />}
                        </div>
                    </div>
                    <DeleteScanModal show={modalShow} onHide={() => setModalShow(false)} deleteDomainData={selectedData}/>
                </div>
            </div>
        </div>
    )
}

export default ScanQueue;