import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import "./index.scss"

import LogOutIcon from "../../../assets/images/common/account-logout-red.svg";
import accountSetting from "../../../assets/images/side-nav/account-setting.svg";

import { getUserImageUrl } from "../../../utils/util.js";
import { toaster } from "../../ui/Toaster";

import { useSelector, useDispatch } from 'react-redux'; 
import { getLoggedInUserDetails } from '../../../store/actions/loginAction.js';
import { userLogOut } from "../../../store/actions/settingsAction.js";


const SideNavProfile = () => {

    const userDetailsResponse = useSelector((state)=> state?.login?.loggedInUserDetailResponse);
    const logOutResponse = useSelector((state) => state.settings?.logOutResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const profileRef = useRef(null);

    const [showOption, setShowOption] = useState(false);
    // const [userLoggedOut, setUserLoggedout] = useState(false);

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setShowOption(false);
        }
    };

    useEffect(() => {
        dispatch(getLoggedInUserDetails())
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    useEffect(()=>{
        if(userDetailsResponse !== null) {
        }
    },[userDetailsResponse])

    useEffect(()=>{
        if(logOutResponse != null) {
            if(logOutResponse?.detail?.status === "success") {
                // setUserLoggedout(true)
                toaster.addToast(logOutResponse?.detail?.description, "success");
                // navigate('/auth/login')
                window.location.href = "/auth/login";
            } else {
                toaster.addToast(logOutResponse?.description, "error");
            }
        }
    },[logOutResponse, navigate])

    return (
        <div className="profile-container position-relative" ref={profileRef}>
            <div className='profile-picture mt-4' onClick={()=>{setShowOption(!showOption)}}>
                <img className="w-100 h-100" src={userDetailsResponse !== null ? getUserImageUrl(userDetailsResponse) : ""} alt="profile"/>
            </div>
            {showOption && <div className="profile-container-option position-absolute">
                <p className="d-flex gap-3 align-items-center" onClick={()=>{navigate('/account/settings')}}><img src={accountSetting} alt="settings"/>{t("sideNav.userProfile.accountSetting")}</p>
                <p className="d-flex gap-3 align-items-center logout" onClick={()=>{dispatch(userLogOut())}}><img src={LogOutIcon} alt="logout" />{t("sideNav.userProfile.logOut")}</p>
            </div>}
        </div>
    )
}

export default SideNavProfile;