import { React, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';

import "./index.scss";


import Button from "../../../../components/ui/Button";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";
import MonitorModal from "../../../../components/PostLogin/Monitor/MonitorModal";

import { getDomainImageUrl } from "../../../../utils/util";


const MonitorListView = ({monitorList}) => {

    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleShowModal = () => {
        setShowDeleteModal(true); // Open the modal
    };
    const handleCloseModal = () => setShowDeleteModal(false);

    return (
        <Row>
            <Col md="12">
                <div className="list-view-container users-list-view-table p-3">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t("monitor.monitorListView.domain")}</th>
                                <th>{t("monitor.monitorListView.status")}</th>
                                <th>{t("monitor.weekly")}</th>
                                <th>{t("monitor.monthly")}</th>
                                <th>{t("monitor.monitorListView.monitorType")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monitorList.results.length > 0 &&
                                monitorList.results.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                            <ProfileAvatar type="profile" firstName={data?.monitor_domain?.domain} imageUrl={getDomainImageUrl(data?.monitor_domain)} />
                                            </td>
                                            <td>
                                                <p>{data?.scan_type === "active" ? "Active Scan" : "Passive Scan"}</p>
                                            </td>
                                            <td>
                                                <p>100</p>
                                            </td>
                                            <td>
                                                <p>32</p>
                                            </td>
                                            <td>
                                                <p>{data.monitor_type}</p>
                                            </td>
                                            <td>
                                                <div className='organization-list-view d-flex'>
                                                    {/* <Button buttonText={"Edit"}  onClick={handleShowModal} buttonType="primary" /> */}
                                                    <button
                                                    className="view-profile-btn"
                                                    onClick={handleShowModal}
                                                >
                                                    {t("monitor.editBtn")}
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>
            <MonitorModal show={showDeleteModal} onHide={handleCloseModal} />
        </Row>
    )
}

export default MonitorListView;