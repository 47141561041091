import { React, useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import closeIcon from "../../../../assets/images/common/close-circle.svg";

import CheckBox from "../../../../components/ui/CheckBox";
import Button from "../../../../components/ui/Button";

import Pagination from '../../../ui/Pagination';
import Spinner from "../../../ui/spinner";
import ProfileAvatar from "../../../ui/ProfileAvatar";
import NoDataFound from "../../../ui/NoDataFound";

import { formatDate, formatRelativeTime, getCountryImageUrl } from "../../../../utils/util.js";


import { useSelector, useDispatch } from 'react-redux';
import { getUserSessionById } from '../../../../store/actions/usersAction.js';

const filterOption = [
    {
        label: "Critical",
        value: "critical"
    },
    {
        label: "High",
        value: "high"
    },
    {
        label: "Medium",
        value: "medium"
    },
    {
        label: "Low",
        value: "low"
    }
]


const UserSessionTab = () => {

    const userSessionList = useSelector((state) => state.users?.userSessionById);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { userId } = useParams();

    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false)
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchUserSession = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}&user_id=${userId}`;
        setIsLoading(true)
        dispatch(getUserSessionById(url))
    },[currentPage, currentPageSize, dispatch, userId])

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList])

    useEffect(()=>{
        fetchUserSession()
    },[fetchUserSession])

    useEffect(()=>{
        if(userSessionList !== null){
            setIsLoading(false)
        }
    },[userSessionList])


    return (
        <Row>
            <Col md={12}>
                <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                    <div className="search-container d-flex align-items-center gap-3">
                        <div className="position-relative">
                            <input className="search-input rounded" placeholder="Search Session" value={domainSearchValue} onChange={(e)=>setDomainSearchValue(e.target.value)}/>
                            <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        </div>
                        <button className="search-btn">Search</button>
                        <div className="position-relative">
                            <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                            {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                <h6>Business Impact</h6>
                                {filterOption.map((data,index)=>{
                                    return (
                                        <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                            <p>{data.label}</p>
                                            <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                        </div>
                                    )
                                })}
                                <div className="filter-apply-btn mt-3">
                                    <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 ? true : false} buttonClicked={applyFilter}/>
                                </div>
                            </div>}
                        </div>
                        {!showFilter && <p className="selected-filters">
                            {
                                filterList.map((data,index)=>{
                                    return (
                                        <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                    )
                                })
                            }
                        </p>}
                    </div>
                    <div className="sort-edit-container">
                        <div className="container d-flex gap-3 align-items-center">
                            <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")} 
                                <select className="border-0">
                                    <option value="latest score">Latest Score</option>
                                    <option value="last scanned">Last scanned</option>
                                    <option value="created">Created</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="domain-table-container user-page-org-table">
                    {
                        isLoading ?
                        <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%',marginBottom:'10%'}}>
                            <Spinner />
                        </div>
                        :
                        <>
                            <Table responsive="lg">
                                <thead>
                                    <tr>
                                        <th>{t("accountSettingPage.userSessionTab.userAgent")}</th>
                                        <th>{t("accountSettingPage.userSessionTab.lastAccessed")}</th>
                                        <th>{t("accountSettingPage.userSessionTab.lastLogin")}</th>
                                        <th>{t("accountSettingPage.userSessionTab.ipAddress")}</th>
                                        <th>{t("accountSettingPage.userSessionTab.address")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userSessionList?.results.length > 0 ? 
                                        userSessionList?.results.map((data, index)=>{
                                            return (
                                                <tr key={index}>
                                                    <td className="system-name">{data.user_agent} {data.current_session ? <span>CURRENT SESSION</span> : ""}</td>
                                                    <td>{formatRelativeTime(data?.last_accessed)}</td>
                                                    <td>{formatDate(data?.last_accessed)}</td>
                                                    <td>
                                                        <p>{data.ip_address}</p>
                                                    </td>
                                                    <td>
                                                        <ProfileAvatar type={"profile"} firstName={`${data?.city}, ${data?.region}`} lastName={""} secondaryText={`${data.country}, ${data.continent}`} imageUrl={getCountryImageUrl(data)}/>
                                                    </td>
                                                </tr>
                                            )
                                        }) 
                                        
                                        :                                             
                                            <tr>
                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                    <NoDataFound />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                            {userSessionList != null && userSessionList?.results.length > 0 && <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    pageSize={currentPageSize}
                                    totalCount={userSessionList.meta.total_results}
                                    onPageChange={setCurrentPageandRecordSize}
                                />}
                        </>
                    }
                </div>
            </Col>
        </Row>
    )
}

export default UserSessionTab;