import React, {useEffect} from 'react';

import './index.scss';

import arrowCircleLeft from "../../../assets/images/common/arrow-circle-left.svg";

const SideModal = ({showSidePanel, hideSidePanel, children}) => {


    useEffect(()=>{
        if(showSidePanel) {
            if (typeof window != 'undefined' && window.document) {
                document.body.style.overflow = 'hidden';
            }
        } else {
            document.body.style.overflow = 'unset';
        }
    },[showSidePanel])

    const sideDrawerClosedHandler = () => {
        hideSidePanel();

        // Unsets Background Scrolling to use when SideDrawer/Modal is closed
        document.body.style.overflow = 'unset';
    }

    const handleOutsideClick = (e) => {
        if(e.target.className === 'side-modal-container active') {
            // sideDrawerClosedHandler()
        }
    }

    return (
        <div className={`side-modal-container ${showSidePanel ? 'active' : ''}`} onClick={handleOutsideClick}>
            <div className={`modal-main-container ${showSidePanel ? 'active' : ''}`}>
                <img src={arrowCircleLeft} alt="back" onClick={sideDrawerClosedHandler} className="go-back-icon"/>
                    {children}
            </div>
        </div>
    )
}

export default SideModal;