import { React } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";



import Button from "../../../ui/Button/index.js";

import { useDispatch } from 'react-redux';
import { deleteScanSingle } from '../../../../store/actions/scanqueueAction.js';

const DeleteScanModal = ({show, onHide, deleteDomainData}) => {

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const confirmDelete = () => {
        let obj = {
            scan_id: deleteDomainData.scan_id
        }
        dispatch(deleteScanSingle(obj))
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("scanQueuePage.deleteDomain.heading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("scanQueuePage.deleteDomain.subText")} {deleteDomainData?.domain}</p>
                        </div>
                        <div className="delete-organization-btn d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={onHide}/>
                            <Button buttonText="Delete" buttonType={"primary"} buttonClicked={()=>{confirmDelete()}} />
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default DeleteScanModal;