import { React } from 'react';


import NodataFound from "../../../assets/images/common/no_data_found.svg";

const NoDataFound = ({noDataText="No Data found"}) => {
    return (
        <div className="d-flex align-items-center justify-content-center flex-column gap-3 my-5 no-data-found-container">
            <img src={NodataFound} alt="no-data-found"/>
            <h2>{noDataText}</h2>
        </div>
    )
}

export default NoDataFound;