import React,{ useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./index.scss";

import ExpandIcon from "../../../../assets/images/scan-info/add-square.svg";
// import MenuCloseIcon from "../../../../assets/images/scan-info/minus-square.svg";
import ArrowRight from "../../../../assets/images/scan-info/arrow-right.svg";
import RightArrow from "../../../../assets/images/scan-info/right-arrow.svg";


const SideMenu = ({toggleMenuUpdate}) => {

    const { t } = useTranslation();

    const location = useLocation();

    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(true);
    const [activeMenuOne, setActiveMenuOne] = useState("exposureManaganemt");
    const [activeMenuTwo, setActiveMenuTwo] = useState("exposureDashboard");
    const [activeMenuThree, setActiveMenuThree] = useState("");

    //destructuring pathname from location
    const { pathname } = location;
    const queryParams = new URLSearchParams(location.search);

    //Javascript split method to get the name of the path in array
    const splitLocation = useMemo(() => pathname.split("/"), [pathname]);

    const customerId = queryParams.get('customer');
    const organizationId = queryParams.get('organization');
    const domainId = queryParams.get('domain');
    const scanId = queryParams.get('scanId');

    


    const menuData = useMemo(() => [
        {
          title: 'Exposure Management',
          id: 'exposureManaganemt',
          activekeyOne: 'exposure',
          items: [
            { 
                label: 'Dashboard',
                id: 'exposureDashboard',
                icon: ArrowRight,
                activekeyTwo: "dashboard",
                link:`/scan-info/exposure/dashboard?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}`,
            },
            {
              label: 'Assest Discovery',
              id: 'assestDiscovery',
              icon: ArrowRight,
              activekeyTwo: "assest-discovery",
              subItems: [
                { id: 'domainInfo', label: 'Domain Info', activekeyThree: "domain-info", link: `/scan-info/exposure/assest-discovery/domain-info?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'subDomainInfo', label: 'Subdomain', activekeyThree: "sub-domain", link: `/scan-info/exposure/assest-discovery/sub-domain?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'ipAddress', label: 'IP Address', activekeyThree: "ip-address", link: `/scan-info/exposure/assest-discovery/ip-address?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'mobileApps', label: 'Mobile Apps', activekeyThree: "mobile-apps", link: `/scan-info/exposure/assest-discovery/mobile-apps?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'associateDomain', label: 'Associated Domain', activekeyThree: "associated-domain", link: `/scan-info/exposure/assest-discovery/associated-domain?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'dnsRecords', label: 'DNS Records', activekeyThree: "dns-records", link: `/scan-info/exposure/assest-discovery/dns-records?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'technologies', label: 'Technologies', activekeyThree: "technologies", link: `/scan-info/exposure/assest-discovery/technologies?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'sslRecords', label: 'SSL Records', activekeyThree: "ssl-records", link: `/scan-info/exposure/assest-discovery/ssl-records?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` }
              ]
            },
            { 
                label: 'Corporate Email Risk', 
                id: 'corporateEmailRisk',
                activekeyTwo: "corporate-email-risk",
                icon: ArrowRight ,
                link:`/scan-info/exposure/corporate-email-risk?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}`,
            },
            { 
                label: 'Concerning Exposure',
                id: 'concerningExposure',
                icon: ArrowRight,
                activekeyTwo: "concerning-exposure",
                subItems: [                    
                    { id: 'gitleaks', label: 'Git Leaks', activekeyThree: "git-leaks", link: `/scan-info/exposure/concerning-exposure/git-leaks?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'JSSecrets', label: 'JS Secrets', activekeyThree: "js-secrets", link: `/scan-info/exposure/concerning-exposure/js-secrets?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'sensitiveExposure', label: 'Sensitive Exposure', activekeyThree: "senstive-exposure", link: `/scan-info/exposure/concerning-exposure/senstive-exposure?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                ]
            },
            {
                label: 'External Detected Vulnerabilities',
                id: 'externalDetectedVulnerabilities',
                activekeyTwo: "external-detected-vulnerabilities",
                icon: ArrowRight,
                subItems: [
                    { id: 'subdomainTakeover', label: 'Sub-Domain Takeover', activekeyThree: "sub-domain-takeover", link: `/scan-info/exposure/external-detected-vulnerabilities/sub-domain-takeover?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'cves', label: 'CVEs', activekeyThree: "cves", link: `/scan-info/exposure/external-detected-vulnerabilities/cves?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'cnvds', label: 'CNVDs', activekeyThree: "cnvds", link: `/scan-info/exposure/external-detected-vulnerabilities/cnvds?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'sslVulnerabilities', label: 'SSL Vulnerabilities', activekeyThree: "ssl-vulnerabilities", link: `/scan-info/exposure/external-detected-vulnerabilities/ssl-vulnerabilities?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'exposedPanel', label: 'Exposed Panel', activekeyThree: "exposed-panel", link: `/scan-info/exposure/external-detected-vulnerabilities/exposed-panel?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'defaultLogin', label: 'Default Login', activekeyThree: "default-login", link: `/scan-info/exposure/external-detected-vulnerabilities/default-login?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}`},
                    { id: 'misconfiguration', label: 'Misconfiguration', activekeyThree: "misconfiguration", link: `/scan-info/exposure/external-detected-vulnerabilities/misconfiguration?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                    { id: 'otherVulnerabilities', label: 'Other Vulnerabilities', activekeyThree: "other-vulnerabilities", link: `/scan-info/exposure/external-detected-vulnerabilities/other-vulnerabilities?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` }
                ]
            },
            {
              label: 'Dark Web',
              id:"darkWeb",
              icon: ArrowRight,
              activekeyTwo: "dark-web",
              subItems: [
                { id: 'leakedCredentials', label: 'Leaked Credentials', activekeyThree: "leaked-credentials", link: `/scan-info/exposure/dark-web/leaked-credentials?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'botnetLeaks', label: 'Botnet Leaks', activekeyThree: "botnet-leaks", link: `/scan-info/exposure/dark-web/botnet-leaks?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                { id: 'ransomewareGroupIntel', label: 'Ransomware Group Leaks', activekeyThree: "ransomeware-group-intel", link: `/scan-info/exposure/dark-web/ransomeware-group-intel?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` }
              ]
            },
            { 
                label: 'Brand Insights',
                id: "brandInsight", 
                icon: ArrowRight,
                activekeyTwo: "brand-insight",
                subItems: [
                    { id: 'similarDomains', label: 'Similar Domains', activekeyThree: "similar-domain", link: `/scan-info/exposure/brand-insight/similar-domain?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}` },
                ]
            }
          ]
        },
        {
            title: 'Portfolio Risk',
            id: 'portfolioRisk',
            activekeyOne: 'portfolio',
            items: [
                { 
                    label: 'Dashboard',
                    id:"porfolioDashboard", 
                    icon: ArrowRight,
                    activekeyTwo: "dashboard",
                    link:`/scan-info/portfolio/dashboard?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}`,
                }
            ]
        },
        {
            title: 'Reports',
            id: 'reports',
            activekeyOne: 'reports',
            items: [
                { 
                    label: 'Exposure Management', 
                    id:"exposureManagement",
                    icon: ArrowRight ,
                    activekeyTwo: "exposure-management",
                    link:`/scan-info/reports/exposure-management?customer=${customerId}&organization=${organizationId}&domain=${domainId}&scanId=${scanId}`,
                }
            ]
        }
    ], [customerId, organizationId, domainId, scanId]); // Empty dependency array so it's only computed once

    const MenuOne = ({ id, activekeyOne,title, items, activeMenuOne, setActiveMenuOne, activeMenuTwo, setActiveMenuTwo, activeMenuThree, setActiveMenuThree }) => (
        <>
            <div className={`menu-one-container ${(splitLocation[2] === activekeyOne)? 'active' : ''}`}>
                <div className="d-flex align-items-center justify-content-between menu-one-heading py-3">
                    <p className="px-3" onClick={() => setActiveMenuOne(id)}>{title}</p>
                    <img src={ExpandIcon} alt="expand" className="px-3" />
                </div>
                {activeMenuOne === id && <div className="menu-two-container">
                    {items.map((item) => (
                        <MenuTwo
                            key={item.label}
                            activekeyOne={activekeyOne}
                            activekeyTwo={item.activekeyTwo}
                            id={item.id}
                            label={item.label}
                            icon={item.icon}
                            items={item.subItems}
                            activeMenuTwo={activeMenuTwo}
                            setActiveMenuTwo={setActiveMenuTwo}
                            activeMenuThree={activeMenuThree}
                            setActiveMenuThree={setActiveMenuThree}
                            link={item.link}
                        />
                    ))}
                </div> }           
            </div>
            <div className="menu-border-bottom"></div>
        </>
    );

    const MenuTwo = ({ id, activekeyOne, activekeyTwo, link, label, icon, items, activeMenuTwo, setActiveMenuTwo, activeMenuThree, setActiveMenuThree }) => (
        <div className={`menu-two-heading py-2 ${splitLocation[2] === activekeyOne && splitLocation[3] === activekeyTwo ? 'active' : ''}`}>
            <div className="menu-two-contnet" onClick={() =>{
                if(items) {
                    setActiveMenuTwo(id)
                } else {
                    navigate(link)
                }
                
            }}>
                {items && <img src={icon || ArrowRight} alt="expand" className={`arrow-right ${items ? "" : 'no-sub-menu'}`} />}
                <p className={`${items ? "" : 'no-menu'}`}>{label}</p>
            </div>
            {items && activeMenuTwo === id && (
                <MenuThree items={items} />
            )}
        </div>
    );

    const MenuThree = ({ items }) => (
        <div className="menu-three-container">
            {items.map((item) => (
                <div
                    key={item.id}
                    className={`menu-three-list ${splitLocation[4] === item.activekeyThree ? 'active' : ''}`}
                    onClick={() => {
                        navigate(item.link)
                        setActiveMenuThree(item.id)
                    }}
                >
                    {item.label}
                </div>
            ))}
        </div>
    );

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(()=>{
        toggleMenuUpdate(menuOpen)
    },[menuOpen, toggleMenuUpdate])

    useEffect(()=>{
        let menuOne = menuData.find(item => splitLocation[2] === item.activekeyOne);
        let menuTwo = menuOne?.items.find(item => splitLocation[3] === item.activekeyTwo);
        setActiveMenuOne(menuOne?.id);
        setActiveMenuTwo(menuTwo?.id);
    },[menuData, splitLocation])

    return (
        <>            
            <div className="menu-list-wrapper">
                {menuData.map((menuOne) => (
                    <MenuOne
                        key={menuOne.title}
                        activekeyOne={menuOne.activekeyOne}
                        id={menuOne.id}
                        title={menuOne.title}
                        items={menuOne.items}
                        activeMenuOne={activeMenuOne}
                        setActiveMenuOne={setActiveMenuOne}
                        activeMenuTwo={activeMenuTwo}
                        setActiveMenuTwo={setActiveMenuTwo}
                        activeMenuThree={activeMenuThree}
                        setActiveMenuThree={setActiveMenuThree}
                    />
                ))}
            </div>
            <div className="menu-close-div" onClick={()=>{toggleMenu()}}>
                <img src={RightArrow} alt="arrow-right" />
                <img src={RightArrow} alt="arrow-right" />
            </div>
        </>
    )
}

export default SideMenu;