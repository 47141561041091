import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import "./index.scss";

import FilterIcon from "../../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../../assets/images/common/search-icon.svg";
import PlusIcon from "../../../../../assets/images/common/add-square.svg";
import MinusIcon from "../../../../../assets/images/common/minus-square.svg";
import PasswordCloseIcon from "../../../../../assets/images/LoginPage/eye-slash.svg";
import PasswordOpenIcon from "../../../../../assets/images/LoginPage/eye.svg";

import ContentBox from "../../../../../components/ui/ContentBox";
import Spinner from "../../../../../components/ui/spinner";
import NoDataFound from "../../../../../components/ui/NoDataFound";
import Pagination from "../../../../../components/ui/Pagination";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getBotnetLeaksList, getBotnetLeaksDataList } from "../../../../../store/actions/scanInfoAction";

import { formatDate } from "../../../../../utils/util";

const BotnetLeaks = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const botnetLeaksListResponse = useSelector((state) => state.scanInfo?.botnetLeaksList);
    const botnetLeakedDataResponse = useSelector((state) => state.scanInfo?.botnetLeakedData);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeContent, setActiveContent] = useState("jsSecretsSummary");
    const [selectedBotnetLeaks, setSelectedBotnetLeaks] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isEndpointLoading, setIsEndpointLoading] = useState(false);
    const [visiblePasswordId, setVisiblePasswordId] = useState(null);

    const hidePassword = (data) => {
        if (data.length <= 2) {
            return data; // Return as is if the length is 2 or less
        }
    
        const firstChar = data[0]; // First character
        const lastChar = data[data.length - 1]; // Last character
        const middle = '*'.repeat(data.length - 2); // Replace middle characters with '*'
    
        return `${firstChar}${middle}${lastChar}`;
    }

    const showPassword = (id) => {
        if (visiblePasswordId === id) {
            setVisiblePasswordId(null); // Hide password if clicked again
        } else {
            setVisiblePasswordId(id); // Show password for the clicked row
        }
    };

    const showContent = (data) => {  
        if(activeContent === data) {
            setActiveContent("")
        } else {
            setActiveContent(data)
            if(data === "affectedEndpoints") {
                fetchAffectedEndPoints()
            }
        }
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const fetchAffectedEndPoints = useCallback(()=>{
        setIsEndpointLoading(true)
        let url = `&page=${currentPage}&page_size=${currentPageSize}&domain=${domainId}&scan_id=${scanId}&result_id=${selectedBotnetLeaks?.id}`;
        dispatch(getBotnetLeaksDataList(url));
    },[dispatch, currentPage, currentPageSize, domainId, scanId, selectedBotnetLeaks])

    const fetchBotnetLeaksList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=10&domain=${domainId}&scan_id=${scanId}`
        dispatch(getBotnetLeaksList(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
        fetchAffectedEndPoints()
    },[selectedBotnetLeaks, fetchAffectedEndPoints])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchBotnetLeaksList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, scanId, fetchBotnetLeaksList])

    useEffect(()=>{
        if(botnetLeaksListResponse !== null) {
            setSelectedBotnetLeaks(botnetLeaksListResponse?.results[0]);
        }
    },[botnetLeaksListResponse])

    useEffect(()=>{
        console.log("botnetLeakedDataResponse", botnetLeakedDataResponse)
        if(botnetLeakedDataResponse !== null) {
            setIsEndpointLoading(false)
        }
    },[botnetLeakedDataResponse])

    return (
        <>
            <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Botnet Leaks List</h1>
                                    <img src={FilterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container">
                                    {
                                        botnetLeaksListResponse?.results.length > 0 &&
                                        botnetLeaksListResponse?.results.map((data, index) => {
                                            return (
                                                <div className={`sub-domain-list py-2 px-3 ${selectedBotnetLeaks?.id === data.id ? "active" : ""}`} key={index} onClick={()=>{setSelectedBotnetLeaks(data)}}>
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <p className="sub-domain-heading">{data?.name}</p>
                                                        {data.new_record && <span className="new-subdomain">New</span>}
                                                    </div>                                                    
                                                    <p className="sub-domain-name">{data?.domain}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="main-content bg-white-with-shadow p-2 h-100">
                                <div className="record-container-content p-2">
                                    <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("jsSecretsSummary")}}>
                                        <h2 className="heading">Botnet Leaks Summary</h2>
                                        <img src={activeContent === "affectedEndpoints" ? MinusIcon : PlusIcon} alt="expand"/>
                                    </div>
                                    {activeContent === "jsSecretsSummary" && <div className="js-secrete-summary mt-3">
                                        <Row>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Name"} value={selectedBotnetLeaks?.name}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"IP Address"} value={selectedBotnetLeaks?.ip_address}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Date"} value={formatDate(selectedBotnetLeaks?.leak_date, "date")}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Malware Location"} value={selectedBotnetLeaks?.file_location}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"System Name"} value={selectedBotnetLeaks?.system_name}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"System Domain"} value={selectedBotnetLeaks?.system_domain}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"System Hostname"} value={selectedBotnetLeaks?.system_hostname}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"System Username"} value={selectedBotnetLeaks?.system_username}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"System Workgroup"} value={selectedBotnetLeaks?.system_workgroup}/>
                                            </Col>
                                            <Col lg={6} className="mb-3">
                                                <ContentBox label={"Operating System"} value={selectedBotnetLeaks?.operating_system}/>
                                            </Col>

                                        </Row>
                                    </div> }                               
                                </div>
                                <div className="record-container-content p-2 mt-3">
                                    <div className="tab-header-container d-flex justify-content-between align-items-center" onClick={()=>{showContent("affectedEndpoints")}}>
                                        <h2 className="heading">Leaked Data</h2>
                                        <img src={activeContent === "affectedEndpoints" ? MinusIcon : PlusIcon} alt="expand"/>
                                    </div>
                                    {activeContent === "affectedEndpoints" && <div className="affected-endpoint-container">
                                        <div className="git-leaks-main-content">
                                            <div className="search-header-container d-flex align-items-center gap-3 my-3">
                                                <div className="search-container position-relative">
                                                    <img src={SearchIcon} alt="Search" className="position-absolute"/>
                                                    <input type="text" placeholder="Search" />
                                                </div>
                                                <button className="search-btn">Search</button>
                                                <img src={FilterIcon} alt="filter" className="filtericon"/>
                                            </div>
                                            <div className="js-secrets-table leaks-table">
                                                {
                                                    isEndpointLoading ?
                                                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%', marginBottom:'5%'}}>
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <Table responsive="lg">
                                                        <thead>
                                                            <tr>
                                                                <th>URL</th>
                                                                <th>Username</th>
                                                                <th>Password/Hash Password</th>
                                                                <th>browser</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                botnetLeakedDataResponse?.results.length > 0 &&
                                                                botnetLeakedDataResponse?.results.map((data, index)=>{
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="repo-url">
                                                                                <a href={data?.url} target="_blank" class="table-url" rel="noreferrer">{data?.url}</a>
                                                                            </td>
                                                                            <td>
                                                                                {data?.username}
                                                                            </td>
                                                                            <td>
                                                                                {data?.password !== null && data?.password !== "" ? <p className="password-data">
                                                                                    {visiblePasswordId === data.id
                                                                                        ? data?.password
                                                                                        : hidePassword(data?.password)}
                                                                                    <img
                                                                                        src={visiblePasswordId === data.id ? PasswordOpenIcon : PasswordCloseIcon}
                                                                                        alt="password"
                                                                                        className="mx-2"
                                                                                        onClick={() => showPassword(data.id)}
                                                                                    />
                                                                                </p> : "-"}
                                                                            </td>
                                                                            <td>
                                                                                {data?.browser}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                                {
                                                    !isEndpointLoading && botnetLeakedDataResponse !== "" && botnetLeakedDataResponse?.results.length === 0 && 
                                                    <NoDataFound noDataText={"No Record Found"}/>
                                                }
                                                {botnetLeakedDataResponse !== "" && botnetLeakedDataResponse?.results.length > 0 && <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    pageSize={currentPageSize}
                                                    totalCount={botnetLeakedDataResponse?.meta?.total_results}
                                                    onPageChange={setCurrentPageandRecordSize}
                                                />}
                                            
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
        </>
        
    )
}


export default BotnetLeaks;