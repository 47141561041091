import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationJP from "./locales/jp.json";

const resources = {
    en: {
      translation: translationEN,
    },
    jp: {
      translation: translationJP,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
});

export default i18n;