import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import "./index.scss";

import dummyImage from "../../../../../assets/images/common/organization-dummy-image.png";
import filterIcon from "../../../../../assets/images/common/filter-icon.svg";
import SearchIcon from "../../../../../assets/images/common/search-icon.svg";

import ProfileAvatar from "../../../../../components/ui/ProfileAvatar";
// import Spinner from "../../../../../components/ui/spinner";

import SubDomainInformation from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/SubDomain/SubDomainInformation";
import DNSRecordInformation from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/SubDomain/DNSRecordInformation";
import Technologies from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/SubDomain/Technologies";
import SSLCertificate from "../../../../../components/PostLogin/ScanInfo/ExposureManagement/AssetDiscovery/IPAddress/SSLCertificate";

import { useScanInfoContext } from "../../../../ScanInfo/ScanInfoContext";

import { useSelector, useDispatch } from 'react-redux';
import { getSubdomainList } from "../../../../../store/actions/scanInfoAction";

const SubDomain = () => {

    const domainListResponse = useSelector((state) => state.scanInfo?.domainList);
    const subdomainListResponse = useSelector((state) => state.scanInfo?.subdomainList);

    const prevDomainIdRef = useRef(null);
    const prevScanIdRef = useRef(null);

    const dispatch = useDispatch();

    const { domainId, scanId } = useScanInfoContext();

    const [activeTab, setActiveTab] = useState("subDomainInformation");
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSubdomain, setSelectedSubdomain] = useState("");

    const fetchSubDomainList = useCallback(()=>{
        let url = `&page=${pageNumber}&page_size=20&domain=${domainId}&scan_id=${scanId}`
        setIsLoading(true);
        dispatch(getSubdomainList(url))
    },[dispatch, domainId, scanId, pageNumber])

    useEffect(()=>{
        if(domainListResponse !== null && (domainId !== prevDomainIdRef.current || scanId !== prevScanIdRef.current)) {
            let selectedDomain = domainListResponse?.results.filter((data) => data.domain === domainId);
            if(selectedDomain) {
                fetchSubDomainList();
                prevDomainIdRef.current = domainId;
                prevScanIdRef.current = scanId;
            }
        }
    },[domainListResponse, domainId, scanId, fetchSubDomainList])

    useEffect(()=>{
        if(subdomainListResponse !== null) {
            setSelectedSubdomain(subdomainListResponse?.results[0])
            setIsLoading(false);
        }
    },[subdomainListResponse])


    return (
        <>
            <div className="scan-info-main-content">
                <div className="bg-white-with-shadow p-4">
                    <Row>
                        <Col lg={4}>
                            <div className="sub-domain-type-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Subdomain Type</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-records-container">
                                    <p className="content">2.6K <span>Total Records</span></p>
                                </div>
                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <p className="count-type">151 <span>General</span></p>
                                    </Col>
                                    <Col lg={6}>
                                        <p className="count-type">151 <span>Internal</span></p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <p className="count-type">151 <span>QA</span></p>
                                    </Col>
                                    <Col lg={6}>
                                        <p className="count-type">151 <span>Staging</span></p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="discover-doamin-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Subdomain Type</p>
                                </div>
                                <div className="d-flex gap-3">
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-score-count">30.8K</p>
                                            <div className="score-count mx-3">
                                                <span className="new-record d-inline-block mx-1"></span>
                                                <span className="existing-record d-inline-block"></span>
                                                <p>Total Records</p>
                                            </div>
                                        </div>
                                        <div className="new-exist-count d-flex gap-3">
                                            <p className="new-record"><span className="d-inline-block">17</span> New Records</p>
                                            <p className="existing-record"><span className="d-inline-block">16</span> Existing Records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="sub-domain-type-container web-server-type-container rounded p-3">
                                <div className="header-content d-flex justify-content-between">
                                    <p className="heading">Webserver Type</p>
                                    <p className="view-all">View all</p>
                                </div>
                                <div className="total-records-container">
                                    <p className="content">370 <span>Total Records</span></p>
                                </div>
                                <div className="sub-domain-content">
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage} />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div>                                        
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div> 
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="count-type">151</p>
                                                <ProfileAvatar type="domain" name="Lollypop" imageUrl={dummyImage}/>
                                            </div>
                                        </Col>
                                    </Row>                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <div className="bg-white-with-shadow subdomain-list-container h-100">
                                <div className="list-heading px-3 pt-3 d-flex justify-content-between align-items-center">
                                    <h1>Subdomain List</h1>
                                    <img src={filterIcon} alt="filter" className="filter-icon"/>
                                </div>
                                <div className="search-container px-3 position-relative">
                                    <img src={SearchIcon} alt="search" className="position-absolute search-icon"/>
                                    <input type="text" placeholder="Search" className="w-100 rounded input"/>
                                </div>
                                <div className="list-main-container">
                                    {
                                        subdomainListResponse?.results.length > 0 &&
                                        subdomainListResponse?.results.map((data, index) => {
                                            return (
                                                <div className={`sub-domain-list py-2 px-3 ${selectedSubdomain !== "" && selectedSubdomain?.id === data?.id ? "active" : ""}`} key={index} onClick={()=>{setSelectedSubdomain(data)}}>
                                                    <div className="d-flex align-items-center gap-2 mb-2">
                                                        <p className="sub-domain-heading">{data?.subdomain}</p>
                                                        {data.new_record && <span className="new-subdomain">New</span>}
                                                    </div>                                                   
                                                    <p className="sub-domain-name">{data.domain}</p>
                                                    <span className={`subdomain-status ${data?.http_status?.http ? "active" : "inactive"}`}>{data?.http_status?.http ? "HTTP Active" : "HTTP Inactive"}</span>
                                                    <span className={`subdomain-status ${data?.http_status?.https ? "active" : "inactive"}`}>{data?.http_status?.https ? "HTTPS Active" : "HTTPS Inactive"}</span>
                                                    {data?.blacklisted?.is_blacklisted && 
                                                        <div>
                                                            <span className="subdomain-status blacklisted">Blacklisted</span>
                                                            {/* <span className="potential">Potential Takeover</span> */}
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            )
                                        })
                                    }                                                                       
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="bg-white-with-shadow sub-domain-main-container p-3 h-100">
                                <div className="tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "subDomainInformation" ? "active" : ""}`} onClick={()=>{setActiveTab('subDomainInformation')}}>Subdomain Information</p>
                                    <p className={`rounded text-center ${activeTab === "DNSRecordInformation" ? "active" : ""}`} onClick={()=>{setActiveTab('DNSRecordInformation')}}>DNS Record Information</p>
                                    <p className={`rounded text-center ${activeTab === "technologies" ? "active" : ""}`} onClick={()=>{setActiveTab('technologies')}}>Technologies</p>
                                    <p className={`rounded text-center ${activeTab === "SSLCertificate" ? "active" : ""}`} onClick={()=>{setActiveTab('SSLCertificate')}}>SSL Certificate</p>
                                </div>
                                <div className="tab-details-content mt-3">
                                    {activeTab === "subDomainInformation" && <SubDomainInformation selectedSubdomain={selectedSubdomain}/>}
                                    {activeTab === "DNSRecordInformation" && <DNSRecordInformation selectedSubdomain={selectedSubdomain} domainId={domainId} scanId={scanId}/>}
                                    {activeTab === "technologies" &&<Technologies selectedSubdomain={selectedSubdomain} domainId={domainId} scanId={scanId}/>}
                                    {activeTab === "SSLCertificate" && <SSLCertificate sslData={selectedSubdomain} domainId={domainId} scanId={scanId} currentPageType={"subdomain"}/>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>        
    )
}


export default SubDomain;